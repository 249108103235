import * as React from "react";
import { UploadForm } from "./UploadForm";
import ModalWrap from "../common/modal";

export class UploadModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
    };
  }

  componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show() {
    this.setState({
      visible: true,
    });
  }

  hide() {
    this.setState({
      visible: false,
    });
    this.props.onClose();
  }

  render() {
    const cardName = this.props.cardUploadSelectedData.name;
    return (
      <ModalWrap
        visible={this.state.visible}
        closable={this.state.closable}
        title={
          this.props.duplicateCode.length > 0 ? (
            "Duplicate Redeem Code"
          ) : (
            <span>
              Upload <span style={{ fontWeight: "bold" }}>{cardName}</span>{" "}
              Redeem Code
            </span>
          )
        }
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        isLoading={this.state.isLoading}
        width="600px"
      >
        <UploadForm
          data={this.props.cardUploadSelectedData}
          authToken={this.props.authToken}
          onSubmit={this.props.onSubmit}
          handleCancel={this.handleCancel}
          duplicateCode={this.props.duplicateCode}
          date={this.props.date}
          addButtonLoading={this.props.addButtonLoading}
        />
      </ModalWrap>
    );
  }
}
