import { callGetApiToServer } from '../callApi';

export async function listUserCompanyTrans(
    authtoken,
    id,
    skipNumber,
    limitNumber
) {
    try{
      let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "authorization" : "Berear " + authtoken
      }
    
      // let endUrl = "v1/list-company-trn/page/" + id + "/" + skipNumber + "/" + limitNumber;
      let endUrl = "v1/auth/list-user-trn-list/" + id + "/" + skipNumber + "/" + limitNumber;
  
      console.log("endUrl printed here", endUrl);
    
      let responseData = await callGetApiToServer(header, "GET", endUrl);
        
      return responseData;
    }catch(err) {
      throw err;
    }
}