import { SET_DISTRIBUTOR_BALANCE, SET_DISTRIBUTOR_DATA, SET_DISTRIBUTOR_DATA_TOTAL } from "../constant/actionTypes";

const initial_state = {
    distributorData: [],
    totalDistributor: 0,
    distributorBalance:0,

};

export default (state = initial_state, action) => {
    switch (action.type) {

        case SET_DISTRIBUTOR_DATA:
            return { ...state, loading: false, distributorData: action.distributorData };

        case SET_DISTRIBUTOR_DATA_TOTAL:
            return { ...state, loading: false, totalDistributor: action.totalDistributor };

        case SET_DISTRIBUTOR_BALANCE:
            console.log("actt",action.distributorBalance );
            return { ...state, loading: false, distributorBalance: action.distributorBalance }

        default: return { ...state };
    }
}
