import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, TimePicker, Select, Spin } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { WEBURL, color } from "../../../constant/comman";
import { PictureOutlined } from "@ant-design/icons";
import { AddCategoryIcon } from "./AddCategoryIcon";
import * as la from "lodash";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.handleCloseTime = this.handleCloseTime.bind(this);
    this.handleDetailsModalclose = this.handleDetailsModalclose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      locationList: [],
      recordSelectedtoView: null,
      data: [],
      view: false,
      index: null,
      isLoading: true,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: false,
      inputVisible: false,
      inputValue: "",
      time: "",
      closeTime: "",
      closeTimeString: "",
      timeString: "",
      roleType: "1",
      errors: {
        uploadImage: "",
      },
      isIconUpdate: false,
    };
  }

  input;
  allColorType = [];
  selectedColorType = "";
  selctedColorKey = "";
  selectDisplayColorName = "";
  src = "";
  viewModalRef;
  isColorChanged = false;

  componentDidMount() {
    console.log("!!!!!!!!did mount in edit form");
    this.setState({
      isLoading: true,
    });
    la.map(color, (data, index) => {
      this.allColorType.push(
        <Option key={index} value={data.value}>
          <div style={{ display: "inline-block", width: "100%" }}>
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: data.value,
                float: "left",
                borderRadius: "5px",
              }}
            ></div>
            <span
              style={{
                float: "left",
                margin: "0px",
                padding: "0px",
                marginLeft: "10px",
                marginTop: "5px",
                lineHeight: "0px",
              }}
            >
              {data.name}
            </span>
          </div>
        </Option>
      );
    });

    console.log(
      "!!!!!in did",
      this.allColorType,
      color[parseInt(this.props.data.color)].value,
      color[parseInt(this.props.data.color)].name
    );

    this.selectedColorType = color[parseInt(this.props.data.color)].value;
    this.selectDisplayColorName = color[parseInt(this.props.data.color)].name;
    this.selctedColorKey = this.props.data.color;
    this.src =
      this.props.data.icon && this.props.data.icon !== null
        ? this.props.data.icon
        : "";

    this.setState({
      isLoading: false,
    });
  }

  uploadImage = (name) => {
    console.log("!!!!!!!!!!!!!!after upload ", name);
    if (name != null) {
      this.src = name;
      this.setState({
        view: false,
        isIconUpdate: true,
      });
    } else {
      this.setState({
        view: false,
      });
    }
  };

  showViewModal(params) {
    this.setState(
      {
        view: true,
      },
      () => {
        this.viewModalRef.show();
      }
    );
  }

  handleSelectColortype(value, option) {
    this.selectedColorType = value;
    this.selctedColorKey = option.key;
    this.isColorChanged = true;
  }

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  validateForm = (values) => {
    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.name) {
      errors.name = "name is required";
    }

    // if (!values.description) {
    //     errors.description = "description is required";
    // }

    if (this.selectedColorType === "") {
      errors.color = "Color is required";
    }

    if (!(values.sort_order > 0)) {
      errors.country = "Sort Order must be greater than 0";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  };

  handleSubmit = (values, action) => {
    const { errors, isIconUpdate } = this.state;
    let listErrors = { ...errors };

    // if(!this.src || (this.src && this.src === "")){
    //     listErrors.uploadImage = "Image is Required";

    //     this.setState({
    //         errors : listErrors
    //     });

    //     return;
    // }

    // if(this.src){
    //     listErrors.uploadImage = "";

    //     this.setState({
    //       errors : listErrors
    //     });

    // }

    values.color = this.selctedColorKey;
    values.icon = isIconUpdate ? this.src : "";
    values.description = values.description ? values.description : null;

    console.log("Basic Value - ", values, this.props.data.id);

    this.props.onSubmit(values, this.props.data.id);
    this.props.handleCancel();

    this.setState({
      visible: false,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleTime(e, s) {
    this.setState({
      time: e,
      timeString: s,
    });
  }

  handleCloseTime(e, s) {
    this.setState({
      closeTime: e,
      closeTimeString: s,
    });
  }

  handleDetailsModalclose(item) {
    this.setState({
      view: false,
      lat: item.lat,
      long: item.lng,
    });
  }

  render() {
    const { isLoading } = this.state;
    console.log("!!!!!selectDisplayColorName", this.selectDisplayColorName);

    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                name: this.props.data.name,
                description: this.props.data.description,
                color: this.props.data.color,
                sort_order: this.props.data.sort_order,
                arabicName: this.props.data.arabic_name,
                advertisement: this.props.data.advertisement
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return isLoading ? (
                  <div style={{ marginLeft: "20px" }}>
                    <Spin
                      size="large"
                      style={{ width: "100%", margin: "0 auto" }}
                    />
                  </div>
                ) : (
                  <div>
                    <div>
                      <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          Name
                          <Input
                            id="name"
                            placeholder="Country Name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.name && touched.name ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.name}
                            </p>
                          ) : null}
                        </div>
                        <div className="formik-field-right">
                          Arabic Name
                          <Input
                            id="arabicName"
                            name="arabicName"
                            placeholder="Arabic Name"
                            value={values.arabicName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>

                      <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          Description
                          <Input
                            id="description"
                            placeholder="Category Description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.description && touched.description ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.description}
                            </p>
                          ) : null}
                        </div>
                        <div className="formik-field-right">
                          Sort Order
                          <Input
                            id="sort_order"
                            placeholder="Sort Order"
                            type="number"
                            min={0}
                            value={values.sort_order}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.sort_order && touched.sort_order ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.sort_order}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          Color
                          <Field
                            name="color"
                            render={({ field }) => (
                              <Select
                                showSearch
                                value={
                                  this.isColorChanged
                                    ? this.selectedColorType
                                    : this.selectDisplayColorName
                                }
                                defaultValue={values.color}
                                placeholder="Select Color"
                                defaultActiveFirstOption={false}
                                showArrow={true}
                                style={{ width: "100%" }}
                                filterOption={false}
                                onChange={(value) =>
                                  setFieldValue("color", value)
                                }
                                onBlur={() => setFieldTouched("color", true)}
                                onSelect={(value, option) =>
                                  this.handleSelectColortype(value, option)
                                }
                              >
                                {this.allColorType}
                              </Select>
                            )}
                          />
                          {errors.color && touched.color ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.color}
                            </p>
                          ) : null}
                        </div>

                      </div>
                      <div style={{
                        display: "inline-block",
                        width: "100%",
                        marginTop: "20px",
                        alignItems: "center",
                      }}>
                        Advertisement
                        <TextArea
                          name="advertisement"
                          rows={3}
                          value={values.advertisement}
                          placeholder="Enter Advertisement"
                          maxLength={128}
                          onChange={handleChange}
                        />
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          width: "100%",
                          marginTop: "20px",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ float: "left", width: "10%" }}>
                          <PictureOutlined
                            style={{ width: "100%", fontSize: "30px" }}
                          />
                        </div>
                        <Button
                          style={{ width: "90%" }}
                          onClick={this.showViewModal.bind(this)} //this.showViewModal.bind(this)
                        >
                          Choose Category Icon
                        </Button>
                        {this.src ? (
                          <p
                            style={{
                              fontSize: "small",
                              margin: "0",
                              marginLeft: "5px",
                            }}
                          >
                            {this.src}
                          </p>
                        ) : null}
                        {this.state.view ? (
                          <AddCategoryIcon
                            refx={(e) => (this.viewModalRef = e)}
                            onUpload={this.uploadImage}
                          />
                        ) : null}
                      </div>
                      <div width="100%" style={{ marginTop: "10px" }}>
                        {this.state.errors.uploadImage != "" ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.uploadImage}
                          </p>
                        ) : null}
                      </div>
                      <div
                        style={{
                          marhinTop: "20px",
                          width: "100px",
                          backgroundColor:
                            this.selectedColorType !== ""
                              ? this.selectedColorType
                              : "#ffffff",
                          borderRadius: "10px",
                        }}
                      >
                        {this.src && this.src !== "" && (
                          <img
                            src={`${WEBURL}category/images/${this.src}`}
                            alt="CategoryIcon"
                            style={{ width: "100px" }}
                          />
                        )}
                      </div>

                      <Button
                        // type="primary"
                        style={{
                          marginTop: "19px",
                          background: "#5C6AC4",
                          color: "#fff",
                        }}
                        onClick={handleSubmit}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
