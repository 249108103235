import { SET_INVENTORY_BATCH_DATA, SET_INVENTORY_BATCH_TOTAL } from "../constant/actionTypes";

const initial_state = {
    inventoryBatchData: null,
    inventoryBatchTotal: 0,
   
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case SET_INVENTORY_BATCH_DATA:
      return {
        ...state,
        loading: false,
        inventoryBatchData: action.inventoryBatchData,
      };

    case SET_INVENTORY_BATCH_TOTAL:
      return {
        ...state,
        loading: false,
        inventoryBatchTotal: action.inventoryBatchTotal,
      };

    default:
      return { ...state };
  }
}
