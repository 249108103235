import {
    Table,
    Button,
    Switch,
    Popconfirm,
    Tooltip,
    Avatar,
    Input,
    notification,
    Spin,
    Modal,
    Card,
    Tag
  } from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllCompanyPayTrn } from "../../Graphs/CompanyPayTrn/companyPayTrn"; 
import { getAllSearchCompanyCoinTrn, getAllSearchCompanyCoinPayTrn } from "../../Graphs/CompanyTrn/listCompanyTrn";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { UserOutlined, EditOutlined, DeleteOutlined, AccountBookFilled, WalletOutlined } from '@ant-design/icons';
import { FormModal } from './FormModal';
import { alertError } from "../../utils/alert";

var moment = require("moment");
  
const BASE_IMAGE_URL =
    "https://s3.us-east-2.amazonaws.com/cerebrus-consulting/bloodman_user_media/";

class CompanyPayTransactionRecordsTable extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        data: [],
        isLoading: true,
        startIndex: 0,
        limitNumber: 10,
        pagination: {
          pageSize: 10,
          current: 1
        },
        userPermission: {
          delete: false,
          edit: false
        }
      };
      this.handleTableChange = this.handleTableChange.bind(this);
      this.getLimitedPaidTransactions = this.getLimitedPaidTransactions.bind(this);
    }
  
    async componentDidMount() {
      this.setState({
        data: this.props.data,
        isLoading: false,
        userSelectedData: null,
        userTrasactionData: null,
        modal1Visible: false,

      });


    }

    modalRef;
    transactionRef;
    deletePermission = false
    editPermission = false

    async getLimitedPaidTransactions(pagination) {
      const { searchText } = this.props;
      console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
      const start =
        pagination.current * pagination.pageSize - pagination.pageSize;
      const end = pagination.pageSize;
  
      this.setState(
        {
          isLoading: true,
          startIndex: start,
          limitNumber: end
        },
        async () => {  
            if (searchText != null && searchText != "") {
                //get search text props from redux store
                this.listCompanySearchTrnData(start, end);
            } else {
                //call normal user Data
                this.listCompanyTrnData(start, end);
            }
        }
      );
    }
    
    listCompanyTrnData = async (start, end) => {
        try{
            const { authToken, history, setLoginFlag, setUserData, setUserToken, setListCompanyTrnData, setListCompanyTrnDataTotal, loginUserData } = this.props;
            this.setState({
                isLoading : true
            });

            let companyData = await getAllCompanyPayTrn(authToken, start, end, loginUserData.id);

            if(companyData.status == 200){
                //set user Data
                await setListCompanyTrnData(companyData.data);
                await setListCompanyTrnDataTotal(companyData.total);

                this.setState({
                    isLoading : false,
                    data : companyData.data
                });
                
            }else if (companyData.status == 401) {
                await setLoginFlag(false);
                await setUserData(null);
                await setUserToken(null);
                history.push(`${process.env.PUBLIC_URL}/login`);
            }  else {
              throw (companyData && companyData.message) ? companyData.message : "Error";
            }

        }catch(e) {
            alertError('Company',e);
        } finally {
            this.setState({
                isLoading : false
            });
        }
    }

    listCompanySearchTrnData = async (start, end) => {
        try{
            const { authToken, history, setLoginFlag, setUserData, setUserToken, setListCompanyTrnData, setListCompanyTrnDataTotal, loginUserData, searchText } = this.props;
            this.setState({
                isLoading : true
            });

            let companyData = await getAllSearchCompanyCoinPayTrn(authToken, loginUserData.id, start, end, searchText);

            if(companyData.status == 200){
                //set user Data
                await setListCompanyTrnData(companyData.data);
                await setListCompanyTrnDataTotal(companyData.total);

                this.setState({
                    isLoading : false,
                    data : companyData.data
                });
                
            }else if (companyData.status == 401) {
                await setLoginFlag(false);
                await setUserData(null);
                await setUserToken(null);
                history.push(`${process.env.PUBLIC_URL}/login`);
            }  else {
              throw (companyData && companyData.message) ? companyData.message : "Error";
            }

        }catch(e) {
            alertError('Company',e);
          
        } finally {
            this.setState({
                isLoading : false
            });
        }
    }
  
    dateFormat(date) {
      var oldDate = new Date(),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.add(2, 'hours').format("Do MMM YYYY");
  
      return newDate;
    }

    // showModal(item) {
    //   this.setState(
    //     {
    //       userSelectedData: item.answer_form
    //       // isOpen: true
    //     },
    //     () => {
    //       this.modalRef.show();
    //     }
    //   );
    // }

    showUserCoinstModal(item) {
      this.setState(
        {
          userSelectedDataForCoin: item
          // isOpen: true
        },
        () => {
          this.modalUserCoinsRef.show();
        }
      );
    }
  
    handleDetailsModalclose() {
      this.setState({
        userSelectedData: null
      });
    }

    showUserTransactions = (item) => {
      this.setState(
        {
          userTrasactionData: item
        },
        () => {
          this.transactionRef.show();
        }
      );
    }

    showModal(item) {
      this.setState(
        {
          userSelectedData: JSON.parse(item.answer_form)
        },
        () => {
          this.modalRef.show();
        }
      );
    }
    
    handleDeleteTransactionModalclose() {
      this.setState({
        userTrasactionData: null
      });
    }
  
    counter = 0;
    tempCounter = 0;
    columns = [
        {
            title: "Sr. No.",
            key: "serial_number",
            fixed : true,
            render: (text , item , index) => {
                return (
                <div>
                    <span>{++index + this.tempCounter}</span>
                </div>
                );
            }
        },
        {
            title: "Coin",
            key: "coin",
            render: (text, item, index) => {
                console.log("coin record --> ", item);
                return (
                <div>
                    <div style={{ alignItems: "center", display : "inline-block", width : "100%" }}>
                    <div flexDirection="column">
                        <strong>
                        {" "}
                        <Tooltip placement="bottom" title="Coin Name">
                            <div>
                                { item.coin && item.coin.name? item.coin.name: "-" }
                            </div>
                        </Tooltip>
                        </strong>
                    </div>
                    </div>
                </div>
                );
            }
        },
        {
            title: "Type",
            key: "Type",
            render: (text, item, index) => {
                console.log("coin record --> ", item);
                return (
                <div>
                    <div style={{ alignItems: "center", display : "inline-block", width : "100%" }}>
                    <div flexDirection="column">
                        <strong>
                        {" "}
                        <Tooltip placement="bottom" title="Coin Name">
                            <div>
                                { item.type ? 
                                    <Tag color={item.type == 'COMPLETED'?'green':'red'} key={'loser'}>
                                        {item.type.toUpperCase()}
                                    </Tag>
                                   : "-" 
                                }
                            </div>
                        </Tooltip>
                        </strong>
                    </div>
                    </div>
                </div>
                );
            }
        },
        {
            title: "Price",
            key: "Price",
            render: (text, item, index) => {
                console.log("user record --> ", item);
                return (
                <div>
                    <div style={{ alignItems: "center", display : "inline-block", width : "100%" }}>
                    <div flexDirection="column">
                        <strong>
                        {" "}
                        <Tooltip placement="bottom" title="Price">
                            <div>
                                { item.price !== null? item.price: "-" }
                            </div>
                        </Tooltip>
                        </strong>
                    </div>
                    </div>
                </div>
                );
            }
        },
        {
            title: "CreatedAt",
            key: "CreatedAt",
            render: (text, item, index) => {
                console.log("user record --> ", item);
                return (
                <div>
                    <div style={{ alignItems: "center", display : "inline-block", width : "100%" }}>
                    <div flexDirection="column">
                        <strong>
                        {" "}
                        <Tooltip placement="bottom" title="CreatedAt">
                            <div>
                                { item.createdAt !== null ? this.dateFormat(item.createdAt) : "-" }
                            </div>
                        </Tooltip>
                        </strong>
                    </div>
                    </div>
                </div>
                );
            }
        },
    ];
  
    handleTableChange(pagination, filters) {
      console.log("outer onchange iusers", pagination);
      this.setState(
        {
          pagination
        },
        () => this.getLimitedPaidTransactions(pagination)
      );
    }
  
    setModal1Visible(modal1Visible) {
      this.setState({ modal1Visible });
    }

    render() {
      const { isLoading } = this.state;
      const { listCompanyTrnData, listCompanyTrnDataTotal, searchLoader, authToken } = this.props;

      return isLoading || searchLoader ? 
      (  
        <div style={{ marginLeft: "20px" }}>
           <Spin
            size="large"
            style={{ marginLeft: "480px", marginTop: "130px" }}
          />
        </div> 
      )
      : (
        <div style={{ "overflow-x": "auto" }}>
          <Table
            loading={this.state.isLoading}
            rowKey={record => record._id}
            columns={this.columns}
            size={"small"}
            // dataSource={this.state.data}
            dataSource={listCompanyTrnData}
            pagination={{
              total: listCompanyTrnDataTotal,
              showSizeChanger: true,
              pageSize: this.state.pagination.pageSize,
              current: this.state.pagination.current,
              pageSizeOptions: ["1", "10", "25", "50", "100"],
              onChange: (e) => {
                console.log("onChanges pagination");
                // e - 1
                //   ? (this.counter = this.tempCounter =
                //       (e - 1) * this.state.pagination.pageSize)
                //   : ((this.counter = 0), (this.tempCounter = 0));
                if(e-1){
                  this.counter=this.tempCounter = (e - 1) * this.state.pagination.pageSize;
                  return;
                }

                this.counter = 0;
                this.tempCounter = 0;
              }
            }}
            onChange={this.handleTableChange}
          />
            
        </div>
      );
    }
}
  
const mapStateToProps = state => ({
    authToken: state.auth.authToken,
    listCompanyTrnData: state.company_trn.listCompanyTrnData,
    listCompanyTrnDataTotal: state.company_trn.listCompanyTrnDataTotal,
    searchLoader: state.auth.searchLoader,
    searchText: state.auth.searchText,
    loginUserData: state.auth.loginUserData
});
  
const mapDispatchToProps = (dispatch) => {
    return {
        setLoginFlag: (flag) => {
            dispatch({
                type: 'SET_LOGIN_FLAG',
                flag: flag
            });
        },
        setUserData: (companyData) => {
            dispatch({
                type: 'SET_COMPANY_DATA',
                companyData: companyData
            });
        },
        setUserToken: (authToken) => {
            dispatch({
                type: 'SET_COMPANY_AUTHTOKEN',
                authToken: authToken
            });
        },
        setListCompanyTrnData: (listCompanyTrnData) => {
            dispatch({
                type : "SET_LIST_COMPANY_TRN_DATA",
                listCompanyTrnData : listCompanyTrnData
            })
        },
        setListCompanyTrnDataTotal: (listCompanyTrnDataTotal) => {
              dispatch({
                  type : "SET_LIST_COMPANY_TRN_DATA_TOTAL",
                  listCompanyTrnDataTotal : listCompanyTrnDataTotal
              })
          },
      };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompanyPayTransactionRecordsTable));