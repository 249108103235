import { ENDPOINT } from "../../network/ENDPOINT";
import { callGetApiToServer } from "../callApi";

export async function getSalesUserOutStandingBalance(authtoken, salesmanId,city) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let endUrl = `${ENDPOINT.sales_user_outstanding_report}?salesman_id=${salesmanId}`;
    if (city) {
      endUrl += endUrl.includes('?') ? `&city=${city}` : `?city=${city}`;
    }

    let responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
