import * as React from "react";
import { Modal, Spin, notification } from "antd";
import { AddUserCoinForm } from "./AddUserCoinForm";
import { getAllCoin } from "../../Graphs/Master/Coin/listCoin";
import { addUserCoin } from "../../Graphs/UserCoin/addUserCoin";
import { addUserCoinByDistributor } from "../../Graphs/distributor/addUserCoinByDistributor";
import { getDistributorBalance } from "../../Graphs/distributor/showDistributorBalance";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { alertError } from "../../utils/alert";
export class AddUserCoinModal extends React.Component {
  constructor(props) {
    super(props);
    console.log("AddUserCoinModal", props);
    this.state = {
      visible: false,
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
      coinsList: [],
    };
    this.addSubmit = this.addSubmit.bind(this);
  }

  async componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }

    await this.getCoinsList();
  }

  addSubmit = async (body) => {
    const { setDistributorBalance } = this.props;
    console.log("setDistributorBalance", this.props);
    try {
      this.setState({
        isLoading: true,
      });

      let createObject = body;

      let coinData =
        this.props.userSelectedData.loginBy == "Distributor"
          ? await addUserCoinByDistributor(this.props.authToken, createObject)
          : await addUserCoin(this.props.authToken, createObject);

      let balanceData =
        this.props.userSelectedData.loginBy == "Distributor" &&
        (await getDistributorBalance(this.props.authToken));

      if (balanceData.status == 200) {
        if (balanceData) {
          console.log("balanceData ree", balanceData.data);
          setDistributorBalance(balanceData.data.total_balance);
        }
      }

      if (coinData.status == 200) {
        this.setState({
          isLoading: true,
        });

        notification["success"]({
          message: "Coin",
          description: "Successfully Added",
        });
        this.props.reload(0, 10);

        return 1;
      } else if (coinData.status == 400) {
        alertError("COIN", coinData.message);
      } else {
        throw coinData.message ? coinData.message : "Error";
      }
    } catch (error) {
      this.setState({
        isLoading: false,
      });

      alertError("COIN", error);
      return -1;
    }
  };

  getCoinsList = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      let coinData = await getAllCoin(this.props.authToken, 0, 100000);
      this.setState({
        coinsList: coinData.data,
      });
    } catch (error) {
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show() {
    this.setState({
      visible: true,
    });
  }

  hide() {
    this.setState({
      visible: false,
    });
    this.props.onClose();
  }

  render() {
    return (
      // <StyleBox>
      <Modal
        width="600px"
        closable={this.state.closable}
        open={this.state.visible}
        title="Add User Coin"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        {this.state.isLoading ? (
          <div style={{ justifyContent: "center" }}>
            <Spin />
          </div>
        ) : (
          <AddUserCoinForm
            coinsData={this.state.coinsList}
            data={this.props.userSelectedData}
            onSubmit={this.addSubmit}
            handleCancel={this.handleCancel}
          />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
});

export default connect(mapStateToProps)(withRouter(AddUserCoinModal));
