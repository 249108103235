import { callApiToServer } from '../../callApi';

export async function addCityAPI(
  authtoken,
  cityObject,
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    };
      
    let body = JSON.stringify({
        name:cityObject.city_name,
        arabic_name:cityObject.city_arrabic_name,
        price_percentage:parseInt(cityObject.extra_price)
    });
  
    let endUrl = "v1/auth/add-city";
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callApiToServer(body, header, "POST", endUrl);
      
    return responseData;
      
  }catch(err) {
    throw err;
  }
}
