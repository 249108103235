/* eslint-disable */

import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
} from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllCoin } from "../../../Graphs/Master/Coin/listCoin";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { UserOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { deleteCoin } from "../../../Graphs/Master/Coin/deleteCoin";
import { changeStatus } from "../../../Graphs/Master/Coin/changeStatus";
import { editCoin } from "../../../Graphs/Master/Coin/editCoin";
import { addCoin } from "../../../Graphs/Master/Coin/addCoin";
import { searchCoin } from "../../../Graphs/Master/Coin/searchCoin";
import { EditModal } from "./EditModal";
import { AddCoinModal } from "./AddCoinModal";
import TableWrap from "../../common/Table";

var moment = require("moment");

const BASE_IMAGE_URL =
  "https://s3.us-east-2.amazonaws.com/cerebrus-consulting/bloodman_user_media/";

class CoinRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedCoin = this.getLimitedCoin.bind(this);
  }

  async componentDidMount() {
    const { setRouteName } = this.props;

    let permission = JSON.parse(this.props.loginUserData.access_permission);
    this.setState({
      userPermission: {
        edit:
          (permission && permission["master"] && permission["master"].edit) ||
          false,
        delete:
          (permission && permission["master"] && permission["master"].delete) ||
          false,
      },
    });

    setRouteName("COIN");
    this.setState({
      isLoading: true,
      coinSelectedData: null,
    });
    await this.listCoinData(0, 10);
  }

  modalRef;

  async getLimitedCoin(pagination) {
    const { searchText } = this.props;
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText != "") {
          //get search text props from redux store
          this.listSearchCoinData(start, end);
        } else {
          //call normal user Data
          this.listCoinData(start, end);
        }
      }
    );
  }

  listSearchCoinData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCoinData,
        setListCoinDataTotal,
        searchText,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let coinData = await searchCoin(authToken, searchText, start, end);

      if (coinData.status == 200) {
        //set user Data
        await setListCoinData(coinData.data);
        await setListCoinDataTotal(coinData.total);

        this.setState({
          isLoading: false,
          data: coinData.data,
        });
      } else if (coinData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw coinData && coinData.message ? coinData.message : "Error";
      }
    } catch (e) {
      alertError("COIN", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listCoinData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCoinData,
        setListCoinDataTotal,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let coinData = await getAllCoin(authToken, start, end);

      if (coinData.status == 200) {
        //set user Data
        console.log("!!!!!!!coinData", coinData);
        await setListCoinData(coinData.data);
        await setListCoinDataTotal(coinData.total);

        this.setState({
          isLoading: false,
          data: coinData.data,
        });
      } else if (coinData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw coinData && coinData.message ? coinData.message : "Error";
      }
    } catch (e) {
      alertError("COIN", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.add(2, "hours").format("Do MMM YYYY");

    return newDate;
  }

  showModal(item) {
    this.setState(
      {
        coinSelectedData: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  handleDetailsModalclose() {
    this.setState({
      coinSelectedData: null,
    });
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Balance Type",
      key: " Balance type",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                  <div>{item.name ? item.name : ""}</div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },

    {
      title: "Actions",
      width: 130,
      key: "action",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            {this.props.loginUserData.role == "Admin" ||
            this.state.userPermission.edit ? (
              <div mr="5px" style={{ float: "left" }}>
                <Tooltip placement="bottom" title="Edit">
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      console.log("isOpen value --- ", this.state.isOpen);
                      this.showModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];

  async deleteCoin(item) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
        setListCoinData,
        setListCoinDataTotal,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let deleteData = await deleteCoin(
        authToken,
        item.id,
        startIndex,
        limitNumber,
        searchText
      );

      if (deleteData.status == 200) {
        notification["success"]({
          message: "Delete Coin",
          description: "Successfully deleted",
        });
        await setListCoinData(deleteData.data);
        await setListCoinDataTotal(deleteData.total);
        //set user Data
        this.setState({
          isLoading: false,
          data: deleteData.data,
        });
      } else if (deleteData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw deleteData && deleteData.message ? deleteData.message : "Error";
      }
    } catch (e) {
      alertError("COIN", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onChange(item, checked) {
    try {
      console.log("!!!!!!!!", checked);

      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeStatus(authToken, item.id, checked);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Change Coin Status",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText != "") {
          this.listSearchCoinData(startIndex, limitNumber);
          return;
        }
        this.listCoinData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (err) {
      alertError("COIN", err);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onGccChange(item, checked) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeGccStatus(authToken, item.id, checked);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Change Coin Gcc Status",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText != "") {
          this.listSearchCoinData(startIndex, limitNumber);
          return;
        }
        this.listCoinData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (err) {
      alertError("COIN", err);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }
  addSubmit = async (coinObject) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await addCoin(authToken, coinObject);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Add Coin",
          description: "Successfully added",
        });
        if (searchText != null && searchText != "") {
          this.listSearchCoinData(startIndex, limitNumber);
          return;
        }
        this.listCoinData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      alertError("COIN", e);
    }
  };

  editSubmit = async (coinObject, coinId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await editCoin(authToken, coinObject, coinId);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Edit Coin",
          description: "Successfully edited",
        });
        if (searchText != null && searchText != "") {
          this.listSearchCoinData(startIndex, limitNumber);
          return;
        }
        this.listCoinData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      alertError("COIN", e);
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedCoin(pagination)
    );
  }

  render() {
    const { isLoading } = this.state;
    const {
      listCoinData,
      listCoinDataTotal,
      searchLoader,
      accessPermission,
      loginUserData,
    } = this.props;

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ display: "inline-block", width: "100%" }}>
        <div style={{ float: "left", cursor: "pointer", width: "100%" }}></div>
        <div
          style={{
            overflowX: "auto",
            float: "left",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <TableWrap
            data={listCoinData}
            columns={this.columns}
            isLoading={this.state.isLoading}
            total={listCoinDataTotal}
            pagination={this.state.pagination}
            handleTableChange={this.handleTableChange}
          />

          {this.state.coinSelectedData ? (
            <EditModal
              admin={false}
              refx={(e) => (this.modalRef = e)}
              onClose={this.handleDetailsModalclose.bind(this)}
              coinSelectedData={this.state.coinSelectedData}
              onSubmit={this.editSubmit}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listCoinData: state.master.listCoinData,
  listCoinDataTotal: state.master.listCoinDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCoinData: (listCoinData) => {
      dispatch({
        type: "SET_LIST_COIN_DATA",
        listCoinData: listCoinData,
      });
    },
    setListCoinDataTotal: (listCoinDataTotal) => {
      dispatch({
        type: "SET_LIST_COIN_DATA_TOTAL",
        listCoinDataTotal: listCoinDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CoinRecordsTable));
