import { Switch, Button, Divider, Checkbox, Row, Col } from "antd";
import * as React from "react";
import { Layout, Input, TimePicker, Select } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { errorMessages } from "../../utils/validationMessages";
import {
  emailValidation,
  mobileLengthValidation,
  mobileValidation,
} from "../../utils/validations";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

const roles = [
  { label: "Admin", value: "Admin" },
  { label: "Employee", value: "Employee" },
  { label: "Accountant", value: "Accountant" },
];
export class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.handleCloseTime = this.handleCloseTime.bind(this);
    this.handleDetailsModalclose = this.handleDetailsModalclose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      locationList: [],
      recordSelectedtoView: null,
      data: [],
      view: false,
      index: null,
      isLoading: false,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: false,
      inputVisible: false,
      inputValue: "",
      time: "",
      closeTime: "",
      closeTimeString: "",
      timeString: "",
      roleType: "1",
      role: roles,
      dashboard: {
        view: false,
        edit: false,
        delete: false,
      },
      master: {
        view: false,
        edit: false,
        delete: false,
      },
      card: {
        view: false,
        edit: false,
        delete: false,
      },
      user: {
        view: false,
        edit: false,
        delete: false,
      },
      sales: {
        view: false,
        edit: false,
        delete: false,
      },
      ticketSupport: {
        view: false,
        edit: false,
        delete: false,
      },
      expenses: {
        view: false,
        edit: false,
        delete: false,
      },
      inventoryBatch: {
        view: false,
        edit: false,
        delete: false,
      },
      defaultDashboard: [],
      defaultUser: [],
      defaultCard: [],
      defaultMaster: [],
      defaultBalanceRequest: [],
      defaultCompany: [],
      defaultReport: [],
      defaultSales: [],
      defaultTicketSupport: [],
      defaultExpenses: [],
      defaultInventoryBatch:[],
      update_permission: null,
    };
  }

  input;

  componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }
    // console.log("component did mount");

    let access = JSON.parse(this.props.data.access_permission);
    this.setState(
      {
        dashboard: access.dashboard ? access.dashboard : {},
        master: access.master ? access.master : {},
        card: access.card ? access.card : {},
        user: access.user ? access.user : {},
        balanceRequest: access.balanceRequest ? access.balanceRequest : {},
        company: access.company ? access.company : {},
        reports: access.reports ? access.reports : {},
        sales: access.sales ? access.sales : {},
        ticketSupport: access.ticketSupport ? access.ticketSupport : {},
        expenses: access.expenses ? access.expenses : {},
        inventoryBatch:access.inventoryBatch?access.inventoryBatch:{},
      },
      () => {
        let defaultVal = {
          dashboard: [],
          master: [],
          card: [],
          user: [],
          balanceRequest: [],
          company: [],
          reports: [],
          sales: [],
          ticketSupport: [],
          expenses: [],
          inventoryBatch:[]
        };

        for (let k of [
          "dashboard",
          "master",
          "card",
          "user",
          "balanceRequest",
          "company",
          "reports",
          "sales",
          "ticketSupport",
          "expenses",
          "inventoryBatch"
        ]) {
          if (access[k]) {
            if (access[k].view == true) {
              defaultVal[k].push("view");
            }
            if (access[k].edit == true) {
              defaultVal[k].push("edit");
            }
            if (access[k].delete == true) {
              defaultVal[k].push("delete");
            }
          }
        }
        this.setState(
          {
            defaultDashboard: defaultVal.dashboard ? defaultVal.dashboard : [],
            defaultMaster: defaultVal.master ? defaultVal.master : [],
            defaultCard: defaultVal.card ? defaultVal.card : [],
            defaultUser: defaultVal.user ? defaultVal.user : [],
            defaultBalanceRequest: defaultVal.balanceRequest
              ? defaultVal.balanceRequest
              : [],
            defaultCompany: defaultVal.company ? defaultVal.company : [],
            defaultReport: defaultVal.reports ? defaultVal.reports : [],
            defaultSales: defaultVal.sales ? defaultVal.sales : [],
            defaultTicketSupport: defaultVal.ticketSupport
              ? defaultVal.ticketSupport
              : [],
            defaultExpenses: defaultVal.expenses ? defaultVal.expenses : [],
            defaultInventoryBatch: defaultVal.inventoryBatch ? defaultVal.inventoryBatch : [],
          },

          () => {
            console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!", this.state);
          }
        );
      }
    );
    // this.setState({
    //     admin: this.props.data.role == "Admin" ? true : false
    // })
  }

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  onChangeCheckBox = (data, key) => {
    console.log(data);
    let obj = {
      update_permission: true,
    };

    obj[key] = {
      view: data.includes("view"),
      edit: data.includes("edit"),
      delete: data.includes("delete"),
    };

    console.log("!!!!!!!!checkbox", key, data);

    if (key == "dashboard") {
      this.setState({ defaultDashboard: data });
    } else if (key == "master") {
      console.log("!!!!!!!!master", key, data);
      this.setState({ defaultMaster: data });
    } else if (key == "card") {
      this.setState({ defaultCard: data });
    } else if (key == "user") {
      this.setState({ defaultUser: data });
    } else if (key == "balanceRequest") {
      this.setState({ defaultBalanceRequest: data });
    } else if (key == "company") {
      this.setState({ defaultCompany: data });
    } else if (key == "reports") {
      this.setState({ defaultReport: data });
    } else if (key == "sales") {
      this.setState({ defaultSales: data });
    } else if (key == "ticketSupport") {
      this.setState({ defaultTicketSupport: data });
    } else if (key == "expenses") {
      this.setState({ defaultExpenses: data });
    } else if (key == "inventoryBatch") {
      this.setState({ defaultInventoryBatch: data });
    }

    this.setState(obj);
  };

  validateForm(values) {
    const errors = {};

    if (!values.first_name) {
      errors.first_name = errorMessages.first_name_required;
    }
    if (!values.last_name) {
      errors.last_name = errorMessages.last_name_required;
    }

    if (!values.phone) {
      errors.phone = errorMessages.mobile_required;
    } else if (mobileLengthValidation(values.phone)) {
      errors.phone = errorMessages.mobile_length_validation;
    } else if (mobileValidation(values.phone)) {
      errors.phone = errorMessages.mobile_validation;
    }

    return errors;
  }

  handleSubmit(values, action) {
    console.log("Basic Value - ", values, this.props.data.id);
    console.log("all vcal", values);

    const {
      dashboard,
      user,
      master,
      card,
      balanceRequest,
      company,
      reports,
      sales,
      ticketSupport,
      expenses,
      inventoryBatch,
      update_permission,
    } = this.state;

    let access = {
      dashboard: dashboard,
      user: user,
      master: master,
      card: card,
      balanceRequest: balanceRequest,
      company: company,
      reports: reports,
      sales: sales,
      ticketSupport: ticketSupport,
      expenses: expenses,
      inventoryBatch: inventoryBatch,
    };

    values.access_permission = JSON.stringify(access);
    values.employeeId = this.props.data.id;

    values.update_permission = update_permission != null ? true : undefined;

    this.props.onSubmit(values);

    this.props.handleCancel();

    this.setState({
      visible: false,
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleTime(e, s) {
    this.setState({
      time: e,
      timeString: s,
    });
  }

  handleCloseTime(e, s) {
    this.setState({
      closeTime: e,
      closeTimeString: s,
    });
  }

  handleDetailsModalclose(item) {
    this.setState({
      view: false,
      lat: item.lat,
      long: item.lng,
    });
  }

  handleSelectRole(value) {
    console.log("value", value);
    this.setState({
      update_permission: true,
      dashboard: {
        view: true,
        edit: true,
        delete: true,
      },
      master: {
        view: true,
        edit: true,
        delete: true,
      },
      card: {
        view: true,
        edit: true,
        delete: true,
      },
      user: {
        view: true,
        edit: true,
        delete: true,
      },
      balanceRequest: {
        view: true,
        edit: true,
        delete: true,
      },
      company: {
        view: true,
        edit: true,
        delete: true,
      },
      reports: {
        view: true,
        edit: true,
        delete: true,
      },
      sales: {
        view: true,
        edit: true,
        delete: true,
      },
      ticketSupport: {
        view: true,
        edit: true,
        delete: true,
      },
      expenses: {
        view: true,
        edit: true,
        delete: true,
      },
      inventoryBatch: {
        view: true,
        edit: true,
        delete: true,
      },
    });
  }

  render() {

    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                first_name: this.props.data.first_name,
                last_name: this.props.data.last_name,
                email: this.props.data.email,
                phone: this.props.data.phone,
                referal_code: this.props.data.referal_code,
                role: this.props.data.role,
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        First Name
                        <Input
                          id="first_name"
                          placeholder="First Name"
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.first_name && touched.first_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.first_name}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Last Name
                        <Input
                          id="last_name"
                          placeholder="Last Name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.last_name && touched.last_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.last_name}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Phone
                        <Input
                          id="phone"
                          placeholder="User Phone"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.phone && touched.phone ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.phone}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Email
                        <Input
                          id="email"
                          placeholder="Email"
                          disabled={true}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      Role
                      <br />
                      <Select
                        value={values.role}
                        style={{ width: 200 }}
                        id="role"
                        name="role"
                        placeholder="Select Role"
                        onChange={(value) => {
                          setFieldValue("role", value);
                        }}
                        onBlur={() => setFieldTouched("role", true)}
                        onSelect={(value) => {
                          this.handleSelectRole(value);
                        }}
                        options={this.state.role}
                      ></Select>
                    </div>

                    <hr />
                    <center>Access Permission</center>
                    <hr />
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        <hr />
                        Dashboard
                        <hr />
                        <Checkbox.Group
                          value={this.state.defaultDashboard}
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            this.onChangeCheckBox(e, "dashboard")
                          }
                        >
                          <Row>
                            <Col span={7}>
                              <Checkbox value="view">View</Checkbox>
                            </Col>
                            <Col span={7}>
                              <Checkbox value="edit">Edit</Checkbox>
                            </Col>
                            <Col span={10}>
                              <Checkbox value="delete">Delete</Checkbox>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </div>
                      <div className="formik-field-right">
                        <hr />
                        User
                        <hr />
                        <Checkbox.Group
                          value={this.state.defaultUser}
                          defaultValue={this.state.defaultUser}
                          style={{ width: "100%" }}
                          onChange={(e) => this.onChangeCheckBox(e, "user")}
                        >
                          <Row>
                            <Col span={7}>
                              <Checkbox value="view">View</Checkbox>
                            </Col>
                            <Col span={7}>
                              <Checkbox value="edit">Edit</Checkbox>
                            </Col>
                            <Col span={10}>
                              <Checkbox value="delete">Delete</Checkbox>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </div>
                    </div>

                    <br />

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        <hr />
                        Card
                        <hr />
                        <Checkbox.Group
                          value={this.state.defaultCard}
                          style={{ width: "100%" }}
                          onChange={(e) => this.onChangeCheckBox(e, "card")}
                        >
                          <Row>
                            <Col span={7}>
                              <Checkbox value="view">View</Checkbox>
                            </Col>
                          
                            
                                <Col span={7}>
                                  <Checkbox value="edit">Edit</Checkbox>
                                </Col>
                                <Col span={10}>
                                  <Checkbox value="delete">Delete</Checkbox>
                                </Col>
                              
                            
                          </Row>
                        </Checkbox.Group>
                      </div>
                     
                     
                      <div className="formik-field-right">
                        <hr />
                        Balance Request
                        <hr />
                        <Checkbox.Group
                          value={this.state.defaultBalanceRequest}
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            this.onChangeCheckBox(e, "balanceRequest")
                          }
                        >
                          <Row>
                            <Col span={7}>
                              <Checkbox value="view">View</Checkbox>
                            </Col>
                            <Col span={7}>
                              <Checkbox value="edit">Edit</Checkbox>
                            </Col>
                            <Col span={10}>
                              <Checkbox value="delete">Delete</Checkbox>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </div>
                     
                     
                     
                     
                     
                    </div>
                    {this.props.loginUserData.role != "Sub_admin" && (
                    <div className="formik-field_wrap">
                
                   
                        <div className="formik-field-right">
                          <hr />
                          Master
                          <hr />
                          <Checkbox.Group
                            style={{ width: "100%" }}
                            value={this.state.defaultMaster}
                            onChange={(e) => this.onChangeCheckBox(e, "master")}
                          >
                            <Row>
                              <Col span={7}>
                                <Checkbox value="view">View</Checkbox>
                              </Col>
                              <Col span={7}>
                                <Checkbox value="edit">Edit</Checkbox>
                              </Col>
                              <Col span={10}>
                                <Checkbox value="delete">Delete</Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </div>
                    
                   
                        <div className="formik-field-left">
                          <hr />
                          Company
                          <hr />
                          <Checkbox.Group
                            value={this.state.defaultCompany}
                            defaultValue={this.state.defaultUser}
                            style={{ width: "100%" }}
                            onChange={(e) =>
                              this.onChangeCheckBox(e, "company")
                            }
                          >
                            <Row>
                              <Col span={7}>
                                <Checkbox value="view">View</Checkbox>
                              </Col>
                              <Col span={7}>
                                <Checkbox value="edit">Edit</Checkbox>
                              </Col>
                              <Col span={10}>
                                <Checkbox value="delete">Delete</Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </div>
                    </div>
                      )}

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        <hr />
                        Report
                        <hr />
                        <Checkbox.Group
                          value={this.state.defaultReport}
                          style={{ width: "100%" }}
                          onChange={(e) => this.onChangeCheckBox(e, "reports")}
                        >
                          <Row>
                            <Col span={7}>
                              <Checkbox value="view">View</Checkbox>
                            </Col>
                            <Col span={7}>
                              <Checkbox value="edit">Edit</Checkbox>
                            </Col>
                            <Col span={10}>
                              <Checkbox value="delete">Delete</Checkbox>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </div>
                      <div className="formik-field-right">
                        <hr />
                        Sales
                        <hr />
                        <Checkbox.Group
                          value={this.state.defaultSales}
                          style={{ width: "100%" }}
                          onChange={(e) => this.onChangeCheckBox(e, "sales")}
                        >
                          <Row>
                            <Col span={7}>
                              <Checkbox value="view">View</Checkbox>
                            </Col>
                            <Col span={7}>
                              <Checkbox value="edit">Edit</Checkbox>
                            </Col>
                            <Col span={10}>
                              <Checkbox value="delete">Delete</Checkbox>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        <hr />
                        Ticket Support
                        <hr />
                        <Checkbox.Group
                          value={this.state.defaultTicketSupport}
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            this.onChangeCheckBox(e, "ticketSupport")
                          }
                        >
                          <Row>
                            <Col span={7}>
                              <Checkbox value="view">View</Checkbox>
                            </Col>
                            <Col span={7}>
                              <Checkbox value="edit">Edit</Checkbox>
                            </Col>
                            <Col span={10}>
                              <Checkbox value="delete">Delete</Checkbox>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </div>
                      <div className="formik-field-right">
                        <hr />
                        Expenses
                        <hr />
                        <Checkbox.Group
                          value={this.state.defaultExpenses}
                          style={{ width: "100%" }}
                          onChange={(e) => this.onChangeCheckBox(e, "expenses")}
                        >
                          <Row>
                            <Col span={7}>
                              <Checkbox value="view">View</Checkbox>
                            </Col>
                            <Col span={7}>
                              <Checkbox value="edit">Edit</Checkbox>
                            </Col>
                            <Col span={10}>
                              <Checkbox value="delete">Delete</Checkbox>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </div>
                    </div>
                    <div className="formik-field_wrap">
                            <div className="formik-field-left">
                              <hr />
                              Inventory Batch
                              <hr />
                              <Checkbox.Group
                                value={this.state.defaultInventoryBatch}
                                style={{ width: "100%" }}
                                onChange={(e) =>
                                  this.onChangeCheckBox(e, "inventoryBatch")
                                }
                              >
                                <Row>
                                  <Col span={7}>
                                    <Checkbox value="view">View</Checkbox>
                                  </Col>
                                  <Col span={7}>
                                    <Checkbox value="edit">Edit</Checkbox>
                                  </Col>
                                  <Col span={10}>
                                    <Checkbox value="delete">Delete</Checkbox>
                                  </Col>
                                </Row>
                              </Checkbox.Group>
                            </div>
                    
                          </div>
                    <Button
                      // type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Update
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
