import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, TimePicker, Select, DatePicker } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;
var amenities = [];
const dateFormat = "YYYY-MM-DD";

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class AddTransactionDate extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDetailsModalclose = this.handleDetailsModalclose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      locationList: [],
      recordSelectedtoView: null,
      data: [],
      view: false,
      index: null,
      isLoading: false,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: false,
      inputVisible: false,
      inputValue: "",
      time: "",
      closeTime: "",
      closeTimeString: "",
      timeString: "",
      roleType: "1",
      startDate: "",
      endDate: "",
      errors: {
        date: "",
      },
    };
  }

  input;

  componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }
  }

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  validateForm = (values) => {
    const errors = {};
    console.log("Validating form -- ", values);

    console.log("Validating errors -- ", errors);
    return errors;
  };

  handleSubmit = (values, action) => {
    console.log("Basic Value - ", values);
    const { errors } = this.state;
    let listErrors = { ...errors };

    if (this.state.startDate === "") {
      listErrors.date = "Start Date Required";
      this.setState({ errors: listErrors });
      return;
    } else {
      listErrors.date = "";
      this.setState({ errors: listErrors });
    }

    if (this.state.endDate === "") {
      listErrors.end_date = "End Date Required";
      this.setState({ errors: listErrors });
      return;
    } else {
      listErrors.end_date = "";
      this.setState({ errors: listErrors });
    }

    values.date = this.state.startDate;
    values.end_date = this.state.endDate;
    this.props.onSubmit(values);

    // this.setState({
    //   visible: false,
    // });
  };

  showModal = () => {
    console.log("!!!!!!visible");
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleTime(e, s) {
    this.setState({
      time: e,
      timeString: s,
    });
  }

  handleCloseTime(e, s) {
    this.setState({
      closeTime: e,
      closeTimeString: s,
    });
  }

  handleDetailsModalclose(item) {
    this.setState({
      view: false,
      lat: item.lat,
      long: item.lng,
    });
  }

  onStartDateChange = (date, dateString) => {
    this.setState({
      startDate: dateString,
    });
  };

  onEndDateChange = (date, dateString) => {
    this.setState({
      endDate: dateString,
    });
  };

  render() {
    const { primaryColor } = this.props;

    return (
      <div>
        <Modal
          destroyOnClose={true}
          title="Add Date"
          open={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                date: "",
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Start Date
                        <DatePicker
                          format={dateFormat}
                          onChange={this.onStartDateChange}
                          // disabledDate={current => {
                          //     return current && current < moment().subtract(1, "day");
                          // }}
                        />
                        {this.state.errors.date ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.date}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        End Date
                        <DatePicker
                          format={dateFormat}
                          onChange={this.onEndDateChange}
                          disabledDate={(current) => {
                            return (
                              current && current < moment(this.state.startDate)
                            );
                          }}
                        />
                        {this.state.errors.end_date ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.end_date}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <Button
                      // type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Generate
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </Modal>
      </div>
    );
  }
}
