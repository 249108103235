import * as React from "react";
import { Link } from "react-router-dom";
import { Flag } from "react-feather";
import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalWrap from "./modal";

class BalanceModal extends React.Component {
  
  render() {
    return (
        
            <ModalWrap
                visible={this.props.visible}
                closable={this.props.onClose}
                title="Total Balance"
                onOk={this.props.handleOk}
                onCancel={this.props.handleCancel}
                isLoading={this.props.isLoading}
            >
                <div className="col-sm-6 col-xl-6 col-lg-6">
                    <Link>
                    <div className="card o-hidden">
                        <div className="bg-primary b-r-4 card-body">
                        <div className="media static-top-widget">
                            <div className="align-self-center text-center">
                            <Flag />
                            </div>
                            <div className="media-body">
                            <h4 className="mb-0 counter">
                                <CountUp
                                className="counter"
                                end={this.props.data.total_balance}
                                />  
                            </h4>
                            <Flag className="icon-bg" />
                            </div>
                        </div>
                        </div>
                    </div>
                    </Link>
                </div>
            </ModalWrap>
    );
  }
}
export default BalanceModal;