import React, { Component, Fragment } from "react";
import Title from "../common/Title";
import FreezeUserTable from "./FreezeUserTable";
import { connect } from "react-redux";
import { alertError } from "../../utils/alert";
import { getFreezerList } from "../../Graphs/FreezerUser/getFreezerList";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { updateFreezerUserAPI } from "../../Graphs/FreezerUser/updateFreezerUser";
import { Spin, notification } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { SET_ROUTE_NAME } from "../../constant/actionTypes";

class FreezeUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      freezeUserList: [],
      totalFreezeUser: 0,
    };
  }

  componentDidMount() {
    const { setRouteName } = this.props;
    this.getFreezeUserData();
    setRouteName("FREEZE-USER");
  }

  getFreezeUserData = async () => {
  
    try {
      const { authToken, history,loginUserData } = this.props;
      let city = loginUserData.role == "Sub_admin" ? loginUserData.city : "";
      let freezeUserData = await getFreezerList(authToken,city);

      this.setState({
        isLoading: true,
      });
      if (freezeUserData.status == 200) {
        this.setState({
          freezeUserList: freezeUserData.data,
          totalFreezeUser: freezeUserData?.data?.length,
          isLoading: false,
        });
      } else if (freezeUserData.status == 401) {
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw freezeUserData && freezeUserData.message
          ? freezeUserData.message
          : "Error";
      }
    } catch (e) {
      alertError("Freezer", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  updateFreezeUser = async (item) => {
    try {
      const { authToken, history,loginUserData } = this.props;
      const city = loginUserData.role === "Sub_admin" ? loginUserData.city : "";
      let updateUser = await updateFreezerUserAPI(authToken, item?.id,city);

      this.setState({
        isLoading: true,
      });
      if (updateUser.status == 200) {
        notification["success"]({
          message: updateUser.message,
        });
        this.getFreezeUserData();
      } else if (updateUser.status == 401) {
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw updateUser && updateUser.message ? updateUser.message : "Error";
      }
    } catch (e) {
      alertError("Freezer", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { freezeUserList, totalFreezeUser, isLoading } = this.state;
    return (
      <Fragment>
        <Title
          parent="Freeze User"
          title="Freeze"
          icon={<LockOutlined />}
          url="/freeze-user"
        />

        {this.state.isLoading ? (
          <div style={{ marginLeft: "20px" }}>
            <Spin
              size="large"
              style={{ marginLeft: "480px", marginTop: "130px" }}
            />
          </div>
        ) : (
          <div className="container-fluid">
            <FreezeUserTable
              freezeData={freezeUserList}
              totalFreezeData={totalFreezeUser}
              isLoading={isLoading}
              updateFreezeUser={this.updateFreezeUser}
            />
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setRouteName: (routeName) => {
      dispatch({
        type: SET_ROUTE_NAME,
        routeName: routeName,
      });
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FreezeUser));
