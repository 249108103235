import { Table, Tooltip, notification, Spin, Button } from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllWalletTransactions } from "../../Graphs/User/listWalletTransactionList";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { EyeOutlined } from "@ant-design/icons";
import { ViewTransaction } from "./ViewTransaction";
import { alertError } from "../../utils/alert";
var moment = require("moment");

class UserWalletTransactionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataTotal: 0,
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      transactionData: null,
    };
    this.dateFormat = this.dateFormat.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  getLimitedTransaction = async (pagination) => {
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        this.listTransactionData(start, end);
      }
    );
  };

  listTransactionData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListTransactionData,
        setListTransactionDataTotal,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let usertransactionData = await getAllWalletTransactions(
        authToken,
        undefined,
        start,
        end
      );
      console.log("!!!!!!!!!!!!usertransactionData", usertransactionData);

      if (usertransactionData.status == 200) {
        this.setState({
          isLoading: false,
          data: usertransactionData.data,
          dataTotal: usertransactionData.total,
        });
        await setListTransactionData(usertransactionData.data);
        await setListTransactionDataTotal(usertransactionData.total);
      } else if (usertransactionData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw usertransactionData && usertransactionData.message
          ? usertransactionData.message
          : "Error";
      }
    } catch (e) {
      alertError("User Wallet Transaction", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedTransaction(pagination)
    );
  }

  transaRef;

  showTransactionModal = (item) => {
    this.setState(
      {
        transactionData: item,
      },
      () => {
        this.transaRef.show();
      }
    );
  };

  handleDeleteTransactionModal() {
    this.setState({
      transactionData: null,
    });
  }

  dateFormat(date) {
    var oldDate = new Date(date),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.add(2, "hours").format("Do MMM YYYY");

    return newDate;
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Card Name",
      key: "card_name",
      fixed: true,
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                  <Tooltip placement="bottom" title="Card Name">
                    <div>
                      {item.card
                        ? item.card.name
                          ? item.card.name
                          : "-"
                        : "For Wallet"}
                    </div>
                  </Tooltip>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "User Name",
      key: "user_name",
      fixed: true,
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                  <Tooltip placement="bottom" title="User Name">
                    <div>
                      {item.user
                        ? item.user.first_name
                          ? item.user.first_name
                          : "-"
                        : "-"}
                    </div>
                  </Tooltip>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Amount (LYD)",
      key: "amount",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <Tooltip placement="bottom" title="Amount">
                  <div>{item.amount ? String(item.amount) : "-"}</div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Card Quantity",
      key: "card_quantity",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <Tooltip placement="bottom" title="Card Quantity">
                  <div>
                    {item.card_quantity
                      ? String(item.card_quantity)
                      : "For Wallet"}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Order Code",
      key: "order_code",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <Tooltip placement="bottom" title="Order Code">
                  <div>{item.order_code ? item.order_code : "-"}</div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Reference Id",
      key: "reference_id",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <Tooltip placement="bottom" title="Reference Id">
                  <div>{item.reference_id ? item.reference_id : "-"}</div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "User Transaction Code",
      key: "order_code",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <Tooltip placement="bottom" title="Order Code">
                  <div>
                    {item.user_transaction
                      ? item.user_transaction.order_code
                      : "-"}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <Tooltip placement="bottom" title="Status">
                  <div>{item.status ? item.status : "-"}</div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Transaction Type",
      key: "type",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <Tooltip placement="bottom" title="Transaction Type">
                  <div>{item.type ? item.type : "-"}</div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Payment Mode",
      key: "payment_mode",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <Tooltip placement="bottom" title="Payment Mode">
                  <div>
                    {item.user_transaction
                      ? item.user_transaction.payment_mode
                      : "-"}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Transaction At",
      key: "transaction_at",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <Tooltip placement="bottom" title="Transaction At">
                  <div>
                    {item.transaction_at
                      ? this.dateFormat(item.transaction_at)
                      : "-"}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 130,
      key: "action",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            <div mr="5px" style={{ float: "left", marginLeft: "5px" }}>
              <Tooltip placement="bottom" title="View">
                <Button
                  shape="circle"
                  icon={<EyeOutlined />}
                  onClick={() => {
                    console.log("isOpen value --- ", this.state.isOpen);
                    this.showTransactionModal(item);
                  }}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  render() {
    const { isLoading, data, dataTotal } = this.state;
    const { listTransactionDataTotal, listTransactionData, authToken } =
      this.props;

    return isLoading ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin size="large" style={{ margin: "0 auto", width: "100%" }} />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          scroll={{ x: 1000 }}
          size={"small"}
          dataSource={listTransactionData}
          pagination={{
            total: listTransactionDataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "5", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />
        {this.state.transactionData ? (
          <ViewTransaction
            admin={false}
            refx={(e) => (this.transaRef = e)}
            onClose={this.handleDeleteTransactionModal.bind(this)}
            transactionData={this.state.transactionData}
            authToken={authToken}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listCardData: state.card.listCardData,
  listCardDataTotal: state.card.listCardDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
  listTransactionData: state.transaction.listTransactionData,
  listTransactionDataTotal: state.transaction.listTransactionDataTotal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCardData: (listCardData) => {
      dispatch({
        type: "SET_LIST_CARD_DATA",
        listCardData: listCardData,
      });
    },
    setListCardDataTotal: (listCardDataTotal) => {
      dispatch({
        type: "SET_LIST_CARD_DATA_TOTAL",
        listCardDataTotal: listCardDataTotal,
      });
    },
    setListTransactionData: (listTransactionData) => {
      dispatch({
        type: "SET_LIST_TRANSACTION_DATA",
        listTransactionData: listTransactionData,
      });
    },
    setListTransactionDataTotal: (listTransactionDataTotal) => {
      dispatch({
        type: "SET_LIST_TRANSACTION_DATA_TOTAL",
        listTransactionDataTotal: listTransactionDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserWalletTransactionList));
