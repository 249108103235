import React, { Fragment, Component } from "react";
import Title from "./Title";
import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllTransactions } from "../../Graphs/User/listTransationList";
import { getAllWalletTransactions } from "../../Graphs/User/listWalletTransactionList";
import { getAllCsvTransaction } from "../../Graphs/User/ListCsvTransaction";
import { getAllCsvWalletTransaction } from "../../Graphs/User/ListCsvWalletTransaction";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification, Tabs } from "antd";
import UserTransactionsList from "./UserTransactionsList";
import UserWalletTransactionList from "./UserWalletTransactionList";
import { AddTransactionDate } from "./AddTransactionDate";
import * as la from "lodash";
import { alertError } from "../../utils/alert";
var moment = require("moment");

const TabPane = Tabs.TabPane;

class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionList: [],
      transactionTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
      transactionData: null,
    };
  }

  async componentDidMount() {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListTransactionData,
        setListTransactionDataTotal,
        setRouteName,
        setSearchText,
      } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");
      console.log("!!!!!!!!editPrimaryColor", editPrimaryColor);

      await setRouteName("TRANSACTION_TAB");
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      await this.listTransactionData();
    } catch (e) {
      alertError("TRANSACTION", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listTransactionData = async () => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListTransactionData,
        setListTransactionDataTotal,
        setRouteName,
        setSearchText,
      } = this.props;

      this.setState({
        isLoading: true,
      });

      await setRouteName("TRANSACTION_TAB");

      let transactionData = await getAllTransactions(
        authToken,
        undefined,
        0,
        10
      );

      if (transactionData.status === 200) {
        //set user data
        await setListTransactionData(transactionData.data);
        await setListTransactionDataTotal(transactionData.total);

        this.setState({
          transactionList: transactionData.data,
          transactionTotal: transactionData.total,
        });
      } else if (transactionData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw transactionData && transactionData.message
          ? transactionData.message
          : "Error";
      }
    } catch (e) {
      alertError("TRANSACTION", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listWalletTransactionData = async () => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListTransactionData,
        setListTransactionDataTotal,
        setRouteName,
        setSearchText,
      } = this.props;

      this.setState({
        isLoading: true,
      });

      await setRouteName("WALLET_TRANSACTION_TAB");

      let walletTransactionData = await getAllWalletTransactions(
        authToken,
        undefined,
        0,
        10
      );

      if (walletTransactionData.status === 200) {
        //set user data
        await setListTransactionData(walletTransactionData.data);
        await setListTransactionDataTotal(walletTransactionData.total);

        this.setState({
          transactionList: walletTransactionData.data,
          transactionTotal: walletTransactionData.total,
        });
      } else if (walletTransactionData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw walletTransactionData && walletTransactionData.message
          ? walletTransactionData.message
          : "Error";
      }
    } catch (e) {
      alertError("TRANSACTION", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  selectedTab = "transaction";
  async dataFetch(key) {
    console.log("dataFetch key -- ", key);
    switch (key) {
      case "1":
        this.selectedTab = "transaction";
        break;
      case "2":
        this.selectedTab = "wallet_transaction";
        break;
    }

    if (this.selectedTab === "transaction") {
      await this.listTransactionData();
      return;
    }

    await this.listWalletTransactionData();
  }

  transaRef;

  showTransactionModal = () => {
    this.setState(
      {
        transactionData: {},
      },
      () => {
        this.transaRef.showModal();
      }
    );
  };

  handleDeleteTransactionModal() {
    this.setState({
      transactionData: null,
    });
  }

  dateFormat(date) {
    var oldDate = new Date(date),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.add(2, "hours").format("Do MMM YYYY");

    return newDate;
  }

  generateCsv = async (values) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListTransactionData,
        setListTransactionDataTotal,
        setRouteName,
        setSearchText,
      } = this.props;

      console.log("!!!!!!!!!values", values);

      this.setState({
        isLoading: true,
      });

      let startDate = `${values.date} 00:00:00`;
      let endDate = `${values.end_date} 23:59:59`;
      let csvData;

      if (this.selectedTab === "transaction") {
        csvData = await getAllCsvTransaction(authToken, startDate, endDate);
      } else {
        csvData = await getAllCsvWalletTransaction(
          authToken,
          startDate,
          endDate
        );
      }
      console.log("!!!!!!!!!csvData", csvData);

      if (csvData.status === 200) {
        //set user data
        console.log("!!!!!!!!!!!!!!!csvData", csvData.data);

        if (!(csvData.data.length > 0)) {
          alertError("TRANSACTION", "No Transaction");
          return;
        }

        let csvArray = [];

        csvArray.push([
          "Card Name",
          "User Name",
          "Amount",
          "Card Quantity",
          "Order Code",
          "Reference Id",
          "Payment Id",
          "status",
          "Transaction Type",
          "Payment Mode",
          "Transaction At",
          "Redeem Code",
        ]);

        csvData.data.map((data) => {
          let displayString = '"';
          if (data.card_inventorys && data.card_inventorys.length > 0) {
            la.map(data.card_inventorys, (subData, subIndex) => {
              if (subIndex === data.card_inventorys.length - 1) {
                displayString = displayString + subData.redeem_code + '"';
                return;
              }
              displayString = displayString + subData.redeem_code + "\n";
            });
          } else {
            displayString = "NA";
          }
          csvArray.push([
            data.card ? data.card.name : "NA",
            data.user ? data.user.first_name : "NA",
            data.amount ? data.amount : "NA",
            data.card_quantity ? String(data.card_quantity) : "For Wallet",
            data.order_code ? data.order_code : "NA",
            data.reference_id ? `"=""${data.reference_id}"""` : "NA",
            data.payment_gateway_order_id
              ? `"=""${data.payment_gateway_order_id}"""`
              : "NA",
            data.status ? data.status : "NA",
            data.type ? data.type : "NA",
            data.payment_mode ? data.payment_mode : "NA",
            data.transaction_at ? this.dateFormat(data.transaction_at) : "NA",
            displayString,
          ]);
        });

        const rows = csvArray;
        let csvName = "AllTranactions.csv";
        let csvContent = "data:text/csv;charset=utf-8,";

        rows.forEach(function (rowArray) {
          let row = rowArray.join(",");
          csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        // // // // window.open(encodedUri);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", csvName);
        document.body.appendChild(link); // Required for FF

        link.click();
      } else if (csvData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw csvData && csvData.message ? csvData.message : "Error";
      }
    } catch (e) {
      alertError("TRANSACTION", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { primaryColor, transactionList, isLoading, transactionTotal } =
      this.state;
    const { authToken } = this.props;

    return (
      <Fragment>
        <Title parent="Transaction" title="transaction" />

        <div
          style={{
            marginTop: "20px",
            display: "inline-block",
            width: "100%",
            marginBottom: "20px",
            paddingLeft: "14px",
            paddingRight: "55px",
          }}
        >
          <div
            onClick={() => this.showTransactionModal()}
            style={{ float: "left", cursor: "pointer", width: "20%" }}
          >
            <div
              style={{
                width: "100px",
                backgroundColor: primaryColor,
                color: "white",
                padding: "7px 0px",
                textAlign: "center",
                borderRadius: "50px",
              }}
            >
              Export Csv
            </div>
          </div>
        </div>

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <Tabs
                defaultActiveKey={
                  this.selectedTab === "transaction" ? "1" : "2"
                }
                onChange={(key) => {
                  this.dataFetch(key);
                }}
              >
                <TabPane tab="Transaction" key="1">
                  <UserTransactionsList
                    data={this.props.userTrasactionData}
                    authToken={this.props.authToken}
                    handleCancel={this.handleCancel}
                  />
                </TabPane>
                <TabPane tab="Wallet Transaction" key="2">
                  <UserWalletTransactionList
                    data={this.props.userTrasactionData}
                    authToken={this.props.authToken}
                    handleCancel={this.handleCancel}
                  />
                </TabPane>
              </Tabs>
              {this.state.transactionData ? (
                <AddTransactionDate
                  admin={false}
                  refx={(e) => (this.transaRef = e)}
                  onClose={this.handleDeleteTransactionModal.bind(this)}
                  transactionData={this.state.transactionData}
                  authToken={authToken}
                  onSubmit={this.generateCsv}
                />
              ) : null}
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListTransactionData: (listTransactionData) => {
      dispatch({
        type: "SET_LIST_TRANSACTION_DATA",
        listTransactionData: listTransactionData,
      });
    },
    setListTransactionDataTotal: (listTransactionDataTotal) => {
      dispatch({
        type: "SET_LIST_TRANSACTION_DATA_TOTAL",
        listTransactionDataTotal: listTransactionDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Transaction));
