import { ENDPOINT } from "../../network/ENDPOINT";
import { callApiToServer } from "../callApi";

export async function uploadRedeem(authtoken, cardObject, cardId, city) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };
    let body = {
      cardId: cardId,
      fileName: cardObject.file,
      note: cardObject.note,
    };
    if (city) {
      body.city = city;
    }
    let requestBody = JSON.stringify(body);

    let endUrl = ENDPOINT.upload_card_redeem;

    let responseData = await callApiToServer(
      requestBody,
      header,
      "POST",
      endUrl
    );

    return responseData;
  } catch (err) {
    throw err;
  }
}

export async function uploadSingleRedeem(authtoken, redeemObject) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify(redeemObject);

    let endUrl = ENDPOINT.upload_single_card_redeem;

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
