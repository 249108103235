import { callApiToServer } from '../callApi';

export async function addUserCoin(
  authtoken,
  coinObject,
) {
  try {
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization": "Berear " + authtoken
    };

    let endUrl = "v1/auth/add-user-coin";

    let responseData = await callApiToServer(JSON.stringify(coinObject), header, "POST", endUrl);

    return responseData;

  } catch (err) {
    throw err;
  }
}
