import { ENDPOINT } from "../../network/ENDPOINT";
import { callApiToServer } from "../callApi";

export async function getActivityLog(authtoken, role, payload, skip, limit) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };
    let body = JSON.stringify(payload);

    let endUrl = `${ENDPOINT.activity_log}/${role}/${skip}/${limit}`;

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
