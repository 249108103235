import {
  Table,
  Tooltip,
  notification,
  Spin,
  Select,
  Input,
  Button,
  Modal,
  Tag,
} from "antd";
import * as React from "react";
import { get } from "lodash";

import { getAllCompanyCoinTrn } from "../../Graphs/CompanyTrn/listCompanyTrn";

import { withRouter } from "react-router";
import { connect } from "react-redux";
import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { Users, List, Gift, DollarSign, CreditCard, Flag } from "react-feather";
import _ from "lodash";
import { color } from "../../constant/comman";
import { alertError } from "../../utils/alert";
var moment = require("moment");
const { Option } = Select;

class UserCoinTrn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      coinList: [],
      dataTotal: 0,
      isLoading: false,
      startIndex: 0,
      limitNumber: 5,
      pagination: {
        pageSize: 5,
        current: 1,
      },
      errors: {},
      price: 0,
      cardData: [],
      company_id: null,

      visible: false,
      closable: true,
    };
    this.dateFormat = this.dateFormat.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }

    console.log("[CONSOLE LOG]", this.props.companyData);

    this.listUserCoinData(0, 5);
  }

  getLimitedUserCoin = async (pagination) => {
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        this.listUserCoinData(start, end);
      }
    );
  };

  listUserCoinData = async (start, end) => {
    try {
      const {
        companyData,
        authToken,
        history,
        setListUserCoinDataData,
        setListUserCoinDataTotal,
        setLoginFlag,
        setUserData,
        setUserToken,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let UserCoinData = await getAllCompanyCoinTrn(
        authToken,
        companyData.id,
        start,
        end
      );
      console.log("!!!!!!!!!!!!UserCoinData", UserCoinData);

      if (UserCoinData.status == 200) {
        this.setState({
          isLoading: false,
          data: UserCoinData.data,
          dataTotal: UserCoinData.total,
        });
        console.log("!!!!!!!!!!!!UserCoinData", UserCoinData);

        await setListUserCoinDataData(UserCoinData.data);
        await setListUserCoinDataTotal(UserCoinData.total);
      } else if (UserCoinData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw UserCoinData && UserCoinData.message
          ? UserCoinData.message
          : "Error";
      }
    } catch (e) {
      alertError("Company", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedUserCoin(pagination)
    );
  }

  dateFormat(date) {
    var oldDate = new Date(date),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.add(2, "hours").format("Do MMM YYYY");
    return newDate;
  }

  onChangeCoin = (value) => {
    this.setState({
      coin: value,
    });
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show() {
    this.setState({
      visible: true,
    });
  }

  hide() {
    this.setState({
      visible: false,
    });
    this.props.onClose();
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      width: 5,
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Coin",
      key: "coin",
      width: 10,
      render: (text, item, index) => {
        console.log("coin record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                    <div>
                      {item.coin && item.coin.name ? item.coin.name : "-"}
                    </div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Type",
      key: "Type",
      width: 10,
      render: (text, item, index) => {
        console.log("coin record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                    <div>
                      {item.type ? (
                        <Tag
                          color={item.type == "CREDIT" ? "green" : "red"}
                          key={"loser"}
                        >
                          {item.type.toUpperCase()}
                        </Tag>
                      ) : (
                        "-"
                      )}
                    </div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Amount (LYD)",
      key: "Price",
      width: 10,
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                    <div>{item.price !== null ? item.price : "-"}</div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  render() {
    const { isLoading, data, dataTotal } = this.state;

    return (
      <Modal
        width="600px"
        closable={this.state.closable}
        open={this.state.visible}
        title="Coin Transactions"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        {isLoading ? (
          <div style={{ marginLeft: "20px" }}>
            <Spin size="large" style={{ margin: "0 auto", width: "100%" }} />
          </div>
        ) : (
          <React.Fragment>
            <div style={{ "overflow-x": "auto" }}>
              <Table
                loading={this.state.isLoading}
                rowKey={(record) => record._id}
                columns={this.columns}
                size={"small"}
                // dataSource={this.state.data}
                dataSource={data}
                pagination={{
                  total: dataTotal,
                  showSizeChanger: true,
                  pageSize: this.state.pagination.pageSize,
                  current: this.state.pagination.current,
                  pageSizeOptions: ["1", "10", "25", "50", "100"],
                  onChange: (e) => {
                    console.log("onChanges pagination");
                    // e - 1
                    //   ? (this.counter = this.tempCounter =
                    //       (e - 1) * this.state.pagination.pageSize)
                    //   : ((this.counter = 0), (this.tempCounter = 0));
                    if (e - 1) {
                      this.counter = this.tempCounter =
                        (e - 1) * this.state.pagination.pageSize;
                      return;
                    }

                    this.counter = 0;
                    this.tempCounter = 0;
                  },
                }}
                onChange={this.handleTableChange}
              />
            </div>
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listUserCoinDataData: state.user_coin.listUserCoinData,
  listUserCoinDataTotal: state.user_coin.listUserCoinDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListUserCoinDataData: (listUserCoinData) => {
      dispatch({
        type: "SET_LIST_USER_COIN_DATA",
        listUserCoinData: listUserCoinData,
      });
    },
    setListUserCoinDataTotal: (listUserCoinDataTotal) => {
      dispatch({
        type: "SET_LIST_USER_COIN_DATA_TOTAL",
        listUserCoinDataTotal: listUserCoinDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserCoinTrn));
