// export const WEBURL = "https://pointapi.dharmatech.in/";
// export const WEBURL = "http://192.168.1.19:4000/";
export const WEBURL = "https://api-staging.paypoint.ly/";

export const color = [
  {
    name: "RED",
    value: "#db0d17",
  },
  {
    name: "ORANGE",
    value: "#ff9901",
  },
  {
    name: "BLUE",
    value: "#00c2ff",
  },
  {
    name: "BLACK",
    value: "#000000",
  },
  {
    name: "WHITE",
    value: "#ffffff",
  },
  {
    name: "SILVER",
    value: "#C0C0C0",
  },
  {
    name: "GRAY",
    value: "#808080",
  },
  {
    name: "MAROON",
    value: "#800000",
  },
  {
    name: "YELLOW",
    value: "#FFFF00",
  },
  {
    name: "OLIVE",
    value: "#808000",
  },
  {
    name: "LIME",
    value: "#00FF00",
  },
  {
    name: "AQUA",
    value: "#00FFFF",
  },
  {
    name: "FUCHSIA",
    value: "#FF00FF",
  },
  {
    name: "PURPLE",
    value: "#800080",
  },
  {
    name: "NAVY",
    value: "#000080",
  },
  {
    name: "Dark spring green",
    value: "#177245",
  },
  {
    name: "Dartmouth green",
    value: "#00703C",
  },
  {
    name: "Fandango pink",
    value: "#DE5285",
  },
  {
    name: "Chrome yellow",
    value: "#FFA700",
  },
  {
    name: "Dark green",
    value: "#013220",
  },
  {
    name: "Dark green (X11)",
    value: "#006400",
  },
  {
    name: "Dark pastel green",
    value: "#03C03C",
  },
  {
    name: "Deep sky blue",
    value: "#00BFFF",
  },
  {
    name: "Electric green",
    value: "#00FF00",
  },
  {
    name: "Fire opal",
    value: "#E95C4B",
  },
  {
    name: "Fuchsia purple",
    value: "#CC397B",
  },
  {
    name: "Caribbean green",
    value: "#00CC99",
  },
];
