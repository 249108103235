import { ENDPOINT } from "../../network/ENDPOINT";
import { callApiToServer } from "../callApi";

export async function getRedeemCode(authtoken, body) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };
    let endUrl = ENDPOINT.redeem_code;
    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
