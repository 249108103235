import { ENDPOINT } from "../../network/ENDPOINT";
import { callGetApiToServer } from "../callApi";

export async function getTicketListAPI(authtoken, skip, limit, filterValue,city) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let endUrl = `${ENDPOINT.support_list}/${skip}/${limit}${
      filterValue ? `?filter_by=${filterValue}` : ''
    }`;
    
    if (city) {
      endUrl += endUrl.includes('?') ? `&city=${city}` : `?city=${city}`;
    }
    
      let responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
