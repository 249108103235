import { callApiToServer } from '../../callApi';

export async function changeStatus(
  authtoken,
  coinId,
  status
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    };
      
    console.log("coin!!!!!!!", coinId, '-', status)
    let body = JSON.stringify({
        coinId: coinId,
        flag: status
    });
    console.log("coin!!!!@!!!url");

    let endUrl = "v1/auth/change-coin-status";
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callApiToServer(body, header, "POST", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}
