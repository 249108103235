import React, { Fragment, Component } from "react";
import Title from "./Title";
import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllUsers } from "../../Graphs/User/listUser";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SearchHeader from "../common/header-component/searchHeader";
import { Spin, notification } from "antd";
import UserRecordsTable from "./UserRecordsTable";
import { createUser } from "../../Graphs/User/createUser";
import { AddUserModal } from "./AddUserModal";
import { getDistributorUser } from "../../Graphs/distributor/getDistributorUser";
import { User as UserAcount } from "react-feather";
import { getCityListAPI } from "../../Graphs/Master/City/getCityListAPI";
import { alertError } from "../../utils/alert";
class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      cityData: [{ label: "--- Select City ---", value: "Select" }],
      userTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
    };
  }

  async componentDidMount() {
    try {
      const { setRouteName, setSearchText } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");
      await setRouteName("USER");
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      await this.listUserdata();
    } catch (e) {
      alertError("User", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }

    this.getCityList();
  }

  listUserdata = async () => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListUserData,
        setListUserDataTotal,
        setRouteName,
        setSearchText,
      } = this.props;

      let role = this.props.loginUserData.role == "Sub_admin";
      let city = this.props.loginUserData.city;
      let userData =
        this.props.loginUserData.role == "Distributor"
          ? await getDistributorUser(authToken, 0, 10)
          : role
          ? await getAllUsers(authToken, 0, 10, city)
          : await getAllUsers(authToken, 0, 10);

      if (userData.status == 200) {
        //set user data

        await setListUserData(userData.data);
        await setListUserDataTotal(userData.total);

        this.setState({
          userList: userData.data,
          userTotal: userData.total,
        });
      } else if (userData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw userData && userData.message ? userData.message : "Error";
      }
    } catch (e) {
      alertError("User", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  addSubmit = async (values) => {
    try {
      const { authToken } = this.props;
      this.setState({
        isLoading: true,
      });

      let addClientData = await createUser(authToken, values);
      if (addClientData.status == 200) {
        notification["success"]({
          message: "Add User",
          description: "User added successFully",
        });
      } else if (addClientData.status == 500) {
        alertError("Error", addClientData.message);
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
        throw addClientData.message ? addClientData.message : "Error";
      }
      await this.listUserdata();
    } catch (e) {
      alertError("USER", e);
      this.setState({
        isLoading: false,
      });
    }
  };

  async getCityList() {
    try {
      let getSalesData = await getCityListAPI(this.props.authToken);

      if (getSalesData.status == 200) {
        let temp = getSalesData.data.map((i) => ({
          ...i,
          label: i.name,
          value: i.name,
        }));
        this.setState({
          cityData: [...this.state.cityData, ...temp],
        });
      }
    } catch (error) {
      alertError("Error", error);

      return -1;
    }
  }

  render() {
    const { primaryColor, userList, isLoading, userTotal } = this.state;
    const { loginUserData } = this.props;

    const accessPermission =
      (loginUserData.access_permission &&
        JSON.parse(loginUserData.access_permission)) ||
      false;

    return loginUserData.role !== "Admin" &&
      loginUserData.role !== "Distributor" &&
      (!accessPermission ||
        !accessPermission["user"] ||
        accessPermission["user"].view == false) ? (
      <Fragment>
        <h5 style={{ marginTop: "30%", marginLeft: "30%" }}>
          You are not authorized for view this page.
        </h5>
      </Fragment>
    ) : (
      <Fragment>
        <Title parent="User" title="user" icon={<UserAcount />} url="/user" />

        {this.props.loginUserData.role != "Distributor" && (
          <div
            style={{
              marginTop: "20px",
              display: "inline-block",
              width: "100%",
              marginBottom: "20px",
              paddingLeft: "14px",
              paddingRight: "55px",
            }}
          >
            <div style={{ float: "left", cursor: "pointer" }}>
              <AddUserModal
                refx={(e) => (this.modalRef = e)}
                primaryColor={primaryColor}
                onSubmit={this.addSubmit}
                authToken={this.props.authToken}
                loginUserData={this.props.loginUserData}
                cityData={this.state.cityData}
              />
            </div>
          </div>
        )}

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <UserRecordsTable
                data={userList}
                primaryColor={primaryColor}
                userTotal={userTotal}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListUserData: (listUserData) => {
      dispatch({
        type: "SET_LIST_USER_DATA",
        listUserData: listUserData,
      });
    },
    setListUserDataTotal: (listUserDataTotal) => {
      dispatch({
        type: "SET_LIST_USER_DATA_TOTAL",
        listUserDataTotal: listUserDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(User));
