import {  SET_BALANCE_REQUEST_DATA, SET_BALANCE_REQUEST_DATA_TOTAL } from "../constant/actionTypes";

const initial_state = {
    balanceData:[],
    totalBalanceRequest : 0

};

export default (state = initial_state, action) => {
    switch (action.type) {
        
        case SET_BALANCE_REQUEST_DATA:
            return { ...state, loading: false, balanceData: action.balanceData };

        case SET_BALANCE_REQUEST_DATA_TOTAL:
            return{...state, loading:false, totalBalanceRequest :action.totalBalanceRequest}

        default: return { ...state };
    }
}
