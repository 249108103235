import { callGetApiToServer, deleteApiToServer } from '../callApi';

export async function deleteCollectUserReport(
    authtoken,
    transaction_id
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    }
  
    let endUrl = `v1/auth/sales/delete-transaction/${transaction_id}`;
    console.log("endUrl printed here", endUrl);
  
    let responseData = await deleteApiToServer(header, "DELETE", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}
