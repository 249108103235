import { putApiToServer } from "../../callApi";

export async function updateCityAPI(
        authtoken,   
        payload,
        id
){
    try {
        let header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "authorization": "Berear " + authtoken
        };

        let body = JSON.stringify({
            name:payload.city_name,
            arabic_name:payload.city_arrabic_name,
            price_percentage:parseInt(payload.extra_price)
        });

        let endUrl = `v1/auth/edit-city/${id}`;

        let responseData = await putApiToServer(body, header, "PUT", endUrl);

        return responseData;

    } catch (err) {
        throw err;
    }
}
