import React, { Fragment, Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TicketSupportTable from "./TicketSupportTable";
import { Button, Select, Spin, notification } from "antd";
import Title from "../User/Title";
import { Headphones } from "react-feather";
import { getTicketListAPI } from "../../Graphs/ticket-support/TicketList";
import { alertError } from "../../utils/alert";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class TicketSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      ticketList: [],
      ticketListSize: 0,
      primaryColor: "#4466f2",
      addTicketModalVisible: false,
      filterValue: "",
      filterOptions: [
        { label: "All", value: "" },
        { label: "Pending", value: "pending" },
        { label: "Progress", value: "progress" },
        { label: "Done", value: "done" },
      ],
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  async componentDidMount() {
    const { setRouteName } = this.props;
    this.getTicketList(0, 10, "");
    setRouteName("TICKET-SUPPORT");
  }

  async getLimitedCards(pagination) {
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;
    this.getTicketList(start, end, "");
  }

  handleTableChange(pagination, filters) {
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedCards(pagination)
    );
  }

  handleChangeTicket = () => {
    this.getTicketList(0, 10, this.state.filterValue);
  };

  getTicketList = async (start, end, filterValue) => {
    try {
      const { authToken, history ,loginUserData} = this.props;
      let city = loginUserData.role == "Sub_admin" ? loginUserData.city : "";

      this.setState({
        isLoading: true,
      });

      let checkFilter =
        filterValue == "" ? this.state.filterValue : filterValue;

      const ticketListData = await getTicketListAPI(
        authToken,
        start,
        end,
        checkFilter,
        city
      );

      if (ticketListData.status == 200) {
        this.setState({
          isLoading: false,
          ticketList: ticketListData.data,
          ticketListSize: ticketListData.count,
        });
      } else if (ticketListData.status == 401) {
         history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw ticketListData && ticketListData.message
          ? ticketListData.message
          : "Error";
      }
    } catch (e) {
      alertError("Support Ticket", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { isLoading, pagination } = this.state;
    return (
      <Fragment>
        <Title
          parent="Ticket"
          title="Ticket"
          icon={<Headphones />}
          url="/ticket"
        />
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            gap: "20px",
            marginBottom: "20px",
            paddingLeft: "14px",
            paddingRight: "55px",
          }}
        >
          <Select
            defaultValue="All"
            style={{
              width: " 150px",
            }}
            onChange={(value) =>
              this.setState({
                filterValue: value,
              })
            }
            options={this.state.filterOptions}
          />

          <Button
            style={{
              background: "#5C6AC4",
              color: "#fff",
              cursor: "pointer",
              display: "flex",
              gap: "5px",
            }}
            onClick={this.handleChangeTicket}
          >
            Filter
          </Button>
        </div>

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <TicketSupportTable
                ticketList={this.state.ticketList}
                isLoading={isLoading}
                ticketListSize={this.state.ticketListSize}
                getTicketList={this.getTicketList}
                pagination={pagination}
                handleTableChange={this.handleTableChange}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TicketSupport));
