import React, { Fragment, Component } from 'react';
import Title from './Title';
import CountUp from 'react-countup';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getCompanyTrn } from "../../Graphs/CompanyTrn/listCompanyTrn";
import { getAllCompanyCoinTrn } from "../../Graphs/CompanyTrn/listCompanyTrn";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import  SearchHeader from "../common/header-component/searchHeader";
import { Spin, notification, Tabs } from "antd";
import CompanyTrnRecordsTable from "./CompanyTrnRecordsTable";
import CompanyCoinTransactionRecordsTable from './CompanyCoinTransactionRecordsTable';
import { getAllCompanyPayTrn } from "../../Graphs/CompanyPayTrn/companyPayTrn"; 
import CompanyPayTransactionRecordsTable from './CompanyPayTransactionRecordsTable';
import { alertError } from '../../utils/alert';

const { TabPane } = Tabs;

class CompanyTrn extends Component {
    constructor(props){
        super(props);
        this.state = {
            companyList : [],
            companyTotal : 0,
            isLoading : false,
            primaryColor: "#4466f2",
            activeKey : "1"
        }
    }

    async componentDidMount() {
        try{
            const { setRouteName, setSearchText } = this.props;
            let editPrimaryColor = await localStorage.getItem('primary_color');
            console.log("!!!!!!!!editPrimaryColor", editPrimaryColor);

            await setRouteName("COMPANY_TRANSACTION");
            await setSearchText("");

            this.setState({
                isLoading : true,
                primaryColor: editPrimaryColor
            });

            await this.listCompanyTransaction();

        }catch(e){
            alertError('Company',e);
        } finally {
            this.setState({
                isLoading : false
            });
        }
    }

    listCompanyTransaction = async () => {
        try{
            const { authToken, history, setLoginFlag, setCompanyData, setCompanyToken, setListCompanyTrnData, setListCompanyTrnDataTotal, loginUserData } = this.props;

            this.setState({
                isLoading : true
            });

            let companyData = await getCompanyTrn(authToken, loginUserData.id, 0, 10);

            if(companyData.status == 200){
                //set user data
                await setListCompanyTrnData(companyData.data);
                await setListCompanyTrnDataTotal(companyData.total);

                this.setState({
                    companyList : companyData.data,
                    companyTotal : companyData.total 
                });
            }else if (companyData.status == 401) {
                await setLoginFlag(false);
                await setCompanyData(null);
                await setCompanyToken(null);
                history.push(`${process.env.PUBLIC_URL}/login`);
            } else {
                throw (companyData && companyData.message) ? companyData.message : "Error";
            }

        }catch(e){
            alertError('Company',e);
        } finally {
            this.setState({
                isLoading : false
            });
        }
    }

    listCompanyCoinTransaction = async () => {
        try{
            const { authToken, history, setLoginFlag, setCompanyData, setCompanyToken, setListCompanyTrnData, setListCompanyTrnDataTotal, loginUserData } = this.props;

            this.setState({
                isLoading : true
            });

            let companyData = await getAllCompanyCoinTrn(authToken, loginUserData.id, 0, 10);

            if(companyData.status == 200){
                //set user data
                await setListCompanyTrnData(companyData.data);
                await setListCompanyTrnDataTotal(companyData.total);

                this.setState({
                    companyList : companyData.data,
                    companyTotal : companyData.total 
                });
            }else if (companyData.status == 401) {
                await setLoginFlag(false);
                await setCompanyData(null);
                await setCompanyToken(null);
                history.push(`${process.env.PUBLIC_URL}/login`);
            } else {
                throw (companyData && companyData.message) ? companyData.message : "Error";
            }

        }catch(e){
            alertError('Company',e);
        } finally {
            this.setState({
                isLoading : false
            });
        }
    }

    listCompanyPayTransactions = async () => {
        try{
            const { authToken, history, setLoginFlag, setCompanyData, setCompanyToken, setListCompanyTrnData, setListCompanyTrnDataTotal, loginUserData } = this.props;

            this.setState({
                isLoading : true
            });

            let companyData = await getAllCompanyPayTrn(authToken, 0, 10, loginUserData.id);

            if(companyData.status == 200){
                //set user data
                await setListCompanyTrnData(companyData.data);
                await setListCompanyTrnDataTotal(companyData.total);

                this.setState({
                    companyList : companyData.data,
                    companyTotal : companyData.total 
                });
            }else if (companyData.status == 401) {
                await setLoginFlag(false);
                await setCompanyData(null);
                await setCompanyToken(null);
                history.push(`${process.env.PUBLIC_URL}/login`);
            } else {
                throw (companyData && companyData.message) ? companyData.message : "Error";
            }

        }catch(e){
            alertError('Company',e);
        } finally {
            this.setState({
                isLoading : false
            });
        }
    }

    tabChange = async (key) => {
        const { setRouteName } = this.props;

        this.setState({
            activeKey : key,
            isLoading : true
        });

        if(key === "1"){
            await setRouteName("COMPANY_TRANSACTION");
            this.listCompanyTransaction();
            return;
        } else if (key === '2') {
            await setRouteName("COMPANY_COIN_TRANSACTION");
            await this.listCompanyCoinTransaction()
        } else{
            await setRouteName("COMPANY_COIN_PAY_TRANSACTION");
            await this.listCompanyPayTransactions()
        }
    }

    render() {
        const {authToken, loginUserData} = this.props;
        const  {  primaryColor, companyList, isLoading, companyTotal } = this.state;
       
        return (
                <Fragment>
                        <Title parent="Transactions" title="Transactions" />
                        <div style={{ marginTop : "20px", display : "inline-block", width : "100%", marginBottom : "20px", paddingLeft : "14px", paddingRight: "55px"}}>
                            
                        </div>
                        <div className="container-fluid">
                            {
                                isLoading
                                ?
                                    <div style={{ marginLeft: "20px" }}>
                                        <Spin
                                        size="large"
                                        style={{ marginLeft: "480px", marginTop: "130px" }}
                                        />
                                    </div> 
                                :
                                <div>
                                    <Tabs activeKey={this.state.activeKey} onChange={(key) => this.tabChange(key)}>
                                        <TabPane tab="Transactions" key="1">
                                            <CompanyTrnRecordsTable
                                                data={companyList}
                                                primaryColor={primaryColor}
                                                companyTotal={companyTotal}
                                            />
                                        </TabPane>
                                        <TabPane tab="Coin Transactions" key="2">
                                            <CompanyCoinTransactionRecordsTable
                                                data={companyList}
                                                primaryColor={primaryColor}
                                                companyTotal={companyTotal}
                                            />
                                        </TabPane>

                                        <TabPane tab="Paid Transactions" key="3">
                                            <CompanyPayTransactionRecordsTable
                                                data={companyList}
                                                primaryColor={primaryColor}
                                                companyTotal={companyTotal}
                                            />
                                        </TabPane>
                                    </Tabs>
                                    
                                </div>
                            }

                        </div>
                    </Fragment>
                
        );
    }
}

const mapStateToProps = state => ({
    authToken: state.auth.authToken,
    loginUserData: state.auth.loginUserData
});
  
const mapDispatchToProps = (dispatch) => {
    return {
        setLoginFlag: (flag) => {
            dispatch({
                type: 'SET_LOGIN_FLAG',
                flag: flag
            });
        },
        setCompanyData: (companyData) => {
            dispatch({
                type: 'SET_USER_DATA',
                companyData: companyData
            });
        },
        setCompanyToken: (authToken) => {
            dispatch({
                type: 'SET_USER_AUTHTOKEN',
                authToken: authToken
            });
        },
        setListCompanyTrnData: (listCompanyData) => {

            console.log("setListCompanyTrnData");
            dispatch({
                type : "SET_LIST_COMPANY_TRN_DATA",
                listCompanyTrnData : listCompanyData
            })
        },
        setListCompanyTrnDataTotal: (listCompanyDataTotal) => {
            dispatch({
                type : "SET_LIST_COMPANY_TRN_DATA_TOTAL",
                listCompanyTrnDataTotal : listCompanyDataTotal
            })
        },
        setRouteName: (routeName) => {
            dispatch({
                type : "SET_ROUTE_NAME",
                routeName : routeName
            })
        },
        setSearchText: (searchText) => {
            dispatch({
                type : "SET_SEARCH_TEXT",
                searchText : searchText
            })
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompanyTrn));