import { Button, Form, Input } from "antd";
import { Formik } from "formik";
import React, { Component } from "react";
import ModalWrap from "../../common/modal";

export default class AddEditCityModal extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      data: [],
      visible: false,
      errors: {},
    };
  }

  handleSubmit(values) {
    console.log("valll", values);
    this.props.data
      ? this.props.onUpdateCityList(values, this.props.data.id)
      : this.props.onCreateCity(values);
  }
  render() {
    return (
      <React.Fragment>
        <ModalWrap
          visible={this.props.visible}
          closable={this.props.onClose}
          title={this.props.data ? "Edit City" : "Add City"}
          onOk={this.props.handleOk}
          onCancel={this.props.handleCancel}
          isLoading={this.props.isLoading}
        >
          <Formik
            initialValues={
              this.props.data
                ? {
                    city_name: this.props.data.name,
                    city_arrabic_name: this.props.data.arabic_name,
                    extra_price: this.props.data.price_percentage,
                  }
                : {
                    city_name: "",
                    city_arrabic_name: "",
                    extra_price: "",
                  }
            }
            onSubmit={this.handleSubmit}
          >
            {({
              values,

              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              return (
                <Form>
                  <div className="formik-field_wrap">
                    <div className="formik-field-left">
                      City Name
                      <Input
                        id="city_name"
                        placeholder="City Name"
                        value={values.city_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="formik-field-right">
                      City Arabic Name
                      <Input
                        id="city_arrabic_name"
                        placeholder="City Arabic Name"
                        value={values.city_arrabic_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>

                  <div className="formik-field_wrap">
                    <div className="formik-field-left">
                      Extra percentage
                      <Input
                        id="extra_price"
                        placeholder="Extra percentage"
                        value={values.extra_price}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>

                  <Button
                    style={{
                      marginTop: "19px",
                      background: "#5C6AC4",
                      color: "#fff",
                    }}
                    onClick={handleSubmit}
                  >
                    {this.props.data ? "Update" : "Save"}
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </ModalWrap>
      </React.Fragment>
    );
  }
}
