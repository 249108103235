import { deleteApiToServer } from "../../callApi";

export async function deleteCityAPI(
    authtoken,
    id
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    }
  
    let endUrl = `v1/auth/delete-city/${id}`;
    console.log("endUrl printed here", endUrl);
  
    let responseData = await deleteApiToServer(header, "DELETE", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}
