import { callApiToServer } from '../callApi';

export async function deleteCompany(
  authtoken,
  companyId
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    };
      
    let body = JSON.stringify({
       
    });
  
    let endUrl = "v1/auth/delete-company/" + companyId ;
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
      
  }catch(err) {
    throw err;
  }
}
