import { callGetApiToServer, callApiToServer } from '../callApi';

export async function getAllCompanyPayTrn(
  authtoken,
  skipNumber,
  limitNumber,
  companyId
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    }
  
    let endUrl = "v1/auth/list-company-pay-trn/" + companyId + "/" + skipNumber + "/" + limitNumber;
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callGetApiToServer(header, "GET", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}

export async function deleteCompanyPayTrn(
  authtoken,
  skipNumber,
  limitNumber,
  payTransactionId,
  companyId
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    }
  
    let endUrl = "v1/auth/delete-company-pay-trn/" + payTransactionId + "/" + companyId + "/" + skipNumber + "/" + limitNumber;
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callGetApiToServer(header, "GET", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}

export async function createCompanyPayTrn(
  authtoken,
  createObject
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    };

    let body = JSON.stringify(createObject);
  
    let endUrl = "v1/auth/create-company-pay-trn/";
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callApiToServer(body, header, "POST", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}
