import React, { Component } from "react";
import TableWrap from "../common/Table";
import { Button, Tooltip } from "antd";
import { dateFormat, timeFormat } from "../../utils/general";

class FreezeUserTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateFreezerUser: null,
    };
  }
  columns = [
    {
      title: "Sr. No.",
      width: 100,
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div key={"sno" + index}>
            <span>{++index}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      width: 250,
      key: "name",
      render: (text, item, index) => {
        return (
          <div key={"name" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column" style={{ marginTop: "5px" }}>
                {item.first_name + " " + item.last_name}
              </div>
            </div>
          </div>
        );
      },
    },

    {
      title: "Email",
      key: "email",
      width: 250,
      dataIndex: "email",
      render: (text, item, index) => {
        return <div key={"email" + index}>{item.email ? item.email : "-"}</div>;
      },
    },
    {
      title: "Mobile",
      key: "mobile",
      width: 150,
      render: (text, item, index) => {
        return <div>{item.phone ? item.phone : "-"}</div>;
      },
    },
    {
      title: "Date",
      key: "date",
      width: 200,
      render: (text, item, index) => {
        return <div>{dateFormat(item.freeze_time)}</div>;
      },
    },
    {
      title: "Time",
      key: "time",
      width: 150,
      render: (text, item, index) => {
        return <div>{timeFormat(item.freeze_time)}</div>;
      },
    },
    {
      title: "Actions",
      width: 220,
      key: "action",
      render: (text, item, index) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "10px",
              width: "100%",
              flexWrap: "wrap",
              cursor: "pointer",
            }}
          >
            {
              <div>
                <Tooltip placement="bottom" title="Edit">
                  <Button
                    shape="rectangle"
                    style={{ borderColor: "green", color: "green" }}
                    onClick={() => {
                      this.props.updateFreezeUser(item);
                    }}
                  >
                    UnFreez
                  </Button>
                </Tooltip>
              </div>
            }
          </div>
        );
      },
    },
  ];

  render() {
    return (
      <div>
        <TableWrap
          data={this.props?.freezeData}
          columns={this.columns}
          isLoading={this.props?.isLoading}
          total={this.props?.totalFreezeData}
        />
      </div>
    );
  }
}

export default FreezeUserTable;
