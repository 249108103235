

import { ENDPOINT } from '../../network/ENDPOINT';
import { callApiToServer } from '../callApi';



export async function updatePin(
    body,
    authToken  
) {    try {
        let header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "authorization": "Bearer " + authToken
        };

        let endUrl = `${ENDPOINT.update_wallet_pin}`;
       let payload = JSON.stringify(body);
        let responseData = await callApiToServer(payload,header, "POST", endUrl);

        return responseData;

    } catch (err) {
        throw err;
    }
}

