import { ENDPOINT } from "../../network/ENDPOINT";
import { callGetApiToServer } from "../callApi";

export async function getFreezerList(authtoken, city) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };
    let endUrl = ENDPOINT.freeze_user_list; 
    if (city) {
      endUrl += `?city=${city}`;
    }
    let responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
