import { callApiToServer } from '../callApi';

export async function getAllWalletTransactionsFilter(
  authtoken,
  orderCode,
  skipNumber,
  limitNumber
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    };
      
    let body = JSON.stringify({
        orderCode: orderCode,
    });
  
    let endUrl = "v1/auth/list-user-wallet-transactions-filter/" + skipNumber + "/" + limitNumber;
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callApiToServer(body, header, "POST", endUrl);
      
    return responseData;
      
  }catch(err) {
    throw err;
  }
}
