import { Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, Select } from "antd";
import { Formik } from "formik";
import styled from "styled-components";
import { passwordValidation } from "../../utils/validations";
import { errorMessages } from "../../utils/validationMessages";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class PasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.handleCloseTime = this.handleCloseTime.bind(this);
    this.handleDetailsModalclose = this.handleDetailsModalclose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      locationList: [],
      recordSelectedtoView: null,
      data: [],
      view: false,
      index: null,
      isLoading: false,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: false,
      inputVisible: false,
      inputValue: "",
      time: "",
      closeTime: "",
      closeTimeString: "",
      timeString: "",
      roleType: "1",
    };
  }

  input;

  componentDidMount() {}

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    amenities = value;
  }

  validateForm(values) {
    const errors = {};

    if (!values.password) {
      errors.password = errorMessages.password_required;
    } else if (passwordValidation(values.password)) {
      errors.password = errorMessages.password_validation;
    }

    return errors;
  }

  handleSubmit(values, action) {
    let result = this.props.onSubmit(values, this.props.data.id);
    if (result) {
      this.props.handleCancel();

      this.setState({
        visible: false,
      });
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleTime(e, s) {
    this.setState({
      time: e,
      timeString: s,
    });
  }

  handleCloseTime(e, s) {
    this.setState({
      closeTime: e,
      closeTimeString: s,
    });
  }

  handleDetailsModalclose(item) {
    this.setState({
      view: false,
      lat: item.lat,
      long: item.lng,
    });
  }

  render() {
    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                password: "",
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-wrap">
                        Password
                        <Input
                          id="password"
                          placeholder="Password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="password"
                        />
                        {errors.password && touched.password ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.password}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <Button
                      // type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Update
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
