import {
  Table,
  Tooltip,
  notification,
  Spin,
  Select,
  Input,
  Button,
  Modal,
} from "antd";
import * as React from "react";
import { get } from "lodash";

import { getCompanyTrn } from "../../Graphs/CompanyTrn/listCompanyTrn";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { Users, List, Gift, DollarSign, CreditCard, Flag } from "react-feather";
import _ from "lodash";
import { alertError } from "../../utils/alert";
var moment = require("moment");
const { Option } = Select;

class CompanyTrn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      coinList: [],
      dataTotal: 0,
      isLoading: false,
      startIndex: 0,
      limitNumber: 5,
      pagination: {
        pageSize: 5,
        current: 1,
      },
      errors: {},
      price: 0,
      cardData: [],
      company_id: null,

      visible: false,
      closable: true,
    };
    this.dateFormat = this.dateFormat.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }

    console.log("[CONSOLE LOG]", this.props.companyData);

    this.listUserCoinData(0, 5);
  }

  getLimitedUserCoin = async (pagination) => {
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        this.listUserCoinData(start, end);
      }
    );
  };

  listUserCoinData = async (start, end) => {
    try {
      const {
        companyData,
        authToken,
        history,
        setListCompanyTrnDataData,
        setListCompanyTrnDataTotal,
        setLoginFlag,
        setUserData,
        setUserToken,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let companyTrnData = await getCompanyTrn(
        authToken,
        companyData.id,
        start,
        end
      );
      console.log("!!!!!!!!!!!!companyTrnData", companyTrnData);

      if (companyTrnData.status == 200) {
        this.setState({
          isLoading: false,
          data: companyTrnData.data,
          dataTotal: companyTrnData.total,
        });
        console.log("!!!!!!!!!!!!companyTrnData", companyTrnData);

        await setListCompanyTrnDataData(companyTrnData.data);
        await setListCompanyTrnDataTotal(companyTrnData.total);
      } else if (companyTrnData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw companyTrnData && companyTrnData.message
          ? companyTrnData.message
          : "Error";
      }
    } catch (e) {
      alertError("Company", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedUserCoin(pagination)
    );
  }

  dateFormat(date) {
    var oldDate = new Date(date),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.add(2, "hours").format("Do MMM YYYY");

    return newDate;
  }

  onChangeCoin = (value) => {
    this.setState({
      coin: value,
    });
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show() {
    this.setState({
      visible: true,
    });
  }

  hide() {
    this.setState({
      visible: false,
    });
    this.props.onClose();
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "User",
      key: "user",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                    <div>
                      {item.user && item.user.email ? item.user.email : "-"}
                    </div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "User Shop Name",
      width: 100,
      key: "",
      render: (text, item, index) => {
        return (
          <div>
            {item.user && item.user.shop_name ? (
                item.user.shop_name
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Company Name",
      width: 100,
      key: "",
      render: (text, item, index) => {
        return (
          <div>
            {item.company && item.company.name ? (
                item.company.name
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Customer Name",
      key: "customer_name",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                    <div>
                      {item.customer_name !== null ? item.customer_name : "-"}
                    </div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Voucher Code",
      key: "voucher_code",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                    <div>{item.voucher_code ? item.voucher_code : ""}</div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Coin Name",
      key: "CoinName",
      render: (text, item, index) => {
        return (
          <div>
              {item.coin ? item.coin.name : "-"}
          </div>
        );
      },
    },
    {
      title: "Commission",
      key: "Commission",
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                  <div>
                    {item.discount !== null ? item.discount + " %" : "-"}
                  </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Commission Amount (LYD)",
      key: "commission_amount",
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                    <div>
                      {item.discount_amount !== null
                        ? item.discount_amount
                        : "-"}
                    </div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Actual Amount (LYD)",
      key: "actual_amount",
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                    <div>
                      {item.actual_amount !== null ? item.actual_amount : "-"}
                    </div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Net Amount (LYD)",
      key: "amount",
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                    <div>{item.amount !== null ? item.amount : "-"}</div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Customer Phone",
      key: "customer_phone",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                    <div>
                      {item.customer_phone !== null ? item.customer_phone : "-"}
                    </div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  render() {
    const { isLoading, data, dataTotal } = this.state;

    return (
      <Modal
        width="1200px"
        closable={this.state.closable}
        open={this.state.visible}
        title="Company Transactions"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        {isLoading ? (
          <div style={{ marginLeft: "20px" }}>
            <Spin size="large" style={{ margin: "0 auto", width: "100%" }} />
          </div>
        ) : (
          <React.Fragment>
            <div style={{ "overflow-x": "auto" }}>
              <Table
                loading={this.state.isLoading}
                rowKey={(record) => record._id}
                columns={this.columns}
                size={"small"}
                scroll={{ x: 1400 }}
                // dataSource={this.state.data}
                dataSource={data}
                pagination={{
                  total: dataTotal,
                  showSizeChanger: true,
                  pageSize: this.state.pagination.pageSize,
                  current: this.state.pagination.current,
                  pageSizeOptions: ["1", "10", "25", "50", "100"],
                  onChange: (e) => {
                    console.log("onChanges pagination");
                    // e - 1
                    //   ? (this.counter = this.tempCounter =
                    //       (e - 1) * this.state.pagination.pageSize)
                    //   : ((this.counter = 0), (this.tempCounter = 0));
                    if (e - 1) {
                      this.counter = this.tempCounter =
                        (e - 1) * this.state.pagination.pageSize;
                      return;
                    }

                    this.counter = 0;
                    this.tempCounter = 0;
                  },
                }}
                onChange={this.handleTableChange}
              />
            </div>
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listCompanyTrnDataData: state.company_trn.listCompanyTrnData,
  listCompanyTrnDataTotal: state.company_trn.listCompanyTrnDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCompanyTrnDataData: (listCompanyTrnData) => {
      dispatch({
        type: "SET_LIST_COMPANY_TRN_DATA",
        listCompanyTrnData: listCompanyTrnData,
      });
    },
    setListCompanyTrnDataTotal: (listCompanyTrnDataTotal) => {
      dispatch({
        type: "SET_LIST_COMPANY_TRN_DATA_TOTAL",
        listCompanyTrnDataTotal: listCompanyTrnDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompanyTrn));
