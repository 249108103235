
import { callApiToServer } from '../callApi';

export async function addUserCoinByDistributor(
    authtoken,
    coinObject,
) {
    try {
        let header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "authorization": "Berear " + authtoken
        };

        let endUrl = "v1/auth/add-balance-user";

        let responseData = await callApiToServer(JSON.stringify(coinObject), header, "POST", endUrl);

        return responseData;

    } catch (err) {
        throw err;
    }
}
