import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
} from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllCompany } from "../../Graphs/Company/listCompany";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
  CiCircleOutlined,
  PlusCircleOutlined,
  WalletOutlined,
  TransactionOutlined,
  PayCircleOutlined,
    LockOutlined,
  StockOutlined,
} from "@ant-design/icons";
import { deleteCompany } from "../../Graphs/Company/deleteCompany";
import { changeStatus } from "../../Graphs/Company/changeStatus";
import { editCompany } from "../../Graphs/Company/editCompany";
import { searchCompany } from "../../Graphs/Company/searchCompany";
import { EditModal } from "./EditModal";
import CompanyTrn from "./CompanyTrn";
import CompanyCoinTrn from "./CompanyCoinTrn";
import { WalletModal } from "./WalletModal";
import { AddCompanyCoinModal } from "./AddCompanyCoinModal";
import CompanyPayCoinTrn from "./CompanyPayCoinTrn";
import { changePassword } from "../../Graphs/Company/changePassword";
import { PasswordModal } from "./PasswordModal";
import { alertError } from "../../utils/alert";

var moment = require("moment");

const BASE_IMAGE_URL =
  "https://s3.us-east-2.amazonaws.com/cerebrus-consulting/bloodman_user_media/";

class CompanyRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      userPermission: {
        delete: false,
        edit: false,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedUsers = this.getLimitedUsers.bind(this);
  }

  async componentDidMount() {
    this.setState({
      data: this.props.data,
      isLoading: false,
      userSelectedData: null,
      userTrasactionData: null,
    });

    let permission = JSON.parse(this.props.loginUserData.access_permission);
    this.setState({
      userPermission: {
        edit:
          (permission && permission["company"] && permission["company"].edit) ||
          false,
        delete:
          (permission &&
            permission["company"] &&
            permission["company"].delete) ||
          false,
      },
    });
  }

  modalRef;
  transactionRef;
  modalPasswordRef;
  payRef;
  deletePermission = false;
  editPermission = false;

  async getLimitedUsers(pagination) {
    const { searchText } = this.props;
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText != "") {
          //get search text props from redux store
          this.listSearchUserData(start, end);
        } else {
          //call normal user Data
          this.listCompanyData(start, end);
        }
      }
    );
  }

  listSearchUserData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCompanyData,
        setListCompanyDataTotal,
        searchText,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let companyData = await searchCompany(authToken, searchText, start, end);

      if (companyData.status == 200) {
        //set user Data
        await setListCompanyData(companyData.data);
        await setListCompanyDataTotal(companyData.total);

        this.setState({
          isLoading: false,
          data: companyData.data,
        });
      } else if (companyData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw companyData && companyData.message
          ? companyData.message
          : "Error";
      }
    } catch (e) {
      console.log("!!!!!!!list search user Data printed here", e);
      alertError("Company", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listCompanyData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCompanyData,
        setListCompanyDataTotal,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let companyData = await getAllCompany(authToken, start, end);

      if (companyData.status == 200) {
        //set user Data
        await setListCompanyData(companyData.data);
        await setListCompanyDataTotal(companyData.total);

        this.setState({
          isLoading: false,
          data: companyData.data,
        });
      } else if (companyData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw companyData && companyData.message
          ? companyData.message
          : "Error";
      }
    } catch (e) {
      alertError("Company", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.add(2, "hours").format("Do MMM YYYY");

    return newDate;
  }

  showModal(item) {
    this.setState(
      {
        userSelectedData: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  showPasswordModal(item) {
    this.setState(
      {
        userPasswordSelectedData: item,
        // isOpen: true
      },
      () => {
        this.modalPasswordRef.show();
      }
    );
  }

  showUserCoinstModal(item) {
    this.setState(
      {
        userSelectedDataForCoin: item,
        // isOpen: true
      },
      () => {
        this.modalUserCoinsRef.show();
      }
    );
  }

  handleDetailsModalclose() {
    this.setState({
      userSelectedData: null,
      userPasswordSelectedData: null,
      userSelectedDataForCoin: null,
    });
  }

  showTrnModal = (item) => {
    console.log(">>>>>>>>>>>>", item);
    this.setState(
      {
        companyData: item,
      },
      () => {
        this.transactionRef.show();
      }
    );
  };

  showUserTransactions = (item) => {
    this.setState(
      {
        userTrasactionData: item,
      },
      () => {
        this.transactionRef.show();
      }
    );
  };

  showWallet = (item) => {
    this.setState(
      {
        walletCompanyData: item,
      },
      () => {
        this.walletRef.show();
      }
    );
  };

  showCoinTrn = (item) => {
    this.setState(
      {
        companyCoinData: item,
      },
      () => {
        this.transactionRef.show();
      }
    );
  };

  showCoinPayTrn = (item) => {
    this.setState(
      {
        companyCoinPayTrnData: item,
      },
      () => {
        this.payTrnRef.show();
      }
    );
  };

  showCoinPay = (item) => {
    this.setState(
      {
        companyCoinPayData: item,
      },
      () => {
        this.payRef.show();
      }
    );
  };

  handleTransactionPayModalclose() {
    this.setState({
      companyData: null,
      companyCoinPayData: null,
    });
  }

  handleTransactionModalclose() {
    this.setState({
      companyData: null,
      companyCoinData: null,
    });
  }

  handlePayTransactionModalclose() {
    this.setState({
      companyData: null,
      companyCoinPayTrnData: null,
    });
  }

  handleWalletModalclose() {
    this.setState({
      walletCompanyData: null,
    });
  }

  handleDeleteTransactionModalclose() {
    this.setState({
      userTrasactionData: null,
    });
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Company Name",
      key: "name",
      render: (text, item, index) => {
        console.log("user record --> ");
        return <div>{item.name ? item.name : "-"}</div>;
      },
    },
    {
      title: "Arabic Name",
      key: "arabic_name",
      render: (text, item, index) => {
        return <div>{item.name ? item.arabic_name : "-"}</div>;
      },
    },
    {
      title: "Contact Person",
      key: "contact_name",
      render: (text, item, index) => {
        return <div>{item.contact_name ? item.contact_name : "-"}</div>;
      },
    },
    {
      title: "Contact Phone",
      key: "contact_phone",
      render: (text, item, index) => {
        console.log("user record --> ");
        return <div>{item.phone ? item.phone : "-"}</div>;
      },
    },
    {
      title: "Email",
      key: "email",
      render: (text, item, index) => {
        return <div>{item.email ? item.email : "-"}</div>;
      },
    },
    {
      title: "Commission",
      key: "Commission",
      render: (text, item, index) => {
        return <div>{item.commission ? item.commission : "-"}</div>;
      },
    },
    {
      title: "Actions",
      width: 130,
      key: "action",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            {this.props.loginUserData.role == "Admin" ||
            this.props.loginUserData.role == "Company" ||
            (this.state.userPermission && this.state.userPermission.edit) ? (
              <>
                <div
                  mr="5px"
                  style={{
                    float: "left",
                    marginRight: "10px",
                    marginBottom: "5px",
                  }}
                >
                  <Tooltip placement="bottom" title="Edit">
                    <Button
                      shape="circle"
                      icon={<EditOutlined />}
                      onClick={() => {
                        console.log("isOpen value --- ", this.state.isOpen);
                        this.showModal(item);
                      }}
                    />
                  </Tooltip>
                </div>
                <div
                  mr="5px"
                  style={{
                    float: "left",
                    marginRight: "10px",
                    marginBottom: "5px",
                  }}
                >
                  <Tooltip placement="bottom" title="Change Password">
                    <Button
                      shape="circle"
                      icon={<LockOutlined />}
                      onClick={() => {
                        console.log("isOpen value --- ", this.state.isOpen);
                        this.showPasswordModal(item);
                      }}
                    />
                  </Tooltip>
                </div>
              </>
            ) : null}
            {this.props.loginUserData.role == "Admin" ||
            this.props.loginUserData.role == "Company" ||
            (this.state.userPermission && this.state.userPermission.delete) ? (
              <div
                ml="15px"
                mr="5px"
                style={{
                  float: "left",
                  marginRight: "10px",
                  marginBottom: "5px",
                }}
              >
                <Tooltip placement="bottom" title="Delete">
                  <Popconfirm
                    onConfirm={this.deleteCompany.bind(this, item)}
                    title="Are you sure you want to delete company ?"
                  >
                    <Button shape="circle" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
            ) : null}
            <div
              mr="5px"
              style={{
                float: "left",
                marginRight: "10px",
                marginBottom: "5px",
              }}
            >
              <Tooltip placement="bottom" title="Transactions">
                <Button
                  shape="circle"
                  icon={<TransactionOutlined />}
                  onClick={() => {
                    console.log("isOpen value --- ", this.state.isOpen);
                    this.showTrnModal(item);
                  }}
                />
              </Tooltip>
            </div>
            <div
              ml="15px"
              mr="5px"
              style={{
                float: "left",
                marginRight: "10px",
                marginBottom: "5px",
              }}
            >
              <Tooltip placement="bottom" title="Coin Transaction">
                <Button
                  shape="circle"
                  icon={<TransactionOutlined />}
                  onClick={() => {
                    console.log("isOpen value --- ", this.state.isOpen);
                    this.showCoinTrn(item);
                  }}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
    {
      title: "Wallet",
      width: 130,
      key: "Wallet",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%", marginTop:"-35px" }}>
            <div
              ml="15px"
              mr="5px"
              style={{
                float: "left",
                marginRight: "10px",
                marginBottom: "5px",
              }}
            >
              <Tooltip placement="bottom" title="Coin Balance">
                <Button
                  shape="circle"
                  icon={<StockOutlined />}
                  onClick={() => {
                    console.log("isOpen value --- ", this.state.isOpen);
                    this.showWallet(item);
                  }}
                />
              </Tooltip>
            </div>
            <div
              ml="15px"
              mr="5px"
              style={{
                float: "left",
                marginRight: "10px",
                marginBottom: "5px",
              }}
            >
              <Tooltip placement="bottom" title="Coin Pay">
                <Button
                  shape="circle"
                  icon={<PayCircleOutlined />}
                  onClick={() => {
                    console.log("isOpen value --- ", this.state.isOpen);
                    this.showCoinPay(item);
                  }}
                />
              </Tooltip>
            </div>
            <div
              ml="15px"
              mr="5px"
              style={{
                float: "left",
                marginRight: "10px",
                marginBottom: "5px",
              }}
            >
              <Tooltip placement="bottom" title="Paid Transaction">
                <Button
                  shape="circle"
                  icon={<TransactionOutlined />}
                  onClick={() => {
                    console.log("isOpen value --- ", this.state.isOpen);
                    this.showCoinPayTrn(item);
                  }}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  async deleteCompany(item) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
        setListCompanyData,
        setListCompanyDataTotal,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let deleteData = await deleteCompany(authToken, item.id);

      if (deleteData.status == 200) {
        notification["success"]({
          message: "Delete User",
          description: "Successfully deleted",
        });

        this.listCompanyData(startIndex, limitNumber);
      } else if (deleteData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw deleteData && deleteData.message ? deleteData.message : "Error";
      }
    } catch (e) {
      alertError("Company", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onChange(item, checked) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeStatus(authToken, item.id, checked);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Change User Status",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText != "") {
          this.listSearchUserData(startIndex, limitNumber);
          return;
        }
        this.listCompanyData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (err) {
      alertError("Company", err);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  editSubmit = async (userObject) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await editCompany(
        authToken,
        userObject,
        userObject.employeeId
      );
      if (statusData.status == 200) {
        notification["success"]({
          message: "Edit Company",
          description: "Successfully edited",
        });
        if (searchText != null && searchText != "") {
          this.listSearchUserData(startIndex, limitNumber);
          return;
        }
        this.listCompanyData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      alertError("Company", e);
    }
  };

  editPasswordSubmit = async (passwordObject, companyId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changePassword(
        authToken,
        passwordObject,
        companyId
      );
      if (statusData.status == 200) {
        notification["success"]({
          message: "Change Company Password",
          description: "Successfully changed",
        });
        if (searchText != null && searchText != "") {
          this.listSearchUserData(startIndex, limitNumber);
          return;
        }
        this.listCompanyData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      alertError("Company", e);
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedUsers(pagination)
    );
  }

  render() {
    const { isLoading } = this.state;
    const { listCompanyData, listCompanyDataTotal, searchLoader, authToken } =
      this.props;

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          size={"small"}
          // dataSource={this.state.data}
          dataSource={listCompanyData}
          pagination={{
            total: listCompanyDataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />

        {this.state.userSelectedData ? (
          <EditModal
            admin={false}
            refx={(e) => (this.modalRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            userSelectedData={this.state.userSelectedData}
            onSubmit={this.editSubmit}
            authToken={this.props.authToken}
          />
        ) : null}

        {this.state.userPasswordSelectedData ? (
          <PasswordModal
            admin={false}
            refx={(e) => (this.modalPasswordRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            userPasswordSelectedData={this.state.userPasswordSelectedData}
            onSubmit={this.editPasswordSubmit}
            authToken={this.props.authToken}
          />
        ) : null}

        {this.state.companyData ? (
          <CompanyTrn
            admin={false}
            refx={(e) => (this.transactionRef = e)}
            onClose={this.handleTransactionModalclose.bind(this)}
            companyData={this.state.companyData}
          />
        ) : null}

        {this.state.companyCoinData ? (
          <CompanyCoinTrn
            admin={false}
            refx={(e) => (this.transactionRef = e)}
            onClose={this.handleTransactionModalclose.bind(this)}
            companyData={this.state.companyCoinData}
          />
        ) : null}

        {this.state.walletCompanyData ? (
          <WalletModal
            admin={false}
            refx={(e) => (this.walletRef = e)}
            onClose={this.handleWalletModalclose.bind(this)}
            data={this.state.walletCompanyData}
          />
        ) : null}
        {this.state.companyCoinPayData ? (
          <AddCompanyCoinModal
            admin={false}
            refx={(e) => (this.payRef = e)}
            onClose={this.handleTransactionPayModalclose.bind(this)}
            companyCoinPayData={this.state.companyCoinPayData}
            authToken={this.props.authToken}
          />
        ) : null}
        {this.state.companyCoinPayTrnData ? (
          <CompanyPayCoinTrn
            admin={false}
            refx={(e) => (this.payTrnRef = e)}
            onClose={this.handlePayTransactionModalclose.bind(this)}
            companyData={this.state.companyCoinPayTrnData}
            authToken={this.props.authToken}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listCompanyData: state.company.listCompanyData,
  listCompanyDataTotal: state.company.listCompanyDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (companyData) => {
      dispatch({
        type: "SET_COMPANY_DATA",
        companyData: companyData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_COMPANY_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCompanyData: (listCompanyData) => {
      dispatch({
        type: "SET_LIST_COMPANY_DATA",
        listCompanyData: listCompanyData,
      });
    },
    setListCompanyDataTotal: (listCompanyDataTotal) => {
      dispatch({
        type: "SET_LIST_COMPANY_DATA_TOTAL",
        listCompanyDataTotal: listCompanyDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompanyRecordsTable));
