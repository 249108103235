import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, TimePicker, Select } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { priceValidation } from "../../utils/validations";
import { errorMessages } from "../../utils/validationMessages";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class AddCompanyCoinForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.handleCloseTime = this.handleCloseTime.bind(this);
    this.handleDetailsModalclose = this.handleDetailsModalclose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      locationList: [],
      recordSelectedtoView: null,
      data: [],
      view: false,
      index: null,
      isLoading: false,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: false,
      inputVisible: false,
      inputValue: "",
      time: "",
      closeTime: "",
      closeTimeString: "",
      timeString: "",
      roleType: "1",
      selectedCoin: "",
      price: 0,
      errors: {},
      coinsList: [],
    };
  }

  input;

  componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }
    console.log("TEST coinsData component did mount", this.props.coinsData);
    this.setState({
      coinsList: this.props.coinsData,
    });
  }

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  validateForm(values) {
    const errors = {};

    if (!values.price) {
      errors.price = errorMessages.price_required;
    } else if (priceValidation(values.price)) {
      errors.price = errorMessages.price_validation;
    }

    return errors;
  }

  async handleSubmit(values, action) {
    console.log("Basic Value - ", values, this.props.data.id);

    const { selectedCoin, errors } = this.state;

    let listErrors = errors;

    if (!selectedCoin) {
      listErrors = {
        selectedCoin: "Select Coin is required.",
      };

      this.setState(
        {
          errors: listErrors,
        },
        () => {
          console.log("!!!!!!!!!!!!!!!!!!errors", this.state.errors);
        }
      );

      return;
    } else {
      listErrors = {
        selectedCoin: "",
      };
    }

    this.setState({
      errors: {},
    });

    values.coin_id = selectedCoin;
    values.price = parseFloat(values.price);

    let result = await this.props.onSubmit(values, this.props.data.id);

    if (result) {
      this.props.handleCancel();

      this.setState({
        visible: false,
      });
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleTime(e, s) {
    this.setState({
      time: e,
      timeString: s,
    });
  }

  handleCloseTime(e, s) {
    this.setState({
      closeTime: e,
      closeTimeString: s,
    });
  }

  handleDetailsModalclose(item) {
    this.setState({
      view: false,
      lat: item.lat,
      long: item.lng,
    });
  }

  onChangeCoin = (value) => {
    this.setState({
      selectedCoin: value,
    });
  };

  render() {
    const { selectedCoin, coinsList } = this.state;
    const { walletDataList } = this.props;

    let balanceData =
      walletDataList &&
      walletDataList.filter((data, index) => {
        return data.coin_id === selectedCoin;
      });

    let balance =
      balanceData && balanceData.length > 0
        ? balanceData[0]["total_wallet_balance"]
        : 0;

    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                price: 0,
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Select Coin
                        <Select
                          showSearch
                          style={{ width: 200 }}
                          placeholder="Select Coin"
                          value={selectedCoin}
                          optionFilterProp="children"
                          onChange={this.onChangeCoin}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value="">Select Coin</Option>

                          {coinsList &&
                            coinsList.map((item) => {
                              return (
                                <Option value={item.id} key={"_" + item.id}>
                                  {item.name}
                                </Option>
                              );
                            })}
                        </Select>
                        {selectedCoin ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {`Balance : ${balance}`}
                          </p>
                        ) : null}
                        {errors.selectedCoin ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.selectedCoin}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Price
                        <Input
                          id="price"
                          placeholder="price"
                          value={values.price}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.price && touched.price ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.price}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <Button
                      // type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
