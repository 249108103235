import React, { Fragment, useEffect, useState } from 'react';
import man from '../../../assets/images/dashboard/boy-2.png'
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import { connect } from "react-redux";
import { withRouter } from "react-router";

const UserPanel = ({adminName, balance,balanceData,loginUserData}) => {
    const url= '';
    
    const [balanceAmount, setBalanceAmount] = useState()
    
    useEffect(() => {
        setBalanceAmount(parseFloat(balanceData == 0 ? balance : balanceData).toFixed(2) )
    }, [balanceData,balance])
    
    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div>
                    <img className="img-60 rounded-circle lazyloaded blur-up" src={url ? url : man} alt="#" />
                    {/* <div className="profile-edit">
                        <Link to="#">
                            <Edit />
                        </Link>
                    </div> */}
                </div>
                <h6 className="mt-3 f-14">{adminName}</h6>
               {loginUserData && loginUserData.role == "Distributor" && balance && <h6 className='f-14'> Balance : {balanceAmount}</h6>}
            </div>
        </Fragment>
    );
};

const mapStateToProps = state => ({
    balanceData: state.distributor.distributorBalance,
    loginUserData: state.auth.loginUserData
});
  
export default connect(mapStateToProps)(withRouter(UserPanel));