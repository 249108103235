import { Button, notification, Popconfirm, Tooltip } from "antd";
import React, { Component } from "react";
import TableWrap from "../../common/Table";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { getCityListAPI } from "../../../Graphs/Master/City/getCityListAPI";
import AddEditCityModal from "./AddCityModal";
import { addCityAPI } from "../../../Graphs/Master/City/addCityAPI";
import { updateCityAPI } from "../../../Graphs/Master/City/updateCityAPI";
import { deleteCityAPI } from "../../../Graphs/Master/City/deleteCityAPI";
import { alertError } from "../../../utils/alert";
import { withRouter } from "react-router";
import { connect } from "react-redux";
class CityRecordsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      addModalVisible: false,
      cityData: [],
      isCityEdit: null,
      totalCity: 0,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    // this.handleTableChange = this.handleTableChange.bind(this);
  }
  componentDidMount() {
    const { setRouteName } = this.props;
    this.getCityListData();
    setRouteName("CITY");
  }

  getCityListData = async () => {
    try {
      const { loginUserData, history } = this.props;
      let cityListData = await getCityListAPI(loginUserData.authToken);

      this.setState({
        isLoading: true,
      });
      if (cityListData.status == 200) {
        this.setState({
          cityData: cityListData.data,
          totalCity: cityListData.total_user,
          isLoading: false,
        });
      } else if (cityListData.status == 401) {
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw cityListData && cityListData.message
          ? cityListData.message
          : "Error";
      }
    } catch (e) {
      alertError("CITY", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  createCity = async (value) => {
    try {
      this.setState({
        isLoading: true,
      });
      const { loginUserData } = this.props;
      let createCityData = await addCityAPI(loginUserData.authToken, value);

      console.log("createCityData", createCityData);
      if (createCityData.status == 200) {
        this.setState({
          isLoading: true,
        });

        notification["success"]({
          message: "success",
          description: createCityData.message,
        });
        this.handleOk();
        this.getCityListData();
        return 1;
      } else {
        throw createCityData.message;
      }
    } catch (error) {
      // this.setState({
      //     isLoading: false,
      // });
      alertError("CITY", error);

      return -1;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  editCity = async (value, id) => {
    try {
      this.setState({
        isLoading: true,
      });
      const { loginUserData } = this.props;
      let updateCityList = await updateCityAPI(
        loginUserData.authToken,
        value,
        id
      );

      console.log("updateCityList", updateCityList);
      if (updateCityList.status == 200) {
        this.setState({
          isLoading: true,
        });

        notification["success"]({
          message: "success",
          description: updateCityList.message,
        });
        this.handleOk();
        this.getCityListData();
        return 1;
      } else {
        throw updateCityList.message;
      }
    } catch (error) {
      alertError("CITY", error);
      return -1;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  deleteCityList = async (item) => {
    try {
      this.setState({
        isLoading: true,
      });
      const { loginUserData } = this.props;
      let deleteCity = await deleteCityAPI(loginUserData.authToken, item.id);

      console.log("deleteCity", deleteCity);
      if (deleteCity.status == 200) {
        this.setState({
          isLoading: true,
        });
        notification["success"]({
          message: "success",
          description: deleteCity.message,
        });
        this.handleOk();
        this.getCityListData();
        return 1;
      } else {
        throw deleteCity.message;
      }
    } catch (error) {
      alertError("CITY", error);
      return -1;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };
  async getLimitedData(pagination) {
    const page = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: page,
        limitNumber: limit,
      },
      async () => {
        this.getCityListData();
      }
    );
  }

  columns = [
    {
      title: "Sr. No.",
      width: 100,
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div key={"sno" + index}>
            <span>{++index}</span>
          </div>
        );
      },
    },
    {
      title: "City Name",
      width: 200,
      key: "name",
      render: (text, item, index) => {
        return (
          <div key={"name" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column" style={{ marginTop: "5px" }}>
                {item.name}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Arabic Name",
      width: 200,
      key: "arabic name",
      render: (text, item, index) => {
        return (
          <div key={"arabic name" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column" style={{ marginTop: "5px" }}>
                {item.arabic_name}
              </div>
            </div>
          </div>
        );
      },
    },

    {
      title: "Extra Price (Percentage)",
      key: "extra_price",
      width: 150,
      render: (text, item, index) => {
        return (
          <div key={"extra price" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column" style={{ marginTop: "5px" }}>
                {item.price_percentage ? item.price_percentage : "--"}
              </div>
            </div>
          </div>
        );
      },
    },

    {
      title: "Actions",
      width: 180,
      key: "action",
      render: (text, item, index) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "10px",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            {
              <div>
                <Tooltip placement="bottom" title="Edit">
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      this.setState({
                        isCityEdit: item,
                      });
                    }}
                  />
                </Tooltip>
              </div>
            }
            {
              <div>
                <Tooltip placement="bottom" title="Delete">
                  <Popconfirm
                    onConfirm={() => this.deleteCityList(item)}
                    title="Are you sure you want to delete city"
                  >
                    <Button shape="circle" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
            }
          </div>
        );
      },
    },
  ];

  handleOk = (e) => {
    this.setState({
      addModalVisible: false,
      isCityEdit: null,
    });
  };

  handleCancel = (e) => {
    this.setState({
      addModalVisible: false,
      isCityEdit: null,
    });
  };

  render() {
    return (
      <div style={{ display: "inline-block", width: "100%" }}>
        <div style={{ float: "left", cursor: "pointer", width: "100%" }}>
          <div
            onClick={() => {
              this.setState({
                addModalVisible: true,
              });
            }}
            style={{
              cursor: "pointer",
              width: "100px",
              padding: "7px 15px",
              backgroundColor: "rgb(57, 73, 171)",
              color: "white",
              textAlign: "center",
              borderRadius: "50px",
              textTransform: "capitalize",
            }}
          >
            ADD
          </div>
        </div>
        <div style={{ float: "left", width: "100%", marginTop: "20px" }}>
          <TableWrap
            data={this.state.cityData}
            columns={this.columns}
            isLoading={this.props.isLoading}
            total={this.state.totalCity}
            // pagination={this.state.pagination}
            // handleTableChange={this.handleTableChange}
            scroll={{ x: 800 }}
          />
        </div>
        {this.state.addModalVisible && (
          <AddEditCityModal
            visible={this.state.addModalVisible}
            handleCancel={this.handleCancel}
            handleOk={this.handleOk}
            onClose={this.handleCancel}
            isLoading={this.state.isLoading}
            onCreateCity={this.createCity}
          />
        )}
        {this.state.isCityEdit && (
          <AddEditCityModal
            visible={this.state.isCityEdit}
            handleCancel={this.handleCancel}
            handleOk={this.handleOk}
            onClose={this.handleCancel}
            data={this.state.isCityEdit}
            isLoading={this.state.isLoading}
            onUpdateCityList={this.editCity}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CityRecordsTable));
