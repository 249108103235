import {
  Modal,
  Button,
  Divider,
  Switch,
  Checkbox,
  Row,
  Col,
  notification,
  Spin,
} from "antd";
import * as React from "react";
import { Layout, Input, TimePicker, Select } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { findByEmail } from "../../Graphs/Employee/searchEmployee";
import { alertError } from "../../utils/alert";
import {
  emailValidation,
  mobileLengthValidation,
  mobileValidation,
  passwordValidation,
} from "../../utils/validations";
import { errorMessages } from "../../utils/validationMessages";
import { StyleSelect } from "../../assets/css/style";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;
var amenities = [];

export class AddSubAdminForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.handleCloseTime = this.handleCloseTime.bind(this);
    this.handleDetailsModalclose = this.handleDetailsModalclose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      cityData: [],
      locationList: [],
      recordSelectedtoView: null,
      data: [],
      view: false,
      index: null,
      isLoading: false,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: false,
      inputVisible: false,
      inputValue: "",
      time: "",
      closeTime: "",
      closeTimeString: "",
      timeString: "",
      roleType: "1",
      selectedCoin: "",
      price: 0,
      errors: {},
      coinsList: [],
      dashboard: {
        view: false,
        edit: false,
        delete: false,
      },
      master: {
        view: false,
        edit: false,
        delete: false,
      },
      card: {
        view: false,
        edit: false,
        delete: false,
      },
      user: {
        view: false,
        edit: false,
        delete: false,
      },
      balanceRequest: {
        view: false,
        edit: false,
        delete: false,
      },
      company: {
        view: false,
        edit: false,
        delete: false,
      },
      reports: {
        view: false,
        edit: false,
        delete: false,
      },
      sales: {
        view: false,
        edit: false,
        delete: false,
      },
      ticketSupport: {
        view: false,
        edit: false,
        delete: false,
      },
      expenses: {
        view: false,
        edit: false,
        delete: false,
      },
    };
  }

  input;

  componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }
  }

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  validateEmail = async (value) => {
    try {
      this.setState({
        isLoading: true,
      });

      let getEmployee = await findByEmail(this.props.authToken, value);
      console.log("!!!!!!!!!!!!!!!getEmployee", getEmployee);
      this.setState({
        isLoading: false,
      });

      if (getEmployee.status == 200) {
        console.log("!!!!!!!!!!!!!!!!!!!!", getEmployee);
        if (getEmployee.data == null) {
          console.log("!!!!!!!!!!!!!!!!!!!!tr", getEmployee);
          return true;
        } else {
          console.log("!!!!!!!!!!!!!!!!!!!!false", getEmployee);
          notification["warn"]({
            message: "Email Validation",
            description: "Email is already in used",
          });
          return false;
        }
      } else {
        return -1;
      }
    } catch (error) {
      this.setState({
        isLoading: false,
      });

      alertError("Employee", error);

      return -1;
    }
  };

  validateForm(values) {
    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.first_name) {
      errors.first_name = errorMessages.first_name_required;
    }
    if (!values.last_name) {
      errors.last_name = errorMessages.last_name_required;
    }

    if (!values.email) {
      errors.email = errorMessages.email_required;
    } else if (emailValidation(values.email)) {
      errors.email = errorMessages.email_validation;
    }

    if (!values.phone) {
      errors.phone = errorMessages.mobile_required;
    } else if (mobileLengthValidation(values.phone)) {
      errors.phone = errorMessages.mobile_length_validation;
    } else if (mobileValidation(values.phone)) {
      errors.phone = errorMessages.mobile_validation;
    }

    if (!values.password) {
      errors.password = errorMessages.password_required;
    } else if (passwordValidation(values.password)) {
      errors.password = errorMessages.password_validation;
    }

    console.log("Validating errors -- ", errors);
    return errors;
  }

  async handleSubmit(values, action) {
    console.log("values--", values);

    // Static permission for sub admin
    const access = {
      dashboard: { view: true, edit: true, delete: false },
      reports: { view: true, edit: true, delete: false },
      user: { view: true, edit: true, delete: true },
      card: { view: true, edit: false, delete: false },
      balanceRequest:{view:true,edit:true,delete:true},
      employee: { view: true, edit: true, delete: true },
      distributor: { view: true, edit: true, delete: true },
      sales: { view: true, edit: true, delete: true },
      ticketSupport:{view:true,edit:true,delete:true},
      expenses:{ view: true, edit: true, delete: false },
      inventoryBatch:{ view: true, edit: true, delete: true },
      freezeUser: { view: true, edit: true, delete: false },
      
    };

    values.access_permission = JSON.stringify(access);

    this.setState({
      errors: {},
    });

    const formValues = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone: values.phone,
      password: values.password,
      city: values.cityId,
      access_permission: values.access_permission,
    };

    let result = await this.props.onSubmit(formValues);

    if (result) {
      this.props.handleCancel();

      this.setState({
        visible: false,
      });
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleTime(e, s) {
    this.setState({
      time: e,
      timeString: s,
    });
  }

  handleCloseTime(e, s) {
    this.setState({
      closeTime: e,
      closeTimeString: s,
    });
  }

  handleDetailsModalclose(item) {
    this.setState({
      view: false,
      lat: item.lat,
      long: item.lng,
    });
  }

  onChangeCoin = (value) => {
    this.setState({
      selectedCoin: value,
    });
  };

  onChangeCheckBox = (data, key) => {
    console.log(data);
    let obj = {};

    obj[key] = {
      view: data.includes("view"),
      edit: data.includes("edit"),
      delete: data.includes("delete"),
    };

    console.log("!!!!!!!!checkbox", key, data);

    if (key == "dashboard") {
      this.setState({ defaultDashboard: data });
    } else if (key == "master") {
      console.log("!!!!!!!!master", key, data);
      this.setState({ defaultMaster: data });
    } else if (key == "card") {
      this.setState({ defaultCard: data });
    } else if (key == "user") {
      this.setState({ defaultUser: data });
    } else if (key == "balanceRequest") {
      this.setState({ defaultBalanceRequest: data });
    } else if (key == "company") {
      this.setState({ defaultCompany: data });
    } else if (key == "reports") {
      this.setState({ defaultReport: data });
    } else if (key == "sales") {
      this.setState({ defaultSales: data });
    } else if (key == "ticketSupport") {
      this.setState({ defaultTicketSupport: data });
    } else if (key == "expenses") {
      this.setState({ defaultExpenses: data });
    }

    this.setState(obj);
  };

  handleSelectRole(value) {
    if (value == "Admin") {
      this.setState({
        dashboard: {
          view: true,
          edit: true,
          delete: true,
        },
        master: {
          view: true,
          edit: true,
          delete: true,
        },
        card: {
          view: true,
          edit: true,
          delete: true,
        },
        user: {
          view: true,
          edit: true,
          delete: true,
        },
        sales: {
          view: true,
          edit: true,
          delete: true,
        },
        ticketSupport: {
          view: true,
          edit: true,
          delete: true,
        },
        expenses: {
          view: true,
          edit: true,
          delete: true,
        },
        defaultDashboard: ["edit", "view", "delete"],
        defaultUser: ["edit", "view", "delete"],
        defaultCard: ["edit", "view", "delete"],
        defaultMaster: ["edit", "view", "delete"],
        defaultBalanceRequest: ["edit", "view", "delete"],
        defaultCompany: ["edit", "view", "delete"],
        defaultReport: ["edit", "view", "delete"],
        defaultSales: ["edit", "view", "delete"],
        defaultTicketSupport: ["edit", "view", "delete"],
        defaultExpenses: ["edit", "view", "delete"],
      });
    } else {
      this.setState({
        dashboard: {
          view: false,
          edit: false,
          delete: false,
        },
        master: {
          view: false,
          edit: false,
          delete: false,
        },
        card: {
          view: false,
          edit: false,
          delete: false,
        },
        user: {
          view: false,
          edit: false,
          delete: false,
        },
        ticketSupport: {
          view: false,
          edit: false,
          delete: false,
        },
        expenses: {
          view: false,
          edit: false,
          delete: false,
        },
        defaultDashboard: [],
        defaultUser: [],
        defaultCard: [],
        defaultMaster: [],
        defaultBalanceRequest: [],
        defaultCompany: [],
        defaultReport: [],
        defaultTicketSupport: [],
        defaultExpenses: [],
      });
    }
  }

  render() {
    // const {  } = this.state;

    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                password: "",
                city: "---Select City---",
                cityId: "",
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                console.log("errors", errors);
                return (
                  <div>
                    {this.state.isLoading ? (
                      <div style={{ justifyContent: "center" }}>
                        <Spin />
                      </div>
                    ) : (
                      <>
                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            First Name
                            <Input
                              id="first_name"
                              placeholder="First Name"
                              value={values.first_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.first_name && touched.first_name ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.first_name}
                              </p>
                            ) : null}
                          </div>
                          <div className="formik-field-right">
                            Last Name
                            <Input
                              id="last_name"
                              placeholder="Last Name"
                              value={values.last_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.last_name && touched.last_name ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.last_name}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            Phone
                            <Input
                              id="phone"
                              placeholder="User Phone"
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.phone && touched.phone ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.phone}
                              </p>
                            ) : null}
                          </div>
                          <div className="formik-field-right">
                            Email
                            <Input
                              id="email"
                              placeholder="Email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.email && touched.email ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.email}
                              </p>
                            ) : null}
                            {this.state.errors.email ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {this.state.errors.email}
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            Password
                            <Input
                              id="password"
                              type="password"
                              placeholder="User Password"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.password && touched.password ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.password}
                              </p>
                            ) : null}
                          </div>
                          <div className="formik-field-right">
                            City
                            <Field
                              name="city"
                              render={({ field }) => (
                                <StyleSelect
                                  placeholder="Select City"
                                  style={{ width: "100%" }}
                                  name="city"
                                  {...field}
                                  onBlur={() => setFieldTouched("city", true)}
                                  onSelect={(value, option) => {
                                    setFieldValue("city", value);
                                    setFieldValue("cityId", option.id);
                                  }}
                                  options={this.props.cityData}
                                />
                              )}
                            />
                          </div>
                        </div>

                        <Button
                          // type="primary"
                          style={{
                            marginTop: "19px",
                            background: "#5C6AC4",
                            color: "#fff",
                          }}
                          onClick={handleSubmit}
                        >
                          Save
                        </Button>
                      </>
                    )}
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
