import { ENDPOINT } from '../../network/ENDPOINT';
import { callApiToServer } from '../callApi';

export async function sendOtpAPI(authtoken) {
  try {

    let body = JSON.stringify({})

    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization": "Berear " + authtoken
    }

    let responseData = await callApiToServer(body, header, "POST", ENDPOINT.send_otp);
    return responseData;

  }catch(error){
    throw error;
  }
}
