import { callGetApiToServer, callApiToServer } from '../../callApi';

export async function getAllCoin(
  authtoken,
  skipNumber,
  limitNumber
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    }
  
    let endUrl = "v1/auth/list-coin/" + skipNumber + "/" + limitNumber;
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callGetApiToServer(header, "GET", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}

export async function checkNameExist(
  name
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
    
    let body = JSON.stringify({
        name: name
    });
  
    let endUrl = "v1/check-coin-name-exist";
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callApiToServer(body, header, "POST", endUrl);
      
    return responseData;
      
  }catch(err) {
    throw err;
  }
}
  