import * as React from "react";
import { Modal, Spin, notification, Button, Input } from "antd";

import { Formik } from "formik";
import { errorMessages } from "../../utils/validationMessages";
import { priceValidation } from "../../utils/validations";

var amenities = [];

export class AddImmediateBalance extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      visible: false,
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
      errors: {},
    };
  }

  async componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }

    this.setState({
      data: this.props.userSelectedData,
    });
  }

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show() {
    this.setState({
      visible: true,
    });
  }

  hide() {
    this.setState({
      visible: false,
    });
    this.props.onClose();
  }

  validateForm(values) {
    const errors = {};

    if (!values.balance) {
      errors.balance = errorMessages.price_required;
    } else if (priceValidation(values.balance)) {
      errors.balance = errorMessages.price_validation;
    }

    return errors;
  }

  async handleSubmit(values, action) {
    let result = await this.props.onSubmit(values, this.state.data.id);

    if (result) {
      //   this.props.handleCancel();

      this.setState({
        visible: false,
      });
    }
  }

  render() {
    return (
      <Modal
        width="600px"
        closable={this.state.closable}
        open={this.state.visible}
        title="Maximum amount for immediate Balance"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        <Formik
          initialValues={{
            balance: "",
          }}
          validate={this.validateForm}
          onSubmit={this.handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => {
            return (
              <div>
                <div className="formik-field_wrap">
                  <div className="formik-field-wrap">
                    Add Immediate Balance
                    <Input
                      id="balance"
                      placeholder="balance"
                      value={values.balance}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.balance && touched.balance ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.balance}
                      </p>
                    ) : null}
                  </div>
                </div>

                <Button
                  style={{
                    marginTop: "19px",
                    background: "#5C6AC4",
                    color: "#fff",
                  }}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </div>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}
