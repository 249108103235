import * as React from "react";
import { Upload, message, Button, Modal } from "antd";
import styled from "styled-components";
import { WEBURL } from "../../constant/comman";
import { InboxOutlined } from "@ant-design/icons";

const Dragger = Upload.Dragger;

const StyledDragger = styled(Dragger)`
  & .ant-upload {
    margin-bottom: 15px;
  }

  & .ant-upload-drag {
    margin-bottom: 15px;
  }
  & .ant-upload-list-item-actions {
    display: none;
  }
`;

const props = {
  name: "file",
  action: `${WEBURL}card/upload/file`,
  // action: "http://localhost:4040/upload/file",
  headers: {
    authorization: "authorization-text",
  },
};

var temp = [];

export class AddCardIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: true,
      jsonFile: [],
      disableUpload: true,
      brandImage: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.verifyDoc = this.verifyDoc.bind(this);
  }

  componentDidMount() {
    console.log("IS LOGO ${this.props.isLogo}", this.props.isLogo);
    if (this.props.refx) {
      this.props.refx(this);
    }
  }

  verifyDoc() {
    var file = [];

    if (temp.length <= 0) {
      message.error("Please select a valid image");
      return;
    }

    this.props.onUpload(temp[temp.length - 1].response.file);
  }

  async beforeUpload(selectedImage) {
    return new Promise((resolve) => {
      const image1 = new Image();
      const image2 = new Image();

      image1.onload = function () {
        image2.onload = function () {
          const canvas = document.createElement("canvas");
          canvas.width = 400;
          canvas.height = 75;

          const ctx = canvas.getContext("2d");
          ctx.fillStyle = "white";
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(image1, 55, 0, 200, 75);
          ctx.drawImage(image2, 270, 0, 75, 75);

          const dataURL = canvas.toDataURL("image/png"); // base64

          fetch(dataURL)
            .then((res) => res.blob())
            .then((blob) => {
              const file = new File([blob], selectedImage.name, {
                type: "image/png",
              });
              resolve(file);
            });
        };

        image2.src = URL.createObjectURL(selectedImage);
      };

      image1.src = "images/point_logo.png";
    });
  }

  async handleChange(info) {
    console.log("!!!!!!!!!!!!!!!!!!!!info printed ", info);

    var error = false;
    if (info.file.status !== "uploading") {
      if (info.fileList.length < 1) {
        this.setState({
          disable: true,
        });
      } else {
        this.setState({
          disable: false,
        });
      }
    }

    if (info.file.status === "done") {
      if (info.file.response.code === "ERROR_IN_UPLOAD") {
        message.error(info.file.response.msg);
        error = true;
      } else {
        message.success(`${info.file.name} file uploaded successfully.`);
        temp = info.fileList;

        let brandImageURL = temp[temp.length - 1].response.file;
        console.log("brandImageURL", brandImageURL);
        error = false;
      }
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
      error = true;
    }
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  show() {
    this.setState({
      visible: true,
    });
  }

  render() {
    return (
      <Modal
        width="600px"
        closable={this.state.closable}
        open={this.state.visible}
        onCancel={this.handleCancel.bind(this)}
        footer={null}
      >
        <div style={{ marginTop: "20px" }}>
          <StyledDragger
            accept=".png,.jpeg,.jpg"
            multiple={false}
            maxCount={1}
            className="clearfix"
            {...props}
            beforeUpload={this.props.isLogo && this.beforeUpload}
            onChange={this.handleChange}
            onRemove={(file) => console.log("Removed -- ", file)}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint" style={{ marginBottom: "10px" }}>
              Please upload a monochrome image in ratio 1:1
            </p>
          </StyledDragger>
          <Button
            style={{ marginTop: "50px" }}
            disabled={this.state.disable}
            onClick={this.verifyDoc}
          >
            Upload
          </Button>
        </div>
      </Modal>
    );
  }
}
