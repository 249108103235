import React, { Fragment, Component } from "react";
import Title from "./Title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllUsers } from "../../Graphs/User/listUser";
import { editAdminSupport } from "../../Graphs/Setting/editAdminSupport";
import { sendNotification } from "../../Graphs/Setting/sendNotification";
import { createAdvertisement } from "../../Graphs/Setting/createAdvertisements";
import { getAllAdvertisements } from "../../Graphs/Setting/listAdvertisement";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification, Input, Button, Tabs } from "antd";
import { EditorState } from "draft-js";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as la from "lodash";
import { AddAdvertisementImage } from "./AddAdvertisementImage";
import { addAdvertisementAPI } from "../../Graphs/Advertisement/addAdvertisement";
import { getAdvertismentAPI } from "../../Graphs/Advertisement/getAdvertisementAPI";
import { alertError } from "../../utils/alert";
import { getSettingDataAPI } from "../../Graphs/Setting/getSettingData";

const { TextArea } = Input;

const TabPane = Tabs.TabPane;

class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      userTotal: 0,
      advertise: "",
      isLoading: false,
      primaryColor: "#4466f2",
      adminData: null,
      settingData: {},
      errors: {
        commission: "",
        exchange_rate: "",
        cost_exchange_rate: "",
        support_email: "",
        support_chat_number: "",
        support_call_number: "",
      },
      editorState: EditorState.createEmpty(),
      notiMessage: null,
      notiTitle: null,
      advertisementData: null,
      advertisementDataTotal: 0,
      advertisementImageArray: [],

      selectedTab: "Setting",
    };
  }

  handleTabChange = (key) => {
    let selectedTab;
    switch (key) {
      case "1":
        selectedTab = "Setting";
        break;
      case "2":
        selectedTab = "Notification";
        break;
      case "3":
        selectedTab = "Advertisement";
        break;
      default:
        selectedTab = "Setting";
    }

    this.setState({
      selectedTab,
    });
  };

  async componentDidMount() {
    try {
      const {
        authToken,
        adminData,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setRouteName,
        setSearchText,
      } = this.props;
      const { advertisementImageArray } = this.state;
      let dummyAdvertisementImageArray = [];
      let editPrimaryColor = await localStorage.getItem("primary_color");
      console.log("!!!!!!!!editPrimaryColor", editPrimaryColor);

      this.setState({
        isLoading: true,
      });

      await setRouteName("SETTING");
      await setSearchText("");

      let advertisementData = await getAllAdvertisements(
        authToken,
        0,
        100000000
      );

      if (advertisementData.status === 200) {
        console.log("succccc add");
        la.map(advertisementData.data, (data, index) => {
          dummyAdvertisementImageArray.push(data.file_name);
        });

        this.setState({
          advertisementData: advertisementData.data,
          advertisementDataTotal: advertisementData.total,
          isLoading: false,
          primaryColor: editPrimaryColor,
          advertisementImageArray: dummyAdvertisementImageArray,
        });
      } else if (advertisementData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw advertisementData && advertisementData.message
          ? advertisementData.message
          : "Error";
      }
    } catch (e) {
      alertError("SETTING", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }

    this.getAdvertismenentData();
    this.getSettingData();
  }

  getSettingData = async () => {
    try {
      const { authToken } = this.props;
      let getSettingData = await getSettingDataAPI(authToken);

      if (getSettingData.status === 200) {
        this.setState({
          adminData: getSettingData.data,
        });
      } else {
        throw getSettingData.message ? getSettingData.message : "Error";
      }
    } catch (e) {
      alertError("SETTING", e);
    }
  };

  editAdminDetails = async () => {
    console.log("admindadadad===", this.state.adminData);
    try {
      const { adminData, errors, settingData } = this.state;
      const { authToken, setUserData } = this.props;

      let dummyErros = { ...errors };

      if (adminData && adminData.commission === "") {
        dummyErros["commission"] = "Commission is required";
        this.setState({
          errors: dummyErros,
        });
        return;
      } else {
        dummyErros["commission"] = "";
        this.setState({
          errors: dummyErros,
        });
      }

      if (
        (adminData && adminData?.exchange_rate === "") ||
        adminData?.exchange_rate == null
      ) {
        dummyErros["exchange_rate"] = "Exchange rate is required";
        this.setState({
          errors: dummyErros,
        });
        return;
      } else {
        dummyErros["exchange_rate"] = "";
        this.setState({
          errors: dummyErros,
        });
      }

      if (
        (adminData && adminData?.cost_exchange_rate === "") ||
        adminData?.cost_exchange_rate == null
      ) {
        dummyErros["cost_exchange_rate"] = "Cost exchange rate is required";
        this.setState({
          errors: dummyErros,
        });
        return;
      } else {
        dummyErros["cost_exchange_rate"] = "";
        this.setState({
          errors: dummyErros,
        });
      }

      if (adminData && adminData.support_email === "") {
        dummyErros["support_email"] = "Support email is required";
        this.setState({
          errors: dummyErros,
        });
        return;
      } else {
        dummyErros["support_email"] = "";
        this.setState({
          errors: dummyErros,
        });
      }

      if (adminData && adminData.support_call_number === "") {
        dummyErros["support_call_number"] = "Support call number is required";
        this.setState({
          errors: dummyErros,
        });
        return;
      } else {
        dummyErros["support_call_number"] = "";
        this.setState({
          errors: dummyErros,
        });
      }

      if (adminData && adminData.support_chat_number === "") {
        dummyErros["support_chat_number"] = "Support chat number is required";
        this.setState({
          errors: dummyErros,
        });
        return;
      } else {
        dummyErros["support_chat_number"] = "";
        this.setState({
          errors: dummyErros,
        });
      }

      if (adminData && adminData.terms === "") {
        dummyErros["terms"] = "Terms is required";
        this.setState({
          errors: dummyErros,
        });
        return;
      } else {
        dummyErros["terms"] = "";
        this.setState({
          errors: dummyErros,
        });
      }

      if (adminData && adminData.privacy === "") {
        dummyErros["privacy"] = "Privacy is required";
        this.setState({
          errors: dummyErros,
        });
        return;
      } else {
        dummyErros["privacy"] = "";
        this.setState({
          errors: dummyErros,
        });
      }

      this.setState({
        isLoading: true,
      });

      console.log("adminData", adminData);
      let editAdminData = await editAdminSupport(authToken, adminData);

      if (editAdminData?.data) {
        await setUserData(editAdminData?.data);

        this.setState({
          adminData: editAdminData?.data,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (e) {
      console.log("!!!!!!!error ", e);
      this.setState({
        isLoading: false,
      });
    }
  };

  onHandleChange = (value, fieldName) => {
    const { adminData } = this.state;
    let dummyAdminData = { ...adminData };
    dummyAdminData[fieldName] = value;

    this.setState({
      adminData: dummyAdminData,
    });
  };

  onClickEditorChange = (data, fieldName) => {
    console.log("!!!!!!!!!!!!onCkEditor change", data, fieldName);
    const { adminData } = this.state;
    let dummyAdminData = { ...adminData };
    dummyAdminData[fieldName] = data;

    this.setState({
      adminData: dummyAdminData,
    });
  };

  sendNotification = async () => {
    try {
      const { notiMessage, errors, notiTitle } = this.state;
      const { authToken, setUserData } = this.props;

      let dummyErros = { ...errors };

      if (!notiTitle) {
        dummyErros["notiTitle"] = "Please enter a title";
        this.setState({
          errors: dummyErros,
        });
        return;
      } else {
        dummyErros["notiTitle"] = "";
        this.setState({
          errors: dummyErros,
        });
      }

      if (!notiMessage) {
        dummyErros["noti"] = "Please enter a message";
        this.setState({
          errors: dummyErros,
        });
        return;
      } else {
        dummyErros["noti"] = "";
        this.setState({
          errors: dummyErros,
        });
      }

      this.setState({
        isLoading: true,
      });

      let sendNotiData = await sendNotification(
        authToken,
        notiMessage,
        notiTitle
      );
      console.log("!!!!!!!!!!!!!!!sendNotiData", sendNotiData);

      this.setState({
        notiMessage: null,
        isLoading: false,
        notiTitle: null,
      });
    } catch (e) {
      console.log("!!!!!!!error ", e);
    }
  };

  sendAdvertisement = async () => {
    try {
      const { authToken } = this.props;
      let body = { banner_text: this.state.advertise };
      let advertisementData = await addAdvertisementAPI(authToken, body);

      if (advertisementData.status === 200) {
        console.log("advertisementData", advertisementData);
        notification["success"]({
          message: "Success",
          description: advertisementData.message,
        });
      } else {
        throw advertisementData.message ? advertisementData.message : "Error";
      }
    } catch (e) {
      alertError("SETTING", e);
    }
  };

  getAdvertismenentData = async () => {
    try {
      const { authToken } = this.props;
      this.setState({
        isLoading: true,
      });
      let advertisementData = await getAdvertismentAPI(authToken);
      if (advertisementData.status === 200) {
        this.setState({
          advertise: advertisementData.data
            ? advertisementData.data.banner_text
            : "",
          isLoading: false,
        });
      } else {
        throw advertisementData.message ? advertisementData.message : "Error";
      }
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      alertError("SETTING", error);
    }
  };

  onCustomNotiChange = (value) => {
    this.setState({
      notiMessage: value,
    });
  };

  onCustomNotiTitleChange = (value) => {
    this.setState({
      notiTitle: value,
    });
  };

  createAdvertisementData = async (data) => {
    try {
      const {
        authToken,
        adminData,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setRouteName,
        setSearchText,
      } = this.props;
      let dummyAdvertisementImageArray = [];
      this.setState({
        isLoading: true,
      });
      console.log("!!!!!!!!!!!!!!testadvertise main", data);
      if (!(data.length > 0)) {
        alertError("ADVERTISEMENT", "Please Upload atlease one Image");
        return;
      }

      let advertisementData = await createAdvertisement(
        authToken,
        data,
        0,
        100000
      );

      if (advertisementData.status === 200) {
        la.map(advertisementData.data, (data, index) => {
          dummyAdvertisementImageArray.push(data.file_name);
        });

        this.setState({
          advertisementData: advertisementData.data,
          advertisementDataTotal: advertisementData.total,
          isLoading: false,
          advertisementImageArray: dummyAdvertisementImageArray,
        });
      } else if (advertisementData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw advertisementData && advertisementData.message
          ? advertisementData.message
          : "Error";
      }
    } catch (e) {
      alertError("SETTING", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const {
      isLoading,
      adminData,
      errors,
      editorState,
      advertisementImageArray,
      selectedTab,
    } = this.state;
    const { loginUserData } = this.props;

    const accessPermission =
      (loginUserData?.access_permission &&
        JSON.parse(loginUserData?.access_permission)) ||
      false;

    return loginUserData.role !== "Admin" &&
      (!accessPermission ||
        !accessPermission["setting"] ||
        accessPermission["setting"].view == false ||
        accessPermission["setting"].edit == false) ? (
      <Fragment>
        <h5 style={{ marginTop: "30%", marginLeft: "30%" }}>
          You are not authorized for view this page.
        </h5>
      </Fragment>
    ) : (
      <Fragment>
        <Title parent={selectedTab} title="setting" />

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <div className="form setting-form">
                <Tabs
                  defaultActiveKey="1"
                  size="middle"
                  onChange={this.handleTabChange}
                  className="tabs-wrap"
                >
                  {/* <Tabs destroyInactiveTabPane="true"> */}
                  <TabPane tab="Setting" key="1">
                    <div className="form-field">
                      <div className="input-label">
                        {" "}
                        User To User Commission{" "}
                      </div>
                      <Input
                        name="commission"
                        placeholder="1%"
                        value={adminData ? adminData.commission : ""}
                        onChange={(e) =>
                          this.onHandleChange(e.target.value, "commission")
                        }
                      />
                      <div className="error-message">
                        {errors && errors["commission"] !== ""
                          ? errors["commission"]
                          : ""}
                      </div>
                    </div>

                    <div className="form-field">
                      <div className="input-label"> Exchange Rate </div>
                      <Input
                        name="exchange_rate"
                        placeholder="5.5"
                        value={adminData ? adminData.exchange_rate : ""}
                        onChange={(e) =>
                          this.onHandleChange(e.target.value, "exchange_rate")
                        }
                      />
                      <div className="error-message">
                        {errors && errors["exchange_rate"] !== ""
                          ? errors["exchange_rate"]
                          : ""}
                      </div>
                    </div>

                    <div className="form-field">
                      <div className="input-label">Cost Exchange Rate </div>
                      <Input
                        name="cost_exchange_rate"
                        placeholder="5.5"
                        value={adminData ? adminData.cost_exchange_rate : ""}
                        onChange={(e) =>
                          this.onHandleChange(
                            e.target.value,
                            "cost_exchange_rate"
                          )
                        }
                      />
                      <div className="error-message">
                        {errors && errors["cost_exchange_rate"] !== ""
                          ? errors["cost_exchange_rate"]
                          : ""}
                      </div>
                    </div>
                    <div className="form-field">
                      <div className="input-label"> Support Email </div>
                      <Input
                        name="support_email"
                        placeholder="support Email"
                        value={adminData ? adminData.support_email : ""}
                        onChange={(e) =>
                          this.onHandleChange(e.target.value, "support_email")
                        }
                      />
                      <div className="error-message">
                        {errors && errors["support_email"] !== ""
                          ? errors["support_email"]
                          : ""}
                      </div>
                    </div>
                    <div className="form-field">
                      <div className="input-label"> Support Call Number </div>
                      <Input
                        name="support_call_number"
                        placeholder="support Call Number"
                        value={adminData ? adminData.support_call_number : ""}
                        onChange={(e) =>
                          this.onHandleChange(
                            e.target.value,
                            "support_call_number"
                          )
                        }
                      />
                      <div className="error-message">
                        {errors && errors["support_call_number"] !== ""
                          ? errors["support_call_number"]
                          : ""}
                      </div>
                    </div>
                    <div className="form-field">
                      <div className="input-label"> Support Chat Number </div>
                      <Input
                        name="support_chat_number"
                        placeholder="support Chat Number"
                        value={adminData ? adminData.support_chat_number : ""}
                        onChange={(e) =>
                          this.onHandleChange(
                            e.target.value,
                            "support_chat_number"
                          )
                        }
                      />
                      <div className="error-message">
                        {errors && errors["support_chat_number"] !== ""
                          ? errors["support_chat_number"]
                          : ""}
                      </div>
                    </div>

                    <div style={{ marginBottom: "15px" }}>
                      <div className="input-label"> Terms and Condition </div>
                      <CKEditor
                        editor={ClassicEditor}
                        data={adminData ? adminData.terms : ""}
                        onInit={(editor) => {
                          console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          this.onClickEditorChange(data, "terms");
                        }}
                      />
                      <div className="error-message">
                        {errors && errors["terms"] !== ""
                          ? errors["terms"]
                          : ""}
                      </div>
                    </div>

                    <div style={{ marginBottom: "15px" }}>
                      <div className="input-label"> Privacy </div>
                      <CKEditor
                        editor={ClassicEditor}
                        data={adminData ? adminData.privacy : ""}
                        onInit={(editor) => {
                          console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          this.onClickEditorChange(data, "privacy");
                        }}
                      />
                      <div className="error-message">
                        {errors && errors["privacy"] !== ""
                          ? errors["privacy"]
                          : ""}
                      </div>
                    </div>

                    <div className="form-field">
                      <Button
                        type="primary"
                        style={{
                          marginTop: "19px",
                          background: "#5C6AC4",
                          color: "#fff",
                        }}
                        onClick={() => this.editAdminDetails()}
                      >
                        Update
                      </Button>
                    </div>
                  </TabPane>

                  <TabPane tab="Notification" key="2">
                    <div style={{ marginBottom: "15px", marginTop: "30px" }}>
                      <h5 style={{ fontWeight: 700 }}> Push Notification </h5>
                      <div className="form setting-form">
                        <div className="form-field">
                          <div className="input-label">Title</div>
                          <Input
                            name="custom_notiTitle"
                            placeholder="Enter Title"
                            onChange={(e) =>
                              this.onCustomNotiTitleChange(e.target.value)
                            }
                          />
                          <div className="error-message">
                            {errors && errors["notiTitle"] !== ""
                              ? errors["notiTitle"]
                              : ""}
                          </div>
                        </div>

                        <div className="form-field">
                          <div className="input-label"> Message</div>
                          <TextArea
                            name="custom_noti"
                            placeholder="Enter Message"
                            onChange={(e) =>
                              this.onCustomNotiChange(e.target.value)
                            }
                          />
                          <div className="error-message">
                            {errors && errors["noti"] !== ""
                              ? errors["noti"]
                              : ""}
                          </div>
                        </div>

                        <div className="form-field">
                          <Button
                            type="primary"
                            style={{
                              marginTop: "19px",
                              background: "#5C6AC4",
                              color: "#fff",
                            }}
                            onClick={() => this.sendNotification()}
                          >
                            Send
                          </Button>
                        </div>
                      </div>
                    </div>
                  </TabPane>

                  <TabPane tab="Advertisement" key="3">
                    <div style={{ marginBottom: "15px", marginTop: "30px" }}>
                      <h5 style={{ fontWeight: 700 }}> Advertisement </h5>
                      <div className="form setting-form">
                        <div>
                          <TextArea
                            name="advertise"
                            rows={8}
                            value={this.state.advertise}
                            placeholder="Enter Advertisement"
                            maxLength={128}
                            onChange={(e) => {
                              this.setState({ advertise: e.target.value });
                            }}
                          />
                        </div>

                        <div className="form-field">
                          <Button
                            type="primary"
                            style={{
                              marginTop: "19px",
                              background: "#5C6AC4",
                              color: "#fff",
                            }}
                            onClick={() => this.sendAdvertisement()}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  // adminData: state.auth.loginUserData,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListUserData: (listUserData) => {
      dispatch({
        type: "SET_LIST_USER_DATA",
        listUserData: listUserData,
      });
    },
    setListUserDataTotal: (listUserDataTotal) => {
      dispatch({
        type: "SET_LIST_USER_DATA_TOTAL",
        listUserDataTotal: listUserDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Setting));
