import * as React from "react";
import { notification, Button, Input, Spin } from "antd";
import ModalWrap from "../common/modal";
import { viewTicketCommentsAPI } from "../../Graphs/ticket-support/ViewComments";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import AddTicketCommentModel from "./AddTicketCommentModel";
import { dateTimeFormat } from "../../utils/general";

class ViewTicketCommentModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      isLoading: false,
      isAddTicketCommentModalVisible: false,
    };
    // Create a ref for the messages container
    this.messagesContainerRef = React.createRef();
  }

  async componentDidMount() {
    this.fetchAndUpdateComments();
  }

  fetchAndUpdateComments = async () => {
    try {
      const response = await viewTicketCommentsAPI(
        this.props.authToken,
        this.props.data.id
      );
      this.setState({ messages: response.data, isLoading: false });
      // Scroll to the bottom of the messages container
      if (this.messagesContainerRef.current) {
        this.messagesContainerRef.current.scrollTop =
          this.messagesContainerRef.current.scrollHeight;
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
      this.setState({ isLoading: false });
    }
  };

  openAddTicketCommentModal = () => {
    this.setState({ isAddTicketCommentModalVisible: true });
  };

  closeAddTicketCommentModal = () => {
    this.setState({ isAddTicketCommentModalVisible: false });
  };

  render() {
    const { messages } = this.state;
    const addCommentModelData = this.props.data;
    const isLoadingData = messages.length === 0;

    const userMessageStyle = {
      backgroundColor: "#e8eaed",
      color: "#000",
      fontSize: "16px",
      padding: "5px 15px 0px 15px",
      maxWidth: "70%",
      width: "fit-content",
      borderRadius: "5px",
      position: "relative",
      marginBottom: "14px",
      // marginTop: "-14px",
      marginLeft: "10px",
      marginRight: "auto",
    };
    const userMessageArrow = {
      position: "absolute",
      top: "-10px",
      left: "15px",
      transform: "translateX(-50%)",
      border: "solid transparent",
      borderWidth: "5px",
      borderBottomColor: "#e8eaed",
    };

    const adminMessageStyle = {
      backgroundColor: "#e8eaed",
      color: "#000",
      fontSize: "16px",
      padding: "5px 15px 0px 15px",
      maxWidth: "70%",
      width: "fit-content",
      borderRadius: "5px",
      position: "relative",
      marginBottom: "14px",
      // marginTop: "-14px",
      marginRight: "30px",
      marginLeft: "auto",
      textAlign: "left",
    };
    const adminMessageArrow = {
      position: "absolute",
      top: "-10px",
      right: "5px",
      transform: "translateX(-50%)",
      border: "solid transparent",
      borderWidth: "5px",
      borderBottomColor: "#e8eaed",
    };

    const sortedMessages = messages
      .slice()
      .sort((a, b) => b.timestamp - a.timestamp);

    return (
      <>
        <ModalWrap
          visible={this.props.visible}
          closable={this.props.onClose}
          title={
            <>
              View <strong>{this.props.data.name}</strong> Comments
            </>
          }
          onOk={this.props.handleOk}
          onCancel={this.props.handleCancel}
          isLoading={this.props.isLoading}
        >
          <div>
            {isLoadingData ? (
              <Spin
                size="large"
                style={{ marginLeft: "260px", marginTop: "20px" }}
              />
            ) : (
              <div
                ref={this.messagesContainerRef}
                style={{ maxHeight: "400px", overflowY: "auto" }}
              >
                <ul>
                  {sortedMessages.map((msg) => (
                    <li key={"message" + msg.id}>
                      {msg.role === "user" ? (
                        <div
                          style={{
                            width: "90%",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "12px",
                              textAlign: "left",
                              marginBottom: "0px",
                            }}
                          >
                            <b>User</b>
                          </p>
                          <div style={userMessageStyle}>
                            {msg.comment}
                            <span style={userMessageArrow} />
                            <p style={{ fontSize: "11px", textAlign: "left" }}>
                              {dateTimeFormat(msg.createdAt)}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            width: "90%",
                            marginLeft: "auto",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "12px",
                              marginRight: "30px",
                              textAlign: "end",
                              marginBottom: "0px",
                            }}
                          >
                            <b>{msg?.role}</b>
                          </p>
                          <div style={adminMessageStyle}>
                            {msg.comment}
                            <span style={adminMessageArrow} />
                            <p style={{ fontSize: "11px", textAlign: "end" }}>
                              {dateTimeFormat(msg.createdAt)}
                            </p>
                          </div>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <hr />
          <Button
            style={{
              marginTop: "19px",
              marginLeft: "220px",
              background: "#5C6AC4",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={this.openAddTicketCommentModal}
            disabled={
              this.props.loginUserData.role != "Admin" &&
              this.props.permission.ticketSupport.edit == false
            }
          >
            Add Comment
          </Button>
        </ModalWrap>
        {this.state.isAddTicketCommentModalVisible && (
          <AddTicketCommentModel
            visible={this.state.isAddTicketCommentModalVisible}
            handleCancel={this.closeAddTicketCommentModal}
            handleOk={this.closeAddTicketCommentModal}
            onClose={this.closeAddTicketCommentModal}
            isLoading={this.state.isLoading}
            data={addCommentModelData}
            authToken={this.props.authToken}
            refresh={this.fetchAndUpdateComments}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

export default connect(mapStateToProps)(withRouter(ViewTicketCommentModel));
