import { Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, Select, Spin } from "antd";
import { Formik } from "formik";
import styled from "styled-components";
import { FileExcelOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import { AddCardExcel } from "./AddCardExcel";
import TableWrap from "../common/Table";
var moment = require("moment");

const { Content } = Layout;

var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class UploadForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.handleCloseTime = this.handleCloseTime.bind(this);
    this.handleDetailsModalclose = this.handleDetailsModalclose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      locationList: [],
      recordSelectedtoView: null,
      data: [],
      view: false,
      index: null,
      isLoading: false,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: false,
      inputVisible: false,
      inputValue: "",
      time: "",
      closeTime: "",
      closeTimeString: "",
      timeString: "",
      roleType: "1",
      errors: {
        uploadImage: "",
      },
      isIconUpdate: false,
      isShouldComponentUpdate: false,
    };
  }

  input;
  src = "";
  filename = "";
  viewModalRef;

  shouldComponentUpdate(nextProps, nextState) {
    const { isShouldComponentUpdate } = nextState;
    if (!isShouldComponentUpdate) {
      // this.src = undefined;
    }

    return true;
  }

  uploadImage = (file) => {
    console.log("file", file);
    if (file?.file != null) {
      this.src = file?.file;
      this.filename = file?.name;
      this.setState({
        view: false,
      });
    } else {
      this.setState({
        view: false,
      });
    }
  };

  showViewModal(params) {
    this.setState(
      {
        view: true,
      },
      () => {
        this.viewModalRef.show();
      }
    );
  }

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  validateForm = (values) => {
    const errors = {};
    console.log("Validating form -- ", values);

    console.log("Validating errors -- ", errors);
    return errors;
  };

  dateFormat(date) {
    var oldDate = new Date(date),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.add(2, "hours").format("Do MMM YYYY HH:mm:ss");
    return newDate;
  }

  handleSubmit = (values, action) => {
    console.log("values", values);
    const { errors } = this.state;
    let listErrors = { ...errors };

    if (!this.src || (this.src && this.src === "")) {
      listErrors.uploadImage = "Excel is required";

      this.setState({
        errors: listErrors,
      });

      return;
    }

    if (this.src) {
      listErrors.uploadImage = "";

      this.setState({
        errors: listErrors,
      });
    }

    values.file = this.src;
    console.log("Basic Value - ", values, this.props.data.id);

    this.props.onSubmit(values, this.props.data.id);
    // this.props.handleCancel();
  };

  showModal = () => {
    this.setState({
      visible: true,
      isShouldComponentUpdate: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
      isShouldComponentUpdate: false,
    });
  };

  handleTime(e, s) {
    this.setState({
      time: e,
      timeString: s,
    });
  }

  handleCloseTime(e, s) {
    this.setState({
      closeTime: e,
      closeTimeString: s,
    });
  }

  handleDetailsModalclose(item) {
    this.setState({
      view: false,
      lat: item.lat,
      long: item.lng,
    });
  }

  generateCsv = async () => {
    try {
      let csvArray = [];
      csvArray.push(["S.no", "Serial Number", "Reedem Code", "Duplicates"]);

      this.props.duplicateCode.forEach((item, index) => {
        csvArray.push([
          index + 1,
          item.serial_number,
          item.redeem_code,
          item?.duplicate_value == "redeem_code"
            ? "Duplicate Redeem code"
            : item?.duplicate_value == "serial_number"
            ? "Duplicate Serial Number"
            : "Blank redeem code",
        ]);
      });

      const rows = csvArray;

      let currentDate = this.dateFormat(
        this.props.date ? this.props.date : null
      );
      let csvName =
        this.props.data.name + " Duplicate_Code " + currentDate + ".csv";

      let csvContent = "data:text/csv;charset=utf-8,";

      rows.forEach(function (rowArray) {
        let row = rowArray.join(",");
        console.log("row", row);
        csvContent += row + "\r\n";
      });

      var encodedUri = encodeURI(csvContent);
      // window.open(encodedUri);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", csvName);
      document.body.appendChild(link); // Required for FF

      link.click();
    } catch (e) {
      console.log("error in generateCsv function", e);
    }
  };

  columns = [
    {
      title: "Sr. No.",
      width: 100,
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div key={"sno" + index}>
            <span>{++index}</span>
          </div>
        );
      },
    },
    {
      title: "Serial Number",
      width: 100,
      key: "serial_code",
      render: (text, item, index) => {
        return (
          <div>
            <span
              style={{
                color:
                  item?.duplicate_value == "serial_number" ? "red" : "black",
              }}
            >
              {item?.serial_number ? item.serial_number : "-"}{" "}
            </span>
          </div>
        );
      },
    },
    {
      title: "Redeem Code",
      width: 100,
      key: "redeem_code",
      render: (text, item, index) => {
        return (
          <div>
            <span
              style={{
                color: item?.duplicate_value == "redeem_code" ? "red" : "black",
              }}
            >
              {item?.redeem_code ? item.redeem_code : "-"}{" "}
            </span>
          </div>
        );
      },
    },
  ];

  render() {
    const { isLoading } = this.state;

    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{note:""}}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({handleChange, handleBlur, values, handleSubmit  }) => {
                return isLoading ? (
                  <div style={{ marginLeft: "20px" }}>
                    <Spin size="small" />
                  </div>
                ) : this.props.duplicateCode.length > 0 ? (
                  <div>
                    <TableWrap
                      data={this.props.duplicateCode}
                      columns={this.columns}
                      isLoading={this.state.isLoading}
                      total={this.props.duplicateCode.length}
                      pagination={this.props.pagination}
                      handleTableChange={() => {}}
                      scroll={{ y: 500 }}
                    />
                    <Button
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={() => this.generateCsv()}
                    >
                      Export CSV
                    </Button>
                  </div>
                ) : (
                  <div>
                  <label htmlFor="note">Note</label>
                   <Input
                    id="note"
                    name="note"
                    placeholder="Enter note"
                    value={values.note}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                    <div>
                      <div
                        style={{
                          display: "inline-block",
                          width: "100%",
                          marginTop: "20px",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ float: "left", width: "10%" }}>
                          {/* <FileExcelOutlined
                            style={{ width: "100%", fontSize: "30px" }}
                          /> */}
                          <UploadOutlined 
                            style={{ width: "100%", fontSize: "30px" }}
                          />
                        </div>
                        <Button
                          style={{ width: "90%" }}
                          onClick={this.showViewModal.bind(this)}
                        >
                          Choose Excel file
                        </Button>
                        {this.filename ? (
                          <p
                            style={{
                              fontSize: "small",
                              margin: "0",
                              marginLeft: "5px",
                            }}
                          >
                            {this.filename}
                          </p>
                        ) : null}
                        {this.state.view ? (
                          <AddCardExcel
                            refx={(e) => (this.viewModalRef = e)}
                            onUpload={this.uploadImage}
                          />
                        ) : null}
                      </div>
                      <div width="100%" style={{ marginTop: "10px" }}>
                        {this.state.errors.uploadImage != "" ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.uploadImage}
                          </p>
                        ) : null}
                      </div>

                      <Button
                        // type="primary"
                        style={{
                          marginTop: "19px",
                          background: "#5C6AC4",
                          color: "#fff",
                        }}
                        onClick={handleSubmit}
                        disabled={this.props.addButtonLoading}
                      >
                        {this.props.addButtonLoading ? "Updating..." : "Save"}
                      </Button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
