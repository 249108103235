import { Button, Switch, Popconfirm, Tooltip, notification, Spin } from "antd";
import * as React from "react";
import TableWrap from "../common/Table";
import { withRouter } from "react-router";
import {
  EyeOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { alertError } from "../../utils/alert";
import AddTicketCommentModel from "./AddTicketCommentModel";
import ViewTicketCommentModel from "./ViewTicketCommentModel";
import { lowerCase } from "lodash";
import { getTicketCommentsAPI } from "../../Graphs/ticket-support/AddComments";

class TicketSupportTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStatus: "done",
      selectedTicketData: null,
    };
  }

  handleDetailsModalclose() {
    this.setState({
      selectedTicketData: null,
      selectedTicketView: null,
    });

    this.props.getTicketList(0, 10, "");
  }

  async addStatus(values) {
    const { selectedStatus } = this.state;
    values.status = lowerCase(selectedStatus);
    values.transaction_id = values.id;

    try {
      let response = await getTicketCommentsAPI(this.props.authToken, values);

      if (response.status == 200) {
        notification["success"]({
          message: response.message,
        });
      } else {
        throw response;
      }
      this.props.getTicketList(0, 10, "");
    } catch (error) {
      alertError("TICKET SUPPORT", error.message);
    }
  }

  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      width: "50px",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "name",
      width: "100px",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                  <div>{item.name ? item.name : "-"}</div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Username",
      key: "username",
      width: "100px",
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <div>
                  {item?.user?.first_name + " " + item?.user?.last_name}
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      width: "150px",
      render: (text, item, index) => {
        return <div>{item?.user?.email ?? "-"}</div>;
      },
    },
    {
      title: "Mobile",
      key: "mobile",
      width: "100px",
      render: (text, item, index) => {
        return <div>{item?.user?.phone ?? "-"}</div>;
      },
    },
    {
      title: "Redeem Code",
      key: "redeem Code",
      width: "150px",
      render: (text, item, index) => {
        return <div>{item?.redeem_code ?? "-"}</div>;
      },
    },
    {
      title: "Serial Number",
      key: "serial number",
      width: "150px",
      render: (text, item, index) => {
        return <div>{item?.serial_number ?? "-"}</div>;
      },
    },
    {
      title: "Card Buy",
      key: "card buy",
      width: "100px",
      render: (text, item, index) => {
        return (
          <div>
            {item.created_by == "Api"
              ? "Anis Api"
              : item.created_by == "like_api"
              ? "Like Api"
              : item.created_by == "admin"
              ? "Admin"
              : item.created_by == "company"
              ? "Company"
              : "-"}
          </div>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      width: "100px",
      render: (text, item, index) => {
        return (
          <div>
            {
              <span
                style={{
                  backgroundColor:
                    item.support_status == "done"
                      ? "green"
                      : item.support_status == "progress"
                      ? "blue"
                      : "orange",
                  color: "white",
                  borderRadius: "15px",
                  padding: "5px 10px",
                  textTransform: "capitalize",
                }}
              >
                {item.support_status}
              </span>
            }
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: "100px",
      key: "action",
      render: (text, item, index) => {
        let permission = JSON.parse(this.props.loginUserData.access_permission);

        return (
          <div style={{ display: "flex", width: "100%" }}>
            {this.props.loginUserData.role == "Admin" ? (
              <div mr="5px" style={{ float: "left" }}>
                <Tooltip placement="bottom" title="Add Comments">
                  <Button
                    shape="circle"
                    icon={<PlusCircleOutlined />}
                    onClick={() => {
                      this.setState({
                        selectedTicketData: item,
                      });
                    }}
                  />
                </Tooltip>
              </div>
            ) : (
              permission.ticketSupport.edit == true && (
                <div mr="5px" style={{ float: "left" }}>
                  <Tooltip placement="bottom" title="Add Comments">
                    <Button
                      shape="circle"
                      icon={<PlusCircleOutlined />}
                      onClick={() => {
                        this.setState({
                          selectedTicketData: item,
                        });
                      }}
                    />
                  </Tooltip>
                </div>
              )
            )}

            {this.props.loginUserData.role == "Admin" ? (
              <div
                ml="15px"
                mr="5px"
                style={{ float: "left", marginLeft: "10px" }}
              >
                {item.support_status !== "done" ? (
                  <Tooltip placement="bottom" title="Done Status">
                    <Popconfirm
                      onConfirm={this.addStatus.bind(this, item)}
                      title="Are you want to close the ticket?"
                    >
                      <Button shape="circle" icon={<CheckCircleOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                ) : (
                  <Tooltip placement="bottom" title="Update Status">
                    <Button
                      shape="circle"
                      disabled
                      icon={<CheckCircleOutlined />}
                    />
                  </Tooltip>
                )}
              </div>
            ) : (
              permission.ticketSupport.edit == true && (
                <div
                  ml="15px"
                  mr="5px"
                  style={{ float: "left", marginLeft: "10px" }}
                >
                  {item.support_status !== "done" ? (
                    <Tooltip placement="bottom" title="Done Status">
                      <Popconfirm
                        onConfirm={this.addStatus.bind(this, item)}
                        title="Are you want to close the ticket?"
                      >
                        <Button shape="circle" icon={<CheckCircleOutlined />} />
                      </Popconfirm>
                    </Tooltip>
                  ) : (
                    <Tooltip placement="bottom" title="Update Status">
                      <Button
                        shape="circle"
                        disabled
                        icon={<CheckCircleOutlined />}
                      />
                    </Tooltip>
                  )}
                </div>
              )
            )}

            <div
              ml="15px"
              mr="5px"
              style={{ float: "left", marginLeft: "10px" }}
            >
              <Tooltip placement="bottom" title="View Comments">
                <Button
                  shape="circle"
                  icon={<EyeOutlined />}
                  onClick={() => {
                    this.setState({
                      selectedTicketView: item,
                    });
                  }}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  render() {
    const { ticketList, ticketListSize, isLoading, pagination } = this.props;

    return (
      <div style={{ "overflow-x": "auto" }}>
        <TableWrap
          data={ticketList}
          columns={this.columns}
          isLoading={isLoading}
          total={ticketListSize}
          pagination={pagination}
          handleTableChange={this.props.handleTableChange}
          scroll={{ x: 2100 }}
        />
        {this.state.selectedTicketData && (
          <AddTicketCommentModel
            visible={this.state.selectedTicketData}
            handleCancel={this.handleDetailsModalclose.bind(this)}
            handleOk={this.handleDetailsModalclose.bind(this)}
            onClose={this.handleDetailsModalclose.bind(this)}
            isLoading={this.props.isLoading}
            data={this.state.selectedTicketData}
            authToken={this.props.authToken}
            refresh={this.props.getTicketList}
          />
        )}

        {this.state.selectedTicketView && (
          <ViewTicketCommentModel
            visible={this.state.selectedTicketView}
            handleCancel={this.handleDetailsModalclose.bind(this)}
            handleOk={this.handleDetailsModalclose.bind(this)}
            onClose={this.handleDetailsModalclose.bind(this)}
            isLoading={this.props.isLoading}
            data={this.state.selectedTicketView}
            authToken={this.props.authToken}
            getTicketList={this.props.getTicketList}
            permission={JSON.parse(this.props.loginUserData.access_permission)}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TicketSupportTable));
