/* eslint-disable */

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import CustomLoader from '../../components/common/customLoader';
import { callLogin } from "../../Graphs/Login/Login";
import { alertError } from '../../utils/alert';
import { SET_OTP_VERIFICATION, SET_USER_DATA } from '../../constant/actionTypes';
import { Eye , EyeOff } from 'react-feather';
import { emailRegx } from '../../utils/regx';
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                email: "",
                password: ""
            },
            errors: {
                "email": "",
                "password": ""
            },
            isLoading: false,
            isPasswordVisible: false,
        };
    }

    togglePasswordVisibility = () => {
        this.setState(prevState => ({
            isPasswordVisible: !prevState.isPasswordVisible
        }));
    }
    

    //call Login method
    loginAuth = async () => {
        try {
            const { fields } = this.state;
            const { history, setUserData, setLoginPage } = this.props;
    
            let subErrors = { ...this.state.errors };
    
            if (fields.email === "") {
                subErrors.email = "Please enter your email";
            } else if (!emailRegx.test(fields.email)) {
                subErrors.email = "Please enter a valid email";
            } else {
                subErrors.email = "";
            }
    
            if (fields.password === "") {
                subErrors.password = "Please enter a password";
            } else {
                subErrors.password = "";
            }
    
            this.setState({
                errors: subErrors
            });
    
            if (subErrors.email || subErrors.password) {
                return;
            }
    
            this.setState({
                isLoading: true
            });
    
            let loginData = await callLogin(fields.email, fields.password);
    
            if (loginData.status === 200) {
                await setUserData(loginData.data);
                await setLoginPage(true);
                history.push(`${process.env.PUBLIC_URL}/otp`);
            } else {
                throw loginData.message || "Error in login";
            }
        } catch (err) {
            alertError("LOGIN", err);
        } finally {
            this.setState({
                isLoading: false
            });
        }
    }

    //onchange of text input
    onChange = (text, name) => {
        const { fields, errors } = this.state;
        let subFields = { ...fields };
        let subErrors = { ...errors };
        
        subFields[name] = text.target.value;
        if (name === 'email' && !emailRegx.test(subFields[name])) {
            subErrors[name] = "Please enter a valid email";
        } else {
            subErrors[name] = "";
        }
        
        this.setState({
            fields: subFields,
            errors: subErrors 
        });
    }

    render() {
        const { errors, isLoading } = this.state
        return (
            <div>
                {
                    isLoading
                        ?
                        <CustomLoader />
                        :
                        null
                }
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        {/* <!-- login page start--> */}
                        <div className="authentication-main">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                            <div className="text-center">
                                                <img src={require("../../assets/images/logo/point_logo.png")} alt='Logo' style={{ height:"120px" , marginBottom:"-40px"}} />
                                            </div>
                                            <div className="card mt-4">
                                                <div className="card-body">
                                                    <div className="text-center">
                                                        <h4>LOGIN</h4>
                                                        <h6 style={{color:"black"}}>Enter your Username and Password </h6>
                                                    </div>
                                                    <form className="theme-form" onSubmit={(e) => {e.preventDefault(); this.loginAuth();  }} >
                                                        <div className="form-group">
                                                            <label className="col-form-label pt-0" style={{color:"black"}}>Email</label>
                                                            <input className="form-control" type="text" required onChange={(text) => this.onChange(text, "email")} />
                                                            <label className="col-form-label pt-0" style={{ color: "red" }}>{errors["email"]}</label>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="col-form-label" style={{color:"black"}}>Password</label>
                                                            {/* <input className="form-control" type="password" required onChange={(text) => this.onChange(text, "password")} /> */}
                                                            <input
                                                                className="form-control"
                                                                type={this.state.isPasswordVisible ? "text" : "password"}
                                                                required
                                                                onChange={(text) => this.onChange(text, "password")}
                                                            />
                                                            <span
                                                                style={{position:"absolute",top:"69%",right:"45PX",transform:"translateY(-50%)",cursor:"pointer",userSelect:"none"}}
                                                                onClick={this.togglePasswordVisibility}
                                                            >
                                                                {this.state.isPasswordVisible ? <EyeOff size={20} /> : <Eye size={20} />}
                                                            </span>

                                                            <label className="col-form-label pt-0" style={{ color: "red" }}>{errors["password"]}</label>
                                                        </div>
                                                        <div className="form-group form-row mt-3 mb-0">
                                                            <button className="btn btn-primary btn-block" type="submit">Login</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- login page end--> */}
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    verifyuserData: state.auth.loginUserData
});

const mapDispatchToProps = (dispatch) => {
    return {
        setUserData: (userData) => {
            dispatch({
                type: SET_USER_DATA,
                userData: userData
            });
        },
        setLoginPage: (data) => {
            dispatch({
                type: SET_OTP_VERIFICATION,
                isVerify: data
            });
        },
      
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));