import { SET_SALES_DATA, SET_SALES_DATA_TOTAL } from "../constant/actionTypes";

const initial_state = {
    salesData: [],
    totalSales: 0,

};

export default (state = initial_state, action) => {
    switch (action.type) {

        case SET_SALES_DATA:
            return { ...state, loading: false, salesData: action.salesData };

        case SET_SALES_DATA_TOTAL:

            return { ...state, loading: false, totalSales: action.totalSales };

        default: return { ...state };
    }
}
