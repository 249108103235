import {
  Button,
  Modal,
  notification,
  Table,
  Tooltip,
} from "antd";
import React, { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { EditModal } from "./EditModal";
import { alertError } from "../../utils/alert";
import { editInventoryAPI } from "../../Graphs/inventory/editinventory";

const SubCategoryModel = ({ onClose = () => { }, data, myProps }) => {
  const handleCancel = () => {
    onClose();
  };
  const [subCategoryList, setSubCategoryList] = useState(data);
  const [isSubCategory, setSubCategory] = useState(true);
  const [isAddSubCategory, setAddSubCategory] = useState(false);
  const [isEditSubCategory, setEditSubCategory] = useState(false);
  const [categorySelectedData, setCategorySelectedData] = useState();
  const columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "name",
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                  <div>{item.name ? item.name : ""}</div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Arabic Name",
      key: "arabic name",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                  <div>{item.arabic_name ? item.arabic_name : "-"}</div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Lower Limit",
      key: "Lower Limit",
      render: (text, item, index) => {
        return <div>{item.lower_limit ? item.lower_limit : "-"}</div>;
      },
    },
    {
      title: "Upper Limit",
      key: "Upper Limit",
      render: (text, item, index) => {
        return <div>{item.upper_limit ? item.upper_limit : "-"}</div>;
      },
    },
    {
      title: "Actions",
      width: 135,
      key: "action",
      render: (text, item, index) => {
        return (
          <>
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ margin: "5px" }}>
                <Tooltip placement="bottom" title="Edit">
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      setEditSubCategory(true);
                      setCategorySelectedData(item);
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const handleBack = () => {
    if (isAddSubCategory) {
      setSubCategory(true);
      setAddSubCategory(false);
    } else if (isEditSubCategory) {
      setSubCategory(true);
      setEditSubCategory(false);
    } else {
      handleCancel();
    }
  };

  const handleEdit = async (categoryObject, categoryId) => {
    try {
      let statusData = await editInventoryAPI(
        myProps.authToken,
        categoryObject,
        categoryId
      );
      if (statusData.status == 200) {
        notification["success"]({
          message: "Edit Limit",
          description: "Successfully edited",
        });
        if (statusData.data) {
          var newList = { ...subCategoryList };

          newList.sub_categories.filter((item) => {
            if (item.id == categorySelectedData.id) {              
              item.lower_limit = categoryObject.lower_limit;
              item.upper_limit = categoryObject.upper_limit;            
            }
          });
          await setSubCategoryList(newList);
        }
      } else if (statusData.status == 401) {
        // history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      alertError("SUBCATEGORY", e);
    }
  };

  return (
    <>
      <Modal
        width="800px"
        title="Sub Categories"
        open={true}
        onCancel={handleCancel}
        footer={[
          <Button
            key="back"
            onClick={() => {
              handleBack();
            }}
          >
            {isAddSubCategory || isEditSubCategory && "Cancel"}
          </Button>,
        ]}
      >
        {isSubCategory && (
          <Table
            rowKey={(record) => record._id}
            columns={columns}
            size={"small"}
            dataSource={subCategoryList?.sub_categories}
          />
        )}

        {isEditSubCategory && (
          <EditModal
            admin={false}
            type="subCategory"
            // refx={(e) => (this.modalRef = e)}
            onClose={() => setEditSubCategory(false)}
            categorySelectedData={categorySelectedData}
            onSubmit={handleEdit}
          />
        )}
      </Modal>
    </>
  );
};

export default SubCategoryModel;
