import { SET_LIST_CARD_DATA, SET_LIST_CARD_DATA_TOTAL, SET_THIRD_PARTY_CARD_DATA } from "../constant/actionTypes";

const initial_state = {
    listCardData: null,
    listCardDataTotal: 0,
    thirdPartyCardData: []
};

export default (state = initial_state, action) => {
    switch (action.type) {

        case SET_LIST_CARD_DATA:
            return { ...state, loading: false, listCardData: action.listCardData };

        case SET_LIST_CARD_DATA_TOTAL:
            return { ...state, loading: false, listCardDataTotal: action.listCardDataTotal };

        case SET_THIRD_PARTY_CARD_DATA:
            return { ...state, loading: false, thirdPartyCardData: action.thirdPartyCardData };

        default: return { ...state };
    }
}
