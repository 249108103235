/* eslint-disable */

import React, { Component } from "react";
import OtpInput from "react-otp-input";
import { verifyOTP } from "../../Graphs/Login/VerifyOTP";
import OTP from "../Login/OTP";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { alertError } from "../../utils/alert";
import { Button, notification } from "antd";

class VerifyOTP extends Component {
  constructor(props) {
    super(props);
  }

  onVerifyUser = async (otp) => {
    try {
      const {
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        verifyuserData,
      } = this.props;
      const otpNumber = parseInt(otp);

      const body = {
        email: verifyuserData.email,
        otp: otpNumber,
      };

      let verifyData = await verifyOTP(body);

      console.log("verifyData", verifyData);

      this.setState({
        isLoading: true,
      });

      if (verifyData.status == 200) {
        notification["success"]({
          message: "Login",
          description: "Login Successfully",
        });

        await setUserToken(verifyData.authToken);
        await setUserData(verifyData.data);
        await setLoginFlag(true);

        if (verifyData.data.role == "Company") {
          history.push(`${process.env.PUBLIC_URL}/balances`);
        } else if (verifyData.data.role == "Distributor") {
          history.push(`${process.env.PUBLIC_URL}/user`);
        } else {
          history.push(`${process.env.PUBLIC_URL}/dashboard`);
        }
      } else {
        throw verifyData && verifyData.message
          ? verifyData.message
          : "Error in login";
      }
    } catch (err) {
      alertError("LOGIN", err);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    return (
      <div>
        <div className="page-wrapper">
          <div className="container-fluid p-0">
            {/* <!-- login page start--> */}
            <div className="authentication-main">
              <div className="row">
                <div className="col-md-12">
                  <div className="auth-innerright">
                    <div className="authentication-box">
                      <div className="text-center">
                        <img
                          src={require("../../assets/images/logo/point_logo.png")}
                          alt="Logo"
                          style={{ height: "120px", marginBottom: "-40px" }}
                        />
                      </div>

                      <div className="card mt-4">
                        <div className="card-body">
                          <div className="text-center mb-4">
                            <h4 style={{ marginBottom: "20px" }}>OTP</h4>
                            <OTP onSubmit={this.onVerifyUser} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- login page end--> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  verifyuserData: state.auth.loginUserData,
  isOTPage: state.auth.otpVerification,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VerifyOTP));
