import React, { Children, Component } from 'react'

 class AddButton  extends Component {
  
  render() {

    return (
        <div
            onClick={this.props.show}
            style={{
                cursor: "pointer",
                // width: "150px",
                // backgroundColor: this.props.color,
                backgroundColor:"#3a3e78",
                color: "white",
                padding: "7px 25px",
                textAlign: "center",
                borderRadius: "50px",
            }}
        >
           {this.props.children ? this.props.children: "ADD"}
        </div>
    )
  }
}

export default AddButton;