import { Button, Input, notification } from "antd";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { CollectCoinToSales } from "../../Graphs/sales/CollectCoinToSales";
import { alertError } from "../../utils/alert";
import ModalWrap from "../common/modal";

export default class CollectBalanceFromSales extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validateForm = (values) => {
    const errors = {};
    if (values.coin > this.props.data.outstanding_balance) {
      errors.coin = `Collection amount exceeds the limit.`;
    }

    return errors;
  };

  handleSubmit = async (value) => {
    try {
      let body = {
        salesman_id: this.props.data.id,
        amount: value.coin,
        city : this.props.loginUserData.role === "Sub_admin" ? this.props.loginUserData.city : "",
      };
      let response = await CollectCoinToSales(this.props.authToken, body);
      if (response.status == 200) {
        notification["success"]({
          message: response.message,
        });
        this.props.onClose();
        this.props.reload();
      } else if (response.status == 400) {
        alertError("SALES", response.message);

        // this.props.onClose();
        // this.props.reload();
      } else {
        throw response.message ? response.message : "Error";
      }
    } catch (error) {
      alertError("SALES", error.message);
    }
  };

  render() {
    return (
      <ModalWrap
        visible={this.props.visible}
        closable={this.props.onClose}
        title="Collect coin from Salesman"
        onOk={this.props.handleOk}
        onCancel={this.props.handleCancel}
        isLoading={this.props.isLoading}
        width={650}
      >
        <Formik
          initialValues={{
            coin: "",
          }}
          onSubmit={this.handleSubmit}
          validate={this.validateForm}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return (
              <Form>
                {
                  <>
                    <div className="formik-field_wrap">
                      <div className="formik-field-wrap">
                        Collect Coin
                        <Input
                          id="coin"
                          placeholder="Collect Coin"
                          value={values.coin}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.coin && touched.coin ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.coin}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <Button
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Collect
                    </Button>
                  </>
                }
              </Form>
            );
          }}
        </Formik>
      </ModalWrap>
    );
  }
}
