import { Button, Popconfirm, Tooltip } from "antd";
import React, { Component } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  EyeOutlined,
  LockOutlined,
  WalletOutlined,
  DollarCircleTwoTone,
  DollarCircleOutlined,
} from "@ant-design/icons";
import AddSalesModal from "./AddSalesModal";
import { AddCoinInSales } from "./AddCoinInSales";
import { UpdatePassword } from "./UpdatePassword";
import TableWrap from "../common/Table";
import BalanceModal from "../common/BalanceModal";
import TransactionModal from "../common/TransactionModal";
import CollectBalanceFromSales from "./CollectBalanceFromSales";
import CollectBalanceFromUser from "./CollectBalanceFromUser";

export default class SalesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editSalesData: null,
      editModalVisible: false,
      addCoinData: null,
      addCoinModalVisible: false,
      passwordData: null,
      userSelectedDataForWallet: null,
      transactionData: null,
      collectAmountData: null,
      collectAmountDataFromUser: null,
      selectShow: false,
    };
  }
  modalRef;
  handleDetailsModalclose() {
    this.setState({
      editSalesData: null,
      editModalVisible: false,
      addCoinData: null,
      addCoinModalVisible: false,
      passwordData: null,
      userSelectedDataForWallet: null,
      transactionData: null,
      collectAmountData: null,
      collectAmountDataFromUser: null,
    });
  }
  showModal(item) {
    this.setState({
      editSalesData: item,
      editModalVisible: true,
    });
  }

  addBalanceInSales(item) {
    this.setState({
      addCoinData: item,
      addCoinModalVisible: true,
    });
  }
  changePasword(item) {
    this.setState({
      passwordData: item,
    });
  }
  showWalletModal(item) {
    this.setState({
      userSelectedDataForWallet: item,
    });
  }

  transaction(item) {
    this.setState({
      transactionData: item,
    });
  }
  collectAmount(item) {
    this.setState({
      collectAmountData: item,
    });
  }

  collectAmountFromUser(item) {
    this.setState({
      collectAmountDataFromUser: item,
    });
  }

  columns = [
    {
      title: "Sr. No.",
      width: 100,
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div key={"sno" + index}>
            <span>{++index}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      width: 200,
      key: "name",
      render: (text, item, index) => {
        return (
          <div key={"name" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column" style={{ marginTop: "5px" }}>
                {item.first_name + " " + item.last_name}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Arabic Name",
      width: 200,
      key: "arabic name",

      render: (text, item, index) => {
        return (
          <div key={"arabic name" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column" style={{ marginTop: "5px" }}>
                {item.arabic_name}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Email",
      key: "email",
      width: 250,
      dataIndex: "email",
      render: (text, item, index) => {
        return <div key={"email" + index}>{item.email ? item.email : "-"}</div>;
      },
    },
    {
      title: "Mobile",
      key: "mobile",
      width: 150,
      render: (text, item, index) => {
        return <div>{item.phone ? item.phone : "-"}</div>;
      },
    },
    {
      title: "City",
      key: "city",
      render: (text, item, index) => {
        return <div>{item.user_city ? item.user_city.name : "-"}</div>;
      },
    },
    {
      title: "Balance",
      key: "wallet balance",
      width: 150,
      render: (text, item, index) => {
        console.log("item...", item);
        return <div>{item.total_balance}</div>;
      },
    },
    {
      title: "Outstanding Balance",
      key: "collected_balance",
      width: 130,
      render: (text, item, index) => {
        return (
          <div style={{ color: "red" }}>
            {item.outstanding_balance ? item.outstanding_balance : "0"}
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 220,
      key: "action",
      render: (text, item, index) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "10px",
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {
              <div>
                <Tooltip placement="bottom" title="Edit">
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      this.showModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            }
            <div>
              <Tooltip placement="bottom" title="Add Balance">
                <Button
                  shape="circle"
                  icon={<PlusCircleOutlined />}
                  onClick={() => {
                    this.addBalanceInSales(item);
                  }}
                />
              </Tooltip>
            </div>
            <div>
              <Tooltip placement="bottom" title="Change Password">
                <Button
                  shape="circle"
                  icon={<LockOutlined />}
                  onClick={() => {
                    this.changePasword(item);
                  }}
                />
              </Tooltip>
            </div>
            <div>
              <Tooltip placement="bottom" title="Transactions">
                <Button
                  shape="circle"
                  icon={<WalletOutlined />}
                  onClick={() => {
                    this.transaction(item);
                  }}
                />
              </Tooltip>
            </div>
            <div>
              <Tooltip placement="bottom" title="Collect Amount">
                <Button
                  shape="circle"
                  icon={<DollarCircleOutlined />}
                  disabled={item.outstanding_balance <= 0}
                  onClick={() => {
                    this.collectAmount(item);
                  }}
                />
              </Tooltip>
            </div>
            {/* {
              <div>
                <Tooltip placement="bottom" title="Delete Salesman">
                  <Popconfirm
                    onConfirm={() => this.props.delete(item)}
                    title="Are you sure you want to delete Salesman"
                  >
                    <Button shape="circle" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
            } */}
            <div>
              <Tooltip placement="bottom" title="Collect Amount From User">
                <Button
                  shape="circle"
                  icon={<WalletOutlined />}
                  onClick={() => {
                    this.collectAmountFromUser(item);
                  }}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  render() {
    return (
      <>
        <TableWrap
          data={this.props.salesList}
          columns={this.columns}
          isLoading={this.props.isLoading}
          total={this.props.totalsales}
          pagination={this.props.pagination}
          handleTableChange={this.props.handleTableChange}
          scroll={{ x: 1000 }}
        />
        {this.state.editModalVisible && (
          <AddSalesModal
            visible={this.state.editModalVisible}
            handleCancel={this.handleDetailsModalclose.bind(this)}
            handleOk={this.handleDetailsModalclose.bind(this)}
            onClose={this.handleDetailsModalclose.bind(this)}
            isLoading={this.state.isLoading}
            data={this.state.editSalesData}
            updateSalesUser={this.props.updateSalesUser}
            selectShow={this.state.selectShow}
            loginUserData={this.props.loginUserData}
          />
        )}
        {this.state.userSelectedDataForWallet ? (
          <BalanceModal
            visible={this.state.userSelectedDataForWallet}
            handleCancel={this.handleDetailsModalclose.bind(this)}
            handleOk={this.handleDetailsModalclose.bind(this)}
            onClose={this.handleDetailsModalclose.bind(this)}
            isLoading={this.state.isLoading}
            data={this.state.userSelectedDataForWallet}
            // onSubmit={this.editSubmit}
          />
        ) : null}
        {this.state.addCoinModalVisible ? (
          <AddCoinInSales
            visible={this.state.addCoinModalVisible}
            handleCancel={this.handleDetailsModalclose.bind(this)}
            handleOk={this.handleDetailsModalclose.bind(this)}
            onClose={this.handleDetailsModalclose.bind(this)}
            isLoading={this.state.isLoading}
            data={this.state.addCoinData}
            authToken={this.props.authToken}
            reload={this.props.reload}
            loginUserData={this.props.loginUserData}
          />
        ) : null}
        {this.state.passwordData ? (
          <UpdatePassword
            visible={this.state.passwordData}
            handleCancel={this.handleDetailsModalclose.bind(this)}
            handleOk={this.handleDetailsModalclose.bind(this)}
            onClose={this.handleDetailsModalclose.bind(this)}
            isLoading={this.state.isLoading}
            data={this.state.passwordData}
            authToken={this.props.authToken}
          />
        ) : null}

        {this.state.transactionData ? (
          <TransactionModal
            visible={this.state.transactionData}
            handleCancel={this.handleDetailsModalclose.bind(this)}
            handleOk={this.handleDetailsModalclose.bind(this)}
            onClose={this.handleDetailsModalclose.bind(this)}
            isLoading={this.state.isLoading}
            data={this.state.transactionData}
            authToken={this.props.authToken}
          />
        ) : null}
        {this.state.collectAmountData ? (
          <CollectBalanceFromSales
            visible={this.state.collectAmountData}
            handleCancel={this.handleDetailsModalclose.bind(this)}
            handleOk={this.handleDetailsModalclose.bind(this)}
            onClose={this.handleDetailsModalclose.bind(this)}
            isLoading={this.state.isLoading}
            data={this.state.collectAmountData}
            authToken={this.props.authToken}
            reload={this.props.reload}
            loginUserData={this.props.loginUserData}
          />
        ) : null}

        {this.state.collectAmountDataFromUser ? (
          <CollectBalanceFromUser
            visible={this.state.collectAmountDataFromUser}
            handleCancel={this.handleDetailsModalclose.bind(this)}
            handleOk={this.handleDetailsModalclose.bind(this)}
            onClose={this.handleDetailsModalclose.bind(this)}
            isLoading={this.state.isLoading}
            data={this.state.collectAmountDataFromUser}
            authToken={this.props.authToken}
            reload={this.props.reload}
          />
        ) : null}
      </>
    );
  }
}
