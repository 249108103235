import { callApiToServer } from "../../callApi";

export async function addCategory(authtoken, categoryObject) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      name: categoryObject.name,
      description: categoryObject.description,
      color: categoryObject.color,
      icon: categoryObject.icon,
      sort_order: categoryObject.sort_order,
      parent_id: categoryObject.parent_id ? categoryObject.parent_id : null,
      arabic_name: categoryObject.arabicName,
      advertisement: categoryObject.advertisement
    });

    let endUrl = "v1/auth/add-category";
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
