import { ENDPOINT } from "../../network/ENDPOINT";
import { callGetApiToServer } from "../callApi";

export async function getUserBalanceReport(
  authtoken,
  salesmanId,
  cityId ,
) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    
    let endUrl = `${ENDPOINT.user_outstanding_balance_report}?salesman_id=${salesmanId}&city=${cityId}`;
    let responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
