import { ENDPOINT } from "../../network/ENDPOINT";
import { callGetApiToServer } from "../callApi";

export async function getUserByRole(authtoken, role) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let endUrl = `${ENDPOINT.user_list_by_role}/${role}`;

    let responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
