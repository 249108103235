import * as React from "react";
import { Modal, Spin } from "antd";
import { getAllCardRedeemFromTrans } from "../../Graphs/User/listRedeemCardsFromTransactionId";
import * as la from "lodash";

export class ViewTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
      redeemData: [],
    };
  }

  async componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }

    try {
      const { authToken, transactionData } = this.props;
      this.setState({
        isLoading: true,
      });

      let usertransactionData = await getAllCardRedeemFromTrans(
        authToken,
        transactionData.id
      );
      console.log("!!!!!!!!!!!!usertransactionData", usertransactionData);

      if (usertransactionData.status == 200) {
        this.setState({
          isLoading: false,
          redeemData: usertransactionData.data,
        });
      }
    } catch (e) {
      console.log("!!!!!!!error ", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show() {
    this.setState({
      visible: true,
    });
  }

  hide() {
    this.setState({
      visible: false,
    });
    this.props.onClose();
  }

  render() {
    const { redeemData } = this.state;

    return (
      <Modal
        width="600px"
        closable={this.state.closable}
        open={this.state.visible}
        title="Redeem Codes"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        {this.state.isLoading ? (
          <div style={{ justifyContent: "center" }}>
            <Spin />
          </div>
        ) : (
          <div>
            {redeemData && redeemData.length > 0 ? (
              <div style={{ marginTop: "10px" }}>
                {la.map(redeemData, (data, index) => {
                  return (
                    <div
                      style={{
                        marginBottom: "5px",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      {data.redeem_code}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div style={{ marginTop: "10px" }}>No Redeem Data found</div>
            )}
          </div>
        )}
      </Modal>
    );
  }
}
