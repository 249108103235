/* eslint-disable */

import React, { Fragment, Component } from "react";
import Title from "./Title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification, Tabs } from "antd";
import CountryRecordsTable from "./Country/CountryRecordsTable";
import CategoryRecordsTable from "./Category/CategoryRecordsTable";
import CoinRecordsTable from "./Coin/CoinRecordsTable";
import CurrencyRecordsTable from "./Currency/CurrencyRecordsTable";
import CityRecordsTable from "./City/CityRecordsTable";

const TabPane = Tabs.TabPane;

class Master extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      primaryColor: "#4466f2",
      selectedTab: "Country",
      defaultActiveKey: "1",
    };
  }

  handleTabChange = (key) => {
    let selectedTab;
    switch (key) {
      case "1":
        selectedTab = "Country";
        break;
      case "2":
        selectedTab = "Category";
        break;
      case "3":
        selectedTab = "Coin";
        break;
      case "4":
        selectedTab = "Currency";
        break;
      case "5":
        selectedTab = "City";
        break;
      default:
        selectedTab = "Country";
    }

    this.setState({
      selectedTab,
    });
  };

  async componentDidMount() {
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const activeTab = searchParams.get("activeTab");

    if (activeTab) {
      this.setState({
        defaultActiveKey: activeTab,
      });
    }

    try {
      const { authToken, history, setRouteName, setSearchText } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");

      await setRouteName("MASTER");
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });
    } catch (e) {
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    const { defaultActiveKey } = this.state;

    const { primaryColor, isLoading, selectedTab } = this.state;
    const { loginUserData } = this.props;

    const accessPermission =
      loginUserData.access_permission &&
      JSON.parse(loginUserData.access_permission);

    return loginUserData.role !== "Admin" &&
      (!accessPermission ||
        !accessPermission["master"] ||
        accessPermission["master"].view == false) ? (
      <Fragment>
        <h5 style={{ marginTop: "30%", marginLeft: "30%" }}>
          You are not authorized for view this page.
        </h5>
      </Fragment>
    ) : (
      <Fragment>
        <Title parent={selectedTab} title="master" />
        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <Tabs
                defaultActiveKey={defaultActiveKey}
                size="middle"
                onChange={this.handleTabChange}
                className="tabs-wrap"
                destroyInactiveTabPane="true"
              >
                <TabPane tab="Country" key="1">
                  <CountryRecordsTable
                    accessPermission={accessPermission}
                    loginUserData={loginUserData}
                    primaryColor={primaryColor}
                  />
                </TabPane>
                <TabPane tab="Category" key="2">
                  <CategoryRecordsTable
                    accessPermission={accessPermission}
                    loginUserData={loginUserData}
                    primaryColor={primaryColor}
                  />
                </TabPane>
                <TabPane tab="Coin" key="3">
                  <CoinRecordsTable
                    accessPermission={accessPermission}
                    loginUserData={loginUserData}
                    primaryColor={primaryColor}
                  />
                </TabPane>
                <TabPane tab="Currency" key="4">
                  <CurrencyRecordsTable
                    accessPermission={accessPermission}
                    loginUserData={loginUserData}
                    primaryColor={primaryColor}
                  />
                </TabPane>
                <TabPane tab="City" key="5">
                  <CityRecordsTable
                    accessPermission={accessPermission}
                    loginUserData={loginUserData}
                    primaryColor={primaryColor}
                  />
                </TabPane>
              </Tabs>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListUserData: (listUserData) => {
      dispatch({
        type: "SET_LIST_USER_DATA",
        listUserData: listUserData,
      });
    },
    setListUserDataTotal: (listUserDataTotal) => {
      dispatch({
        type: "SET_LIST_USER_DATA_TOTAL",
        listUserDataTotal: listUserDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Master));
