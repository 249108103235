import { ENDPOINT } from '../../network/ENDPOINT';
import { callGetApiToServer } from '../callApi';



export async function getWalletRequestsData(
    authtoken,   
    skip,
    limit,
    city = "" ,
    search = "", 
) {
    try {
        let header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "authorization": "Bearer " + authtoken
        };

        let endUrl = `${ENDPOINT.profit_wallet_requests}/${skip}/${limit}`;

        const params = new URLSearchParams();
        if (search) params.append("search", search);
        if (city) params.append("city", city);

        if (params.toString()) {
            endUrl += `?${params.toString()}`;
        }

        let responseData = await callGetApiToServer(header, "get", endUrl);

        return responseData;

    } catch (err) {
        throw err;
    }
}

