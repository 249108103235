import { ENDPOINT } from '../../network/ENDPOINT';
import { callApiToServer } from '../callApi';

export async function inventoryReportAPI(
    authtoken,
    obj,
    skip,
    limit
) {
  try{
    console.log("___________",skip,limit)
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    };
      
    let body = JSON.stringify(obj);
  
    let endUrl = `${ENDPOINT.inventory_management_report}/${skip}/${limit}`;

    console.log("endUrl printed here", endUrl);
  
    let responseData = await callApiToServer(body, header, "POST", endUrl);
      
    return responseData;
      
  }catch(err) {
    throw err;
  }
}
