import { Table } from "antd";
import React, { Component } from "react";

class TableWrap extends Component {
  render() {
    return (
      <div style={{ "overflow-x": "auto" }}>
        <Table
          loading={this.props.isLoading}
          rowKey={(record) => record._id}
          columns={this.props.columns}
          scroll={this.props.scroll}
          size={"small"}
          style={this.props.style}
          dataSource={this.props.data}
          pagination={{
            ...(this.props.pagination && {
              total: this.props.total,
              showSizeChanger: true,
              pageSize: this.props.pagination.pageSize,
              current: this.props.pagination.current,
              pageSizeOptions: ["1", "10", "25", "50", "100"],
              onChange: (e) => {
                if (e - 1) {
                  this.counter = this.tempCounter =
                    (e - 1) * this.props.pagination.pageSize;
                  return;
                }
                this.counter = 0;
                this.tempCounter = 0;
              },
            }),
          }}
          onChange={this.props.handleTableChange}
          summary={this.props.footer}
        />
      </div>
    );
  }
}

export default TableWrap;
