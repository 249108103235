import React, { Component, createRef } from "react";
import ModalWrap from "../common/modal";
import OTP from "./OTP";

export default class OTPModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
    };

    this.inputRefs = Array.from({ length: 6 }, () => createRef());
  }

  componentDidMount() {}

  render() {
    return (
      <ModalWrap
        visible={this.props.uploadRedeemData}
        closable={this.props.onClose}
        title="OTP"
        onOk={this.props.onClose}
        onCancel={this.props.onClose}
        isLoading={this.state.isLoading}
      >
        <div className="authentication-box">
          <div className="text-center">
            <div className="card-body">
              <div className="text-center mb-4" style={{ marginLeft: "26px" }}>
                <OTP onSubmit={this.props.onSubmitOTP} />
              </div>
            </div>
          </div>
        </div>
      </ModalWrap>
    );
  }
}
