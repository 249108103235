import { ENDPOINT } from "../../network/ENDPOINT";
import { callGetApiToServer } from "../callApi";

export async function getMintAPICardDetail(cardId, authtoken) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let endUrl = `${ENDPOINT.get_mint_card_detail}/${cardId}`;

    let responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
