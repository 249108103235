import {SET_PROFIT_REQUESTS_DATA, SET_PROFIT_REQUESTS_TOTAL } from "../constant/actionTypes";

const initial_state = {
    profitRequestsData: null,
    profitRequestsTotal: 0,
   
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case SET_PROFIT_REQUESTS_DATA:
      return {
        ...state,
        loading: false,
        profitRequestsData: action.profitRequestsData,
      };

    case SET_PROFIT_REQUESTS_TOTAL:
      return {
        ...state,
        loading: false,
        profitRequestsTotal: action.profitRequestsTotal,
      };

    default:
      return { ...state };
  }
}
