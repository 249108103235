import { ENDPOINT } from "../../network/ENDPOINT";
import { callApiToServer } from "../callApi";

export async function verifyOTP(payload, token) {
  try {
    let body = JSON.stringify(payload);

    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    let isTokenHeader = token
      ? { ...header, authorization: `${"Berear " + token}` }
      : header;
    let url = token ? ENDPOINT.verify_redeem_otp : ENDPOINT.verify_otp;

    let responseData = await callApiToServer(body, isTokenHeader, "POST", url);
    return responseData;
  } catch (error) {
    throw error;
  }
}
