import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, TimePicker, Select, Spin } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { WEBURL, color } from "../../../constant/comman";
import { PictureOutlined } from "@ant-design/icons";
import * as la from "lodash";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.handleCloseTime = this.handleCloseTime.bind(this);
    this.handleDetailsModalclose = this.handleDetailsModalclose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      locationList: [],
      recordSelectedtoView: null,
      data: [],
      view: false,
      index: null,
      isLoading: true,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: false,
      inputVisible: false,
      inputValue: "",
      time: "",
      closeTime: "",
      closeTimeString: "",
      timeString: "",
      roleType: "1",
      errors: {
        uploadImage: "",
      },
      isIconUpdate: false,
    };
  }

  input;
  allColorType = [];
  selectedColorType = "";
  selctedColorKey = "";
  selectDisplayColorName = "";
  src = "";
  viewModalRef;
  isColorChanged = false;

  componentDidMount() {
    console.log("!!!!!!!!did mount in edit form");
    this.setState({
      isLoading: true,
    });

    this.setState({
      isLoading: false,
    });
  }

  uploadImage = (name) => {
    console.log("!!!!!!!!!!!!!!after upload ", name);
    if (name != null) {
      this.src = name;
      this.setState({
        view: false,
        isIconUpdate: true,
      });
    } else {
      this.setState({
        view: false,
      });
    }
  };

  showViewModal(params) {
    this.setState(
      {
        view: true,
      },
      () => {
        this.viewModalRef.show();
      }
    );
  }

  handleSelectColortype(value, option) {
    this.selectedColorType = value;
    this.selctedColorKey = option.key;
    this.isColorChanged = true;
  }

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  validateForm = (values) => {
    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.name) {
      errors.name = "Name is required";
    }

    if (!values.short_name) {
      errors.short_name = "Short name is required";
    }

    if (!values.conversion_rate) {
      errors.conversion_rate = "Convertion rate is required";
    } else {
      if (isNaN(values.conversion_rate)) {
        errors.conversion_rate = "Only numeric value allowed";
      }
    }

    if (!values.country_id) {
      errors.country_id = "Country is required";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  };

  handleSubmit = (values,action) => {
    const { errors, isIconUpdate } = this.state;
    let listErrors = { ...errors };

    values.color = this.selctedColorKey;
    values.icon = isIconUpdate ? this.src : "";

    console.log("Basic Value - ", values, this.props.data.id);

    this.props.onSubmit(values, this.props.data.id);
    this.props.handleCancel();

    this.setState({
      visible: false,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleTime(e, s) {
    this.setState({
      time: e,
      timeString: s,
    });
  }

  handleCloseTime(e, s) {
    this.setState({
      closeTime: e,
      closeTimeString: s,
    });
  }

  handleDetailsModalclose(item) {
    this.setState({
      view: false,
      lat: item.lat,
      long: item.lng,
    });
  }

  render() {
    const { data } = this.props;
    const { isLoading } = this.state;
    console.log(
      data.country.id,
      "!!!!!selectDisplayColorName",
      this.selectDisplayColorName
    );

    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                id: data.id,
                name: data.name,
                short_name: data.short_name,
                country_id: data.country_id,
                conversion_rate: data.conversion_rate,
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Currency Name
                        <Input
                          id="name"
                          placeholder="US Dollar"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.name && touched.name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.name}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Short Name
                        <Input
                          id="short_name"
                          placeholder="USD"
                          value={values.short_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.short_name && touched.short_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.short_name}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Conversion Rate
                        <Input
                          addonBefore="1 LYD = "
                          id="conversion_rate"
                          placeholder="0.00"
                          value={values.conversion_rate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.conversion_rate && touched.conversion_rate ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.conversion_rate}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Country
                        <Field
                          name="country_id"
                          render={({ field }) => (
                            <Select
                              showSearch
                              defaultValue={data.country.id}
                              style={{ width: "100%" }}
                              placeholder="Choose country"
                              {...field}
                              onChange={(value) => {
                                setFieldValue("country_id", value);
                              }}
                              onBlur={() => setFieldTouched("country_id", true)}
                              onSelect={(value, option) =>
                                this.handleSelectColortype(value, option)
                              }
                            >
                              <option value="">Select Country</option>
                              {this.props.countryData &&
                                this.props.countryData.length &&
                                this.props.countryData.map((i) => {
                                  return <option value={i.id}>{i.name}</option>;
                                })}
                            </Select>
                          )}
                        />
                        {errors.country_id && touched.country_id ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.country_id}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    {/* <Button
                      // type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Update
                    </Button> */}
                    <Button
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit} // Make sure this line is correct
                    >
                      Update
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
