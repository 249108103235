import { ENDPOINT } from "../../network/ENDPOINT";
import { callGetApiToServer } from "../callApi";

export async function getExpensesListAPI(authtoken, skip, limit,  searchData="", city) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let endUrl = `${ENDPOINT.get_expenses}/${skip}/${limit}?search_text=${searchData}`;
    if (city) {
      endUrl += `&city=${encodeURIComponent(city)}`;
    }

    let responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
