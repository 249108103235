import React, { Fragment, Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Users,
  List,
  Gift,
  Settings,
  CreditCard,
  Flag,
  DollarSign,
} from "react-feather";
import { callAllKpis } from "../../Graphs/Dashboard/callKpis";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { notification, Spin } from "antd";
import { Link } from "react-router-dom";
import { getCompanyWalletBalance } from "../../Graphs/Company/listCompany";
import SearchTransactionRecordsTable from "./SearchTransactionRecordsTable";
import { alertError } from "../../utils/alert";

class Balance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      walletData: [],
      isLoading: false,
    };
  }

  async componentDidMount() {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setRouteName,
        loginUserData,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      await setRouteName("BALANCE");
      let walletData = await getCompanyWalletBalance(
        authToken,
        loginUserData.id
      );

      if (walletData.status == 200) {
        //set walletData
        this.setState({
          walletData: walletData.data,
        });
      } else if (walletData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw walletData && walletData.message ? walletData.message : "Error";
      }
    } catch (e) {
      alertError("BALANCE", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    const { walletData, isLoading } = this.state;
    const { loginUserData } = this.props;

    return (
      <Fragment>
        <Breadcrumb
          parent="Balance"
          title="Balance"
          icon={<DollarSign />}
          url="/balances"
        />
        {isLoading ? (
          <div style={{ marginLeft: "20px" }}>
            <Spin
              size="large"
              style={{ marginLeft: "480px", marginTop: "130px" }}
            />
          </div>
        ) : null}
        <div className="container-fluid">
          {walletData && walletData.length > 0 ? (
            <div className="row">
              {walletData.map((data, index) => {
                return (
                  <div className="col-sm-6 col-xl-3 col-lg-6">
                    <div className="card o-hidden">
                      <div className="bg-primary b-r-4 card-body">
                        <div className="media static-top-widget">
                          <div className="align-self-center text-center">
                            <Users />
                          </div>
                          <div className="media-body">
                            <span className="m-0">{data.coin_name}</span>
                            <h4 className="mb-0 counter">
                              {parseFloat(data.total_wallet_balance).toFixed(2)}
                            </h4>
                            <Users className="icon-bg" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <Fragment>
              <h5 style={{ marginTop: "30%", marginLeft: "30%" }}>
                No balance in coins.
              </h5>
            </Fragment>
          )}
        </div>

        <div>
          <div
            style={{ fontWeight: "bold", fontSize: "18px", marginTop: "10px" }}
          >
            Search Company Transactions
          </div>
          <div style={{ marginTop: "10px" }}>
            <SearchTransactionRecordsTable />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Balance));
