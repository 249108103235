import {Tooltip, notification, Spin, Button, Popconfirm } from "antd";
import * as React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { searchCard } from "../../Graphs/Card/searchCard";
import { searchCompanyCard } from "../../Graphs/Card/searchCompanyCard";
import TableWrap from "../common/Table";
import OTPModal from "../Login/OTPModal";
import { alertError } from "../../utils/alert";
import { sendOtpAPI } from "../../Graphs/Login/sendOTP";
import { verifyOTP } from "../../Graphs/Login/VerifyOTP";
import {
  CloudFilled,
  DeleteOutlined,
  
} from "@ant-design/icons";
import { Layers } from "react-feather";
import Title from "../User/Title";
import { getInventoryBatch } from "../../Graphs/inventory/getInventoryBatch";
import { inventoryOtpVerify } from "../../Graphs/inventory/inventoryOtpVerify";
import { deleteInventoryBatch } from "../../Graphs/inventory/deleteInventoryBatch";
class InventoryBatchList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      cardLimit: {},
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      deletedItems: null,
      isShowOTP: false,
      BatchId: "",
      otpVerifyToken: "",
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedBatch = this.getLimitedBatch.bind(this);
    this.expireToken = this.expireToken.bind(this);
  }

  async componentDidMount() {
    const { setRouteName } = this.props;
    setRouteName("INVENTORY-BATCH-LIST");
    this.inventoryBatchData(0, 10);
    this.setState({
      data: this.props.data,
      isLoading: false,
      cardSelectedData: null,
      cardLimit: null,
    });
  
  }

  async getLimitedBatch(pagination) {
    const { search } = this.props;
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (search != null && search != "") {
          this.handleBatchListSearch(start, end);
        } else {
          this.inventoryBatchData(start, end);
        }
      }
    );
  }

  handleBatchListSearch = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setInventoryBatchData,
        setInventoryBatchTotal,
        search,
        loginUserData
      } = this.props;
      this.setState({
        isLoading: true,
      });

    
      const city = loginUserData.role === "Sub_admin" ? loginUserData.city : "";
       const response = await getInventoryBatch(authToken, start, end, city, search);
       
      if (response.status == 200) {
        //set user Data
        await setInventoryBatchData(response.data);
        await setInventoryBatchTotal(response.total);

        this.setState({
          isLoading: false,
          data: response.data,
        });
      } else if (response.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw response && response.message ? response.message : "Error";
      }
    } catch (e) {
      alertError("Inventory Batch List", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  inventoryBatchData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setInventoryBatchData,
        setInventoryBatchTotal,
        loginUserData
      } = this.props;
      this.setState({
        isLoading: true,
      });
      const city = loginUserData.role === "Sub_admin" ? loginUserData.city : "";
       const response = await getInventoryBatch(authToken, start, end,city, "");
      if (response.status == 200) {
        //set user Data
        await setInventoryBatchData(response.data);
        await setInventoryBatchTotal(response.total);

        this.setState({
          isLoading: false,
          data: response.data,
        });
      } else if (response.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
         history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw response && response.message ? response.message : "Error";
      }
    } catch (e) {
      alertError("Inventory Batch List", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };
  expireToken() {
    this.setState({
      otpVerifyToken: "",
    });
  }

  sendOTP = async (item) => {
    try {
      const { authToken } = this.props;
      this.setState({
        isLoading: true,
      });

      const otp = await sendOtpAPI(authToken);

      if (otp.status == 200) {
        notification["success"]({
          message: "Inventory Batch",
          description: otp.message,
        });
        this.setState({
          deletedItems: item,
          isShowOTP: true,
          BatchId: item.id,
        });
      } else {
        throw otp && otp.message ? otp.message : "Error";
      }
    } catch (e) {
      alertError("Inventory Batch List", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  onSubmitOTP = async (values) => {
    try {
      const { authToken ,loginUserData } = this.props;
      const { deletedItems, startIndex, limitNumber } = this.state;

      const body = {
        batch_number: deletedItems.id,
        otp: parseInt(values),
      };
      this.setState({
        isLoading: true,
      });

      const verifyData = await inventoryOtpVerify(body, authToken);
      if (verifyData.status == 200) {
     const city = loginUserData.role === "Sub_admin" ? loginUserData.city : "";
      const body = {
        batch_number: deletedItems.id,
        token: verifyData.token,
        ...(city && { city }) 
      }
       const response = await deleteInventoryBatch(authToken,body);
       if (response.status == 200) {
         notification["success"]({
           message: "Inventory Batch",
           description: response.message,
         });
         this.setState({
           otpVerifyToken: verifyData?.token,
           isShowOTP: false,
         });
         this.inventoryBatchData(startIndex, limitNumber);

       }else{
        notification["error"]({
          message: "Inventory Batch",
          description: response.message,
        });
        this.setState({
          isShowOTP: false,
        });
       }
       
      } else {
        throw verifyData && verifyData.message
          ? verifyData.message
          : "Error in verification";
      }
    } catch (err) {
      notification["error"]({
        message: "Inventory Batch",
        description: err.message,
      });
    } finally {
      this.setState({
        isLoading: false,
        isShowOTP: false,
        otpVerifyToken: "",
      });
    }
  };
  handleConfirmDelete = (item) => {
    this.sendOTP(item);
  };
  handleCloseOtpModal() {
    this.setState({
      deletedItems: null,
      isShowOTP: false,
    });
  }
  handleTableChange(pagination, filters) {
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedBatch(pagination)
    );
  }
  counter = 0;
  tempCounter = 0;
   columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      fixed: true,
      render: (text, item, index) => (
        <div>
          <span>{index + 1}</span>
        </div>
      ),
    },
    {
      title: "Batch No.",
      key: "Batch_number",
      fixed: true,
      render: (text, item) => (
        <div>
          <span>{item.id || "-"}</span>
          </div>
      ),
    },
    {
      title: "Card Name",
      key: "card_name",
      fixed: true,
      render: (text, item) => (
        <div>
          <strong>{item.card?.name || "-"}</strong>
        </div>
      ),
    },
    {
      title: "File Note",
      key: "note",
      render: (text, item) => (
        <div>
          <span>{item.note || "-"}</span>
        </div>
      ),
    },
    {
      title: "Card Upload Count",
      key: "card_upload_count",
      render: (text, item) => (
        <div>
          <span>{item.card_upload_count || 0}</span>
        </div>
      ),
    },
    {
      title: "Paid Count",
      key: "paid_count",
      render: (text, item) => (
        <div>
          <span>{item.paid_count || 0}</span>
        </div>
      ),
    },
    {
      title: "Unpaid Count",
      key: "unpaid_count",
      render: (text, item) => (
        <div>
          <span>{item.unpaid_count || 0}</span>
        </div>
      ),
    },
    {
      title: "Deleted Count",
      key: "Deleted_count",
      render: (text, item) => (
        <div>
          <span>{item.card_upload_count - (item.unpaid_count + item.paid_count) || 0}</span>
        </div>
      ),
    },
    {
      title: "Added By",
      key: "added_by",
      render: (text, item) => (
        <div>
          <span>{item?.admin?.first_name + " " + item?.admin?.last_name || "-"}</span>
        </div>
      ),
    },
    {
      title: "File Upload Date",
      key: "createdAt",
      render: (text, item) => (
        <div>
          <span>{new Date(item.createdAt).toLocaleString() || "-"}</span>
        </div>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (text, item) => (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {item.unpaid_count > 0 && (
            <Tooltip placement="bottom" title="Delete">
              <Popconfirm
                onConfirm={this.handleConfirmDelete.bind(this, item)}
                title="Are you sure you want to delete this unpaid batch?"
              >
                <Button shape="circle" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];
  
  render() {
    const {
      isLoading,
      deletedItems,
        isShowOTP,
    } = this.state;
    const { inventoryBatchData, inventoryBatchTotal, searchLoader, authToken } =
      this.props;
    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <Title
          parent="Inventory Batch List"
          title="Inventory Batch List"
          icon={<Layers />}
          url="/inventory-batch-list"
        />
        <TableWrap
          data={inventoryBatchData}
          columns={this.columns}
          isLoading={this.state.isLoading}
          total={inventoryBatchTotal}
          pagination={this.state.pagination}
          handleTableChange={this.handleTableChange}
          scroll={{ x: 1440 }}
        />
     
        {isShowOTP ? (
          <OTPModal
            admin={false}
            onClose={this.handleCloseOtpModal.bind(this)}
            onSubmitOTP={this.onSubmitOTP}
            uploadRedeemData={deletedItems}
            authToken={authToken}
          />
        ) : null}
        

  

     

      
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  inventoryBatchData: state.inventoryBatch.inventoryBatchData,
  inventoryBatchTotal: state.inventoryBatch.inventoryBatchTotal,
  searchLoader: state.auth.searchLoader,
  search: state.auth.searchText,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    // setUserData: (userData) => {
    //   dispatch({
    //     type: "SET_USER_DATA",
    //     userData: userData,
    //   });
    // },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setInventoryBatchData: (data) => {
      dispatch({
        type: "SET_INVENTORY_BATCH_DATA",
        inventoryBatchData: data,
      });
    },
    setInventoryBatchTotal: (data) => {
      dispatch({
        type: "SET_INVENTORY_BATCH_TOTAL",
        inventoryBatchTotal: data,
      });
      
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InventoryBatchList));