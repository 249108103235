import * as React from "react";
import { Modal, Spin, notification } from "antd";
import { AddEmployeeForm } from "./AddEmployeeForm";

import { addEmployee } from "../../Graphs/Employee/addEmployee";
import { alertError } from "../../utils/alert";
import { getCityListAPI } from "../../Graphs/Master/City/getCityListAPI";

export class AddEmployeeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
      coinsList: [],
      cityData: [{ label: "--- Select City ---", value: "Select" }],

    };
  }
  async getCityList() {
    try {
      let getSalesData = await getCityListAPI(this.props.authToken);

      console.log(getSalesData,"getSalesDatagetSalesDatagetSalesData")
      if (getSalesData.status == 200) {
        let temp = getSalesData.data.map((i) => ({
          ...i,
          label: i.name,
          value: i.name,
        }));
        this.setState({
          cityData: [...this.state.cityData, ...temp],
        });
      }
    } catch (error) {
    console.log(error,"errorerrorerrorerror")
      alertError("Error", error);

      return -1;
    }
  }
  async componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }

    this.getCityList();
    await this.getCoinsList();

  }

  addSubmit = async (value) => {
    try {
      this.setState({
        isLoading: true,
      });
      const { loginUserData } = this.props;

      let city = loginUserData.role == "Sub_admin" ? loginUserData.city : "";
      let body;
      if (city) {
        body = { ...value, city };
      } else {
        body = value;
      }
      let coinData = await addEmployee(this.props.authToken, body);

      if (coinData.status == 200) {
        this.setState({
          isLoading: true,
        });

        notification["success"]({
          message: "Employee",
          description: "Successfully Added",
        });

        this.props.reloadData();

        return 1;
      } else {
        throw coinData.message ? coinData.message : "Error";
      }
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      alertError("Employee", error);
      return -1;
    }
  };

  getCoinsList = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      let coinData = {}; // await ( this.props.authToken, 0, 100000);
      this.setState({
        coinsList: coinData.data,
      });
    } catch (error) {
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show = () => {
    this.setState({
      visible: true,
    });
  };

  hide() {
    this.setState({
      visible: false,
    });
    //this.props.onClose();
  }

  render() {
    const { primaryColor } = this.props;
    return (
      <React.Fragment>
        <div
          onClick={this.show}
          style={{
            cursor: "pointer",
            width: "100px",
            backgroundColor: primaryColor,
            color: "white",
            padding: "7px 0px",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          ADD 
        </div>

        <Modal
          width="600px"
          closable={this.state.closable}
          open={this.state.visible}
          title="Add Employee"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          destroyOnClose={true}
        >
          {this.state.isLoading ? (
            <div style={{ justifyContent: "center" }}>
              <Spin />
            </div>
          ) : (
            <AddEmployeeForm
              authToken={this.props.authToken}
              onClose={this.hide}
              onSubmit={this.addSubmit}
              handleCancel={this.handleCancel}
              loginUserData={this.props.loginUserData}
              cityData={this.state.cityData}

            />
          )}
        </Modal>
      </React.Fragment>
    );
  }
}
