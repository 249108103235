import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, TimePicker, Select, notification } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";

import { checkNameExist } from "../../../Graphs/Master/Coin/listCoin";
import { alertError } from "../../../utils/alert";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.handleCloseTime = this.handleCloseTime.bind(this);
    this.handleDetailsModalclose = this.handleDetailsModalclose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      locationList: [],
      recordSelectedtoView: null,
      data: [],
      view: false,
      index: null,
      isLoading: false,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: false,
      inputVisible: false,
      inputValue: "",
      time: "",
      closeTime: "",
      closeTimeString: "",
      timeString: "",
      roleType: "1",
    };
  }

  input;

  componentDidMount() {
    console.log("component did mount");
  }

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  validateForm = (values) => {
    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.name) {
      errors.name = "name is required";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  };

  validateName = async (name) => {
    try {
      this.setState({
        isLoading: true,
      });

      let coinData = await checkNameExist(name);

      if (coinData.status == 200) {
        this.setState({
          isLoading: false,
        });

        return coinData.data;
      } else {
        throw coinData.message ? coinData.message : "Error";
      }
    } catch (e) {
      alertError("COIN", e);

      this.setState({
        isLoading: false,
      });

      return -1;
    }
  }; 

  handleSubmit = async (values, action) => {
    console.log("Basic Value - ", values, this.props.data.id);

    // let isNameUnique = this.validateName(values.name);

    // if(isNameUnique == false){
    //     this.setState({
    //         errors: {
    //             name: "Name Already In Used."
    //         }
    //     })
    //     return;
    // }
    // else{

    //     return;
    // }

    // if(isNameUnique == -2){
    //     return;
    // }

    // this.setState({
    //     errors: {
    //         name: ""
    //     }
    // })

    this.props.onSubmit(values, this.props.data.id);
    this.props.handleCancel();

    this.setState({
      visible: false,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleTime(e, s) {
    this.setState({
      time: e,
      timeString: s,
    });
  }

  handleCloseTime(e, s) {
    this.setState({
      closeTime: e,
      closeTimeString: s,
    });
  }

  handleDetailsModalclose(item) {
    this.setState({
      view: false,
      lat: item.lat,
      long: item.lng,
    });
  }

  render() {
    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                name: this.props.data.name,
                price: this.props.data.price,
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-wrap">
                        Name
                        <Input
                          id="name"
                          placeholder="Coin Name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.name && touched.name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.name}
                          </p>
                        ) : null}
                      </div>
                      {/* <div className="formik-field-right">
                                Price
                                <Input
                                    id="price"
                                    placeholder="Coin Price"
                                    value={values.price}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.price && touched.price ? (
                                <p
                                    style={{
                                    color: "red",
                                    fontSize: "small",
                                    margin: "0"
                                    }}
                                >
                                    {errors.price}
                                </p>
                                ) : null}
                            </div> */}
                    </div>

                    <Button
                      // type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Update
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
