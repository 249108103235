import { callApiToServer } from '../callApi';

export async function CardLimitAPI(
  authtoken,
  cardId,
  payload
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    };
      
    let body = JSON.stringify({
      max_buy_limit: payload.card_limit == "" ? null : payload.card_limit,
    });
    console.log("endUrl printed here", body);

    let endUrl = `v1/auth/update-card-buy-limit/${cardId}`;
  
    let responseData = await callApiToServer(body, header, "POST", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}
