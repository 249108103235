import { callGetApiToServer } from '../callApi';

export async function getAllCsvTransaction(
  authtoken,
  startDate,
  endDate
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    }
  
    let endUrl = "v1/auth/list-user-transactions-csv/" + startDate + "/" + endDate;
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callGetApiToServer(header, "GET", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}
