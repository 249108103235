import { callGetApiToServer, callApiToServer } from '../callApi';

export async function getAllCompanyTrn(
  authtoken,
  skipNumber,
  limitNumber
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    }
  
    let endUrl = "v1/auth/list-company-trn/" + skipNumber + "/" + limitNumber;
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callGetApiToServer(header, "GET", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}

export async function getCompanyTrn(
  authtoken,
  id,
  skipNumber,
  limitNumber
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    }
  
    // let endUrl = "v1/list-company-trn/page/" + id + "/" + skipNumber + "/" + limitNumber;
    let endUrl = "v1/auth/list-company-trn-list/" + id + "/" + skipNumber + "/" + limitNumber;

    console.log("endUrl printed here", endUrl);
  
    let responseData = await callGetApiToServer(header, "GET", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}


export async function getAllCompanyCoinTrn(
  authtoken,
  id,
  skipNumber,
  limitNumber
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    }
  
    let endUrl = "v1/company-transaction/" + id + "/" + skipNumber + "/" + limitNumber;
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callGetApiToServer(header, "GET", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}

export async function getAllSearchCompanyCoinTrn(
  authtoken,
  id,
  skipNumber,
  limitNumber,
  searchText
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    };

    let body = JSON.stringify({
      search_text: searchText,
    });
  
    let endUrl = "v1/company-search-transaction/" + id + "/" + skipNumber + "/" + limitNumber;
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callApiToServer(body, header, "POST", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}

export async function getAllSearchCompanyCoinPayTrn(
  authtoken,
  id,
  skipNumber,
  limitNumber,
  searchText
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    };

    let body = JSON.stringify({
      search_text: searchText,
    });
  
    let endUrl = "/v1/search-company-pay-trn/" + id + "/" + skipNumber + "/" + limitNumber;
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callApiToServer(body, header, "POST", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}