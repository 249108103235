import { callGetApiToServer, callApiToServer } from '../callApi';

export async function getDistributorTransaction(
    authtoken,
    skipNumber,
    limitNumber,
    distributorId
) {
    try {
        let header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "authorization": "Berear " + authtoken
        }

        let endUrl = "v1/auth/distributor-transactions/" + distributorId + "/" + skipNumber + "/" + limitNumber;

        let responseData = await callGetApiToServer(header, "GET", endUrl);

        return responseData;
    } catch (err) {
        throw err;
    }
}