import * as React from "react";
import {  notification, Button, Input } from "antd";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Formik } from "formik";
import ModalWrap from "../common/modal";
import { CardLimitAPI } from "../../Graphs/User/sellCardLimit";
import { alertError } from "../../utils/alert";

export class CardLimitModal extends React.Component {
   
  addSubmit = async (body) => {
    try {
      let sellCardData = await CardLimitAPI(this.props.authToken, this.props.data.id,body); 
      
      if(sellCardData.status == 200){
        if(sellCardData){
          notification["success"]({
            message: "Success",
            description: sellCardData.message,
          });
        }
      }
      this.props.onClose();
      this.props.reload({
        pageSize: 10,
        current: 1,
      });
      
    } catch (error) {
      alertError("ERROR",error);
     
      return -1;
    }
  };


  render() {

    return (
      // <StyleBox>
      <ModalWrap
            visible={this.props.visible}
            closable={this.props.onClose}
            title="Card limit"
            onOk={this.props.handleOk}
            onCancel={this.props.handleCancel}
            isLoading={this.props.isLoading}
        >
        <Formik
            initialValues={{
                card_limit:this.props.data ? this.props.data.max_buy_limit : ''
            }}
            onSubmit={this.addSubmit}
        >
            {({
                values,
                handleChange,
                handleSubmit,
            }) => {
                return (
                    <div>
                        <div className="formik-field_wrap" >
                            <div className="formik-field">
                                <Input
                                    id="card_limit"
                                    placeholder="Card Limit"
                                    name="card_limit"
                                    value={values.card_limit}
                                    onChange={handleChange}
                                    // onBlur={handleBlur}
                                />
                            </div>
                        </div>

                        <Button
                            style={{ marginTop: "19px", background: "#5C6AC4", color: "#fff" }}
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </div>
                );
            }}
        </Formik>
      </ModalWrap>
    );
  }
}

const mapStateToProps = state => ({
  authToken: state.auth.authToken,
});

export default connect(mapStateToProps)(withRouter(CardLimitModal));