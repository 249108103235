import {
  Button,
  Modal,
  notification,
  Popconfirm,
  Switch,
  Table,
  Tooltip,
} from "antd";
import React, { useState } from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { color } from "../../../constant/comman";
import { changeStatus } from "../../../Graphs/Master/Category/changeStatus";
import { deleteCategory } from "../../../Graphs/Master/Category/deleteCategory";
import { AddCategoryModal } from "./AddCategoryModal";
import { EditModal } from "./EditModal";
import { editCategory } from "../../../Graphs/Master/Category/editCategory";
import { addCategory } from "../../../Graphs/Master/Category/addCategory";
import { alertError } from "../../../utils/alert";

const SubCategoryModel = ({ onClose = () => { }, data, myProps }) => {
  const handleCancel = () => {
    onClose();
  };
  const [subCategoryList, setSubCategoryList] = useState(data);
  const [isSubCategory, setSubCategory] = useState(true);
  const [isAddSubCategory, setAddSubCategory] = useState(false);
  const [isEditSubCategory, setEditSubCategory] = useState(false);
  const [categorySelectedData, setCategorySelectedData] = useState();
  const [startIndex, setIndex] = useState(0);
  const [limitNumber, setLimitNumber] = useState(10);
  const columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "name",
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                  <div>{item.name ? item.name : ""}</div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Arabic Name",
      key: "arabic name",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                  <div>{item.arabic_name ? item.arabic_name : "-"}</div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      render: (text, item, index) => {
        return <div>{item.description ? item.description : "-"}</div>;
      },
    },
    {
      title: "Color",
      key: "color",
      width:"150px",
      dataIndex: "color",
      render: (text, item, index) => {
        return (
          <div>
            {item.color ? (
              <div style={{ display: "inline-block", width: "100%" }}>
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: color[item.color].value,
                    float: "left",
                    borderRadius: "5px",
                  }}
                ></div>
                <span
                  style={{
                    float: "left",
                    margin: "0px",
                    padding: "0px",
                    marginLeft: "25px",
                    marginTop: "5px",
                    lineHeight: "0px",
                  }}
                >
                  {color[item.color].name}
                </span>
              </div>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Sort Order",
      key: "sort_order",
      dataIndex: "sort_order",
      render: (text, item, index) => {
        return (
          <div>
            {item.sort_order
              ? item.sort_order
                ? String(item.sort_order)
                : "-"
              : "-"}
          </div>
        );
      },
    },
    {
      title: "Active Status",
      key: "isActive",
      render: (text, item, index) => {
        return (
          <div>
            {
              <Switch
                checked={item.is_active}
                style={{
                  backgroundColor: item.is_active ? "#4466f2" : "#bababa",
                }}
                onChange={(e) => {
                  console.log(e);
                  updateActive(item, e);
                }}
              />
            }
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 135,
      key: "action",
      render: (text, item, index) => {
        return (
          <>
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ margin: "5px" }}>
                <Tooltip placement="bottom" title="Edit">
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      setEditSubCategory(true);
                      setCategorySelectedData(item);
                    }}
                  />
                </Tooltip>
              </div>
              <div style={{ margin: "5px" }}>
                <Tooltip placement="bottom" title="Delete">
                  <Popconfirm
                    onConfirm={() => deleteSubCategory(item)}
                    title="Are you sure you want to delete category?"
                  >
                    <Button
                      shape="circle"
                      icon={<DeleteOutlined />}
                    // onClick={() => {
                    //   deleteSubCategory(item);
                    // }}
                    />
                  </Popconfirm>
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const updateActive = async (value, checked) => {
    try {
      let statusData = await changeStatus(
        myProps?.authToken,
        value.id,
        checked
      );
      console.log("statusData", statusData);
      if (statusData.status == 200) {
        notification["success"]({
          message: statusData?.message,
          description: "Successfully changed status",
        });
        if (statusData?.data == 1) {
          var newList = { ...subCategoryList };
          newList.sub_categories.filter((item) => {
            if (item.id == value.id) {
              item.is_active = checked;
            }
          });
        }
        await setSubCategoryList(newList);
      } else if (statusData.status == 401) {
        // router.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (err) {
      alertError("SUBCATEGORY", err);
    } finally {
    }
  };

  const deleteSubCategory = async (value) => {
    console.log("value", value);
    try {
      let deleteData = await deleteCategory(
        myProps.authToken,
        value.id,
        startIndex,
        limitNumber
      );
      console.log("statusData", deleteData);
      if (deleteData.status === 200) {
        notification["success"]({
          message: "Delete Category",
          description: "Successfully deleted",
        });
        var data = { ...subCategoryList };
        if (deleteData.data) {
          data.sub_categories = data.sub_categories.filter(
            (item) => item.id !== value.id
          );
        }
        await setSubCategoryList(data);
      } else if (deleteData.status === 401) {
        // router.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw deleteData && deleteData.message ? deleteData.message : "Error";
      }
    } catch (err) {
      alertError("SUBCATEGORY", err);
    } finally {
    }
  };

  const handleBack = () => {
    if (isAddSubCategory) {
      setSubCategory(true);
      setAddSubCategory(false);
    } else if (isEditSubCategory) {
      setSubCategory(true);
      setEditSubCategory(false);
    } else {
      handleCancel();
    }
  };

  const handleEdit = async (categoryObject, categoryId) => {
    try {
      let statusData = await editCategory(
        myProps.authToken,
        categoryObject,
        categoryId
      );
      if (statusData.status == 200) {
        notification["success"]({
          message: "Edit Category",
          description: "Successfully edited",
        });
        if (statusData.data[0] == 1) {
          var newList = { ...subCategoryList };

          newList.sub_categories.filter((item) => {
            console.log("categoryObject", categoryObject);
            if (item.id == categorySelectedData.id) {
              item.name = categoryObject.name;
              item.description = categoryObject.description;
              item.color = categoryObject.color;
              item.sort_order = categoryObject.sort_order;
              item.icon = categoryObject.icon;
              item.arabic_name = categoryObject.arabicName;
              item.advertisement = categoryObject.advertisement
            }
          });
          await setSubCategoryList(newList);
        }
      } else if (statusData.status == 401) {
        // history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      alertError("SUBCATEGORY", e);
    }
  };

  const handleAdd = async (categoryObject) => {
    setAddSubCategory(false);
    categoryObject.parent_id = subCategoryList.id;
    try {
      let statusData = await addCategory(myProps.authToken, categoryObject);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Add Category",
          description: "Successfully added",
        });
        var data = { ...subCategoryList };
        if (statusData.data) {
          statusData.data.sort_order = categoryObject.sort_order;
          data = {
            ...data,
            sub_categories: Array.isArray(data?.sub_categories)
              ? [...data?.sub_categories, { ...statusData.data }]
              : [{ ...statusData.data }],
          };
        }
        setSubCategoryList(data);
      } else if (statusData.status == 401) {
        // history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      alertError("SUBCATEGORY", e);
    }
  };

  return (
    <>
      <Modal
        width="800px"
        title="Sub Categories"
        open={true}
        onCancel={handleCancel}
        footer={[
          <Button
            key="Add"
            onClick={() =>
              isEditSubCategory ? handleEdit : setAddSubCategory(true)
            }
          >
            {isEditSubCategory ? "Edit" : "Add SubCategory"}
          </Button>,
          <Button
            key="back"
            onClick={() => {
              handleBack();
            }}
          >
            {isAddSubCategory || isEditSubCategory ? "Back" : "Cancel"}
          </Button>,
        ]}
      >
        {isSubCategory && (
          <Table
            rowKey={(record) => record._id}
            columns={columns}
            size={"small"}
            dataSource={subCategoryList?.sub_categories}
          />
        )}

        {isAddSubCategory && (
          <AddCategoryModal
            onSubmit={handleAdd}
            onCancel={() => setAddSubCategory(false)}
            type="subCategory"
            primaryColor="#4466f2"
          />
        )}

        {isEditSubCategory && (
          <EditModal
            admin={false}
            type="subCategory"
            // refx={(e) => (this.modalRef = e)}
            onClose={() => setEditSubCategory(false)}
            categorySelectedData={categorySelectedData}
            onSubmit={handleEdit}
          />
        )}
      </Modal>
    </>
  );
};

export default SubCategoryModel;
