import { ENDPOINT } from "../../network/ENDPOINT";
import { callGetApiToServer } from "../callApi";

export async function viewTicketCommentsAPI(authtoken,transaction_id) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let endUrl = `${ENDPOINT.support_list_comments}/${transaction_id}`;

    let responseData = await callGetApiToServer(header, "GET", endUrl);
    console.log("responseData==",responseData);

    return responseData;
  } catch (err) {
    throw err;
  }
}
