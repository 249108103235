import { callApiToServer } from "../callApi";

export async function addMaximumInstantBalance(
  authtoken,
  user_id,
  body,
) {
    console.log("body --",body, user_id);

  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    }
    console.log("payload",body);
    let endUrl = "v1/auth/update-max-instant-balance/"+user_id;
  
    let responseData = await callApiToServer(body, header, "POST", endUrl);
    console.log("responseData",responseData);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}


