import React, { Fragment, Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Button, Input, Select, Spin, Table } from "antd";
import Title from "../User/Title";
import { DollarSign } from "react-feather";
import { getRedeemCode } from "../../Graphs/Card/getRedeemCode";
import { dateFormat } from "../../utils/general";

const options = [
  {
    value: "redeem_code",
    label: "Redeem Code",
  },
  {
    value: "serial_number",
    label: "Serial Number",
  },
];

class SearchRedeemCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showTable: false,
      redeemData: [],
      totalRedeemData: 0,
      inputValue: "",
      SelectValue: "",
    };
  }

  async getRedeemCodeData() {
    try {
      const { authToken, history } = this.props;

      let data = {
        search_type: this.state.SelectValue,
      };

      if (this.state.SelectValue === "redeem_code") {
        data.redeem_code = this.state.inputValue;
      }

      if (this.state.SelectValue === "serial_number") {
        data.serial_number = this.state.inputValue;
      }
      let body = JSON.stringify(data);
      let responseData = await getRedeemCode(authToken, body);
      this.setState({
        isLoading: true,
      });
      if (responseData.status == 200) {
        this.setState({
          redeemData: responseData.data,
          totalRedeemData: responseData.total,
          isLoading: false,
          showTable: true,
        });
      } else if (responseData.status == 401) {
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw responseData && responseData.message
          ? responseData.message
          : "Error";
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }
  handelSearchTextChange = async (event) => {
    const inputValue = event?.target?.value;
    this.setState({ inputValue });
  };

  handleChange = (value) => {
    this.setState({ SelectValue: value });
  };
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div key={index}>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Card Name",
      key: "CoinName",
      render: (text, item, index) => {
        return (
          <div key={item?.card?.name}>
            {item?.card?.name ? item.card.name : "-"}
          </div>
        );
      },
    },
    {
      title: "Redeem Code",
      key: "RedeemCode",
      render: (text, item, index) => {
        return (
          <div key={item?.redeem_code}>
            {item?.redeem_code ? item.redeem_code : "-"}
          </div>
        );
      },
    },
    {
      title: "Serial Number",
      key: "SerialNumber",
      render: (text, item, index) => {
        return (
          <div key={`SerialNumber${index}`}>
            {item?.serial_number ? item.serial_number : "-"}
          </div>
        );
      },
    },
    {
      title: "Inventory From",
      key: "InventoryFrom",
      render: (text, item, index) => {
        return (
          <div key={item?.added_by}>
            {item?.added_by
              ? item.added_by === "admin"
                ? "Admin"
                : item.added_by === "sub_admin"
                ? "Sub Admin"
                : item.added_by === "company"
                ? "Company"
                : "-"
              : "-"}
          </div>
        );
      },
    },
    {
      title: "Uploaded Date",
      key: "UploadedDate",
      render: (text, item, index) => {
        return <div key={item?.createdAt}>{dateFormat(item.createdAt)}</div>;
      },
    },
    {
      title: "Is Paid",
      key: "IsPaid",
      render: (text, item, index) => {
        return <div key={`${index}`}>{item.is_paid ? "PAID" : "UNPAID"}</div>;
      },
    },
    {
      title: "Inventory Batch Details",
      key: "InventoryBatchDetails",
      render: (text, item, index) => {
        return (
          <div key={index}>
            {(item?.inventory_batches?.id || item?.inventory_batches?.note) ? (
              <div
                key={item?.inventory_batches?.added_by}
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                {item?.inventory_batches?.id && <span>
                  <strong>Batch Id:</strong> {item?.inventory_batches?.id}
                </span>}
                {item?.inventory_batches?.note && <span>
                  <strong>Note:</strong> {item?.inventory_batches?.note}
                </span>}
              </div>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },

    {
      title: "Purchased By",
      key: "PurchasedBy",
      render: (text, item, index) => {
        return (
          <div key={index}>
            {item?.user_cards?.length > 0 ? (
              item.user_cards.map((card, cardIndex) => (
                <div
                  key={card.id}
                  style={{
                    marginBottom: "10px",
                    borderBottom: "1px solid #ddd",
                    paddingBottom: "10px",
                  }}
                >
                  <div>
                    <strong>Name:</strong> {card.user.first_name}{" "}
                    {card.user.last_name}
                  </div>
                  <div>
                    <strong>Email:</strong> {card.user.email}
                  </div>
                  <div>
                    <strong>Phone:</strong> {card.user.phone}
                  </div>
                  <div>
                    <strong>Purchased At:</strong>{" "}
                    {new Date(card.createdAt).toLocaleString()}
                  </div>
                </div>
                
              ))
            ) : (
              <div>-</div>
            )}
          </div>
        );
      },
    },
  ];
  render() {
    return (
      <Fragment>
        <Title
          parent="Search Redeem Code"
          title="Search Redeem Code"
          icon={<DollarSign />}
          url="/Search-Redeem-Code"
        />

        <div
          style={{
            marginLeft: "15px",
            marginBottom: "15px",
            display: "flex",
            gap: "20px",
          }}
        >
          <Select
            defaultValue="Select Filter"
            style={{
              width: " 150px",
            }}
            onChange={this.handleChange}
            options={options}
          />
          <div style={{ cursor: "pointer", marginLeft: 10 }}>
            <Input
              value={this.state.inputValue}
              placeholder={
                this.state.SelectValue
                  ? this.state.SelectValue === "redeem_code"
                    ? "Enter Redeem Code"
                    : this.state.SelectValue === "serial_number"
                    ? "Enter Serial Number"
                    : "Enter Redeem Code"
                  : "Enter Redeem Code"
              }
              style={{ width: 300 }}
              onChange={this.handelSearchTextChange}
            />
          </div>
          <Button
            type="primary"
            onClick={() => this.getRedeemCodeData()}
            disabled={
              this.state.SelectValue && this.state.inputValue ? false : true
            }
          >
            Search
          </Button>
        </div>

        <div className="container-fluid">
          {this.state.isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : this.state.showTable ? (
            <div>
              <Table
                loading={this.state.isLoading}
                rowKey={(record) => record.id}
                columns={this.columns}
                size={"small"}
                scroll={{ x: 1260 }}
                dataSource={this.state.redeemData}
                pagination={false}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

export default connect(mapStateToProps)(withRouter(SearchRedeemCode));
