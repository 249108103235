/* eslint-disable */

import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
} from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllCountry } from "../../../Graphs/Master/Country/listCountry";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { UserOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { deleteCountry } from "../../../Graphs/Master/Country/deleteCountry";
import { changeStatus } from "../../../Graphs/Master/Country/changeStatus";
import { changeGccStatus } from "../../../Graphs/Master/Country/changeGccStatus";
import { editCountry } from "../../../Graphs/Master/Country/editCountry";
import { addCountry } from "../../../Graphs/Master/Country/addCountry";
import { searchCountry } from "../../../Graphs/Master/Country/searchCountry";
import { EditModal } from "./EditModal";
import { AddCountryModal } from "./AddCountryModal";
import TableWrap from "../../common/Table";
import { alertError } from "../../../utils/alert";

var moment = require("moment");

const BASE_IMAGE_URL =
  "https://s3.us-east-2.amazonaws.com/cerebrus-consulting/bloodman_user_media/";

class CountryRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedCountry = this.getLimitedCountry.bind(this);
  }

  async componentDidMount() {
    const { setRouteName } = this.props;

    let permission = JSON.parse(this.props.loginUserData.access_permission);
    this.setState({
      userPermission: {
        edit:
          (permission && permission["master"] && permission["master"].edit) ||
          false,
        delete:
          (permission && permission["master"] && permission["master"].delete) ||
          false,
      },
    });

    setRouteName("COUNTRY");
    this.setState({
      isLoading: true,
      countrySelectedData: null,
    });
    await this.listCountryData(0, 10);
  }

  modalRef;

  async getLimitedCountry(pagination) {
    const { searchText } = this.props;
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText != "") {
          this.listSearchCountryData(start, end);
        } else {
          this.listCountryData(start, end);
        }
      }
    );
  }

  listSearchCountryData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCountryData,
        setListCountryDataTotal,
        searchText,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let countryData = await searchCountry(authToken, searchText, start, end);

      if (countryData.status == 200) {
        //set user Data
        await setListCountryData(countryData.data);
        await setListCountryDataTotal(countryData.total);

        this.setState({
          isLoading: false,
          data: countryData.data,
        });
      } else if (countryData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw countryData && countryData.message
          ? countryData.message
          : "Error";
      }
    } catch (e) {
      alertError("COUNTRY", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listCountryData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCountryData,
        setListCountryDataTotal,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let countryData = await getAllCountry(authToken, start, end);

      if (countryData.status == 200) {
        //set user Data
        console.log("!!!!!!!countryData", countryData);
        await setListCountryData(countryData.data);
        await setListCountryDataTotal(countryData.total);

        this.setState({
          isLoading: false,
          data: countryData.data,
        });
      } else if (countryData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw countryData && countryData.message
          ? countryData.message
          : "Error";
      }
    } catch (e) {
      alertError("COUNTRY", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.add(2, "hours").format("Do MMM YYYY");

    return newDate;
  }

  showModal(item) {
    this.setState(
      {
        countrySelectedData: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  handleDetailsModalclose() {
    this.setState({
      countrySelectedData: null,
    });
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "name",
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                  <div>{item.name ? item.name : ""}</div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Country Code",
      key: "code",
      dataIndex: "code",
      render: (text, item, index) => {
        return <div>{item.code ? item.code : "-"}</div>;
      },
    },
    {
      title: "ISO Code",
      key: "isd_code",
      dataIndex: "isd_code",
      render: (text, item, index) => {
        return <div>{item.isd_code ? item.isd_code : "-"}</div>;
      },
    },
    {
      title: "Active Status",
      key: "isActive",
      render: (text, item, index) => {
        return (
          <div>
            {this.props.loginUserData.role == "Admin" ||
              this.state.userPermission.edit ? (
              item.is_active != null ? (
                <Switch
                  checked={item.is_active}
                  style={{
                    backgroundColor: item.is_active
                      ? this.props.primaryColor
                      : "#bababa",
                  }}
                  onChange={this.onChange.bind(this, item)}
                />
              ) : (
                "-"
              )
            ) : item.is_active ? (
              "Active"
            ) : (
              "In-active"
            )}
          </div>
        );
      },
    },
    {
      title: "Country Activation",
      key: "is_gcc",
      render: (text, item, index) => {
        return (
          <div>
            {item.is_gcc != null ? (
              <Switch
                disabled={true}
                checked={item.is_gcc}
                style={{
                  backgroundColor: item.is_gcc
                    ? this.props.primaryColor
                    : "#bababa",
                }}
                onChange={this.onGccChange.bind(this, item)}
              />
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 200,
      key: "action",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            {this.props.loginUserData.role == "Admin" ||
              this.state.userPermission.edit ? (
              <div mr="5px" style={{ float: "left" }}>
                <Tooltip placement="bottom" title="Edit">
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      console.log("isOpen value --- ", this.state.isOpen);
                      this.showModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}

            {this.props.loginUserData.role == "Admin" ||
              this.state.userPermission.delete ? (
              !item.is_gcc ? (
                <div
                  ml="15px"
                  mr="5px"
                  style={{ float: "left", marginLeft: "10px" }}
                >
                  <Tooltip placement="bottom" title="Delete">
                    <Popconfirm
                      onConfirm={this.deleteCountry.bind(this, item)}
                      title="Are you sure you want to delete country？"
                    >
                      <Button shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                </div>
              ) : null
            ) : null}
          </div>
        );
      },
    },
  ];

  async deleteCountry(item) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
        setListCountryData,
        setListCountryDataTotal,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let deleteData = await deleteCountry(
        authToken,
        item.id,
        startIndex,
        limitNumber,
        searchText
      );

      if (deleteData.status == 200) {
        notification["success"]({
          message: "Delete Country",
          description: "Successfully deleted",
        });
        await setListCountryData(deleteData.data);
        await setListCountryDataTotal(deleteData.total);
        //set user Data
        this.setState({
          isLoading: false,
          data: deleteData.data,
        });
      } else if (deleteData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw deleteData && deleteData.message ? deleteData.message : "Error";
      }
    } catch (e) {
      alertError("COUNTRY", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onChange(item, checked) {
    try {
      console.log("!!!!!!!!", checked);

      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeStatus(authToken, item.id, checked);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Change Country Status",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText != "") {
          this.listSearchCountryData(startIndex, limitNumber);
          return;
        }
        this.listCountryData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (err) {
      alertError("COUNTRY", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onGccChange(item, checked) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeGccStatus(authToken, item.id, checked);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Change Country Gcc Status",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText != "") {
          this.listSearchCountryData(startIndex, limitNumber);
          return;
        }
        this.listCountryData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (err) {
      alertError("COUNTRY", err);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  addSubmit = async (countryObject) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await addCountry(authToken, countryObject);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Add Country",
          description: "Successfully added",
        });
        if (searchText != null && searchText != "") {
          this.listSearchCountryData(startIndex, limitNumber);
          return;
        }
        this.listCountryData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      alertError("COUNTRY", e);
    }
  };

  editSubmit = async (countryObject, countryId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await editCountry(authToken, countryObject, countryId);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Edit Country",
          description: "Successfully edited",
        });
        if (searchText != null && searchText != "") {
          this.listSearchCountryData(startIndex, limitNumber);
          return;
        }
        this.listCountryData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      alertError("COUNTRY", e);
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedCountry(pagination)
    );
  }

  render() {
    const { isLoading } = this.state;
    const {
      listCountryData,
      listCountryDataTotal,
      searchLoader,
      accessPermission,
      loginUserData,
    } = this.props;

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ display: "inline-block", width: "100%" }}>
        <div style={{ float: "left", cursor: "pointer", width: "100%" }}>
          {loginUserData.role == "Admin" ||
            (accessPermission &&
              accessPermission["master"] &&
              accessPermission["master"].edit) ? (
            <AddCountryModal
              onSubmit={this.addSubmit}
              primaryColor={this.props.primaryColor}
            />
          ) : null}
        </div>
        <div
          style={{
            overflowX: "auto",
            float: "left",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <TableWrap
            data={listCountryData}
            columns={this.columns}
            isLoading={this.state.isLoading}
            total={listCountryDataTotal}
            pagination={this.state.pagination}
            handleTableChange={this.handleTableChange}
          />

          {this.state.countrySelectedData ? (
            <EditModal
              admin={false}
              refx={(e) => (this.modalRef = e)}
              onClose={this.handleDetailsModalclose.bind(this)}
              countrySelectedData={this.state.countrySelectedData}
              onSubmit={this.editSubmit}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listCountryData: state.master.listCountryData,
  listCountryDataTotal: state.master.listCountryDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
  loginUserData: state.auth.loginUserData,
  role: state.auth.loginUserData.role,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCountryData: (listCountryData) => {
      dispatch({
        type: "SET_LIST_COUNTRY_DATA",
        listCountryData: listCountryData,
      });
    },
    setListCountryDataTotal: (listCountryDataTotal) => {
      dispatch({
        type: "SET_LIST_COUNTRY_DATA_TOTAL",
        listCountryDataTotal: listCountryDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CountryRecordsTable));
