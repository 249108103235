import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, TimePicker, Select, Spin } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { WEBURL, color } from "../../constant/comman";
import * as la from "lodash";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.handleCloseTime = this.handleCloseTime.bind(this);
    this.handleDetailsModalclose = this.handleDetailsModalclose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      locationList: [],
      recordSelectedtoView: null,
      data: [],
      view: false,
      index: null,
      isLoading: true,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: false,
      inputVisible: false,
      inputValue: "",
      time: "",
      closeTime: "",
      closeTimeString: "",
      timeString: "",
      roleType: "1",
      errors: {
        uploadImage: "",
      },
      isIconUpdate: false,
    };
  }

  input;
  allColorType = [];
  selectedColorType = "";
  selctedColorKey = "";
  selectDisplayColorName = "";
  src = "";
  viewModalRef;
  isColorChanged = false;

  componentDidMount() {
    console.log("!!!!!!!!did mount in edit form");
    this.setState({
      isLoading: true,
    });
    la.map(color, (data, index) => {
      this.allColorType.push(
        <Option key={index} value={data.value}>
          <div style={{ display: "inline-block", width: "100%" }}>
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: data.value,
                float: "left",
                borderRadius: "5px",
              }}
            ></div>
            <span
              style={{
                float: "left",
                margin: "0px",
                padding: "0px",
                marginLeft: "10px",
                marginTop: "5px",
                lineHeight: "0px",
              }}
            >
              {data.name}
            </span>
          </div>
        </Option>
      );
    });

    this.setState({
      isLoading: false,
    });
  }

  showViewModal(params) {
    this.setState(
      {
        view: true,
      },
      () => {
        this.viewModalRef.show();
      }
    );
  }

  handleSelectColortype(value, option) {
    this.selectedColorType = value;
    this.selctedColorKey = option.key;
    this.isColorChanged = true;
  }

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  validateForm = (values) => {
    const errors = {};
    console.log("Validating form -- ", values);

    if (!(values.lower_limit > 0)) {
      errors.lower_limit = "Lower limit must be greater than 0";
    }

    if ((values.upper_limit <= values.lower_limit)) {
      errors.upper_limit = "Upper limit must be greater than lower limit";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  };

  handleSubmit = (values, action) => {
    const { errors, isIconUpdate } = this.state;
    let listErrors = { ...errors };

  
    console.log("Basic Value - ", values, this.props.data.id);

    this.props.onSubmit(values, this.props.data.id);
    this.props.handleCancel();

    this.setState({
      visible: false,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleTime(e, s) {
    this.setState({
      time: e,
      timeString: s,
    });
  }

  handleCloseTime(e, s) {
    this.setState({
      closeTime: e,
      closeTimeString: s,
    });
  }

  handleDetailsModalclose(item) {
    this.setState({
      view: false,
      lat: item.lat,
      long: item.lng,
    });
  }

  render() {
    const { isLoading } = this.state;
    console.log("!!!!!selectDisplayColorName", this.selectDisplayColorName);

    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                lower_limit: this.props.data.lower_limit,
                upper_limit: this.props.data.upper_limit,
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return isLoading ? (
                  <div style={{ marginLeft: "20px" }}>
                    <Spin
                      size="large"
                      style={{ width: "100%", margin: "0 auto" }}
                    />
                  </div>
                ) : (
                  <div>
                    <div>
                      <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          Lower Limit
                          <Input
                            id="lower_limit"
                            placeholder="Lower Limit"
                            type="number"
                            min={0}
                            value={values.lower_limit}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.lower_limit && touched.lower_limit ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.lower_limit}
                            </p>
                          ) : null}
                        </div>
                        <div className="formik-field-right">
                          Upper Limit
                          <Input
                            id="upper_limit"
                            placeholder="Upper Limit"
                            type="number"
                            min={0}
                            value={values.upper_limit}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.upper_limit && touched.upper_limit ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.upper_limit}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <Button
                        // type="primary"
                        style={{
                          marginTop: "19px",
                          background: "#5C6AC4",
                          color: "#fff",
                        }}
                        onClick={handleSubmit}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
