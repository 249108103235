import { Button, Switch, Popconfirm, Tooltip, notification, Spin } from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllCard } from "../../Graphs/Card/listCard";
import { getAllCompanyCard } from "../../Graphs/Card/listCompanyCard";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  DeleteFilled,
  CreditCardOutlined,
} from "@ant-design/icons";
import { deleteCard } from "../../Graphs/Card/deleteCard";
import { changeStatus } from "../../Graphs/Card/changeStatus";
import { changeFeatureStatus } from "../../Graphs/Card/changeFeatureStatus";
import {
  uploadRedeem,
  uploadSingleRedeem,
} from "../../Graphs/Card/uploadRedeem";
import { editCard } from "../../Graphs/Card/editCard";
import { searchCard } from "../../Graphs/Card/searchCard";
import { searchCompanyCard } from "../../Graphs/Card/searchCompanyCard";
import { EditModal } from "./EditModal";
import { UploadModal } from "./UploadModal";
import { ViewRedeemModal } from "./ViewRedeemModal";
import { WEBURL, color } from "../../constant/comman";
import { AddRedeemCodeModal } from "./AddRedeemCodeModal";
import { DeleteRedeemModal } from "./DeleteRedeemModal";
import BuyCardLimitModal, { CardLimitModal } from "../User/BuyCardLimitModal";
import TableWrap from "../common/Table";
import OTPModal from "../Login/OTPModal";
import { alertError } from "../../utils/alert";
import { sendOtpAPI } from "../../Graphs/Login/sendOTP";
import { verifyOTP } from "../../Graphs/Login/VerifyOTP";

var moment = require("moment");

const BASE_IMAGE_URL =
  "https://s3.us-east-2.amazonaws.com/cerebrus-consulting/bloodman_user_media/";

class CardRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      cardLimit: {},
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      uploadRedeemData: null,
      duplicateCode: [],
      download_date: null,
      addButtonClicked: false,
      isShowOTP: false,
      cardId: "",
      otpVerifyToken: "",
      isOpenRedeemModal: false,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedCards = this.getLimitedCards.bind(this);
    this.expireToken = this.expireToken.bind(this);
  }

  async componentDidMount() {
    this.setState({
      data: this.props.data,
      isLoading: false,
      cardSelectedData: null,
      cardLimit: null,
    });
    if (this.props.loginUserData.access_permission != undefined) {
      let permission = JSON.parse(this.props.loginUserData.access_permission);

      if (permission) {
        this.setState({
          userPermission: {
            edit:
              (permission && permission["card"] && permission["card"].edit) ||
              false,
            delete:
              (permission && permission["card"] && permission["card"].delete) ||
              false,
          },
        });
      }
    }
  }

  modalRef;
  uploadModalRef;
  transaRef;
  deleteRedeemRef;

  async getLimitedCards(pagination) {
    const { searchText } = this.props;
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText != "") {
          //get search text props from redux store
          this.listSearchCardData(start, end);
        } else {
          //call normal user Data
          this.listCardData(start, end);
        }
      }
    );
  }

  listSearchCardData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCardData,
        setListCardDataTotal,
        searchText,
        loginUserData,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let cardData;
      if (loginUserData && loginUserData.role === "Company") {
        cardData = await searchCompanyCard(
          authToken,
          searchText,
          start,
          end,
          loginUserData.id
        );
      } else {
        let city = loginUserData.role === "Sub_admin" ? loginUserData.city : "";
        cardData = await searchCard(authToken, searchText, start, end, city);
      }

      if (cardData.status == 200) {
        //set user Data
        await setListCardData(cardData.data);
        await setListCardDataTotal(cardData.total);

        this.setState({
          isLoading: false,
          data: cardData.data,
        });
      } else if (cardData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw cardData && cardData.message ? cardData.message : "Error";
      }
    } catch (e) {
      alertError("CARD", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listCardData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCardData,
        setListCardDataTotal,
        loginUserData,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let cardData;
      if (loginUserData && loginUserData.role === "Company") {
        cardData = await getAllCompanyCard(
          authToken,
          start,
          end,
          loginUserData.id
        );
      } else {
        cardData = await getAllCard(authToken, start, end, loginUserData.city);
      }

      if (cardData.status == 200) {
        //set user Data
        await setListCardData(cardData.data);
        await setListCardDataTotal(cardData.total);

        this.setState({
          isLoading: false,
          data: cardData.data,
        });
      } else if (cardData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw cardData && cardData.message ? cardData.message : "Error";
      }
    } catch (e) {
      alertError("CARD", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(date),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.add(2, "hours").format("Do MMM YYYY");
    return newDate;
  }

  addCardLimit = (item) => {
    this.setState({
      cardLimit: item,
    });
  };

  showUploadModal = (item) => {
    this.setState(
      {
        cardUploadSelectedData: item,
        // isOpen: true
      },
      () => {
        this.uploadModalRef.show();
      }
    );
  };

  handleDetailsUploadModalclose() {
    const { startIndex, limitNumber } = this.state;
    this.setState({
      cardUploadSelectedData: null,
      cardLimit: null,
      duplicateCode: [],
      download_date: null,
    });
    this.listCardData(startIndex, limitNumber);
  }

  showRedeemCodeListModal = (item) => {
    this.setState({
      uploadRedeemData: item,
    });
  };

  handleDeleteRedeemCodeModalclose() {
    this.setState({
      uploadRedeemData: null,
      isOpenRedeemModal: false,
      isShowOTP: false,
    });
  }

  expireToken() {
    this.setState({
      otpVerifyToken: "",
    });
  }

  showDeleteRedeemCodeListModal = (item) => {
    this.setState(
      {
        deleteRedeemCodeData: item,
      },
      () => {
        this.deleteRedeemRef.show();
      }
    );
  };

  handleDeleteRedeemCodeDeleteModalclose() {
    this.setState({
      deleteRedeemCodeData: null,
    });
  }

  handleSingleRedeemCodeModalclose() {
    this.setState({
      uploadSingleRedeemCode: null,
    });
  }

  showSingleRedeemCodeModal(item) {
    this.setState(
      {
        uploadSingleRedeemCode: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  showModal(item) {
    this.setState({
      cardSelectedData: item,
    });
  }

  handleDetailsModalclose() {
    this.setState({
      cardSelectedData: null,
      cardLimit: null,
    });
  }

  sendOTP = async (item) => {
    try {
      const { authToken } = this.props;
      this.setState({
        isLoading: true,
      });

      const otp = await sendOtpAPI(authToken);

      if (otp.status == 200) {
        notification["success"]({
          message: "Card",
          description: otp.message,
        });
        this.setState({
          uploadRedeemData: item,
          isShowOTP: true,
          cardId: item.id,
        });
      } else {
        throw otp && otp.message ? otp.message : "Error";
      }
    } catch (e) {
      alertError("CARD", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  onSubmitOTP = async (values) => {
    try {
      const { authToken } = this.props;
      const { uploadRedeemData } = this.state;

      const body = {
        card_id: uploadRedeemData.id,
        otp: parseInt(values),
      };
      this.setState({
        isLoading: true,
      });

      let verifyData = await verifyOTP(body, authToken);

      if (verifyData.status == 200) {
        notification["success"]({
          message: "Card",
          description: verifyData.message,
        });
        this.setState({
          otpVerifyToken: verifyData?.token,
          isOpenRedeemModal: true,
          isShowOTP: false,
        });
      } else {
        throw verifyData && verifyData.message
          ? verifyData.message
          : "Error in verification";
      }
    } catch (err) {
      alertError("CARD", err);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "name",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                  <div>{item.name ? item.name : "-"}</div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Arabic Name",
      key: "arabic name",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                  <div>{item.arabic_name ? item.arabic_name : "-"}</div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Company Name",
      key: "company_name",
      dataIndex: "company_name",
      render: (text, item, index) => {
        return (
          <div>
            {item.company ? (item.company.name ? item.company.name : "-") : "-"}
          </div>
        );
      },
    },
    {
      title: "Company Amount",
      key: "company_amount",
      dataIndex: "company_amount",
      render: (text, item, index) => {
        return (
          <div>
            {item.company_amount && item.company_amount > 0 ? (
              <Tooltip placement="bottom" title="Company Amount">
                {item.company_amount ? item.company_amount : "-"}
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Card List",
      key: "type_name",
      dataIndex: "type_name",
      render: (text, item, index) => {
        return (
          <div>{item.type ? (item.type.name ? item.type.name : "-") : "-"}</div>
        );
      },
    },
    {
      title: "Category",
      key: "category_name",
      dataIndex: "category_name",
      render: (text, item, index) => {
        return (
          <div>
            {item.category
              ? item.category.name
                ? item.category.name
                : "-"
              : "-"}
          </div>
        );
      },
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      render: (text, item, index) => {
        return <div>{item.description ? item.description : "-"}</div>;
      },
    },
    {
      title: "Card Limit",
      key: "card_limit",
      width: "100px",
      render: (text, item, index) => {
        return <div>{item.max_buy_limit ? item.max_buy_limit : "-"}</div>;
      },
    },
    {
      title: "Color",
      key: "color",
      dataIndex: "color",
      width: "150px",
      render: (text, item, index) => {
        return (
          <div>
            {item.color ? (
              <div style={{ display: "inline-block", width: "100%" }}>
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: color[item.color].value,
                    float: "left",
                    borderRadius: "5px",
                  }}
                ></div>
                <span
                  style={{
                    float: "left",
                    margin: "0px",
                    padding: "0px",
                    marginLeft: "10px",
                    marginTop: "5px",
                    lineHeight: "0px",
                  }}
                >
                  {color[item.color].name}
                </span>
              </div>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Cost Price (LYD)",
      key: "cost_amount",
      render: (text, item, index) => {
        return <div>{item.cost_amount ? String(item.cost_amount) : "-"}</div>;
      },
    },
    {
      title: "Card Price (LYD)",
      key: "amount",
      render: (text, item, index) => {
        return <div>{item.amount ? String(item.amount.toFixed(2)) : "-"}</div>;
      },
    },
    {
      title: "Store Price (LYD)",
      key: "store_sell_price",
      render: (text, item, index) => {
        return (
          <div>
            {item.store_sell_price
              ? String(item.store_sell_price.toFixed(2))
              : "-"}
          </div>
        );
      },
    },
    {
      title: "Discount Amount (LYD)",
      key: "discount_amount",
      render: (text, item, index) => {
        return (
          <div>{item.discount_amount ? String(item.discount_amount) : "0"}</div>
        );
      },
    },

    {
      title: "Available Cards",
      key: "available_count",
      dataIndex: "available_count",
      render: (text, item, index) => {
        return <div>{item.available_count ? item.available_count : "-"}</div>;
      },
    },
    {
      title: "Sold Cards",
      key: "sold_count",
      dataIndex: "sold_count",
      render: (text, item, index) => {
        return <div>{item.sold_count ? item.sold_count : "-"}</div>;
      },
    },
    {
      title: "Active Status",
      key: "isActive",
      render: (text, item, index) => {
        return (
          <div>
           {!this.props.loginUserData.city ? (
              item.is_active != null ? (
                <Switch
                  checked={item.is_active}
                  style={{
                    backgroundColor: item.is_active
                      ? this.props.primaryColor
                      : "#bababa",
                  }}
                  onChange={this.onChange.bind(this, item)}
                />
              ) : (
                "-"
              )
            ) : null}
          </div>
        );
      },
    },
    {
      title: "Feature Status",
      key: "isFeature",
      render: (text, item, index) => {
        return (
          <div>
            {!this.props.loginUserData.city ?(
            
              item.is_feature != null ? (
                <Switch
                  checked={item.is_feature}
                  style={{
                    backgroundColor: item.is_feature
                      ? this.props.primaryColor
                      : "#bababa",
                  }}
                  onChange={this.onFeatureChange.bind(this, item)}
                />
              ) : (
                "-"
              )
            ) : null}
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 150,
      key: "action",
      render: (text, item, index) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "10px",
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <div>
              <Tooltip placement="bottom" title="Add Redeem Code">
                <Button
                  shape="circle"
                  icon={<PlusCircleOutlined />}
                  onClick={() => {
                    this.showSingleRedeemCodeModal(item);
                  }}
                />
              </Tooltip>
            </div>

            {
              (this.state.userPermission && this.state.userPermission.edit) &&
            !this.props.loginUserData.city  ? (
              <div>
                <Tooltip placement="bottom" title="Edit">
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      this.showModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}

            
            
              <div>
                <Tooltip placement="bottom" title="Upload">
                  <Button
                    shape="circle"
                    icon={<UploadOutlined />}
                    onClick={() => {
                      this.showUploadModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            

            {!this.props.loginUserData.city &&
            this.state.userPermission &&
              this.state.userPermission.delete  ? (
              <React.Fragment>
                <div>
                  <Tooltip placement="bottom" title="Delete">
                    <Popconfirm
                      onConfirm={this.deleteCard.bind(this, item)}
                      title="Are you sure you want to delete card?"
                    >
                      <Button shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                </div>
              </React.Fragment>
            ) : null}

            {!this.props.loginUserData.city ? (
              <div>
                <Tooltip placement="bottom" title="Card limit">
                  <Button
                    shape="circle"
                    icon={<CreditCardOutlined />}
                    onClick={() => this.addCardLimit(item)}
                  />
                </Tooltip>
              </div>
            ) : null}

            <div>
              <Tooltip placement="bottom" title="View">
                <Button
                  shape="circle"
                  icon={<EyeOutlined />}
                  onClick={() => {
                    if (this.state.cardId == item.id) {
                      this.state.otpVerifyToken == ""
                        ? this.sendOTP(item)
                        : this.setState({
                            uploadRedeemData: item,
                            isOpenRedeemModal: true,
                          });
                    } else {
                      this.sendOTP(item);
                    }
                  }}
                />
              </Tooltip>
            </div>

            {this.props.loginUserData.role == "Admin" ||
            this.props.loginUserData.role == "Sub_admin" ||
            this.props.loginUserData.role == "Company" ||
            (this.state.userPermission && this.state.userPermission.delete) ? (
              <div>
                <Tooltip placement="bottom" title="Delete Redeem Code">
                  <Button
                    shape="circle"
                    icon={<DeleteFilled />}
                    onClick={() => {
                      this.showDeleteRedeemCodeListModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}
          </div>
        );
      },
    },
  ].filter(
    (item) =>
      !(
        this.props.loginUserData.city && 
        (item.title === "Active Status" || item.title === "Feature Status")
      )
  );
  
  

  async deleteCard(item) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
        setListCardData,
        setListCardDataTotal,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let deleteData = await deleteCard(
        authToken,
        item.id,
        startIndex,
        limitNumber,
        searchText
      );

      if (deleteData.status == 200) {
        notification["success"]({
          message: "Delete Card",
          description: "Successfully deleted",
        });
        await setListCardData(deleteData.data);
        await setListCardDataTotal(deleteData.total);

        //set card Data
        this.setState({
          isLoading: false,
          data: deleteData.data,
        });
      } else if (deleteData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw deleteData && deleteData.message ? deleteData.message : "Error";
      }
    } catch (e) {
      alertError("Card", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onChange(item, checked) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeStatus(authToken, item.id, checked);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Change Card Status",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText != "") {
          this.listSearchCardData(startIndex, limitNumber);
          return;
        }
        this.listCardData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (err) {
      alertError("Card", err);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onFeatureChange(item, checked) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeFeatureStatus(authToken, item.id, checked);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Feature Card",
          description: "Successfully featured",
        });
        if (searchText != null && searchText != "") {
          this.listSearchCardData(startIndex, limitNumber);
          return;
        }
        this.listCardData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (err) {
      alertError("Card", err);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  editSubmit = async (cardObject, cardId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await editCard(authToken, cardObject, cardId);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Edit Card Status",
          description: "Successfully edited",
        });
        if (searchText != null && searchText != "") {
          this.listSearchCardData(startIndex, limitNumber);
          return;
        }
        this.listCardData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      alertError("Card", e);
    }
  };

  handleTableChange(pagination, filters) {
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedCards(pagination)
    );
  }

  uploadSubmit = async (cardObject, cardId) => {
  
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        duplicateCode: [],
        addButtonClicked: true,
      });
      const { loginUserData } = this.props;
      let city = loginUserData.role == "Sub_admin" ? loginUserData.city : "";
      let statusData = await uploadRedeem(authToken, cardObject, cardId, city);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Upload Redeem",
          description: statusData.message,
        });
        if (statusData?.data?.length > 0 && statusData?.data?.[0] != null) {
          this.setState({
            duplicateCode: statusData.data,
            download_date: statusData.date,
            addButtonClicked: false,
          });
        } else {
          if (searchText != null && searchText != "") {
            this.listSearchCardData(startIndex, limitNumber);
            return;
          }
          this.listCardData(startIndex, limitNumber);
          this.setState({
            addButtonClicked: false,
          });
        }
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
        this.setState({
          addButtonClicked: false,
        });
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      this.setState({
        addButtonClicked: false,
      });
      alertError("Card", e);
    }
  };

  uploadSingleSubmit = async (value) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      const { role } = this.props.loginUserData;
      const { loginUserData } = this.props;
      let city = loginUserData.role == "Sub_admin" ? loginUserData.city : "";
      let addedBy =
        role === "Sub_admin"
          ? "sub_admin"
          : role === "Company"
          ? "company"
          : "admin";
      let redeemObject = {
        ...value,
        added_by: addedBy,
      };
      if (city) {
        redeemObject.city = city;
      }
      this.setState({
        isLoading: true,
      });
      let statusData = await uploadSingleRedeem(authToken, redeemObject);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Add Redeem",
          description: "Successfully Added",
        });
        if (searchText != null && searchText != "") {
          this.listSearchCardData(startIndex, limitNumber);
          return;
        }
        this.listCardData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      alertError("Card", e);
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const {
      isLoading,
      uploadRedeemData,
      uploadSingleRedeemCode,
      deleteRedeemCodeData,
      isOpenRedeemModal,
      isShowOTP,
    } = this.state;
    const { listCardData, listCardDataTotal, searchLoader, authToken } =
      this.props;
    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <TableWrap
          data={listCardData}
          columns={this.columns}
          isLoading={this.state.isLoading}
          total={listCardDataTotal}
          pagination={this.state.pagination}
          handleTableChange={this.handleTableChange}
          scroll={{ x: 2000 }}
        />
        {this.state.cardSelectedData ? (
          <EditModal
            isEdit={true}
            admin={false}
            onClose={this.handleDetailsModalclose.bind(this)}
            cardSelectedData={this.state.cardSelectedData}
            onSubmit={this.editSubmit}
            authToken={authToken}
            loginUserData={this.props.loginUserData}
          />
        ) : null}
        {this.state.cardUploadSelectedData ? (
          <UploadModal
            admin={false}
            refx={(e) => (this.uploadModalRef = e)}
            onClose={this.handleDetailsUploadModalclose.bind(this)}
            cardUploadSelectedData={this.state.cardUploadSelectedData}
            onSubmit={this.uploadSubmit}
            authToken={authToken}
            duplicateCode={this.state.duplicateCode}
            date={this.state.download_date}
            addButtonLoading={this.state.addButtonClicked}
          />
        ) : null}
        {this.state.cardLimit ? (
          <CardLimitModal
            visible={this.state.cardLimit}
            handleCancel={this.handleDetailsModalclose.bind(this)}
            handleOk={this.handleDetailsModalclose.bind(this)}
            onClose={this.handleDetailsModalclose.bind(this)}
            isLoading={this.state.isLoading}
            data={this.state.cardLimit}
            authToken={this.props.authToken}
            reload={this.getLimitedCards}
          />
        ) : null}

        {isShowOTP ? (
          <OTPModal
            admin={false}
            onClose={this.handleDeleteRedeemCodeModalclose.bind(this)}
            onSubmitOTP={this.onSubmitOTP}
            uploadRedeemData={uploadRedeemData}
            authToken={authToken}
          />
        ) : null}

        {isOpenRedeemModal ? (
          <ViewRedeemModal
            admin={false}
            onClose={this.handleDeleteRedeemCodeModalclose.bind(this)}
            uploadRedeemData={uploadRedeemData}
            isVisible={isOpenRedeemModal}
            authToken={authToken}
            otpToken={this.state.otpVerifyToken}
            expireToken={this.expireToken}
            loginUserData={this.props.loginUserData}
          />
        ) : null}

        {deleteRedeemCodeData ? (
          <DeleteRedeemModal
            admin={false}
            refx={(e) => (this.deleteRedeemRef = e)}
            onClose={this.handleDeleteRedeemCodeDeleteModalclose.bind(this)}
            deleteRedeemCodeData={deleteRedeemCodeData}
            authToken={authToken}
            loginUserData={this.props.loginUserData}
          />
        ) : null}

        {uploadSingleRedeemCode ? (
          <AddRedeemCodeModal
            admin={false}
            refx={(e) => (this.modalRef = e)}
            onClose={this.handleSingleRedeemCodeModalclose.bind(this)}
            onSubmit={this.uploadSingleSubmit}
            data={uploadSingleRedeemCode}
            authToken={authToken}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listCardData: state.card.listCardData,
  listCardDataTotal: state.card.listCardDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCardData: (listCardData) => {
      dispatch({
        type: "SET_LIST_CARD_DATA",
        listCardData: listCardData,
      });
    },
    setListCardDataTotal: (listCardDataTotal) => {
      dispatch({
        type: "SET_LIST_CARD_DATA_TOTAL",
        listCardDataTotal: listCardDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CardRecordsTable));
