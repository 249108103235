import * as React from "react";
import { Modal, Spin, notification } from "antd";
import { getAllCoin } from "../../Graphs/Master/Coin/listCoin";
import { AddCompanyCoinForm } from "./AddCompanyCoinForm";
import { createCompanyPayTrn } from "../../Graphs/CompanyPayTrn/companyPayTrn";
import { getCompanyWalletBalance } from "../../Graphs/Company/listCompany";
import { alertError } from "../../utils/alert";

export class AddCompanyCoinModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
      coinsList: [],
      walletDataList: null,
    };
  }

  async componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }

    await this.getCoinsList();
    await this.getCompanyWalletBalance();
  }

  addSubmit = async (value) => {
    try {
      this.setState({
        isLoading: true,
      });

      let createObject = value;
      createObject.company_id = this.props.companyCoinPayData.id;
      console.log("@@@@@@@@@@@@@@@@@@", createObject);

      let coinData = await createCompanyPayTrn(
        this.props.authToken,
        createObject
      );

      if (coinData.status == 200) {
        this.setState({
          isLoading: true,
        });

        notification["success"]({
          message: "Coin",
          description: "Successfully Paid",
        });
        return 1;
      } else {
        throw "Error";
      }
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      alertError('Coin',error);
      return -1;
    }
  };

  getCoinsList = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      let coinData = await getAllCoin(this.props.authToken, 0, 100000);
      console.log("!!!!!!!!!!!!!TEST coinData", this.props.authToken, coinData);

      this.setState({
        coinsList: coinData.data,
      });
    } catch (error) {
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  getCompanyWalletBalance = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      let walletData = await getCompanyWalletBalance(
        this.props.authToken,
        this.props.companyCoinPayData.id
      );
      if (walletData.status == 200) {
        this.setState({
          walletDataList: walletData.data,
        });
      }
    } catch (error) {
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show() {
    this.setState({
      visible: true,
    });
  }

  hide() {
    this.setState({
      visible: false,
    });
    this.props.onClose();
  }

  render() {
    return (
      // <StyleBox>
      <Modal
        width="600px"
        closable={this.state.closable}
        open={this.state.visible}
        title="Pay Company"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        {this.state.isLoading ? (
          <div style={{ justifyContent: "center" }}>
            <Spin />
          </div>
        ) : (
          <AddCompanyCoinForm
            walletDataList={this.state.walletDataList}
            coinsData={this.state.coinsList}
            data={this.props.companyCoinPayData}
            onSubmit={this.addSubmit}
            handleCancel={this.handleCancel}
          />
        )}
      </Modal>
    );
  }
}
