import { callApiToServer } from '../callApi';

export async function searchEmployee(
  authtoken,
  searchText,
  skipNumber,
  limitNumber,
  city,
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    };

  

    let body = {
      search_text: searchText
  };
  
  if (city) {
      body.city = city;
  }
  
  body = JSON.stringify(body);
    let endUrl = "v1/auth/filter-employee/" + skipNumber + "/" + limitNumber;
  
    let responseData = await callApiToServer(body, header, "POST", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}

export async function findByEmail(
  authtoken,
  searchText
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    };

    let body = JSON.stringify({
        email: searchText,
    });
  
    let endUrl = "v1/auth/find-admin-by-email/";
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callApiToServer(body, header, "POST", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}
