import {  putApiToServer } from '../callApi';

export async function updatePassword(
    authtoken,
    id,
    passwordObject
) {
    try {
        let header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "authorization": "Berear " + authtoken
        };

        let body = JSON.stringify(passwordObject);

        let endUrl = `v1/auth/sales/update-password/${id}`;

        let responseData = await putApiToServer(body, header, "PUT", endUrl);

        return responseData;

    } catch (err) {
        throw err;
    }
}
