import React, { Fragment, Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import CountUp from 'react-countup';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Users, List, Gift, Settings, CreditCard, Flag, Delete, BookOpen } from 'react-feather';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { notification, Button, Input, Select, Switch, Spin, Tooltip } from "antd";
import { Link } from 'react-router-dom';
import { editCompanyFormQuestion } from "../../Graphs/Company/editCompanyFormQuestion";
import { alertError } from '../../utils/alert';

const { TextArea } = Input;
const Option = Select.Option;

class Question extends Component {
    constructor(props){
        super(props);
        this.state = {
            questionData : [],
            isLoading : false,
            questionText : "",
            is_mandatory : false,
            type : "text",
            optionData : [],
            optionName : ""
        };
    }

    allquestionType = [
        <Option key={"text"} value={"text"}>
            Text
        </Option>,
        <Option key={"dropdown"} value={"dropdown"}>
            Dropdown
        </Option>,
        <Option key={"radio"} value={"radio"}>
            Radio
        </Option>
    ];

    async componentDidMount() {
        try{
            const { authToken, history, setLoginFlag, setUserData, setUserToken, setRouteName, loginUserData } = this.props;
            this.setState({
                isLoading : true
            });

            await setRouteName("QUESTION");
            this.setState({
                questionData : loginUserData.form_question ? JSON.parse(loginUserData.form_question) : [],
            });
        }catch(e){
            alertError("BALANCE",e);
        } finally {
            this.setState({
                isLoading : false
            });
        }
    }

    onRemoveQuestions = (data, index) => {
        const { questionData } = this.state;
        let newQuestionData  = questionData.splice(index, 1);
        this.setState({
            questionData : newQuestionData
        })
    }

    addQuestion = () => {
        const { questionData } = this.state;
        const { questionText, is_mandatory, type, optionData } = this.state;
        let dummyQuestionData = questionData;
    
        if(!questionText) {
            alertError("Questions","Please add question Text");
            return;
        }

        if(type !== "text" && !(optionData.length))  {
            alertError("Questions","Please add option");
            return;
        }

        dummyQuestionData.push({
            type : type,
            is_mandatory : is_mandatory,
            question_text : questionText,
            data : type !== "text" ? optionData : []
        })

        this.setState({
            questionData : dummyQuestionData,
            questionText : "",
            is_mandatory : false,
            type : "text",
            optionData : []
        })
    }

    handleTypeChange = (value) => {
        this.setState({
            type : value
        })
    }

    onChangeQuestionText = (event) => {
        this.setState({
            questionText : event.target.value
        })
    }

    onAddOptions = (newValue) => {
        const { optionData } = this.state;
        if(!newValue) {
            return;
        }
        
        let newOptionData = optionData;
        newOptionData.push(newValue);
        this.setState({
            optionData : newOptionData,
            optionName : ""
        })
    }

    onRemoveOptions = (deleteValue) => {
        const { optionData } = this.state;
        let newOptionData = optionData.filter((data, index) => {
            return data !== deleteValue;
        });
        this.setState({
            optionData : newOptionData
        })
    }

    onChangeOptionsName = (value) => {
        this.setState({
            optionName : value
        })
    }

    onEnterKeyPress = (e) => {
        const { optionName } = this.state;
        if(optionName) {
            this.onAddOptions(optionName);
        }
    }

    submitQuestion = async () => {
        try{
            const { questionData } = this.state;
            const { loginUserData, authToken, setUserData } = this.props;
            this.setState({
                isLoading : true
            })

            console.log("!!!!!!!!!!TEST questionData", questionData);
            let submitData = await editCompanyFormQuestion(authToken, questionData, loginUserData.id);
            await setUserData(submitData.data);

            this.setState({
                questionData : JSON.parse(submitData.data.form_question)
            });
        }catch(e) {
            console.log("!!!!!error", e);
        } finally {
            this.setState({
                isLoading : false
            })
        }
    }

    render() {
        const  { questionData, isLoading } = this.state;
        const { loginUserData } = this.props;
       
        return (
            <Fragment>
                <Breadcrumb parent="Questions" title="Questions" icon={<BookOpen />} url="/company-question"/>
                {
                    isLoading ? (
                        <div style={{ marginLeft: "20px" }}>
                            <Spin
                                size="large"
                                style={{ marginLeft: "480px", marginTop: "130px" }}
                            />
                        </div> 
                    ) : (
                        <div className="container-fluid">
                            {
                                questionData && questionData.length === 0 ? (
                                    <div>
                                        <Fragment>
                                            <h5 style={{ marginTop: "15px",  marginLeft: "30%" }}>
                                                No Questions as of now
                                            </h5>
                                        </Fragment>
                                    </div>
                                ) : null
                            }
                            {
                                questionData && questionData.length > 0 ? (
                                    <div style={{ marginTop : "15px", marginBottom : "15px"}}>
                                        {
                                            questionData.map((data, index) => {
                                                return (
                                                    <div style={{ marginBottom : "15px" }}>
                                                        <div style={{ marginTop : "5px", marginBottom : "10px", fontWeight : "bold", fontSize : "18px", width : "100%", display : "inline-block" }}>
                                                            <span style={{ float : "left" }}>
                                                                {`Question ${index + 1} :-`}
                                                            </span>
                                                            <div onClick={() => this.onRemoveQuestions(data, index)} style={{ cursor : "pointer", float : "left", marginLeft : "5px" }}>
                                                                <Delete />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom : "5px", float : "left" }}>
                                                            <span style={{ width : "100%", display : "inline-block", fontWeight : "bold" }}>
                                                                Quesion Type:-
                                                            </span>
                                                            <span>
                                                                {data.type}
                                                            </span>
                                                        </div>
                                                        <div style={{ marginBottom : "5px", float : "left", marginLeft : "15px" }}>
                                                            <span style={{ width : "100%", display : "inline-block", fontWeight : "bold" }}>
                                                                Quesion Text:-
                                                            </span>
                                                            <Tooltip placement="bottom" title={data.question_text}>
                                                                <div style={{ textOverflow : "ellipsis", whiteSpace : "nowrap", overflow : "hidden", width : "200px" }}>
                                                                    {data.question_text}
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                        <div style={{ marginBottom : "5px", float : "left", marginLeft : "15px" }}>
                                                            <span style={{ width : "100%", display : "inline-block", fontWeight : "bold" }}>
                                                                Mandatory:-
                                                            </span>
                                                            <span>
                                                                {data.is_mandatory ? "Mandatory ": "Not Mandatory"}
                                                            </span>
                                                        </div>
                                                        {
                                                            data.data && data.data.length > 0 ? (
                                                                <div style={{ marginBottom : "5px", float : "left", marginLeft : "15px" }}>
                                                                    <span style={{ width : "100%", display : "inline-block", fontWeight : "bold" }}>
                                                                        Available Options:-
                                                                    </span>
                                                                    {
                                                                        data.data.map((subData, index) => {
                                                                            return (
                                                                                <div style={{ width : "auto", float : "left", marginBottom : "3px", display : "inline-block", marginLeft : "5px" }}>
                                                                                    <span style={{ float : "left" }}>
                                                                                        {
                                                                                            (data.data.length - 1) === index ? subData : `${subData}, `
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            ) : null
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                ) : null
                            }

                            <div style={{ marginTop : "15px" }}>
                                <div className="formik-field_wrap question_form_wrap" >
                                    <div className="formik-field-left">
                                        Question Type
                                        <Select 
                                            style={{ width : "100%" }} 
                                            value={this.state.type} 
                                            onChange={(value) => this.handleTypeChange(value)} 
                                            placeholder="Select Type"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.allquestionType}
                                        </Select>
                                    </div>
                                    <div className="formik-field-left">
                                        Question Text
                                        <Input
                                            id="question_text"
                                            placeholder="Question"
                                            value={this.state.questionText} 
                                            onChange={(value) => this.onChangeQuestionText(value)}
                                            style={{ width : "100%" }}
                                            // rows={5}
                                        />
                                    </div>
                                    <div className="formik-field-left">
                                        <span style={{ display : "inline-block", width : "100%" }}>
                                            Option Text
                                        </span>
                                        <Input
                                            id="optionName"
                                            placeholder="Option"
                                            value={this.state.optionName}
                                            onChange={(event) => this.onChangeOptionsName(event.target.value)}
                                            onPressEnter={(e) => this.onEnterKeyPress(e)}
                                        />
                                    </div>
                                    <div className="formik-field-left">
                                        <span style={{ display : "inline-block", width : "100%" }}>
                                            Mandatory
                                        </span>
                                        <Switch 
                                            checked={this.state.is_mandatory}
                                            onChange={(checked) => {
                                                this.setState({
                                                    is_mandatory : checked
                                                })
                                            }}
                                        />
                                    </div>
                                </div>    

                                <div className="formik-field_wrap">
                                    {
                                        this.state.optionData && this.state.optionData.length > 0 ? (
                                            <div style={{ marginTop : "15px" }}>
                                                <div style={{ marginTop : "5px" }}>
                                                    <span style={{ fontWeight : "bold" }}>
                                                        Added Options:-
                                                    </span>
                                                    <div style={{ marginTop : "5px" }}>
                                                        {
                                                            this.state.optionData && this.state.optionData.length > 0 ? (
                                                                <div style={{ width : "100%", display : "inline-block" }}>
                                                                    {
                                                                        this.state.optionData.map((data, index) => {
                                                                            return (
                                                                                <div style={{ width : "auto", float : "left", marginBottom : "3px", display : "inline-block", marginLeft : "5px" }}>
                                                                                    <span style={{ float : "left" }}>
                                                                                        {data}
                                                                                    </span>
                                                                                    <div onClick={() => this.onRemoveOptions(data)} style={{ cursor : "pointer", float : "left", marginLeft : "5px" }}>
                                                                                        <Delete />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            ) : null
                                                        }

                                                        {/* <div style={{ marginTop : "10px" }}>
                                                            <Input
                                                                id="optionName"
                                                                placeholder="Option Name"
                                                                value={this.state.optionName}
                                                                onChange={(event) => this.onChangeOptionsName(event.target.value)}
                                                                onPressEnter={(e) => this.onEnterKeyPress(e)}
                                                            />
                                                        </div> */}

                                                        {/* <div style={{ marginTop : "5px" }}>
                                                            <Button
                                                                style={{ marginTop: "19px", background: "#5C6AC4", color: "#fff"}}
                                                                onClick={() => this.onAddOptions(this.state.optionName)} 
                                                            >
                                                                Add Options
                                                            </Button>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                    } 
                                </div>
                            </div>

                            <div style={{ marginTop : "5px" }}>
                                <Button
                                    style={{ marginTop: "19px", background: "#5C6AC4", color: "#fff"}}
                                    onClick={() => this.onAddOptions(this.state.optionName)} 
                                >
                                    Add Options
                                </Button>
                            </div>
                        
                            <div style={{ width : "100%" }}>
                                <Button 
                                    style={{ marginTop: "20px", background: "#5C6AC4", color: "#fff"}}
                                    onClick={() => this.addQuestion()}
                                >
                                    Add Questions
                                </Button>
                            </div>

                            <div style={{ width : "100%" }}>
                                <Button 
                                    type="primary"
                                    style={{ marginTop : "20px", float : "right" }}
                                    onClick={() => this.submitQuestion()}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div> 
                    )
                }
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    authToken: state.auth.authToken,
    loginUserData: state.auth.loginUserData
});
  
const mapDispatchToProps = (dispatch) => {
    return {
        setLoginFlag: (flag) => {
            dispatch({
                type: 'SET_LOGIN_FLAG',
                flag: flag
            });
        },
        setUserData: (userData) => {
            dispatch({
                type: 'SET_USER_DATA',
                userData: userData
            });
        },
        setUserToken: (authToken) => {
            dispatch({
                type: 'SET_USER_AUTHTOKEN',
                authToken: authToken
            });
        },
        setRouteName: (routeName) => {
            dispatch({
                type : "SET_ROUTE_NAME",
                routeName : routeName
            })
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Question));