import { ENDPOINT } from "../../network/ENDPOINT";
import { putApiToServer } from "../callApi";

export async function updateFreezerUserAPI(authtoken, id,city) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let payload = {};
    if (city) {
      payload.city = city; 
    }

    let body = JSON.stringify(payload);

    let endUrl = `${ENDPOINT.update_freeze_user}/${id}`;

    let responseData = await putApiToServer(body, header, "PUT", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
