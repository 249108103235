import { ENDPOINT } from '../../network/ENDPOINT';
import { callApiToServer } from '../callApi';



export async function walletActivate(
    body,
    authtoken  
) {    try {
        let header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "authorization": "Bearer " + authtoken
        };

        let endUrl = `${ENDPOINT.wallet_activate}`;
       let payload = JSON.stringify(body);
        let responseData = await callApiToServer(payload,header, "POST", endUrl);

        return responseData;

    } catch (err) {
        throw err;
    }
}

