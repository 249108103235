import React, { Fragment, Component } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Select, Spin } from "antd";
import BalanceRequestTable from './BalanceRequestTable'
import { getBalanceRequestList } from '../../Graphs/balanceRequest/balanceRequestList';
import Title from '../User/Title';
import { DollarSign } from 'react-feather';


const options = [
    {
        value: 'All',
        label: 'All',
    },
    {
        value: 'approved',
        label: 'Approve',
    },
    {
        value: 'un_approved',
        label: 'Pending',
    },
    {
        value: 'not_instant_balance',
        label: 'Balance request',
    },
    {
        value: 'instant_balance',
        label: 'Instant balance',
    },
]

const distributorFilteroptions = [
    {
        value: 'All',
        label: 'All',
    },
    {
        value: 'approved',
        label: 'Approved',
    },
    {
        value: 'un_approved',
        label: 'Unapproved',
    },
]

class BalanceRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            balanceRequestData: [],
            totalBalanceReq: 0
        }
        this.updateRequestData = this.updateRequestData.bind(this);
    }

    async getBalanceData(filter = "all") {
        
        try {
            const { authToken, history, setBalanceRequestData, setBalanceRequestDataTotal, loginUserData } = this.props;
            let distributorId = loginUserData.role == "Distributor" ? loginUserData.id : null;
            let city = loginUserData.role == "Sub_admin" ? loginUserData.city : "";
            let page = 0;
            let limit = 10;
            let balanceData = await getBalanceRequestList(authToken, page, limit, filter, distributorId,city);
            this.setState({
                isLoading: true,
            });
            if (balanceData.status == 200) {
                await setBalanceRequestData(balanceData.data);
                await setBalanceRequestDataTotal(balanceData.total);
                this.setState({
                    balanceRequestData: balanceData.data,
                    totalBalanceReq: balanceData.total,
                    isLoading: false,
                });
            } else if (balanceData.status == 401) {
                console.log("I amm 401");
                history.push(`${process.env.PUBLIC_URL}/login`);


            } else {
                throw (balanceData && balanceData.message) ? balanceData.message : "Error";
            }

        } catch (e) {
            console.log("!!!!!!!!TEST error in did mount", e);

        } finally {
            this.setState({
                isLoading: false
            });
        }
    }

    componentDidMount() {
        this.getBalanceData()
    }

    updateRequestData() {
        this.getBalanceData();
    }


    handleChange = (value) => {
        console.log(`selected ${value}`);
        this.getBalanceData(value);
    };

    render() {
        const { totalBalanceReq, balanceRequestData } = this.state


        const { loginUserData } = this.props;

        const accessPermission =
            (loginUserData.access_permission &&
                JSON.parse(loginUserData.access_permission)) ||
            false;

        return (
            loginUserData.role !== "Admin" &&
                loginUserData.role !== "Company" &&
                loginUserData.role !== "Distributor" &&
                (!accessPermission ||
                    !accessPermission["balanceRequest"] ||
                    accessPermission["balanceRequest"].view == false) ?
                <Fragment>
                    <h5 style={{ marginTop: "30%", marginLeft: "30%" }}>
                        You are not authorized for view this page.
                    </h5>
                </Fragment> :

                <Fragment Fragment >
                    <Title parent="Balance Request" title="Balance Request" icon={<DollarSign />} url="/balance-request"/>

                    <div style={{ marginLeft: "15px", marginBottom: "15px" }}>
                        <Select
                            defaultValue="All"
                            style={{
                                width: " 150px",
                            }}
                            onChange={this.handleChange}
                            options={loginUserData.role == "Distributor" ? distributorFilteroptions : options}
                        />
                    </div>

                    <div className="container-fluid">
                        {
                            this.state.isLoading
                                ?
                                <div style={{ marginLeft: "20px" }}>
                                    <Spin
                                        size="large"
                                        style={{ marginLeft: "480px", marginTop: "130px" }}
                                    />
                                </div>
                                :
                                <div>
                                    <BalanceRequestTable
                                        data={balanceRequestData}
                                        // primaryColor={primaryColor}
                                        total={totalBalanceReq}
                                        updateRequestData={this.updateRequestData}
                                    />
                                </div>
                        }
                    </div>
                </Fragment >

        );
    }
}

const mapStateToProps = state => ({
    authToken: state.auth.authToken,
    loginUserData: state.auth.loginUserData
});

const mapDispatchToProps = (dispatch) => {
    return {
        setBalanceRequestData: (balRequestData) => {
            dispatch({
                type: 'SET_BALANCE_REQUEST_DATA',
                balanceData: balRequestData
            });
        },
        setBalanceRequestDataTotal: (balRequestTotal) => {
            dispatch({
                type: "SET_BALANCE_REQUEST_DATA_TOTAL",
                totalBalanceRequest: balRequestTotal
            })
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BalanceRequest));