import { SET_EXPENSES_DATA, SET_EXPENSES_DATA_TOTAL } from "../constant/actionTypes";
const initial_state = {
    expensesData: [],
    totalExpenses: 0,

};

export default (state = initial_state, action) => {
    switch (action.type) {

        case SET_EXPENSES_DATA:
            return { ...state, loading: false, expensesData: action.expensesData };

        case SET_EXPENSES_DATA_TOTAL:

            return { ...state, loading: false, totalExpenses: action.totalExpenses };

        default: return { ...state };
    }
}
