import { callApiToServer } from '../callApi';

export async function createSalesUser(
    authtoken,
    payload
) {
    try {
        let header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "authorization": "Berear " + authtoken
        };

        let body = JSON.stringify(payload);

        let endUrl = "v1/auth/sales/create";
        console.log("endUrl printed here", endUrl);

        let responseData = await callApiToServer(body, header, "POST", endUrl);

        return responseData;

    } catch (err) {
        throw err;
    }
}
