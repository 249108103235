import { callApiToServer } from '../callApi';

export async function searchUser(
  authtoken,
  searchText,
  skipNumber,
  limitNumber,
  city
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    };

    let body = {
      search_text: searchText
  };
  
  if (city) {
      body.city = city;
  }
  
  body = JSON.stringify(body);
    let endUrl = "v1/auth/filter-user/" + skipNumber + "/" + limitNumber;
      let responseData = await callApiToServer(body, header, "POST", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}
