import { callGetApiToServer } from '../callApi';

export async function getDistributorBalance(
    authtoken,
) {
    try {
        let header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "authorization": "Berear " + authtoken
        }

        let endUrl = "v1/auth/distributor";
        console.log("endUrl",endUrl);
        let responseData = await callGetApiToServer(header, "GET", endUrl);

        return responseData;
    } catch (err) {
        throw err;
    }
}