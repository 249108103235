import { SET_LIST_EMPLOYEE_DATA, SET_LIST_EMPLOYEE_DATA_TOTAL } from "../constant/actionTypes";

const initial_state = {
    listEmployeeData : null,
    listEmployeeDataTotal: 0,
};

export default (state = initial_state, action) => {
    switch (action.type) {

        case SET_LIST_EMPLOYEE_DATA:
            return { ...state, loading: false, listEmployeeData: action.listEmployeeData };

        case SET_LIST_EMPLOYEE_DATA_TOTAL:
            return { ...state, loading: false, listEmployeeDataTotal: action.listEmployeeDataTotal };
            
        default: return { ...state };
    }
}
