import { Modal, Button, Divider, Spin } from "antd";
import * as React from "react";
import { Layout, Input, TimePicker, Select } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { WEBURL, color } from "../../../constant/comman";
import { PictureOutlined } from "@ant-design/icons";

import * as la from "lodash";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class AddCurrencyModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDetailsModalclose = this.handleDetailsModalclose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      locationList: [],
      recordSelectedtoView: null,
      data: [],
      view: false,
      index: null,
      isLoading: false,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: false,
      inputVisible: false,
      inputValue: "",
      time: "",
      closeTime: "",
      closeTimeString: "",
      timeString: "",
      errors: {
        uploadImage: "",
      },
      roleType: "1",
      isShouldComponentUpdate: false,
    };
  }

  input;
  allColorType = [];
  selectedColorType = "";
  selctedColorKey = "";
  src = "";
  viewModalRef;

  componentDidMount() {
    la.map(color, (data, index) => {
      this.allColorType.push(
        <Option key={index} value={data.value}>
          <div style={{ display: "inline-block", width: "100%" }}>
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: data.value,
                float: "left",
                borderRadius: "5px",
              }}
            ></div>
            <span
              style={{
                float: "left",
                margin: "0px",
                padding: "0px",
                marginLeft: "10px",
                marginTop: "5px",
                lineHeight: "0px",
              }}
            >
              {data.name}
            </span>
          </div>
        </Option>
      );
    });
    console.log("!!!!!!!this.allColorType", this.allColorType);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isShouldComponentUpdate } = nextState;
    console.log("!!!!!!!!!!!!!update 1", nextState);
    if (!isShouldComponentUpdate) {
      this.selectedColorType = "";
      this.src = undefined;
    }

    return true;
  }

  uploadImage = (name) => {
    console.log("!!!!!!!!!!!!!!after upload ", name);
    if (name != null) {
      this.src = name;
      this.setState({
        view: false,
      });
    } else {
      this.setState({
        view: false,
      });
    }
  };

  showViewModal(params) {
    this.setState(
      {
        view: true,
      },
      () => {
        this.viewModalRef.show();
      }
    );
  }

  handleSelectColortype(value, option) {
    this.selectedColorType = value;
    this.selctedColorKey = option.key;
  }

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  validateForm = (values) => {
    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.name) {
      errors.name = "Currency name is required";
    }

    if (!values.short_name) {
      errors.short_name = "Short name is required";
    }

    if (!values.conversion_rate) {
      errors.conversion_rate = "Convertion rate is required";
    } else {
      if (isNaN(values.conversion_rate)) {
        errors.conversion_rate = "Only numeric value allowed";
      }
    }
    if (!values.country_id) {
      errors.country_id = "Country is required";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  };

  handleSubmit = async (values, action) => {
    console.log("obj val ----", values)
    const { errors } = this.state;
    let listErrors = { ...errors };

    values.conversion_rate = parseFloat(values.conversion_rate);
    this.setState({
      isLoading: true,
    });
    let result = await this.props.onSubmit(values);
    console.log("result: ", result);
    if (result != 1) {
      this.setState({
        isLoading: false,
      });
      return;
    } else {
      this.setState({
        isLoading: false,
        visible: false,
        isShouldComponentUpdate: false,
      });
    }
  };

  showModal = () => {
    console.log("!!!!!!visible");
    this.setState({
      visible: true,
      isShouldComponentUpdate: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
      isShouldComponentUpdate: false,
    });
  };

  handleTime(e, s) {
    this.setState({
      time: e,
      timeString: s,
    });
  }

  handleCloseTime(e, s) {
    this.setState({
      closeTime: e,
      closeTimeString: s,
    });
  }

  handleDetailsModalclose(item) {
    this.setState({
      view: false,
      lat: item.lat,
      long: item.lng,
    });
  }

  render() {
    const { primaryColor } = this.props;
    const { isLoading } = this.state;
    console.log("!!!!!!!!!!!!!this.src", this.src);

    return (
      <div>
        <div
          onClick={this.showModal}
          style={{
            cursor: "pointer",
            width: "100px",
            backgroundColor: primaryColor,
            color: "white",
            padding: "7px 0px",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          ADD
        </div>
        {this.state.visible && (
          <Modal
            destroyOnClose={true}
            title="Add Currency"
            open={this.state.visible}
            onCancel={this.handleCancel}
            footer={null}
          >
            {isLoading ? (
              <div style={{ marginLeft: "20px" }}>
                <Spin
                  size="large"
                  style={{ marginLeft: "480px", marginTop: "130px" }}
                />
              </div>
            ) : (
              <Content style={{ background: "#fff", marginLeft: "10px" }}>
                <Formik
                  initialValues={{
                    name: "",
                    short_name: "",
                    conversion_rate: "",
                    country_id: "",
                  }}
                  validate={this.validateForm}
                  onSubmit={this.handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting,
                  }) => {
                    return (
                      <div>
                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            Currency Name
                            <Input
                              id="name"
                              placeholder="US Dollar"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.name && touched.name ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.name}
                              </p>
                            ) : null}
                          </div>
                          <div className="formik-field-right">
                            Short Name
                            <Input
                              id="short_name"
                              placeholder="USD"
                              value={values.short_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.short_name && touched.short_name ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.short_name}
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            Conversion Rate
                            <Input
                              addonBefore="1 LYD = "
                              id="conversion_rate"
                              placeholder="0.00"
                              value={values.conversion_rate}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.conversion_rate &&
                            touched.conversion_rate ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.conversion_rate}
                              </p>
                            ) : null}
                          </div>
                          <div className="formik-field-right">
                            Country
                            <Field
                              name="country_id"
                              render={({ field }) => (
                                <Select
                                  showSearch
                                  style={{ width: "100%" }}
                                  placeholder="Select Country"
                                  {...field}
                                  onChange={(value) => {
                                    setFieldValue("country_id", value);
                                  }}
                                  onBlur={() =>
                                    setFieldTouched("country_id", true)
                                  }
                                  onSelect={(value, option) =>
                                    this.handleSelectColortype(value, option)
                                  }
                                >
                                  <option value="">Select Country</option>
                                  {this.props.countryData &&
                                    this.props.countryData.length &&
                                    this.props.countryData.map((i) => {
                                      return (
                                        <option value={i.id}>{i.name}</option>
                                      );
                                    })}
                                </Select>
                              )}
                            />
                            {errors.country_id && touched.country_id? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.country_id}
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <Button
                          // type="primary"
                          style={{
                            marginTop: "19px",
                            background: "#5C6AC4",
                            color: "#fff",
                          }}
                          onClick={handleSubmit}
                        >
                          Save
                        </Button>
                      </div>
                    );
                  }}
                </Formik>
              </Content>
            )}
          </Modal>
        )}
      </div>
    );
  }
}
