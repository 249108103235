import * as React from "react";
import { notification, Button, Input } from "antd";
import { Form, Formik } from "formik";
import ModalWrap from "../common/modal";
import { alertError } from "../../utils/alert";
import { getTicketCommentsAPI } from "../../Graphs/ticket-support/AddComments";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { lowerCase } from "lodash";

const { TextArea } = Input;
var amenities = [];

class AddTicketCommentModel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      selectedStatus: "progress",
      isSaveButton: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  validateForm = (values) => {
    const errors = {};
    if (!values.comment) {
      errors.comment = "Enter a valid comment";
    }
    return errors;
  };

  handleChange(value) {
    amenities = value;
  }

  handleSubmit = async (values) => {
    const { selectedStatus } = this.state;
    values.status = lowerCase(selectedStatus);
    values.transaction_id = this.props.data.id;

    this.setState({isSaveButton: true});

    try {
      let response = await getTicketCommentsAPI(this.props.authToken, values);
      console.log("response", response);

      if (response.status == 200) {
        notification["success"]({
          message: response.message,
        });
      } else {
        throw response;
      }
      this.props.refresh && this.props.refresh(0, 10, "");
    } catch (error) {
      alertError("TICKET SUPPORT", error.message);
    }
    finally {
      // Re-enable the "Save" button after the API request is completed
      this.setState({ isSaveButton: false });
    }
    this.props.onClose();
  };

  onChangeStatus = (value) => {
    this.setState({
      selectedStatus: value,
    });
  };

  render() {
    return (
      <ModalWrap
        visible={this.props.visible}
        closable={this.props.onClose}
        title="Add Comments"
        onOk={this.props.handleOk}
        onCancel={this.props.handleCancel}
        isLoading={this.props.isLoading}
      >
        <Formik
          initialValues={{
            transaction_id: "",
            comment: "",
            status: "",
          }}
          validate={this.validateForm}
          onSubmit={this.handleSubmit}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => {
            return (
              <Form>
                {
                  <>
                    <div className="formik-field_wrap">
                      <div className="formik-field-wrap">
                        Comment
                        <TextArea
                          id="comment"
                          rows={3}
                          maxLength={500}
                          placeholder="Add Comment"
                          value={values.comment}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.comment ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.comment}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <Button
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                      disabled={this.state.isSaveButton} // Disable button if isSaveButton is true
                    >
                      Save
                    </Button>
                  </>
                }
              </Form>
            );
          }}
        </Formik>
      </ModalWrap>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
});

export default connect(mapStateToProps)(withRouter(AddTicketCommentModel));
