import { callApiToServer } from '../../callApi';

export async function editCategory(
  authtoken,
  categoryObject,
  categoryId,
) {
  try {
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization": "Berear " + authtoken
    };

    let body = JSON.stringify({
      categoryId: categoryId,
      name: categoryObject.name,
      description: categoryObject.description,
      color: categoryObject.color,
      icon: categoryObject.icon,
      sort_order: categoryObject.sort_order,
      arabic_name: categoryObject.arabicName,
      advertisement: categoryObject.advertisement
    });

    let endUrl = "v1/auth/edit-category";
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;

  } catch (err) {
    throw err;
  }
}
