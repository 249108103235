import React, { Component, createRef } from "react";
import { Form, Input, Button, notification } from "antd";
import { Field, Formik } from "formik";
import { otpValidation } from "../../utils/validations";
import { errorMessages } from "../../utils/validationMessages";
import OTPInput from "react-otp-input";
import { verifyOTP } from "../../Graphs/Login/VerifyOTP";

export default class OTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      valuesArray: [],
      otp: "",
    };
  }
  handleSubmit = (e) => {
    e.preventDefault(); 
    const { otp } = this.state;

    if (!otp) {
      notification["error"]({
        message: "OTP",
        description: errorMessages.otp_required,
      });
      return;
    }else if(!otpValidation(otp) || otp.length != 6){
      notification["error"]({
        message: "OTP",
        description: errorMessages.otp_validation,
      });
      return;
    }
    this.props.onSubmit(this.state.otp); 
  };
  render() {
    const { otp } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <OTPInput
          value={otp}
          onChange={(e) => this.setState({ otp: e })}
          numInputs={6}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
          shouldAutoFocus={true}
          inputStyle={{
            width: "40px",
            height: "40px",
            textAlign: "center",
            margin: "5px 11px",
          }}
        />
        <div style={{ margin: "20px auto 0px", width: "35%" }}>
        <button
          type="submit"
          style={{
            width: "100%",
            height: "40px",
            color: "#fff",
            background: "#1890ff",
            border: "1px solid #1890ff",
            fontSize: "14px",
            borderRadius: "2px",
            cursor: "pointer",
            textAlign: "center",
            padding: "4px 15px",
               }}
        >
          Verify OTP
        </button>

        </div>
      </form>
    );
  }
}
