import React, { Component } from "react";
import { Modal, Spin, notification, Input, Button, DatePicker } from "antd";
import { Form, Formik } from "formik";
import TextArea from "antd/lib/input/TextArea";
import ModalWrap from "../common/modal";
import { dateFormat, dateTimeMoment } from "../../utils/general";
import moment from "moment";

class AddEditExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isLoading: false,
      closable: true,
      data: [],
      errors: {},
      date: "",
    };
  }

  validateForm(values) {
    const errors = {};

    if (!values.amount) {
      errors.amount = "Enter the amount";
    }

    if (!values.description) {
      errors.description = "Description must be required";
    }

    return errors;
  }

  handleSubmit = (values, action) => {
    try {
      this.props.onSubmit(values, this.props.data && this.props.data.id);
      this.props.close();
    } catch (error) {}
  };

  render() {
    const { data } = this.props;
    const dateEdit = dateTimeMoment(data?.expenses_date);
    return (
      <ModalWrap
        visible={this.props.visible}
        closable={this.props.close}
        onOk={this.props.close}
        onCancel={this.props.close}
        isLoading={this.state.isLoading}
        title={data ? "Update Expenses" : "Add Expenses"}
      >
        <Formik
          initialValues={{
            date: data ? moment(dateEdit) : "",
            amount: data?.amount ?? "",
            description: data?.description ?? "",
          }}
          validate={this.validateForm}
          onSubmit={this.handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form>
              <div className="formik-field_wrap">
                <div className="formik-field-left">
                  Select Date
                  <DatePicker
                    showTime ={{
                      format: 'YYYY-MM-DD HH:mm:ss'
                    }}
                    id="date"
                    name="date"
                    value={values.date}
                    onChange={(dateTime) => {
                      setFieldValue("date", dateTime);
                    }}
                    disabledDate={(current) => {
                      return moment().add(0, "days") <= current;
                    }}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="formik-field-right">
                  Amount
                  <Input
                    id="amount"
                    type="number"
                    min={0}
                    placeholder="Amount"
                    value={values.amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.amount && touched.amount ? (
                    <p
                      style={{
                        color: "red",
                        fontSize: "small",
                        margin: "0",
                      }}
                    >
                      {errors.amount}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="formik-field_wrap">
                <div className="formik-field-wrap">
                  Description
                  <TextArea
                    id="description"
                    rows={4}
                    placeholder="Expenses Description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.description && touched.description ? (
                    <p
                      style={{
                        color: "red",
                        fontSize: "small",
                        margin: "0",
                      }}
                    >
                      {errors.description}
                    </p>
                  ) : null}
                </div>
              </div>
              <Button
                style={{
                  marginTop: "19px",
                  background: "#5C6AC4",
                  color: "#fff",
                }}
                onClick={handleSubmit}
              >
                {data ? "Update" : "Save"}
              </Button>
            </Form>
          )}
        </Formik>
      </ModalWrap>
    );
  }
}

export default AddEditExpenses;
