import { SET_LIST_COMPANY_DATA, SET_LIST_COMPANY_DATA_TOTAL } from "../constant/actionTypes";

const initial_state = {
    listCompanyData : null,
    listCompanyDataTotal: 0,
};

export default (state = initial_state, action) => {
    console.log("[store ListCompanyData]", action.listCompanyData);
    switch (action.type) {

        case SET_LIST_COMPANY_DATA:
            return { ...state, loading: false, listCompanyData: action.listCompanyData };

        case SET_LIST_COMPANY_DATA_TOTAL:
            return { ...state, loading: false, listCompanyDataTotal: action.listCompanyDataTotal };
            
        default: return { ...state };
    }
}