/* eslint-disable */

import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
} from "antd";
import { Modal } from "antd";
import { Layout } from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllCategory } from "../../../Graphs/Master/Category/listCategory";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
  ApartmentOutlined,
  PlusCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { deleteCategory } from "../../../Graphs/Master/Category/deleteCategory";
import { changeStatus } from "../../../Graphs/Master/Category/changeStatus";
import { editCategory } from "../../../Graphs/Master/Category/editCategory";
import { addCategory } from "../../../Graphs/Master/Category/addCategory";
import { searchCategory } from "../../../Graphs/Master/Category/searchCategory";
import { EditModal } from "./EditModal";
import { AddCategoryModal } from "./AddCategoryModal";
import { color } from "../../../constant/comman";
import SubCategoryModel from "./SubCategoryModel";
import TableWrap from "../../common/Table";

var moment = require("moment");
const { Content } = Layout;

const BASE_IMAGE_URL =
  "https://s3.us-east-2.amazonaws.com/cerebrus-consulting/bloodman_user_media/";

class CountryRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      isCategoryModel: false,
      subCategoryData: [],
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      userPermission: {
        edit: false,
        delete: false,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedCategory = this.getLimitedCategory.bind(this);
    this.handleSubCategoryModel = this.handleSubCategoryModel.bind(this);
  }

  async componentDidMount() {
    const { setRouteName } = this.props;

    let permission = JSON.parse(this.props.loginUserData.access_permission);
    this.setState({
      userPermission: {
        edit:
          (permission && permission["master"] && permission["master"].edit) ||
          false,
        delete:
          (permission && permission["master"] && permission["master"].delete) ||
          false,
      },
    });

    setRouteName("CATEGORY");
    this.setState({
      isLoading: true,
      categorySelectedData: null,
    });
    await this.listCategoryData(0, 10);
  }

  modalRef;

  async getLimitedCategory(pagination) {
    const { searchText } = this.props;
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText != "") {
          //get search text props from redux store
          this.listSearchCategoryData(start, end);
        } else {
          //call normal user Data
          this.listCategoryData(start, end);
        }
      }
    );
  }

  listSearchCategoryData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCategoryData,
        setListCategoryDataTotal,
        searchText,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let categoryData = await searchCategory(
        authToken,
        searchText,
        start,
        end
      );

      if (categoryData.status == 200) {
        //set user Data
        await setListCategoryData(categoryData.data);
        await setListCategoryDataTotal(categoryData.total);

        this.setState({
          isLoading: false,
          data: categoryData.data,
        });
      } else if (categoryData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw categoryData && categoryData.message
          ? categoryData.message
          : "Error";
      }
    } catch (e) {
      alertError("CATEGORY", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listCategoryData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCategoryData,
        setListCategoryDataTotal,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let categoryData = await getAllCategory(authToken, start, end);

      if (categoryData.status == 200) {
        //set user Data
        console.log("!!!!!!!categoryData", categoryData);
        await setListCategoryData(categoryData.data);
        await setListCategoryDataTotal(categoryData.total);

        this.setState({
          isLoading: false,
          data: categoryData.data,
        });
      } else if (categoryData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw categoryData && categoryData.message
          ? categoryData.message
          : "Error";
      }
    } catch (e) {
      alertError("CATEGORY", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.add(2, "hours").format("Do MMM YYYY");

    return newDate;
  }

  showModal(item) {
    this.setState(
      {
        categorySelectedData: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  handleSubCategoryModel(item) {
    console.log("handleSubCategoryModel", item);
    this.setState({
      isCategoryModel: true,
      subCategoryData: item,
    });
  }

  handleDetailsModalclose() {
    this.setState({
      categorySelectedData: null,
    });
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "name",
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                  <div>{item.name ? item.name : ""}</div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Arabic Name",
      key: "arabic name",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                  <div>{item.arabic_name ? item.arabic_name : "-"}</div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      render: (text, item, index) => {
        return <div>{item.description ? item.description : "-"}</div>;
      },
    },
    // {
    //   title: "Color",
    //   key: "color",
    //   dataIndex: "color",
    //   render: (text, item, index) => {
    //     return <div>{item.color ? color[parseInt(item.color)].name : "-"}</div>;
    //   },
    // },
    {
      title: "Color",
      key: "color",
      dataIndex: "color",
      render: (text, item, index) => {
        return (
          <div>
            {item.color ? (
              <div style={{ display: "inline-block", width: "100%" }}>
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: color[item.color].value,
                    float: "left",
                    borderRadius: "5px",
                  }}
                ></div>
                <span
                  style={{
                    float: "left",
                    margin: "0px",
                    padding: "0px",
                    marginLeft: "10px",
                    marginTop: "5px",
                    lineHeight: "0px",
                  }}
                >
                  {color[item.color].name}
                </span>
              </div>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Sort Order",
      key: "sort_order",
      dataIndex: "sort_order",
      render: (text, item, index) => {
        return (
          <div>
            {item.sort_order
              ? item.sort_order
                ? String(item.sort_order)
                : "-"
              : "-"}
          </div>
        );
      },
    },
    {
      title: "Active Status",
      key: "isActive",
      render: (text, item, index) => {
        return (
          <div>
            {this.props.loginUserData.role == "Admin" ||
            this.state.userPermission.edit ? (
              item.is_active != null ? (
                    <Switch
                      checked={item.is_active}
                      style={{
                        backgroundColor: item.is_active
                          ? this.props.primaryColor
                          : "#bababa",
                      }}
                      onChange={this.onChange.bind(this, item)}
                    />
              ) : (
                "-"
              )
            ) : item.is_active ? (
              "Active"
            ) : (
              "In-active"
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 200,
      key: "action",
      render: (text, item, index) => {
        return (
          <>
            <div style={{ display: "inline-block", width: "100%"}}>
              {this.props.loginUserData.role == "Admin" ||
              this.state.userPermission.edit ? (
                <div mr="5px" style={{ float: "left" }}>
                  <Tooltip placement="bottom" title="Edit">
                    <Button
                      shape="circle"
                      icon={<EditOutlined />}
                      onClick={() => {
                        this.showModal(item);
                      }}
                    />
                  </Tooltip>
                </div>
              ) : null}

              {this.props.loginUserData.role == "Admin" ||
              this.state.userPermission.delete ? (
                <div
                  ml="15px"
                  mr="5px"
                  style={{ float: "left", marginLeft: "10px" }}
                >
                  <Tooltip placement="bottom" title="Delete">
                    <Popconfirm
                      onConfirm={this.deleteCategory.bind(this, item)}
                      title="Are you sure you want to delete category?"
                    >
                      <Button shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                </div>
              ) : null}

              <div
                ml="15px"
                mr="5px"
                style={{ float: "left", marginLeft: "10px" }}
              >
                <Tooltip placement="bottom" title="Add Sub Categories">
                  <Button
                    onClick={() => {
                      this.handleSubCategoryModel(item);
                    }}
                    shape="circle"
                    icon={<PlusCircleOutlined/>}
                  />
                </Tooltip>
              </div>
            </div>
            {/* {console.log(this.state, "this.state")} */}
            {/* {this.state.isCategoryModel && <SubCategoryModel />} */}
          </>
        );
      },
    },
  ];

  async deleteCategory(item) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
        setListCategoryData,
        setListCategoryDataTotal,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let deleteData = await deleteCategory(
        authToken,
        item.id,
        startIndex,
        limitNumber,
        searchText
      );

      if (deleteData.status == 200) {
        notification["success"]({
          message: "Delete Category",
          description: "Successfully deleted",
        });
        await setListCategoryData(deleteData.data);
        await setListCategoryDataTotal(deleteData.total);
        //set user Data
        this.setState({
          isLoading: false,
          data: deleteData.data,
        });
      } else if (deleteData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw deleteData && deleteData.message ? deleteData.message : "Error";
      }
    } catch (e) {
      alertError("CATEGORY", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onChange(item, checked) {
    try {
      console.log("!!!!!!!!", checked);

      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeStatus(authToken, item.id, checked);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Change Category Status",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText != "") {
          this.listSearchCategoryData(startIndex, limitNumber);
          return;
        }
        this.listCategoryData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (err) {
      alertError("CATEGORY", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  addSubmit = async (categoryObject) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await addCategory(authToken, categoryObject);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Add Category",
          description: "Successfully added",
        });
        if (searchText != null && searchText != "") {
          this.listSearchCategoryData(startIndex, limitNumber);
          return;
        }
        this.listCategoryData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      alertError("CATEGORY", e);
      this.setState({
        isLoading: false,
      });
    }
  };

  editSubmit = async (categoryObject, categoryId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await editCategory(
        authToken,
        categoryObject,
        categoryId
      );
      if (statusData.status == 200) {
        notification["success"]({
          message: "Edit Category",
          description: "Successfully edited",
        });
        if (searchText != null && searchText != "") {
          this.listSearchCategoryData(startIndex, limitNumber);
          return;
        }
        this.listCategoryData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      alertError("CATEGORY", e);
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedCategory(pagination)
    );
  }

  render() {
    const { isLoading } = this.state;
    const {
      listCategoryData,
      listCategoryDataTotal,
      searchLoader,
      accessPermission,
      loginUserData,
    } = this.props;
    console.log("listCategoryData", listCategoryData);

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ display: "inline-block", width: "100%" }}>
        <div style={{ float: "left", cursor: "pointer", width: "100%" }}>
          {loginUserData.role == "Admin" ||
          (accessPermission &&
            accessPermission["master"] &&
            accessPermission["master"].edit) ? (
            <AddCategoryModal
              onSubmit={this.addSubmit}
              type="category"
              primaryColor={this.props.primaryColor}
            />
          ) : null}
        </div>
        <div
          style={{
            overflowX: "auto",
            float: "left",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <TableWrap
            data={listCategoryData}
            columns={this.columns}
            isLoading={this.state.isLoading}
            total={listCategoryDataTotal}
            pagination={this.state.pagination}
            handleTableChange={this.handleTableChange}
          />

          {this.state.categorySelectedData ? (
            <EditModal
              admin={false}
              refx={(e) => (this.modalRef = e)}
              onClose={this.handleDetailsModalclose.bind(this)}
              categorySelectedData={this.state.categorySelectedData}
              onSubmit={this.editSubmit}
            />
          ) : null}

          {this.state.isCategoryModel ? (
            <SubCategoryModel
              data={this.state.subCategoryData}
              myProps={this.props}
              onClose={() => {
                this.setState({
                  isCategoryModel: false,
                });
              }}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listCategoryData: state.master.listCategoryData,
  listCategoryDataTotal: state.master.listCategoryDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCategoryData: (listCategoryData) => {
      dispatch({
        type: "SET_LIST_CATEGORY_DATA",
        listCategoryData: listCategoryData,
      });
    },
    setListCategoryDataTotal: (listCategoryDataTotal) => {
      dispatch({
        type: "SET_LIST_CATEGORY_DATA_TOTAL",
        listCategoryDataTotal: listCategoryDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CountryRecordsTable));
