import { Button, DatePicker, notification, Table } from "antd";
import { Formik } from "formik";
import moment from "moment";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { profitReportAPI } from "../../Graphs/Report/profitReportAPI";
import { alertError } from "../../utils/alert";
import { connect } from "react-redux";
import { formatePrice, formattedDate } from "../../utils/general";
import TableWrap from "../common/Table";
const { RangePicker } = DatePicker;

class ProfitReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profitList: [],
      startDate: "",
      endDate: "",
      allProfitsData: null,
      isLoading: false,
      totalProfit: 0,
      anisAPIProfit: 0,
      likeAPIProfit: 0,
      mintAPIProfit: 0,
      adminCardBuyProfit: 0,
      subAdminCardBuyProfit: 0,
      companyProfit: 0,
      cityProfit: 0,
      userProfit: 0,

    };
    this.getProfitReportList = this.getProfitReportList.bind(this);
  }

  resetProfitReportTable = () => {
    this.setState({
      profitList: [],
      totalProfit: 0,
      anisAPIProfit: 0,
      companyProfit: 0,
      cityProfit: 0,
      userProfit: 0,
    });
  };

  onChangeDate = (date) => {
    if (!date || !date.length) {
      this.resetProfitReportTable();
      this.setState({
        startDate: null,
        endDate: null,
      });
    }
    this.setState({
      startDate: date?.[0]?.format("YYYY-MM-DD"),
      endDate: date?.[1]?.format("YYYY-MM-DD"),
    });
  };

  getProfitReportList = async () => {
    try {
      const { authToken, loginUserData,history } = this.props;
      let city = loginUserData.role == "Sub_admin" ? loginUserData.city : "";

      let obj = {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        city: city
      };
      let profitListData = await profitReportAPI(authToken, obj);
      this.setState({
        isLoading: true,
        totalProfit: 0,
        anisAPIProfit: 0,
        likeAPIProfit: 0,
        mintAPIProfit: 0,
        adminCardBuyProfit: 0,
        subAdminCardBuyProfit:0,
        companyProfit: 0,
        cityProfit: 0,
        userProfit: 0,
      });
      if (profitListData.status == 200) {
        profitListData.data.forEach((element) => {
          this.setState({
            totalProfit: this.state.totalProfit + element?.total_profits ?? 0,
            anisAPIProfit:
              this.state.anisAPIProfit + element?.anis_api_total ?? 0,
            likeAPIProfit:
              this.state.likeAPIProfit + element?.like_api_total ?? 0,
            mintAPIProfit:
              this.state.mintAPIProfit + element?.mint_api_total ?? 0,
            adminCardBuyProfit:this.state.adminCardBuyProfit + element?.admin_card_buy_total ?? 0,
            subAdminCardBuyProfit:this.state.subAdminCardBuyProfit + element?.sub_admin_card_buy_total ?? 0,
            companyProfit:
              this.state.companyProfit + element?.company_total ?? 0,
            cityProfit: this.state.cityProfit + element?.city_total ?? 0,
            userProfit: this.state.userProfit + element?.user_total ?? 0,
          });
        });

        this.setState({
          profitList: profitListData.data,
          allProfitsData: profitListData,
          isLoading: false,
        });
      } else if (profitListData.status == 401) {
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw profitListData && profitListData.message
          ? profitListData.message
          : "Error";
      }
    } catch (e) {
      alertError("REPORT", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      fixed: true,
      render: (text, item, index) => {
        return item.date ? (
          <div>
            <span>{++index}</span>
          </div>
        ) : (
          <div key={"sno" + index}>
            <span style={{ fontWeight: 700 }}>Grand</span>
          </div>
        );
      },
    },
    {
      title: "Date",
      key: "date",
      render: (text, item, index) => {
        return item.date ? (
          <div>
            <span>{formattedDate(item.date)}</span>
          </div>
        ) : (
          <div key={"name" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div
                flexDirection="column"
                style={{ marginTop: "5px", fontWeight: 700 }}
              >
                Total Amount
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Anis API Profit",
      key: "profit",
      render: (text, item, index) => {
        return item.date ? (
          <div>
            <span>{item?.anis_api_total?.toFixed(2)}</span>
          </div>
        ) : (
          <div key={"name" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div
                flexDirection="column"
                style={{ marginTop: "5px", fontWeight: 700 }}
              >
                {this.state.anisAPIProfit.toFixed(2)}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Like api profit",
      key: "profit",
      render: (text, item, index) => {
        return item.date ? (
          <div>
            <span>{item?.like_api_total?.toFixed(2)}</span>
          </div>
        ) : (
          <div key={"name" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div
                flexDirection="column"
                style={{ marginTop: "5px", fontWeight: 700 }}
              >
                {this.state.likeAPIProfit.toFixed(2)}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Mint api profit",
      key: "profit",
      render: (text, item, index) => {
        return item.date ? (
          <div>
            <span>{item?.mint_api_total?.toFixed(2)}</span>
          </div>
        ) : (
          <div key={"name" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div
                flexDirection="column"
                style={{ marginTop: "5px", fontWeight: 700 }}
              >
                {this.state.mintAPIProfit.toFixed(2)}
              </div>
            </div>
          </div>
        );
      },
    },
    ...(this.props.loginUserData.role === "Admin"
      ? [
          {
            title: "Admin card buy profit",
            key: "profit",
            render: (text, item, index) => {
              return item.date ? (
                <div>
                  <span>{item?.admin_card_buy_total?.toFixed(2)}</span>
                </div>
              ) : (
                <div key={"name" + index}>
                  <div
                    style={{
                      alignItems: "center",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <div
                      flexDirection="column"
                      style={{ marginTop: "5px", fontWeight: 700 }}
                    >
                      {this.state.adminCardBuyProfit.toFixed(2)}
                    </div>
                  </div>
                </div>
              );
            },
          },
        ]
      : []),
       ...(this.props.loginUserData.role === "Sub_admin"? [
        {
          title: "Sub Admin card buy profit",
          key: "profit",
          render: (text, item, index) => {
            return item.date ? (
              <div>
                <span>{item?.sub_admin_card_buy_total?.toFixed(2)}</span>
              </div>
            ) : (
              <div key={"name" + index}>
                <div
                  style={{
                    alignItems: "center",
                    display: "inline-block",
                    width: "100%",
                  }}
                >
                  <div
                    flexDirection="column"
                    style={{ marginTop: "5px", fontWeight: 700 }}
                  >
                    {this.state.subAdminCardBuyProfit.toFixed(2)}
                  </div>
                </div>
              </div>
            );
          },
        },
       ]:[]),

    
    {
      title: "City percentage profit",
      key: "profit",
      render: (text, item, index) => {
        return item.date ? (
          <div>
            <span>{item?.city_total?.toFixed(2)}</span>
          </div>
        ) : (
          <div key={"name" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div
                flexDirection="column"
                style={{ marginTop: "5px", fontWeight: 700 }}
              >
                {this.state.cityProfit.toFixed(2)}
              </div>
            </div>
          </div>
        );
      },
    },
    ...(this.props.loginUserData.role !== "Sub_admin" ? [
    {
      title: "Company Transaction Profit",
      key: "profit",
      render: (text, item, index) => {
        return item.date ? (
          <div>
            <span>{item?.company_total?.toFixed(2)}</span>
          </div>
        ) : (
          <div key={"name" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div
                flexDirection="column"
                style={{ marginTop: "5px", fontWeight: 700 }}
              >
                {this.state.companyProfit.toFixed(2)}
              </div>
            </div>
          </div>
        );
      },
    },
    ]:[]),
    ...(this.props.loginUserData.role !== "Sub_admin"? [
    {
      title: "Users Transaction Profit",
      key: "profit",
      render: (text, item, index) => {
        return item.date ? (
          <div>
            <span>{item?.user_total?.toFixed(2)}</span>
          </div>
        ) : (
          <div key={"name" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div
                flexDirection="column"
                style={{ marginTop: "5px", fontWeight: 700 }}
              >
                {this.state.userProfit.toFixed(2)}
              </div>
            </div>
          </div>
        );
      },
    },]:[]),

    {
      title: "Total Profit",
      key: "profit",
      render: (text, item, index) => {
        return item.date ? (
          <div>
            <span>{item?.total_profits?.toFixed(2)}</span>
          </div>
        ) : (
          <div key={"name" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div
                flexDirection="column"
                style={{ marginTop: "5px", fontWeight: 700 }}
              >
                {this.state.totalProfit.toFixed(2)}
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  render() {
    const { allProfitsData } = this.state;
    return (
      <>
        <Formik
          initialValues={{
            date: "",
          }}
          onSubmit={() => {
            this.getProfitReportList();
          }}
        >
          {({ values, handleSubmit }) => {
            return (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  width: "100%",
                  marginBottom: "20px",
                  paddingRight: "55px",
                  justifyContent: "left",
                }}
              >
                <div style={{ cursor: "pointer" }}>
                  <RangePicker
                    format="DD-MM-YYYY"
                    onChange={this.onChangeDate}
                    disabledDate={(current) => {
                      return moment().add(0, "days") <= current;
                    }}
                  />
                </div>

                <div style={{ cursor: "pointer", width: "100%" }}>
                  <Button
                    style={{ background: "#5C6AC4", color: "#fff" }}
                    color={this.state.primaryColor}
                    onClick={handleSubmit}
                    disabled={this.state.startDate == ""}
                  >
                    Filter
                  </Button>
                </div>
              </div>
            );
          }}
        </Formik>
        {this.state.startDate && (
          <TableWrap
            data={this.state.profitList}
            columns={this.columns}
            isLoading={this.state.isLoading}
            pagination={{ pageSize: this.state.profitList?.length || 50  }}
            scroll={{ y: 400}}
            footer={(pageData) => {
              return (
                <>
                  <Table.Summary.Row style={{ fontWeight: 700, position: "sticky", bottom: "0", zIndex: 1, background: "white" }}>
                    <Table.Summary.Cell style={{ fontWeight: 700, fixed: true}}>
                      Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell style={{ fontWeight: 700 }}>
                      -
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {formatePrice(this.state.anisAPIProfit)}
                      {/* {` (${formatePrice(
                        allProfitsData?.total_anis_api_profit || 0
                      )})`} */}
                    </Table.Summary.Cell>

                    <Table.Summary.Cell>
                      {formatePrice(this.state.likeAPIProfit)}
                    </Table.Summary.Cell>

                    <Table.Summary.Cell>
                      {formatePrice(this.state.mintAPIProfit)}
                    </Table.Summary.Cell>
                    { this.props.loginUserData.role === "Admin" &&
                    <Table.Summary.Cell>
                      {formatePrice(this.state.adminCardBuyProfit)}
                    </Table.Summary.Cell>
            }
                 { this.props.loginUserData.role === "Sub_admin" &&
                    <Table.Summary.Cell>
                      {formatePrice(this.state.subAdminCardBuyProfit)}
                    </Table.Summary.Cell>
                    }
                    <Table.Summary.Cell style={{ fontWeight: 700 }}>
                      {formatePrice(this.state.cityProfit)}
                    </Table.Summary.Cell>
                   {
                    this.props.loginUserData.role !== "Sub_admin" &&
                    <Table.Summary.Cell style={{ fontWeight: 700 }}>
                      {formatePrice(this.state.companyProfit)}
                    </Table.Summary.Cell>
                   }
                  {this.props.loginUserData.role !== "Sub_admin" &&
                    <Table.Summary.Cell style={{ fontWeight: 700 }}>
                      {formatePrice(this.state.userProfit)}
                    </Table.Summary.Cell>
                    }

                    <Table.Summary.Cell style={{ fontWeight: 700 }}>
                      {formatePrice(this.state.totalProfit)}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

export default connect(mapStateToProps)(withRouter(ProfitReport));
