import {
    Table,
    Tooltip,
    notification,
    Spin,
    Modal,
    Tag,
} from "antd";
import * as React from "react";

import { withRouter } from "react-router";
import { connect } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getDistributorTransaction } from "../../Graphs/distributor/getDistributorTransaction";
import { alertError } from "../../utils/alert";
import { dateFormat } from "../../utils/general";
var moment = require("moment");
class DistibutionTransaction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            dataTotal: 0,
            isLoading: false,
            startIndex: 0,
            limitNumber: 5,
            pagination: {
                pageSize: 5,
                current: 1,
            },

            visible: false,
            closable: true,
        };
        this.handleTableChange = this.handleTableChange.bind(this);
    }

    componentDidMount() {
        if (this.props.refx) {
            this.props.refx(this);
        }

        console.log("[CONSOLE LOG]", this.props.transactionListData);

        this.listDistributorTransaction(0, 5);
    }

    updatePagination = async (pagination) => {
        console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
        const start =
            pagination.current * pagination.pageSize - pagination.pageSize;
        const end = pagination.pageSize;

        this.setState(
            {
                isLoading: true,
                startIndex: start,
                limitNumber: end,
            },
            async () => {
                this.listDistributorTransaction(start, end);
            }
        );
    };

    listDistributorTransaction = async (start, end) => {
        try {
            const {
                transactionListData,
                authToken,
                history,
                setListDistributorTrnData,
                setListDistributorTrnDataTotal,
            } = this.props;
            this.setState({
                isLoading: true,
            });

            let distributorTranData = await getDistributorTransaction(
                authToken,
                start,
                end,
                transactionListData.id
            );
            console.log("!!!!!!!!!!!!distributorTranData", distributorTranData);

            if (distributorTranData.status == 200) {
                this.setState({
                    isLoading: false,
                    data: distributorTranData.data,
                    dataTotal: distributorTranData.total,
                });
                console.log("!!!!!!!!!!!!distributorTranData", distributorTranData);

                await setListDistributorTrnData(distributorTranData.data);
                await setListDistributorTrnDataTotal(distributorTranData.total);
            } else if (distributorTranData.status == 401) {
                // await setUserData(null);
                // await setUserToken(null);
                history.push(`${process.env.PUBLIC_URL}/login`);
            } else {
                throw distributorTranData && distributorTranData.message
                    ? distributorTranData.message
                    : "Error";
            }
        } catch (e) {
            alertError("Distributor",e)
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    };

    handleTableChange(pagination, filters) {
        console.log("outer onchange iusers", pagination);
        this.setState(
            {
                pagination,
            },
            () => this.updatePagination(pagination)
        );
    }


    onBlur = () => {
        console.log("blur");
    };

    onFocus = () => {
        console.log("focus");
    };

    handleOk = (e) => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = (e) => {
        this.hide();
    };

    show() {
        this.setState({
            visible: true,
        });
    }

    hide() {
        this.setState({
            visible: false,
        });
        this.props.onClose();
    }

    counter = 0;
    tempCounter = 0;
    columns = [
        {
            title: "Sr. No.",
            key: "serial_number",
            width: 5,
            fixed: true,
            render: (text, item, index) => {
                return (
                    <div>
                        <span>{++index + this.tempCounter}</span>
                    </div>
                );
            },
        },
        {
            title: "Date",
            key: "date",
            width: 5,
            render: (text, item, index) => {
                return (
                    <div>
                        <span>{item.createdAt !== null ? dateFormat(item.createdAt) : "-"}</span>
                    </div>
                );
            },
        },
        {
            title: "Type",
            key: "Type",
            width: 10,
            render: (text, item, index) => {
                console.log("coin record --> ", item);
                return (
                    <div>
                        <div
                            style={{
                                alignItems: "center",
                                display: "inline-block",
                                width: "100%",
                            }}
                        >
                            <div flexDirection="column">
                                <strong>
                                    {" "}
                                        <div>
                                            {item.type ? (
                                                <Tag
                                                    color={item.type == "CREDIT" ? "green" : "red"}
                                                    key={"loser"}
                                                >
                                                    {item.type.toUpperCase()}
                                                </Tag>
                                            ) : (
                                                "-"
                                            )}
                                        </div>
                                </strong>
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            title: "Price",
            key: "Price",
            width: 10,
            render: (text, item, index) => {
                console.log("user record --> ", item);
                return (
                    <div>
                        <div
                            style={{
                                alignItems: "center",
                                display: "inline-block",
                                width: "100%",
                            }}
                        >
                            <div flexDirection="column">
                                <strong>
                                    {" "}
                                        <div>{item.price !== null ? (item.price).toFixed(2) : "-"}</div>
                                </strong>
                            </div>
                        </div>
                    </div>
                );
            },
        },
    ];

    render() {
        const { isLoading, data, dataTotal } = this.state;

        return (
            <Modal
                width="600px"
                closable={this.state.closable}
                open={this.state.visible}
                title="Distributor Transactions"
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={null}
                destroyOnClose={true}
            >
                {isLoading ? (
                    <div style={{ marginLeft: "20px" }}>
                        <Spin size="large" style={{ margin: "0 auto", width: "100%" }} />
                    </div>
                ) : (
                    <React.Fragment>
                        <div style={{ "overflow-x": "auto" }}>
                            <Table
                                loading={this.state.isLoading}
                                rowKey={(record) => record._id}
                                columns={this.columns}
                                size={"small"}
                                scroll={{ x: 900 }}
                                // dataSource={this.state.data}
                                dataSource={data}
                                pagination={{
                                    total: dataTotal,
                                    showSizeChanger: true,
                                    pageSize: this.state.pagination.pageSize,
                                    current: this.state.pagination.current,
                                    pageSizeOptions: ["1", "10", "25", "50", "100"],
                                    onChange: (e) => {
                                        console.log("onChanges pagination");
                                        // e - 1
                                        //   ? (this.counter = this.tempCounter =
                                        //       (e - 1) * this.state.pagination.pageSize)
                                        //   : ((this.counter = 0), (this.tempCounter = 0));
                                        if (e - 1) {
                                            this.counter = this.tempCounter =
                                                (e - 1) * this.state.pagination.pageSize;
                                            return;
                                        }

                                        this.counter = 0;
                                        this.tempCounter = 0;
                                    },
                                }}
                                onChange={this.handleTableChange}
                            />
                        </div>
                    </React.Fragment>
                )}
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    authToken: state.auth.authToken,
    listCompanyTrnDataData: state.company_trn.listCompanyTrnData,
    listCompanyTrnDataTotal: state.company_trn.listCompanyTrnDataTotal,
});

const mapDispatchToProps = (dispatch) => {
    return {
        setListDistributorTrnData: (listDistributorTrnData) => {
            dispatch({
                type: "SET_LIST_DISTRIBUTOR_TRN_DATA",
                listDistributorTrnData: listDistributorTrnData,
            });
        },
        setListDistributorTrnDataTotal: (listDistributorTrnDataTotal) => {
            dispatch({
                type: "SET_LIST_DISTRIBUTOR_TRN_DATA_TOTAL",
                listDistributorTrnDataTotal: listDistributorTrnDataTotal,
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DistibutionTransaction));
