import { callGetApiToServer } from '../callApi';

export async function listUserCoinTrans(
    authtoken,
    skipNumber,
    limitNumber,
    userId
  ) {
    try{
      let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "authorization" : "Berear " + authtoken
      }
    
      let endUrl = "v1/auth/user-transactions/" + userId + "/" + skipNumber + "/" + limitNumber;
      console.log("endUrl printed here", endUrl);
    
      let responseData = await callGetApiToServer(header, "GET", endUrl);
        
      return responseData;
    }catch(err) {
      throw err;
    }
  }