import { callApiToServer } from '../callApi';

export async function profitReportAPI(
    authtoken,
    obj,
    // skip,
    // limit
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    };
      
    let body = JSON.stringify(obj);
  
    let endUrl = `v1/auth/admin-profit-report`;
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callApiToServer(body, header, "POST", endUrl);
      
    return responseData;
      
  }catch(err) {
    throw err;
  }
}
