import { ENDPOINT } from '../../network/ENDPOINT';
import { callApiToServer } from '../callApi';

export async function deleteInventoryBatch(
        authtoken,   
        payload,
){
    try {
        let header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "authorization": "Berear " + authtoken
        };

        let body = JSON.stringify(payload);

        let endUrl = `${ENDPOINT.inventory_batch_unpaid_delete}`;

        let responseData = await callApiToServer(body, header, "POST", endUrl);

        return responseData;

    } catch (err) {
        throw err;
    }
}

