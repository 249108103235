import * as React from "react";
import { Modal, Spin, notification } from "antd";
import { AddCompanyForm } from "./AddCompanyForm";

import { addCompany } from "../../Graphs/Company/addCompany";

export class AddCompanyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
      coinsList: [],
    };
  }

  async componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }

    await this.getCoinsList();
  }

  getCoinsList = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      let coinData = {}; // await ( this.props.authToken, 0, 100000);
      this.setState({
        coinsList: coinData.data,
      });
    } catch (error) {
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show = () => {
    this.setState({
      visible: true,
    });
  };

  hide() {
    this.setState({
      visible: false,
    });
    //this.props.onClose();
  }

  render() {
    const { primaryColor } = this.props;
    return (
      <React.Fragment>
        <div
          onClick={this.show}
          style={{
            cursor: "pointer",
            width: "100px",
            backgroundColor: primaryColor,
            color: "white",
            padding: "7px 0px",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          ADD
        </div>

        <Modal
          width="600px"
          closable={this.state.closable}
          open={this.state.visible}
          title="Add Company"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          destroyOnClose={true}
        >
          {this.state.isLoading ? (
            <div style={{ justifyContent: "center" }}>
              <Spin />
            </div>
          ) : (
            <AddCompanyForm
              authToken={this.props.authToken}
              onClose={this.hide}
              onSubmit={this.props.onSubmit}
              handleCancel={this.handleCancel}
            />
          )}
        </Modal>
      </React.Fragment>
    );
  }
}
