import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, TimePicker, Select } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class AddCountryModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDetailsModalclose = this.handleDetailsModalclose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      locationList: [],
      recordSelectedtoView: null,
      data: [],
      view: false,
      index: null,
      isLoading: false,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: false,
      inputVisible: false,
      inputValue: "",
      time: "",
      closeTime: "",
      closeTimeString: "",
      timeString: "",
      roleType: "1",
    };
  }

  input;

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  validateForm = (values) => {
    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.name) {
      errors.name = "Name is required";
    }
    if (!values.code) {
      errors.code = "ISO code is required";
    }
    if (!values.isd_code) {
      errors.isd_code = "ISD code is required";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  };

  handleSubmit = (values, action) => {
    console.log("Basic Value - ", values);
    this.props.onSubmit(values);

    this.setState({
      visible: false,
    });
  };

  showModal = () => {
    console.log("!!!!!!visible");
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleTime(e, s) {
    this.setState({
      time: e,
      timeString: s,
    });
  }

  handleCloseTime(e, s) {
    this.setState({
      closeTime: e,
      closeTimeString: s,
    });
  }

  handleDetailsModalclose(item) {
    this.setState({
      view: false,
      lat: item.lat,
      long: item.lng,
    });
  }

  render() {
    const { primaryColor } = this.props;

    return (
      <div>
        <div
          onClick={this.showModal}
          style={{
            cursor: "pointer",
            width: "100px",
            backgroundColor: primaryColor,
            color: "white",
            padding: "7px 0px",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          ADD
        </div>
        {this.state.visible && (
          <Modal
            destroyOnClose={true}
            title="Add Country"
            open={this.state.visible}
            onCancel={this.handleCancel}
            footer={null}
          >
            <Content style={{ background: "#fff", marginLeft: "10px" }}>
              <Formik
                initialValues={{
                  name: "",
                  code: "",
                  isd_code: "",
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }) => {
                  return (
                    <div>
                      <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          Name
                          <Input
                            id="name"
                            placeholder="Country Name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.name && touched.name ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.name}
                            </p>
                          ) : null}
                        </div>
                        <div className="formik-field-right">
                          ISO Code
                          <Input
                            id="code"
                            placeholder="Country Code"
                            value={values.code}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.code && touched.code ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.code}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          ISD Code
                          <Input
                            id="isd_code"
                            placeholder="Country Isd Code"
                            value={values.isd_code}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.isd_code && touched.isd_code ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.isd_code}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <Button
                        // type="primary"
                        style={{
                          marginTop: "19px",
                          background: "#5C6AC4",
                          color: "#fff",
                        }}
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                    </div>
                  );
                }}
              </Formik>
            </Content>
          </Modal>
        )}
      </div>
    );
  }
}
