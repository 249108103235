/* eslint-disable */

import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
} from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllCurrency } from "../../../Graphs/Master/Currency/listCurrency";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { UserOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { deleteCurrency } from "../../../Graphs/Master/Currency/deleteCurrency";
import { changeStatus } from "../../../Graphs/Master/Currency/changeStatus";
import { editCurrency } from "../../../Graphs/Master/Currency/editCurrency";
import { addCurrency } from "../../../Graphs/Master/Currency/addCurrency";
import { searchCurrency } from "../../../Graphs/Master/Currency/searchCurrency";
import { EditModal } from "./EditModal";
import { AddCurrencyModal } from "./AddCurrencyModal";
import { color } from "../../../constant/comman";
import { getAllCountry } from "../../../Graphs/Master/Country/listCountry";
import TableWrap from "../../common/Table";
import { alertError } from "../../../utils/alert";

var moment = require("moment");

const BASE_IMAGE_URL =
  "https://s3.us-east-2.amazonaws.com/cerebrus-consulting/bloodman_user_media/";

class CurrencyRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      userPermission: {
        edit: false,
        delete: false,
      },
      countryData: [],
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedCurrency = this.getLimitedCurrency.bind(this);
  }

  async componentDidMount() {
    const { setRouteName } = this.props;

    let permission = JSON.parse(this.props.loginUserData.access_permission);
    if (permission) {
      this.setState({
        userPermission: {
          edit:
            (permission && permission["master"] && permission["master"].edit) ||
            false,
          delete:
            (permission &&
              permission["master"] &&
              permission["master"].delete) ||
            false,
        },
      });
    }

    setRouteName("CURRENCY");
    this.setState({
      isLoading: true,
      categorySelectedData: null,
    });
    await this.listCurrencyData(0, 10);
    await this.listCountryData();
  }

  modalRef;

  async getLimitedCurrency(pagination) {
    const { searchText } = this.props;
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText != "") {
          //get search text props from redux store
          this.listSearchCurrencyData(start, end);
        } else {
          //call normal user Data
          this.listCurrencyData(start, end);
        }
      }
    );
  }

  listCountryData = async () => {
    try {
      const { authToken } = this.props;
      this.setState({
        isLoading: true,
      });

      let countryData = await getAllCountry(authToken, 0, 100000);

      if (countryData.status == 200) {
        this.setState({
          isLoading: false,
          countryData: countryData.data,
        });
      } else {
        throw countryData && countryData.message
          ? countryData.message
          : "Error";
      }
    } catch (e) {
      alertError("COURRENCY", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listSearchCurrencyData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCurrencyData,
        setListCurrencyDataTotal,
        searchText,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let categoryData = await searchCurrency(
        authToken,
        searchText,
        start,
        end
      );

      if (categoryData.status == 200) {
        //set user Data
        await setListCurrencyData(categoryData.data);
        await setListCurrencyDataTotal(categoryData.total);

        this.setState({
          isLoading: false,
          data: categoryData.data,
        });
      } else if (categoryData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw categoryData && categoryData.message
          ? categoryData.message
          : "Error";
      }
    } catch (e) {
      alertError("CURRENCY", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listCurrencyData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCurrencyData,
        setListCurrencyDataTotal,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let categoryData = await getAllCurrency(start, end);

      if (categoryData.status == 200) {
        //set user Data
        console.log("!!!!!!!categoryData", categoryData);
        await setListCurrencyData(categoryData.data);
        await setListCurrencyDataTotal(categoryData.total);

        this.setState({
          isLoading: false,
          data: categoryData.data,
        });
      } else if (categoryData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw categoryData && categoryData.message
          ? categoryData.message
          : "Error";
      }
    } catch (e) {
      alertError("CURRENCY", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.add(2, "hours").format("Do MMM YYYY");

    return newDate;
  }

  showModal(item) {
    this.setState(
      {
        categorySelectedData: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  handleDetailsModalclose() {
    this.setState({
      categorySelectedData: null,
    });
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "name",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                  <div>{item.name ? item.name : ""}</div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Short Name",
      key: "short_name",
      dataIndex: "short_name",
      render: (text, item, index) => {
        return <div>{item.short_name ? item.short_name : "-"}</div>;
      },
    },
    {
      title: "Convertion Rate",
      key: "conversion_rate",
      dataIndex: "conversion_rate",
      render: (text, item, index) => {
        return <div>{item.conversion_rate ? item.conversion_rate : "-"}</div>;
      },
    },
    {
      title: "Country",
      key: "country",
      dataIndex: "country",
      render: (text, item, index) => {
        return (
          <div>
            {item.country && item.country.name ? item.country.name : "-"}
          </div>
        );
      },
    },
    {
      title: "Active Status",
      key: "isActive",
      render: (text, item, index) => {
        return (
          <div>
            {item.is_active != null ? (
              <Switch
                checked={item.is_active}
                style={{
                  backgroundColor: item.is_active
                    ? this.props.primaryColor
                    : "#bababa",
                }}
                onChange={this.onChange.bind(this, item)}
              />
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 200,
      key: "action",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            {this.props.loginUserData.role == "Admin" ||
            this.state.userPermission.edit ? (
              <div mr="5px" style={{ float: "left" }}>
                <Tooltip placement="bottom" title="Edit">
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      console.log("isOpen value --- ", this.state.isOpen);
                      this.showModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}

            {this.props.loginUserData.role == "Admin" ||
            this.state.userPermission.delete ? (
              <div
                ml="15px"
                mr="5px"
                style={{ float: "left", marginLeft: "10px" }}
              >
                <Tooltip placement="bottom" title="Delete">
                  <Popconfirm
                    onConfirm={this.deleteCurrency.bind(this, item)}
                    title="Are you sure you want to delete currency?"
                  >
                    <Button shape="circle" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];

  async deleteCurrency(item) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
        setListCurrencyData,
        setListCurrencyDataTotal,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let deleteData = await deleteCurrency(
        authToken,
        item.id,
        startIndex,
        limitNumber,
        searchText
      );

      if (deleteData.status == 200) {
        notification["success"]({
          message: "Delete Currency",
          description: "Successfully deleted",
        });
        await setListCurrencyData(deleteData.data);
        await setListCurrencyDataTotal(deleteData.total);
        //set user Data
        this.setState({
          isLoading: false,
          data: deleteData.data,
        });
      } else if (deleteData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw deleteData && deleteData.message ? deleteData.message : "Error";
      }
    } catch (e) {
      alertError("CURRENCY", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onChange(item, checked) {
    try {
      console.log("!!!!!!!!", checked);

      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeStatus(authToken, item.id, checked);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Change Currency Status",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText != "") {
          this.listSearchCurrencyData(startIndex, limitNumber);
          return;
        }
        this.listCurrencyData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (err) {
      alertError("CURRENCY", err);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  addSubmit = async (categoryObject) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      let statusData = await addCurrency(authToken, categoryObject);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Add Currency",
          description: "Successfully added",
        });
        if (searchText != null && searchText != "") {
          this.listSearchCurrencyData(startIndex, limitNumber);
          return;
        }
        this.listCurrencyData(startIndex, limitNumber);
        return 1;
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
        return -1;
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      alertError("CURRENCY", e);
      return -1;
    }
  };

  editSubmit = async (categoryObject, categoryId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await editCurrency(
        authToken,
        categoryObject,
        categoryId
      );
      if (statusData.status == 200) {
        notification["success"]({
          message: "Edit Currency",
          description: "Successfully edited",
        });
        this.setState({
          isLoading: false,
        });
        if (searchText != null && searchText != "") {
          this.listSearchCurrencyData(startIndex, limitNumber);
          return;
        }
        this.listCurrencyData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      this.setState({
        isLoading: false,
      });
      alertError("CURRENCY", e);
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedCurrency(pagination)
    );
  }

  render() {
    const { isLoading, countryData } = this.state;
    const {
      listCurrencyData,
      listCurrencyDataTotal,
      searchLoader,
      accessPermission,
      loginUserData,
    } = this.props;

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ display: "inline-block", width: "100%" }}>
        <div style={{ float: "left", cursor: "pointer", width: "100%" }}>
          <AddCurrencyModal
            countryData={countryData}
            onSubmit={this.addSubmit}
            primaryColor={this.props.primaryColor}
          />
        </div>
        <div
          style={{
            overflowX: "auto",
            float: "left",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <TableWrap
            data={listCurrencyData}
            columns={this.columns}
            isLoading={this.state.isLoading}
            total={listCurrencyDataTotal}
            pagination={this.state.pagination}
            handleTableChange={this.handleTableChange}
          />

          {this.state.categorySelectedData ? (
            <EditModal
              countryData={countryData}
              admin={false}
              refx={(e) => (this.modalRef = e)}
              onClose={this.handleDetailsModalclose.bind(this)}
              categorySelectedData={this.state.categorySelectedData}
              onSubmit={this.editSubmit}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listCurrencyData: state.master.listCurrencyData,
  listCurrencyDataTotal: state.master.listCurrencyDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCurrencyData: (listCurrencyData) => {
      dispatch({
        type: "SET_LIST_CURRENCY_DATA",
        listCurrencyData: listCurrencyData,
      });
    },
    setListCurrencyDataTotal: (listCurrencyDataTotal) => {
      dispatch({
        type: "SET_LIST_CURRENCY_DATA_TOTAL",
        listCurrencyDataTotal: listCurrencyDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CurrencyRecordsTable));
