import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, TimePicker, Select } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { WEBURL, color } from "../../../constant/comman";
import { PictureOutlined } from "@ant-design/icons";
import { AddCategoryIcon } from "./AddCategoryIcon";
import * as la from "lodash";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class AddCategoryModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDetailsModalclose = this.handleDetailsModalclose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      locationList: [],
      recordSelectedtoView: null,
      data: [],
      view: false,
      index: null,
      isLoading: false,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: this.props.type == "category" ? false : true,
      inputVisible: false,
      inputValue: "",
      time: "",
      closeTime: "",
      closeTimeString: "",
      timeString: "",
      errors: {
        uploadImage: "",
      },
      roleType: "1",
      isShouldComponentUpdate: false,
    };
  }

  input;
  allColorType = [];
  selectedColorType = "";
  selctedColorKey = "";
  src = "";
  viewModalRef;

  componentDidMount() {
    la.map(color, (data, index) => {
      this.allColorType.push(
        <Option key={index} value={data.value}>
          <div style={{ display: "inline-block", width: "100%" }}>
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: data.value,
                float: "left",
                borderRadius: "5px",
              }}
            ></div>
            <span
              style={{
                float: "left",
                margin: "0px",
                padding: "0px",
                marginLeft: "10px",
                marginTop: "5px",
                lineHeight: "0px",
              }}
            >
              {data.name}
            </span>
          </div>
        </Option>
      );
    });
    console.log("!!!!!!!this.allColorType", this.allColorType);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isShouldComponentUpdate } = nextState;
    console.log("!!!!!!!!!!!!!update 1", nextState);
    if (!isShouldComponentUpdate) {
      this.selectedColorType = "";
      // this.src = undefined;
    }

    return true;
  }

  uploadImage = (name) => {
    console.log("!!!!!!!!!!!!!!after upload ", name);
    if (name != null) {
      this.src = name;
      this.setState({
        view: false,
      });
    } else {
      this.setState({
        view: false,
      });
    }
  };

  showViewModal(params) {
    this.setState(
      {
        view: true,
      },
      () => {
        this.viewModalRef.show();
      }
    );
  }

  handleSelectColortype(value, option) {
    this.selectedColorType = value;
    this.selctedColorKey = option.key;
  }

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  validateForm = (values) => {
    const errors = {};
    console.log("Validating value -- ", values);

    if (!values.name) {
      errors.name = "Name is required";
    }
    // if (!values.description) {
    //     errors.description = "Description is required";
    // }
    if (this.selectedColorType === "") {
      errors.color = "Color is required";
    }
    if (!(values.sort_order > 0)) {
      errors.sort_order = "Sort order must be greater than 0";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  };

  handleSubmit = (values, action) => {
    const { errors } = this.state;
    let listErrors = { ...errors };

    // if(!this.src || (this.src && this.src === "")){
    //     listErrors.uploadImage = "Image is Required";
    //     this.setState({
    //         errors : listErrors
    //     });
    //     return;
    // }

    // if(this.src){
    //     listErrors.uploadImage = "";

    //     this.setState({
    //       errors : listErrors
    //     });

    // }
    values.color = this.selctedColorKey;

    if (this.src && this.src === "") {
      values.icon = null;
    } else {
      values.icon = this.src;
    }

    console.log("Basic Value - ", values);
    values.description = values.description ? values.description : null;
    this.props.onSubmit(values);

    this.setState({
      visible: false,
      isShouldComponentUpdate: false,
    });
  };

  showModal = () => {
    console.log("!!!!!!visible");
    this.setState({
      visible: true,
      isShouldComponentUpdate: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
      isShouldComponentUpdate: false,
    });
    if (this.props.type == "subCategory") {
      this.props.onCancel();
    }
  };

  handleTime(e, s) {
    this.setState({
      time: e,
      timeString: s,
    });
  }

  handleCloseTime(e, s) {
    this.setState({
      closeTime: e,
      closeTimeString: s,
    });
  }

  handleDetailsModalclose(item) {
    this.setState({
      view: false,
      lat: item.lat,
      long: item.lng,
    });
  }

  render() {
    const { primaryColor } = this.props;
    console.log("!!!!!!!!!!!!!this.src", this.src);

    return (
      <div>
        {this.props.type == "category" && (
          <div
            onClick={this.showModal}
            style={{
              cursor: "pointer",
              width: "100px",
              backgroundColor: primaryColor,
              color: "white",
              padding: "7px 0px",
              textAlign: "center",
              borderRadius: "50px",
            }}
          >
            ADD
          </div>
        )}
        {this.state.visible && (
          <Modal
            destroyOnClose={true}
            title={
              this.props.type == "subCategory"
                ? "Add Sub Category"
                : "Add Category"
            }
            open={this.state.visible}
            onCancel={this.handleCancel}
            footer={null}
          >
            <Content style={{ background: "#fff", marginLeft: "10px" }}>
              <Formik
                initialValues={{
                  name: "",
                  arabicName: "",
                  description: "",
                  color: undefined,
                  sort_order: 1,
                  advertisement: "",
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }) => {
                  return (
                    <div>
                      <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          Name
                          <Input
                            id="name"
                            placeholder="Category Name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.name && touched.name ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.name}
                            </p>
                          ) : null}
                        </div>
                        <div className="formik-field-right">
                          Arabic Name
                          <Input
                            id="arabicName"
                            name="arabicName"
                            placeholder="Arabic Name"
                            value={values.arabicName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>

                      <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          Description
                          <Input
                            id="description"
                            placeholder="Category Description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.description && touched.description ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.description}
                            </p>
                          ) : null}
                        </div>
                        <div className="formik-field-right">
                          Sort Order
                          <Input
                            id="sort_order"
                            placeholder="Sort Order"
                            type="number"
                            min={0}
                            value={values.sort_order}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.sort_order && touched.sort_order ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.sort_order}
                            </p>
                          ) : null}
                        </div>

                      </div>
                      <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          Color
                          {this.allColorType && (
                            <Field
                              name="color"
                              render={({ field }) => (
                                <Select
                                  showSearch
                                  style={{ width: "100%" }}
                                  placeholder="Choose Color"
                                  {...field}
                                  onChange={(value) => {
                                    setFieldValue("color", value);
                                  }}
                                  onBlur={() => setFieldTouched("color", true)}
                                  onSelect={(value, option) =>
                                    this.handleSelectColortype(value, option)
                                  }
                                >
                                  {console.log(
                                    "{this.allColorType}",
                                    this.allColorType.length
                                  )}
                                  {this.allColorType}
                                </Select>
                              )}
                            />
                          )}
                          {errors.color && touched.color ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.color}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div style={{
                        display: "inline-block",
                        width: "100%",
                        marginTop: "20px",
                        alignItems: "center",
                      }}>
                        Advertisement
                        <TextArea
                          name="advertisement"
                          rows={3}
                          value={values.advertisement}
                          placeholder="Enter Advertisement"
                          maxLength={128}
                          onChange={handleChange}
                        />
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          width: "100%",
                          marginTop: "20px",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ float: "left", width: "10%" }}>
                          <PictureOutlined
                            style={{ width: "100%", fontSize: "30px" }}
                          />
                        </div>
                        <Button
                          style={{ width: "90%" }}
                          onClick={this.showViewModal.bind(this)} //this.showViewModal.bind(this)
                        >
                          Choose Category Icon
                        </Button>
                        {this.src ? (
                          <p
                            style={{
                              fontSize: "small",
                              margin: "0",
                              marginLeft: "5px",
                            }}
                          >
                            {this.src}
                          </p>
                        ) : null}
                        {this.state.view ? (
                          <AddCategoryIcon
                            refx={(e) => (this.viewModalRef = e)}
                            onUpload={this.uploadImage}
                          />
                        ) : null}
                      </div>
                      <div width="100%" style={{ marginTop: "10px" }}>
                        {this.state.errors.uploadImage != "" ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.uploadImage}
                          </p>
                        ) : null}
                      </div>
                      <div
                        style={{
                          marhinTop: "20px",
                          width: "100px",
                          backgroundColor:
                            this.selectedColorType !== ""
                              ? this.selectedColorType
                              : "#ffffff",
                          borderRadius: "10px",
                        }}
                      >
                        {this.src && this.src !== "" ? (
                          <img
                            src={`${WEBURL}category/images/${this.src}`}
                            alt="CategoryIcon"
                            style={{ width: "100px" }}
                          />
                        ) : null}
                      </div>

                      <Button
                        // type="primary"
                        style={{
                          marginTop: "19px",
                          background: "#5C6AC4",
                          color: "#fff",
                        }}
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                    </div>
                  );
                }}
              </Formik>
            </Content>
          </Modal>
        )}
      </div>
    );
  }
}
