import React, { Fragment, Component } from "react";
import { Upload, Modal, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { WEBURL } from "../../constant/comman";
import * as la from "lodash";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export class AddAdvertisementImage extends Component {
  state = {
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [],
  };

  componentDidMount() {
    console.log(
      "!!!!!!!!!testadvertise advertisementImageArray",
      this.props.advertisementImageArray
    );
    let dummyarray = [];
    la.map(this.props.advertisementImageArray, (data, index) => {
      dummyarray.push({
        uid: index,
        name: data,
        status: "done",
        url: `${WEBURL}advertise/images/${data}`,
      });
    });
    this.setState({
      fileList: dummyarray,
    });
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  handleChange = ({ fileList }) => this.setState({ fileList });

  createAdvertisementData = () => {
    const { fileList } = this.state;
    let dummySendArray = [];
    console.log("!!!!!!!!testadvertise fileList", fileList);

    la.map(fileList, (data, index) => {
      dummySendArray.push(data.response ? data.response.file : data.name);
    });
    this.props.createAdvertisementData(dummySendArray);
  };

  render() {
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <div className="clearfix">
        <Upload
          action={`${WEBURL}advertise/upload/file`}
          listType="picture-card"
          accept=".png,.jpeg,.jpg"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Modal
          open={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
        <div className="form-field">
          <Button
            type="primary"
            style={{ marginTop: "19px", background: "#5C6AC4", color: "#fff" }}
            onClick={() => this.createAdvertisementData()}
          >
            Upload
          </Button>
        </div>
      </div>
    );
  }
}
