import {
  Modal,
  Button,
  Divider,
  Switch,
  Checkbox,
  Row,
  Col,
  notification,
  Spin,
} from "antd";
import { PictureOutlined } from "@ant-design/icons";
import * as React from "react";
import { Layout, Input, InputNumber, TimePicker, Select } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { checkUnique } from "../../Graphs/Company/searchCompany";
import { AddMedia } from "./AddMedia";
import { WEBURL } from "../../constant/comman";
import { getAllCoin } from "../../Graphs/Master/Coin/listCoin";
import { alertError } from "../../utils/alert";
import { passwordRegx } from "../../utils/regx";
import {
  emailValidation,
  mobileLengthValidation,
  mobileValidation,
  passwordValidation,
} from "../../utils/validations";
import { errorMessages } from "../../utils/validationMessages";
import { StyleSelect } from "../../assets/css/style";
import { values } from "lodash";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class AddCompanyForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.handleCloseTime = this.handleCloseTime.bind(this);
    this.handleDetailsModalclose = this.handleDetailsModalclose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      locationList: [],
      recordSelectedtoView: null,
      data: [],
      view: false,
      index: null,
      isLoading: false,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: false,
      inputVisible: false,
      inputValue: "",
      time: "",
      closeTime: "",
      closeTimeString: "",
      timeString: "",
      roleType: "1",
      selectedCoin: "",
      price: 0,
      errors: {},
      coinsList: [],
      dashboard: {
        view: false,
        edit: false,
        delete: false,
      },
      master: {
        view: false,
        edit: false,
        delete: false,
      },
      card: {
        view: false,
        edit: false,
        delete: false,
      },
      user: {
        view: false,
        edit: false,
        delete: false,
      },
      admin: false,
    };
  }

  input;
  viewModalRef;
  srcMedia = "";
  allCoinsList = [];
  selectedCoin = "";

  componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }
    this.getCoinsList();
  }

  getCoinsList = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      let coinData = await getAllCoin(this.props.authToken, 0, 100000);
      if (coinData.status == 200) {
        this.allCoinsList = coinData.data;
      }
    } catch (error) {
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  validateEmail = async (field, value) => {
    try {
      this.setState({
        isLoading: true,
      });

      let getEmployee = await checkUnique(this.props.authToken, value);
      console.log("!!!!!!!!!!!!!!!getEmployee", getEmployee);
      this.setState({
        isLoading: false,
      });

      if (getEmployee.status == 200) {
        console.log("!!!!!!!!!!!!!!!!!!!!", getEmployee);
        if (getEmployee.data == null) {
          console.log("!!!!!!!!!!!!!!!!!!!!tr", getEmployee);
          return true;
        } else {
          console.log("!!!!!!!!!!!!!!!!!!!!false", getEmployee);
          notification["warn"]({
            message: "Unique Validation",
            description: field + " is already in used",
          });
          return false;
        }
      } else {
        return -1;
      }
    } catch (error) {
      this.setState({
        isLoading: false,
      });

      alertError("Company", error);

      return -1;
    }
  };

  validateForm(values) {
    const errors = {};

    if (!values.name) {
      errors.name = "Company name is required";
    }

    if (!values.contact_name) {
      errors.contact_name = "Contact name is required";
    }

    if (!values.phone) {
      errors.phone = errorMessages.mobile_required;
    } else if (mobileLengthValidation(values.phone)) {
      errors.phone = errorMessages.mobile_length_validation;
    } else if (mobileValidation(values.phone)) {
      errors.phone = errorMessages.mobile_validation;
    }

    if (!values.username) {
      errors.username = "Username is required";
    }

    if (!values.email) {
      errors.email = errorMessages.email_required;
    } else if (emailValidation(values.email)) {
      errors.email = errorMessages.email_validation;
    }

    if (!values.password) {
      errors.password = errorMessages.password_required;
    } else if (passwordValidation(values.password)) {
      errors.password = errorMessages.password_validation;
    }

    if (!values.address) {
      errors.address = "Address is required";
    }

    if (values.commission <= 0) {
      errors.commission = "Commission is required";
    }

    if (
      !Array.isArray(values.allowed_coins) ||
      values.allowed_coins.length == 0
    ) {
      errors.allowed_coins = "Coin is required";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  }

  async handleSubmit(values, action) {
    const { errors } = this.state;

    let listErrors = errors;

    let checkEmail = await this.validateEmail("Company", values.name);
    if (checkEmail == false || checkEmail == -1) {
      if (checkEmail == false) {
        listErrors.name = "Company Name is already in used.";
        this.setState({
          errors: listErrors,
        });
        return;
      }
      return;
    }

    checkEmail = await this.validateEmail("Email", values.email);
    if (checkEmail == false || checkEmail == -1) {
      if (checkEmail == false) {
        listErrors.email = "Email is already in used.";
        this.setState({
          errors: listErrors,
        });
        return;
      }
      return;
    }

    checkEmail = await this.validateEmail("Username", values.username);
    if (checkEmail == false || checkEmail == -1) {
      if (checkEmail == false) {
        listErrors.username = "Username is already in used.";
        this.setState({
          errors: listErrors,
        });
        return;
      }
      return;
    }

    checkEmail = await this.validateEmail("Phone", values.phone);
    if (checkEmail == false || checkEmail == -1) {
      if (checkEmail == false) {
        listErrors.phone = "Phone is already in used.";
        this.setState({
          errors: listErrors,
        });
        return;
      }
      return;
    }

    values.media_file = this.srcMedia;
    values.allowed_coins = values.allowed_coins.join(",");

    this.setState({
      errors: {},
    });

    let result = await this.props.onSubmit(values);

    if (result) {
      this.props.handleCancel();

      this.setState({
        visible: false,
      });
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleTime(e, s) {
    this.setState({
      time: e,
      timeString: s,
    });
  }

  handleCloseTime(e, s) {
    this.setState({
      closeTime: e,
      closeTimeString: s,
    });
  }

  handleDetailsModalclose(item) {
    this.setState({
      view: false,
      lat: item.lat,
      long: item.lng,
    });
  }

  onChangeCoin = (value) => {
    this.setState({
      selectedCoin: value,
    });
  };

  onChangeCheckBox = (data, key) => {
    console.log(data);
    let obj = {};

    obj[key] = {
      view: data.includes("view"),
      edit: data.includes("edit"),
      delete: data.includes("delete"),
    };

    console.log("!!!!!!!!checkbox", key, data);

    if (key == "dashboard") {
      this.setState({ defaultDashboard: data });
    } else if (key == "master") {
      console.log("!!!!!!!!master", key, data);
      this.setState({ defaultMaster: data });
    } else if (key == "card") {
      this.setState({ defaultCard: data });
    } else if (key == "user") {
      this.setState({ defaultUser: data });
    }

    this.setState(obj);
  };

  onChangeSwich = (data) => {
    if (data == true) {
      this.setState({
        dashboard: {
          view: true,
          edit: true,
          delete: true,
        },
        master: {
          view: true,
          edit: true,
          delete: true,
        },
        card: {
          view: true,
          edit: true,
          delete: true,
        },
        user: {
          view: true,
          edit: true,
          delete: true,
        },
        defaultDashboard: ["edit", "view", "delete"],
        defaultUser: ["edit", "view", "delete"],
        defaultCard: ["edit", "view", "delete"],
        defaultMaster: ["edit", "view", "delete"],
      });
    } else {
      this.setState({
        dashboard: {
          view: false,
          edit: false,
          delete: false,
        },
        master: {
          view: false,
          edit: false,
          delete: false,
        },
        card: {
          view: false,
          edit: false,
          delete: false,
        },
        user: {
          view: false,
          edit: false,
          delete: false,
        },
        defaultDashboard: [],
        defaultUser: [],
        defaultCard: [],
        defaultMaster: [],
      });
    }
    this.setState({
      admin: data,
    });
  };

  uploadMedia = (name) => {
    if (name != null) {
      const { errors } = this.state;

      let listErrors = { ...errors };
      listErrors.uploadAudio = "";

      this.srcMedia = name;
      this.setState({
        viewMedia: false,
        errors: listErrors,
      });
    } else {
      this.setState({
        viewMedia: false,
      });
    }
  };

  showViewModalMedia(params) {
    this.setState(
      {
        viewMedia: true,
      },
      () => {
        this.viewModalRef.show();
      }
    );
  }

  render() {
    // const {  } = this.state;
    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                name: "",
                arabic_name: "",
                contact_name:"",
                username: "",
                email: "",
                phone: "",
                password: "",
                address: "",
                allowed_coins: undefined,
                commission: 0,
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    {this.state.isLoading ? (
                      <div style={{ justifyContent: "center" }}>
                        <Spin />
                      </div>
                    ) : (
                      <>
                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            Company Name
                            <Input
                              id="name"
                              placeholder="Company Name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.name && touched.name ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.name}
                              </p>
                            ) : null}
                          </div>
                          <div className="formik-field-right">
                            Arabic Name
                            <Input
                              id="arabic_name"
                              placeholder="Arabic Name"
                              value={values.arabic_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            Contact Person Name
                            <Input
                              id="contact_name"
                              placeholder="Contact Person Name"
                              value={values.contact_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.contact_name && touched.contact_name ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.contact_name}
                              </p>
                            ) : null}
                          </div>
                          <div className="formik-field-right">
                            Phone
                            <Input
                              id="phone"
                              placeholder=" Phone"
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.phone && touched.phone ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.phone}
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            Username
                            <Input
                              id="username"
                              placeholder="username"
                              value={values.username}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.username && touched.username ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.username}
                              </p>
                            ) : null}
                            {this.state.errors.username ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {this.state.errors.username}
                              </p>
                            ) : null}
                          </div>

                          <div className="formik-field-right">
                            Email
                            <Input
                              id="email"
                              placeholder="Email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.email && touched.email ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.email}
                              </p>
                            ) : null}
                            {this.state.errors.email ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {this.state.errors.email}
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            Password
                            <Input
                              id="password"
                              type="password"
                              placeholder="User Password"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.password && touched.password ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.password}
                              </p>
                            ) : null}
                          </div>
                          <div className="formik-field-right">
                            Coin
                            {this.selectedAllowCoins}
                            <Field
                              name="allowed_coins"
                              render={({ field }) => (
                                <StyleSelect
                                  mode="multiple"
                                  tokenSeparators={[","]}
                                  showSearch
                                  style={{ width: "100%" }}
                                  placeholder="Choose coins"
                                  {...field}
                                  onChange={(value) => {
                                    setFieldValue("allowed_coins", value);
                                  }}
                                  onBlur={() =>
                                    setFieldTouched("allowed_coins", true)
                                  }
                                >
                                  {this.allCoinsList &&
                                    this.allCoinsList.length &&
                                    this.allCoinsList.map((i) => {
                                      return (
                                        <option value={i.id}> {i.name}</option>
                                      );
                                    })}
                                </StyleSelect>
                              )}
                            />
                            {errors.allowed_coins && touched.allowed_coins ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.allowed_coins}
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            Address
                            <TextArea
                              id="address"
                              placeholder=" address"
                              value={values.address}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.address && touched.address ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.address}
                              </p>
                            ) : null}
                          </div>
                          <div className="formik-field-right">
                            Commission
                            <Input
                              type="number"
                              id="commission"
                              name="commission"
                              value={values.commission}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              min={0}
                            />
                            {/* <InputNumber
                                                id="commission"
                                                name="commission"
                                                placeholder="Commission Name"
                                                value={values.commission}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            /> */}
                            {errors.commission && touched.commission ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.commission}
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <div className="formik-field_wrap">
                          <div style={{ float: "left", width: "10%" }}>
                            <PictureOutlined
                              style={{ width: "100%", fontSize: "30px" }}
                            />
                          </div>
                          <Button
                            style={{ width: "90%" }}
                            onClick={this.showViewModalMedia.bind(this)} //this.showViewModal.bind(this)
                          >
                            Choose Media
                          </Button>
                          {this.state.errors && this.state.errors.srcMedia ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {this.state.errors.srcMedia}
                            </p>
                          ) : null}
                          {this.srcMedia ? (
                            <React.Fragment>
                              <p></p>
                              <a
                                target="_blank"
                                style={{ width: "100%" }}
                                controls
                                href={WEBURL + "company/" + this.srcMedia}
                              >
                                Click To View
                              </a>
                            </React.Fragment>
                          ) : null}
                        </div>

                        <Button
                          // type="primary"
                          style={{
                            marginTop: "19px",
                            background: "#5C6AC4",
                            color: "#fff",
                          }}
                          onClick={handleSubmit}
                        >
                          Save
                        </Button>

                        {this.state.viewMedia ? (
                          <AddMedia
                            refx={(e) => (this.viewModalRef = e)}
                            onUpload={this.uploadMedia}
                          />
                        ) : null}
                      </>
                    )}
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
