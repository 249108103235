import { Button, Input, Spin } from "antd";
import { Formik } from "formik";
import React, { Component } from "react";
import { alertError } from "../../utils/alert";
import { errorMessages } from "../../utils/validationMessages";
import ModalWrap from "../common/modal";
import { getSettingDataAPI } from "../../Graphs/Setting/getSettingData";
import { StyleSelect } from "../../assets/css/style";
import { getMintAPICardCategory } from "../../Graphs/Card/getMintCardCategories.js";
import { getMintAPICardBrands } from "../../Graphs/Card/getMintCardBrands.js";
import { getMintAPICard } from "../../Graphs/Card/getMintCards.js";

export default class MintCardModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      allThirdPartyCategoryOptions: [],
      thirdPartySubCategoryOptions: [],
      selectedThirdPartyCategoryId: null,
      selectedThirdPartySubCategoryId: null,
      cardData: [],
      sigleCardDetail: null,
      disableSubCategory: false,
      apiCardPrice: 0,
      settingData: {},
      // apiCardSellPrice: 0,
    };
  }

  async componentDidMount() {
    this.getThirdPartyCardDetail();
    this.getSettingData();
  }


  validateForm = (values) => {
    const errors = {};
    if (!values.mintCategoryId) {
      errors.mintCategoryId = errorMessages.thirdPartyCategory_required;
    }
    if (this.state.thirdPartySubCategoryOptions.length > 0) {
      if (!values.mintBrandId) {
        errors.mintBrandId =
          errorMessages.thirdPartySubCategory_required;
      }
    }

    if (!values.mintCardEan) {
      errors.mintCardEan = errorMessages.card_required;
    }
    if (!values.mintCardAmount) {
      errors.mintCardAmount = errorMessages.amount_required;
    }
    return errors;
  };

  getSettingData = async () => {
    try {
      const { authToken } = this.props;
      let getSettingData = await getSettingDataAPI(authToken);

      if (getSettingData.status === 200) {
        this.setState({
          settingData: getSettingData.data,
        });
      } else {
        throw getSettingData.message ? getSettingData.message : "Error";
      }
    } catch (e) {
      alertError("CARD", e);
    }
  };


  getThirdPartyCardDetail = async () => {
    try {
      const { authToken } = this.props;

      this.setState({
        isLoading: true,
      });

      let allthirdPartyData = await getMintAPICardCategory(authToken);

      if (allthirdPartyData) {
        this.setState({
          isLoading: false,
          allThirdPartyCategoryOptions: allthirdPartyData.data.map((i) => ({
            ...i,
            label: i.id,
            value: i.id,
          })),
        });
      } else {
        throw allthirdPartyData ? allthirdPartyData.message : "Error";
      }
    } catch (e) {
      alertError("Third party card", e);
      this.setState({
        isLoading: false,
      });
    }
  };

  submitThirdPartyCategory = async (value) => {
    try {
      const { authToken } = this.props;

      let thirdPartySubCategoryData = await getMintAPICardBrands(authToken, value);
      if (thirdPartySubCategoryData.success) {
        this.setState({
          thirdPartySubCategoryOptions: thirdPartySubCategoryData.data.map((i) => ({
            id: i.brand_id,
            label: i.brand_name,
            value: i.brand_id,
          })),
        });
      } else {
        throw thirdPartySubCategoryData ? thirdPartySubCategoryData.message : "Error";
      }
    } catch (e) {
      alertError("Third party card", e);
    }
  }

  submitThirdPartySubCategory = async (value) => {
    if (value && this.state.selectedThirdPartyCategoryId) {
      try {
        const { authToken } = this.props;

        let mintCardName = await getMintAPICard(authToken, this.state.selectedThirdPartyCategoryId, value);
        if (mintCardName.success) {
          this.setState({
            cardData: mintCardName.data.map((i) => ({
              id: i.denomination_id,
              label: i.denomination_name,
              value: i.ean,
              price: i.denomination_contract_price,
            })),
          });
        } else {
          throw mintCardName ? mintCardName.message : "Error";
        }
      } catch (e) {
        alertError("Third party card", e);
      }
    }
  }


  handleSubmit = (values) => {
    if (values) {
      const getCardData = (ean) => {
        if (this.state.cardData?.length) {
          const foundCard = this.state.cardData.find((card) => card.value === (ean?.value || ean));
          return foundCard ? foundCard : ean;
        }
        return ean;
      };

      const getBrandData = (brandId) => {
        if (this.state.thirdPartySubCategoryOptions?.length) {
          const foundBrand = this.state.thirdPartySubCategoryOptions.find((brandData) => brandData.value === (brandId?.value || brandId));
          return foundBrand ? foundBrand : brandId;
        }
        return brandId;
      };

      const savedCard = {
        mintCardAmount: values.mintCardAmount,
        mintCardEan: getCardData(values.mintCardEan),
        mintCategoryId: values.mintCategoryId,
        mintBrandId: getBrandData(values.mintBrandId),
      };
      this.props.onSubmit(savedCard);
      this.props.onClose();
    }
  };

  render() {
    const {
      isLoading,
      allThirdPartyCategoryOptions,
      thirdPartySubCategoryOptions,
      cardData,
      apiCardPrice,
      // apiCardSellPrice,
      sigleCardDetail,
    } = this.state;

    const { mintCardData } = this.props;

    return (
      <div>
        <ModalWrap
          visible={this.props.isVisible}
          closable={this.props.onClose}
          title={mintCardData ? "Edit Mint Card " : "Add Mint card"}
          onOk={this.props.onClose}
          onCancel={this.props.onClose}
          isLoading={isLoading}
        >
          <Formik
            onSubmit={this.handleSubmit}
            initialValues={{
              mintCategoryId: mintCardData?.mintCategoryId ?? "",
              mintBrandId: mintCardData?.mintBrandId ?? "",
              mintCardEan: mintCardData?.mintCardEan ?? "",
              mintCardAmount: mintCardData?.mintCardAmount ?? "",
            }}
            validate={this.validateForm}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
            }) => {
              return isLoading ? (
                <div style={{ marginLeft: "20px" }}>
                  <Spin
                    size="large"
                    style={{ width: "100%", margin: "0 auto" }}
                  />
                </div>
              ) : (
                <div>
                  <div className="formik-field_wrap mt-5">
                    <div className="formik-field-left">
                      Third Party Category
                      <StyleSelect
                        value={values.mintCategoryId}
                        style={{ width: 200 }}
                        id="mintCategoryId"
                        name="mintCategoryId"
                        placeholder="Category Name"
                        onChange={(value) => {
                          setFieldValue("mintCategoryId", value);
                          this.submitThirdPartyCategory(value);
                          this.setState({
                            selectedThirdPartyCategoryId: value,
                            thirdPartySubCategoryOptions: [],
                            cardData: [],
                          });
                          setFieldValue("mintBrandId", "");
                          setFieldValue("mintCardEan", "");
                          setFieldTouched("mintCategoryId", value)
                        }}
                        onBlur={() =>
                          setFieldTouched("mintCategoryId", true)
                        }
                        onSelect={(value) => {
                          this.submitThirdPartyCategory(value);
                          this.setState({ selectedThirdPartyCategoryId: value });
                        }}
                        options={allThirdPartyCategoryOptions}
                      ></StyleSelect>
                      {errors.mintCategoryId && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.mintCategoryId}
                        </p>
                      )}
                    </div>
                    <div className="formik-field-right">
                      Third Party Sub Category
                      <StyleSelect
                        value={values.mintBrandId}
                        style={{ width: 200 }}
                        id="mintBrandId"
                        name="mintBrandId"
                        placeholder="Sub Category Name"
                        disabled={this.state.disableSubCategory}
                        onChange={(value) => {
                          setFieldValue("mintBrandId", value);
                        }}
                        onBlur={() =>
                          setFieldTouched("mintBrandId", true)
                        }
                        onSelect={(value) => {
                          this.submitThirdPartySubCategory(value);
                          this.setState({ selectedThirdPartySubCategoryId: value });
                        }}
                        options={thirdPartySubCategoryOptions}
                      ></StyleSelect>
                      {errors.mintBrandId && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.mintBrandId}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="formik-field_wrap">

                    <div className="formik-field-left">
                      Name
                      <StyleSelect
                        value={values.mintCardEan}
                        options={this.state.cardData}
                        style={{ width: 200 }}
                        onChange={(value) => {
                          setFieldValue("mintCardEan", value);
                        }}
                        id="mintCardEan"
                        name="mintCardEan"
                        placeholder="Card Name"
                        onSelect={(value) => {
                          const card = cardData.find((card) => card.value === value);
                          this.setState({
                            apiCardPrice: card.price,
                          });
                        }}
                      ></StyleSelect>

                      {errors.mintCardEan && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.mintCardEan}
                        </p>
                      )}
                    </div>
                    <div className="formik-field-right">
                      Price
                      <Input
                        id="mintCardAmount"
                        name="mintCardAmount"
                        style={{ width: 200 }}
                        type="number"
                        min={0}
                        value={values.mintCardAmount}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.mintCardAmount && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.mintCardAmount}
                        </p>
                      )}
                    </div>
                  </div>


                  {apiCardPrice != 0 && (
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Api card price : {apiCardPrice}
                      </div>
                      <div className="formik-field-right">
                        Total Price :{" "}
                        {(
                          this.state.settingData?.exchange_rate *
                          values.mintCardAmount
                        ).toFixed(2)}
                      </div>
                    </div>
                  )}

                  <Button
                    style={{
                      marginTop: "19px",
                      background: "#5C6AC4",
                      color: "#fff",
                    }}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              );
            }}
          </Formik>
        </ModalWrap>
      </div>
    );
  }
}
