import { callApiToServer } from "../callApi";

export async function editAdminSupport(authtoken, adminObject) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      commission: adminObject.commission,
      support_email: adminObject.support_email,
      support_call_number: adminObject.support_call_number,
      support_chat_number: adminObject.support_chat_number,
      terms: adminObject.terms,
      privacy: adminObject.privacy,
      exchange_rate: parseFloat(adminObject.exchange_rate),
      cost_exchange_rate: parseFloat(adminObject.cost_exchange_rate),
    });

    let endUrl = "v1/auth/edit-admin-support";
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
