import { Button, Divider, notification } from "antd";
import * as React from "react";
import { Layout, Input, Select } from "antd";
import { Formik } from "formik";
import styled from "styled-components";
import {pinValidation } from "../../utils/validations";
import { errorMessages } from "../../utils/validationMessages";
import { updatePin } from "../../Graphs/walletRequest/updateWalletPin";

const { Content } = Layout;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class PinUpdateForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDetailsModalclose = this.handleDetailsModalclose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      data: [],
      view: false,
      item: null,
      visible: false,
    };
  }

  input;

  componentDidMount() {}

  handleChange(value) {
    amenities = value;
  }

  validateForm(values) {
    const errors = {};

    if (!values.auth_pin) {
      errors.auth_pin = errorMessages.pin_required;
    } else if (pinValidation(values.auth_pin)) {
      errors.auth_pin = errorMessages.pin_validation;
    }

    return errors;
  }

  handleSubmit = async (values) => {
    try {
      const body = {
        user_id: this.props.data.id,
        new_pin: values.auth_pin,
      };
      const result = await updatePin(body, this.props.authToken);
      if (result.status === 200) {
        this.props.handleCancel();
        this.setState({
            visible: false,
        });
        notification["success"]({
            message: "Pin Updated",
            description: "Successfully Updated",
          });
      }
    } catch (error) {
        notification["error"]({
            message: "Error",
            description: "Something went wrong",
        })
    }
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleDetailsModalclose(item) {
    this.setState({
      view: false,
      lat: item.lat,
      long: item.lng,
    });
  }

  render() {
    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                auth_pin: "",
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-wrap">
                        Update Pin
                        <Input
                          id="auth_pin"
                          placeholder="Enter Pin"
                          value={values.auth_pin}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          maxLength={4}
                        />
                        {errors.auth_pin && touched.auth_pin ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.auth_pin}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <Button
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Update
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
