import { callApiToServer } from '../callApi';

export async function removeUserCoin(
  authtoken,
  coinObject,
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    };
    
    let body = JSON.stringify({
        user_id: coinObject.user_id,
        coin_id: coinObject.coin_id,
        price: coinObject.price
    });
  
    let endUrl = "v1/auth/remove-user-coin";
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callApiToServer(body, header, "POST", endUrl);
      
    return responseData;
      
  }catch(err) {
    throw err;
  }
}
