import * as React from "react";
import { Modal, Spin, notification, Button, Select } from "antd";
import { Field, Form, Formik } from "formik";
import { getDistributorList } from "../../Graphs/distributor/getDistributorList";
import { assignUserToDistributor } from "../../Graphs/distributor/assignUserToDistributor";
import { alertError } from "../../utils/alert";
import { StyleSelect } from "../../assets/css/style";

export class AssignDistributorModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isLoading: false,
      closable: true,
      selectedDistributorValue: "",
      distributorData: [],
    };
  }

  async componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }

    let page = 0;
    let limit = 100000;
    let city = this.props.loginUserData.role == "Sub_admin" ? this.props.loginUserData.city : "";
    let distributorList = await getDistributorList(
      this.props.authToken,
      page,
      limit,
      city
    );
    if (distributorList.status == 200) {
      this.setState({
        distributorData: distributorList.data.map((i) => ({
          ...i,
          label: i.first_name + " " + i.last_name,
          value: i.first_name + " " + i.last_name,
        })),
      });
    }
  }

  handleSubmit = async (value) => {
    try {
      let body = {
        user_id: this.props.SelectedData.id,
        distributor_id: this.state.selectedDistributorValue.id,
      };
      let response = await assignUserToDistributor(this.props.authToken, body);
      if (response.status == 200) {
        notification["success"]({
          message: response.message,
        });
        this.setState = {
          visible: false,
        };
        this.props.onClose();
      } else {
        throw response.message ? response.message : "Error";
      }
    } catch (error) {
      alertError("User", error);
    }
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show() {
    this.setState({
      visible: true,
    });
  }

  hide() {
    this.setState({
      visible: false,
    });
    this.props.onClose();
  }

  handleSelectDistributor(value, option) {
    this.setState({
      selectedDistributorValue: option,
    });
  }

  render() {
    const { SelectedData } = this.props;

    return (
      <Modal
        width="600px"
        closable={this.state.closable}
        open={this.state.visible}
        title="Assign Distributor"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        {this.state.isLoading ? (
          <div style={{ justifyContent: "center" }}>
            <Spin />
          </div>
        ) : (
          <Formik
            initialValues={{
              select_distributor: SelectedData.distributor
                ? SelectedData.distributor.first_name +
                  " " +
                  SelectedData.distributor.last_name
                : "",
            }}
            onSubmit={this.handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldTouched,
              setFieldValue,
            }) => {
              return (
                <Form>
                  {
                    <>
                      <div className="formik-field_wrap">
                        <div className="formik-field-wrap">
                          Selector Distributor
                          <Field
                            name="selectDistributor"
                            render={({ field }) => (
                              <StyleSelect
                                style={{ width: "100%" }}
                                placeholder="Select Distributor"
                                value={values.select_distributor}
                                defaultValue={values.select_distributor}
                                onChange={(value) => {
                                  setFieldValue("selectDistributor", value);
                                }}
                                onBlur={() =>
                                  setFieldTouched("selectDistributor", true)
                                }
                                onSelect={(value, option) =>
                                  this.handleSelectDistributor(value, option)
                                }
                                options={this.state.distributorData}
                                {...field}
                              ></StyleSelect>
                            )}
                          />
                        </div>
                      </div>
                      <Button
                        style={{
                          marginTop: "19px",
                          background: "#5C6AC4",
                          color: "#fff",
                        }}
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                    </>
                  }
                </Form>
              );
            }}
          </Formik>
        )}
      </Modal>
    );
  }
}
