import * as React from "react";
import { notification, Button, Select, Input } from "antd";
import { Form, Formik } from "formik";
import ModalWrap from "../common/modal";
import { updatePassword } from "../../Graphs/sales/UpdatePassword";
import { alertError } from "../../utils/alert";
import { passwordValidation } from "../../utils/validations";
import { errorMessages } from "../../utils/validationMessages";

export class UpdatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = async (value) => {
    try {
      let response = await updatePassword(
        this.props.authToken,
        this.props.data.id,
        value
      );
      if (response.status == 200) {
        notification["success"]({
          message: response.message,
        });
        this.props.onClose();
      } else {
        throw response.message ? response.message : "Error";
      }
    } catch (error) {
      alertError("PASSWORD", error);
    }
  };

  validateForm(values) {
    const errors = {};

    if (!values.password) {
      errors.password = errorMessages.password_required;
    } else if (passwordValidation(values.password)) {
      errors.password = errorMessages.password_validation;
    }

    return errors;
  }

  render() {
    return (
      <ModalWrap
        visible={this.props.visible}
        closable={this.props.onClose}
        title="New Password"
        onOk={this.props.handleOk}
        onCancel={this.props.handleCancel}
        isLoading={this.props.isLoading}
      >
        <Formik
          initialValues={{
            password: "",
          }}
          validate={this.validateForm}
          onSubmit={this.handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return (
              <Form>
                {
                  <>
                    <div className="formik-field_wrap">
                      <div className="formik-field-wrap">
                        Password
                        <Input
                          id="password"
                          placeholder="Password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="password"
                          name="password"
                        />
                        {errors.password && touched.password ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.password}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <Button
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Update
                    </Button>
                  </>
                }
              </Form>
            );
          }}
        </Formik>
      </ModalWrap>
    );
  }
}
