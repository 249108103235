import { Table, Button, Tooltip, Spin } from "antd";
import * as React from "react";
import { getCompanyTrn } from "../../Graphs/CompanyTrn/listCompanyTrn";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { AccountBookFilled } from "@ant-design/icons";
import { FormModal } from "./FormModal";
import { searchCompanyTransaction } from "../../Graphs/CompanyTrn/searchCompanyTrn";
import { alertError } from "../../utils/alert";

var moment = require("moment");

const BASE_IMAGE_URL =
  "https://s3.us-east-2.amazonaws.com/cerebrus-consulting/bloodman_user_media/";

class CompanyTrnRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      userPermission: {
        delete: false,
        edit: false,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedUsers = this.getLimitedUsers.bind(this);
  }

  async componentDidMount() {
    this.setState({
      data: this.props.data,
      isLoading: false,
      userSelectedData: null,
      userTrasactionData: null,
      modal1Visible: false,
    });
  }

  modalRef;
  transactionRef;
  deletePermission = false;
  editPermission = false;

  async getLimitedUsers(pagination) {
    const { searchText } = this.props;
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText != "") {
          //get search text props from redux store
          this.listSearchCompanyTrnData(start, end);
        } else {
          //call normal user Data
          this.listCompanyTrnData(start, end);
        }
      }
    );
  }

  listSearchCompanyTrnData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCompanyTrnData,
        searchText,
        setListCompanyTrnDataTotal,
        loginUserData,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let companyData = await searchCompanyTransaction(
        authToken,
        searchText,
        start,
        end,
        loginUserData.id
      );

      if (companyData.status == 200) {
        //set user Data
        await setListCompanyTrnData(companyData.data);
        await setListCompanyTrnDataTotal(companyData.total);

        this.setState({
          isLoading: false,
          data: companyData.data,
        });
      } else if (companyData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw companyData && companyData.message
          ? companyData.message
          : "Error";
      }
    } catch (e) {
      alertError("Company", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listCompanyTrnData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCompanyTrnData,
        setListCompanyTrnDataTotal,
        loginUserData,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let companyData = await getCompanyTrn(
        authToken,
        loginUserData.id,
        start,
        end
      );

      if (companyData.status == 200) {
        //set user Data
        await setListCompanyTrnData(companyData.data);
        await setListCompanyTrnDataTotal(companyData.total);

        this.setState({
          isLoading: false,
          data: companyData.data,
        });
      } else if (companyData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw companyData && companyData.message
          ? companyData.message
          : "Error";
      }
    } catch (e) {
      alertError("Company", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.add(2, "hours").format("Do MMM YYYY");

    return newDate;
  }

  showUserCoinstModal(item) {
    this.setState(
      {
        userSelectedDataForCoin: item,
        // isOpen: true
      },
      () => {
        this.modalUserCoinsRef.show();
      }
    );
  }

  handleDetailsModalclose() {
    this.setState({
      userSelectedData: null,
    });
  }

  showUserTransactions = (item) => {
    this.setState(
      {
        userTrasactionData: item,
      },
      () => {
        this.transactionRef.show();
      }
    );
  };

  showModal(item) {
    this.setState(
      {
        userSelectedData: JSON.parse(item.answer_form),
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  handleDeleteTransactionModalclose() {
    this.setState({
      userTrasactionData: null,
    });
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Customer Name",
      width: 100,
      key: "name",
      render: (text, item, index) => {
        return <div>{item.customer_name ? item.customer_name : ""}</div>;
      },
    },
    {
      title: "Transaction Id",
      width: 100,
      key: "TransactionId",
      render: (text, item, index) => {
        return (
          <div>
            {item.order_code ? "#" + item.order_code.split("_")[1] : ""}
          </div>
        );
      },
    },
    {
      title: "Voucher Code",
      key: "voucher_code",
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                  <div>{item.voucher_code ? item.voucher_code : ""}</div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Customer Phone",
      width: 100,
      key: "phone",
      render: (text, item, index) => {
        return <div>{item.customer_phone ? item.customer_phone : ""}</div>;
      },
    },
    {
      title: "Shop Name",
      width: 100,
      key: "shopName",
      render: (text, item, index) => {
        return (
          <div>
            {item.user && item.user.shop_name ? item.user.shop_name : "-"}
          </div>
        );
      },
    },
    {
      title: "Email",
      width: 100,
      key: "email",
      render: (text, item, index) => {
        return (
          <div>{item.user && item.user.email ? item.user.email : "-"}</div>
        );
      },
    },
    {
      title: "Coin Name",
      width: 80,
      key: "CoinName",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Coin Name">
              {item.coin ? item.coin.name : "-"}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Commission",
      width: 80,
      key: "commision",
      render: (text, item, index) => {
        return <div>{item.discount + " %"}</div>;
      },
    },
    {
      title: "Commission Amount (LYD)",
      width: 80,
      key: "commisionAmount",
      render: (text, item, index) => {
        return <div>{item.discount_amount}</div>;
      },
    },
    {
      title: "Actual Amount (LYD)",
      width: 80,
      key: "",
      render: (text, item, index) => {
        return <div>{item.actual_amount}</div>;
      },
    },
    {
      title: "Net Amount (LYD)",
      width: 80,
      key: "netAmount",
      render: (text, item, index) => {
        return <div>{item.amount}</div>;
      },
    },
    {
      title: "Actions",
      width: 80,
      key: "action",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            <div mr="5px" style={{ float: "left" }}>
              <Tooltip placement="bottom" title="Answers">
                <Button
                  shape="circle"
                  icon={<AccountBookFilled />}
                  onClick={() => {
                    this.showModal(item);
                  }}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedUsers(pagination)
    );
  }

  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible });
  }

  render() {
    const { isLoading } = this.state;
    const {
      listCompanyTrnData,
      listCompanyTrnDataTotal,
      searchLoader,
      authToken,
    } = this.props;

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          size={"small"}
          scroll={{ x: 1300 }}
          dataSource={listCompanyTrnData}
          pagination={{
            total: listCompanyTrnDataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />

        {this.state.userSelectedData ? (
          <FormModal
            refx={(e) => (this.modalRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            data={this.state.userSelectedData}
            primaryColor={this.state.primaryColor}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listCompanyTrnData: state.company_trn.listCompanyTrnData,
  listCompanyTrnDataTotal: state.company_trn.listCompanyTrnDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (companyData) => {
      dispatch({
        type: "SET_COMPANY_DATA",
        companyData: companyData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_COMPANY_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCompanyTrnData: (listCompanyTrnData) => {
      dispatch({
        type: "SET_LIST_COMPANY_TRN_DATA",
        listCompanyTrnData: listCompanyTrnData,
      });
    },
    setListCompanyTrnDataTotal: (listCompanyTrnDataTotal) => {
      dispatch({
        type: "SET_LIST_COMPANY_TRN_DATA_TOTAL",
        listCompanyTrnDataTotal: listCompanyTrnDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompanyTrnRecordsTable));
