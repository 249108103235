import { SET_LIST_TRANSACTION_DATA, SET_LIST_TRANSACTION_DATA_TOTAL } from "../constant/actionTypes";

const initial_state = {
    listTransactionData : null,
    listTransactionDataTotal: 0,
};

export default (state = initial_state, action) => {
    switch (action.type) {

        case SET_LIST_TRANSACTION_DATA:
            return { ...state, loading: false, listTransactionData: action.listTransactionData };

        case SET_LIST_TRANSACTION_DATA_TOTAL:
            return { ...state, loading: false, listTransactionDataTotal: action.listTransactionDataTotal };
            
        default: return { ...state };
    }
}
