import React, { Fragment, Component } from 'react';
import Title from './Title';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification } from "antd";
import { alertError } from '../../utils/alert';
import { getAllSubAdmin } from '../../Graphs/SubAdmin/listSubAdmin';
import { AddSubAdminModal } from './AddSubAdminModal';
import UserRecordsTable from './UserRecordsTable.js';
class SubAdmin extends Component {
    constructor(props){
        super(props);
        this.state = {
            subAdminList : [],
            subAdminTotal : 0,
            isLoading : false,
            primaryColor: "#4466f2"
        }
    }

    async componentDidMount() {
        try{
            const { authToken, history, setLoginFlag, setUserData, setUserToken, setListSubAdminData, setListSubAdminDataTotal, setRouteName, setSearchText } = this.props;
            let editPrimaryColor = await localStorage.getItem('primary_color');
            console.log("!!!!!!!!editPrimaryColor", editPrimaryColor);

            await setRouteName("SUBADMIN");
            await setSearchText("");

            this.setState({
                isLoading : true,
                primaryColor: editPrimaryColor
            });

            let subAdminData = await getAllSubAdmin(authToken, 0, 10, "");

            if(subAdminData.status == 200){
                //set user data
                await setListSubAdminData(subAdminData.data);
                await setListSubAdminDataTotal(subAdminData.total);

                this.setState({
                    subAdminList : subAdminData.data,
                    subAdminTotal : subAdminData.total 
                });
            }else if (subAdminData.status == 401) {
                await setLoginFlag(false);
                await setUserData(null);
                await setUserToken(null);
                history.push(`${process.env.PUBLIC_URL}/login`);
            } else {
                throw (subAdminData && subAdminData.message) ? subAdminData.message : "Error";
            }

        }catch(e){
            alertError("SubAdmin",e)
        } finally {
            this.setState({
                isLoading : false
            });
        }
    }

    reloadData = async () => {
        try{
            const { authToken, history, setLoginFlag, setUserData, setUserToken, setListSubAdminData, setListSubAdminDataTotal, setRouteName, setSearchText } = this.props;

            this.setState({
                isLoading : true
            });

            let subAdminData = await getAllSubAdmin(authToken, 0, 10,"");

            if(subAdminData.status == 200){
                //set user data
                await setListSubAdminData(subAdminData.data);
                await setListSubAdminDataTotal(subAdminData.total);

                this.setState({
                    subAdminList : subAdminData.data,
                    subAdminTotal : subAdminData.total 
                });
            }else if (subAdminData.status == 401) {
                await setLoginFlag(false);
                await setUserData(null);
                await setUserToken(null);
                history.push(`${process.env.PUBLIC_URL}/login`);
            } else {
                throw (subAdminData && subAdminData.message) ? subAdminData.message : "Error";
            }

        }catch(e){
            alertError("Sub Admin",e)
        } finally {
            this.setState({
                isLoading : false
            });
        }
    }


    generateCsv = async () => {
        try {
            const { authToken } = this.props;
            this.setState(
                {
                    isLoading: true
                },
                () => {
                    getAllSubAdmin(authToken, 0, 1000000,"")
                    .then((res) => {
                        let csvArray = [];
            
                        // csvArray.push();
            
                        // res.data.map((data) => {
                        //     csvArray.push([
                        //         data.first_name ? data.first_name : "NA",
                        //         data.email ? data.email : "NA",
                        //         data.phone ? data.phone : "NA",
                        //         data.username ? data.username : "NA",
                        //         data.wallet_balance ? String(data.wallet_balance) : "NA",
                        //     ]);
                        // });
            
            
                        const rows = res.data;
                        let csvName = "AllUsers.csv";
                        let csvContent = "data:text/csv;charset=utf-8,";
            
                        rows.forEach(function(rowArray) {
                            let row = rowArray.join(",");
                            csvContent += row + "\r\n";
                        });
            
                        var encodedUri = encodeURI(csvContent);
                        // // // // window.open(encodedUri);
                        var link = document.createElement("a");
                        link.setAttribute("href", encodedUri);
                        link.setAttribute("download", csvName);
                        document.body.appendChild(link); // Required for FF
            
                        link.click();
            
                        this.setState({
                            isLoading: false
                        });
                    })
                    .catch((err) => {
                        console.log("ERROR", err);
                        throw err;
                    });
                }
            );
        } catch (e) {
          console.log("error in generateCsv function", e);
        }
    }

    render() {
        const {authToken, loginUserData} = this.props;
        const  {  primaryColor, subAdminList, isLoading, subAdminTotal } = this.state;
        
        const accessPermission = loginUserData.access_permission && JSON.parse(loginUserData.access_permission) || false;

        return (
            (loginUserData.role !== "Admin" && (!accessPermission || !accessPermission["subAdmin"] || (accessPermission["subAdmin"].view == false)))
            ?   

                <Fragment>
                   
                    <h5 style={{ marginTop: "30%",  marginLeft: "30%" }}>
                        You are not authorized for view this page.
                    </h5>
                </Fragment>
            :
                    <Fragment>
                        <Title parent="Sub Admin" title="sub admin" />
                        <div style={{ marginTop : "20px", display : "inline-block", width : "100%", marginBottom : "20px", paddingLeft : "14px", paddingRight: "55px"}}>
                            {/* <div onClick={() => this.generateCsv()} style={{float : "left", cursor : "pointer"}}>
                                <div style={{width : "100px", backgroundColor : primaryColor, color : "white", padding : "7px 0px", textAlign : "center", borderRadius : "50px"}}>
                                    Export Csv
                                </div>
                            </div> */}
                            <div style={{float : "right", cursor : "pointer", width : "100%"}}>
                            {
                                // (loginUserData.role == "Admin") || (accessPermission && accessPermission["employee"] && accessPermission["employee"].edit)
                                    // ?
                                        <AddSubAdminModal authToken={authToken} reloadData={this.reloadData} primaryColor={this.state.primaryColor}  />
                                    // : null
                                }
                               
                            </div>
                        </div>
                        <div className="container-fluid">
                            {
                                isLoading
                                ?
                                <div style={{ marginLeft: "20px" }}>
                                    <Spin
                                    size="large"
                                    style={{ marginLeft: "480px", marginTop: "130px" }}
                                    />
                                </div> 
                                :
                                <div>
                                    <UserRecordsTable
                                        accessPermission={accessPermission}
                                        loginUserData={loginUserData}
                                        data={subAdminList}
                                        primaryColor={primaryColor}
                                        subAdminTotal={subAdminTotal}
                                    />
                                </div>
                            }

                            <AddSubAdminModal
                                admin={false}
                                authToken={this.props.authToken}
                                refx={(e) => (this.modalUserCoinsRef = e)}
                                onClose=""//{this.handleDetailsModalclose.bind(this)}
                                onSubmit=""//{this.editSubmit}
                            />
                        </div>
                    </Fragment>
                
        );
    }
}

const mapStateToProps = state => ({
    authToken: state.auth.authToken,
    loginUserData: state.auth.loginUserData
});
  
const mapDispatchToProps = (dispatch) => {
    return {
        setLoginFlag: (flag) => {
            dispatch({
                type: 'SET_LOGIN_FLAG',
                flag: flag
            });
        },
        setUserData: (subAdminData) => {
            dispatch({
                type: 'SET_SUB_ADMIN_DATA',
                subAdminData: subAdminData
            });
        },
        setUserToken: (authToken) => {
            dispatch({
                type: 'SET_USER_AUTHTOKEN',
                authToken: authToken
            });
        },
        setListSubAdminData: (listSubAdminData) => {
            dispatch({
                type : "SET_LIST_SUB_ADMIN_DATA",
                listSubAdminData : listSubAdminData
            })
        },
        setListSubAdminDataTotal: (listSubAdminDataTotal) => {
            dispatch({
                type : "SET_LIST_SUB_ADMIN_DATA_TOTAL",
                listSubAdminDataTotal : listSubAdminDataTotal
            })
        },
        setRouteName: (routeName) => {
            dispatch({
                type : "SET_ROUTE_NAME",
                routeName : routeName
            })
        },
        setSearchText: (searchText) => {
            dispatch({
                type : "SET_SEARCH_TEXT",
                searchText : searchText
            })
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SubAdmin));