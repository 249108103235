import { callGetApiToServer } from "../callApi";

export async function deleteRedeemCard(authtoken, searchText, city) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };
    let endUrl = `v1/auth/delete-card-inventory/${searchText}`;
    if (city) {
      endUrl += `?city=${city}`;
    }

    console.log("endUrl printed here", endUrl);

    let responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
