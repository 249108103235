import { ENDPOINT } from '../../network/ENDPOINT';
import { callApiToServer, putApiToServer } from '../callApi';

export async function editInventoryAPI(
        authtoken,   
        payload,
        id
){
    try {
        console.log("pay",payload,id)
        let header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "authorization": "Berear " + authtoken
        };

        let body = JSON.stringify(payload);

        let endUrl = `${ENDPOINT.edit_inventory}/${id}`;

        let responseData = await putApiToServer(body, header, "PUT", endUrl);

        return responseData;

    } catch (err) {
        throw err;
    }
}
