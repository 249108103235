import { Table, Tooltip, Select, Input, Button, Modal } from "antd";
import * as React from "react";
import { get } from "lodash";
import { searchAllCompanyTransaction } from "../../Graphs/CompanyTrn/searchAllCompanyTrn";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import _ from "lodash";
import { alertError } from "../../utils/alert";
var moment = require("moment");
const { Option } = Select;

class SearchTransactionRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      coinList: [],
      dataTotal: 0,
      isLoading: false,
      startIndex: 0,
      limitNumber: 5,
      pagination: {
        pageSize: 5,
        current: 1,
      },
      errors: {},
      price: 0,
      cardData: [],
      transactionData: [],
      transactionDataTotal: [],
      searchText: "",
      company_id: null,
      visible: false,
      closable: true,
    };
    this.dateFormat = this.dateFormat.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }

    console.log("[CONSOLE LOG]", this.props.userData);

    this.listTransactionData(0, 5);
  }

  getLimitedTransaction = async (pagination) => {
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        this.listTransactionData(start, end);
      }
    );
  };

  listTransactionData = async (start, end) => {
    try {
      const {
        userData,
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
      } = this.props;
      const { searchText } = this.state;
      this.setState({
        isLoading: true,
      });

      let companyTrnData = await searchAllCompanyTransaction(
        authToken,
        searchText,
        start,
        end
      );
      console.log("!!!!!!!!!!!!companyTrnData", companyTrnData);

      if (companyTrnData.status == 200) {
        this.setState({
          isLoading: false,
          transactionData: companyTrnData.data,
          transactionDataTotal: companyTrnData.total,
        });
      } else if (companyTrnData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw companyTrnData && companyTrnData.message
          ? companyTrnData.message
          : "Error";
      }
    } catch (e) {
      alertError("Company Transaction", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedTransaction(pagination)
    );
  }

  dateFormat(date) {
    var oldDate = new Date(date),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.add(2, "hours").format("Do MMM YYYY");
    return newDate;
  }

  onChangeCoin = (value) => {
    this.setState({
      coin: value,
    });
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show() {
    this.setState({
      visible: true,
    });
  }

  hide() {
    this.setState({
      visible: false,
    });
    this.props.onClose();
  }

  onSearchTextChange = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Customer Name",
      width: 100,
      key: "name",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="customer name">
              {item.customer_name ? item.customer_name : ""}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Transaction Id",
      width: 100,
      key: "TransactionId",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Transaction Id">
              {item.order_code ? "#" + item.order_code.split("_")[1] : ""}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Customer Phone",
      width: 100,
      key: "phone",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="customer phone">
              {item.customer_phone ? item.customer_phone : ""}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Shop Name",
      width: 100,
      key: "shopName",
      render: (text, item, index) => {
        return (
          <div>
            {item.user && item.user.shop_name ? (
              <Tooltip placement="bottom" title="First Name">
                {item.user.shop_name}
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Email",
      width: 100,
      key: "email",
      render: (text, item, index) => {
        return (
          <div>
            {item.user && item.user.email ? (
              <Tooltip placement="bottom" title="Email">
                {item.user.email}
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Coin Name",
      width: 80,
      key: "CoinName",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Coin Name">
              {item.coin ? item.coin.name : "-"}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Commission",
      width: 80,
      key: "commision",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Commission">
              {item.discount + " %"}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Commission Amount",
      width: 80,
      key: "commisionAmount",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="actual amount">
              {item.discount_amount}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Actual Amount (LYD)",
      width: 80,
      key: "",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="actual amount">
              {item.actual_amount}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Net Amount (LYD)",
      width: 80,
      key: "netAmount",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="net amount">
              {item.amount}
            </Tooltip>
          </div>
        );
      },
    },
  ];

  render() {
    const { isLoading, searchText, transactionData, transactionDataTotal } =
      this.state;

    return (
      <React.Fragment>
        <div
          style={{
            marginTop: "5px",
            marginBottom: "15px",
            display: "inline-block",
            width: "100%",
          }}
        >
          <div style={{ float: "left" }}>
            <Input
              onChange={(e) => this.onSearchTextChange(e)}
              value={searchText}
              width={200}
            />
          </div>
          <div style={{ float: "left" }}>
            <Button
              onClick={() =>
                this.listTransactionData(
                  this.state.startIndex,
                  this.state.limitNumber
                )
              }
            >
              {" "}
              GET{" "}
            </Button>
          </div>
        </div>
        <div style={{ "overflow-x": "auto" }}>
          <Table
            loading={this.state.isLoading}
            rowKey={(record) => record._id}
            columns={this.columns}
            size={"small"}
            scroll={{ x: 900 }}
            // dataSource={this.state.data}
            dataSource={transactionData}
            pagination={{
              total: transactionDataTotal,
              showSizeChanger: true,
              pageSize: this.state.pagination.pageSize,
              current: this.state.pagination.current,
              pageSizeOptions: ["1", "10", "25", "50", "100"],
              onChange: (e) => {
                console.log("onChanges pagination");
                // e - 1
                //   ? (this.counter = this.tempCounter =
                //       (e - 1) * this.state.pagination.pageSize)
                //   : ((this.counter = 0), (this.tempCounter = 0));
                if (e - 1) {
                  this.counter = this.tempCounter =
                    (e - 1) * this.state.pagination.pageSize;
                  return;
                }

                this.counter = 0;
                this.tempCounter = 0;
              },
            }}
            onChange={this.handleTableChange}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listCompanyTrnDataData: state.company_trn.listCompanyTrnData,
  listCompanyTrnDataTotal: state.company_trn.listCompanyTrnDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      }); 
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SearchTransactionRecordsTable));
