import * as React from "react";
import { Modal, Spin, notification, List } from "antd";

export class FormModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
      coinsList: [],
      parse_data: [],
      isShouldComponentUpdate: false,
    };
  }

  componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }

    try {
      this.setState({
        isLoading: true,
      });

      let data = this.props.data.answer_form;
      console.log("data: ", data);

      let parseData = JSON.parse(data);
      this.setState({
        parse_data: parseData,
        isLoading: false,
      });
    } catch (e) {
      this.setState({
        isLoading: false,
      });
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //     const { isShouldComponentUpdate } = nextState;

  //     if(!isShouldComponentUpdate){
  //         this.selectedValidityType = "";
  //     }

  //     return true;
  // }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show = () => {
    this.setState({
      visible: true,
    });
  };

  hide() {
    this.setState({
      visible: false,
    });
    //this.props.onClose();
  }

  render() {
    const { primaryColor, data } = this.props;
    const { parse_data, isLoading } = this.state;
    return (
      <React.Fragment>
        {isLoading ? (
          <div style={{ marginLeft: "20px" }}>
            <Spin
              size="large"
              style={{ marginLeft: "480px", marginTop: "130px" }}
            />
          </div>
        ) : (
          <Modal
            width="600px"
            closable={this.state.closable}
            open={this.state.visible}
            title="Q / A"
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={null}
            destroyOnClose={true}
          >
            {Array.isArray(data) && (
              <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item, i) => (
                  <List.Item key={"__" + i}>
                    <List.Item.Meta
                      key={"__" + i}
                      title={<b>{item.question}</b>}
                      description={item.answer}
                    />
                  </List.Item>
                )}
              />
            )}
          </Modal>
        )}
      </React.Fragment>
    );
  }
}
