import { callApiToServer } from '../../callApi';

export async function addCountry(
  authtoken,
  countryObject,
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    };
      
    let body = JSON.stringify({
        name: countryObject.name,
        code: countryObject.code,
        isd_code : countryObject.isd_code
    });
  
    let endUrl = "v1/auth/add-country";
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callApiToServer(body, header, "POST", endUrl);
      
    return responseData;
      
  }catch(err) {
    throw err;
  }
}
