import { SET_LIST_USER_COIN_DATA, SET_LIST_USER_COIN_DATA_TOTAL } from "../constant/actionTypes";

const initial_state = {
    listUserCoinData : null,
    listUserCoinDataTotal: 0,
};

export default (state = initial_state, action) => {
    switch (action.type) {

        case SET_LIST_USER_COIN_DATA:
            return { ...state, loading: false, listUserCoinData: action.listUserCoinData };

        case SET_LIST_USER_COIN_DATA_TOTAL:
            return { ...state, loading: false, listUserCoinDataTotal: action.listUserCoinDataTotal };
            
        default: return { ...state };
    }
}
