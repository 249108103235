import { Modal, Spin } from "antd";
import React, { Component } from "react";

class ModalWrap extends Component {
  render() {
    return (
      <Modal
        width={this.props.width ? this.props.width : "600px"}
        closable={this.props.closable}
        open={this.props.visible}
        title={this.props.title}
        onOk={this.props.handleOk}
        onCancel={() => this.props.onCancel()}
        footer={null}
        destroyOnClose={true}
      >
        {this.props.isLoading ? (
          <div style={{ justifyContent: "center" }}>
            <Spin />
          </div>
        ) : (
          this.props.children
        )}
      </Modal>
    );
  }
}

export default ModalWrap;
