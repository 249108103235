import * as React from "react";
import { Modal, Spin, notification, Input, Button } from "antd";

import { addCompany } from "../../Graphs/Company/addCompany";
import { Formik } from "formik";
import { addBalanceIntoUserAccount } from "../../Graphs/balanceRequest/addBalanceIntoUserAccount";
import { alertError } from "../../utils/alert";

var amenities = [];

export class AddBalanceIntoUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
      coinsList: [],
      errors: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  async componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show = () => {
    this.setState({
      visible: true,
    });
  };

  hide() {
    this.setState({
      visible: false,
    });
    this.props.onClose();
  }

  addSubmit = async (value) => {
    try {
      const { authToken, request, updateRequestData } = this.props;
      console.log("all props", this.props);

      this.setState({
        isLoading: true,
      });
      console.log("test add submit");
      let body = JSON.stringify({
        request_balance: parseInt(value.balanceAmount),
      });

      let amount = await addBalanceIntoUserAccount(authToken, request.id, body);
      if (amount.status == 200) {
        this.setState({
          isLoading: false,
          visible: false,
        });
        updateRequestData();
        notification["success"]({
          message: amount.message,
        });
      } else {
        throw amount.message ? amount.message : "Error";
      }
    } catch (error) {
      alertError("BALANCE REQUEST", error);
      this.setState({
        isLoading: false,
      });
    }
  };

  async handleSubmit(values, action) {
    const { errors } = this.state;

    this.setState({
      errors: {},
    });

    let result = await this.addSubmit(values);

    if (result) {
      this.props.handleCancel();
      this.setState({
        visible: false,
      });
    }
  }

  render() {
    const { request } = this.props;
    return (
      <React.Fragment>
        <Modal
          width="600px"
          closable={this.state.closable}
          visible={this.state.visible}
          title={"Requested balance by " + request.user.first_name}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          destroyOnClose={true}
        >
          {this.state.isLoading ? (
            <div style={{ justifyContent: "center" }}>
              <Spin />
            </div>
          ) : (
            <Formik
              initialValues={{
                balanceAmount: request.request_balance
                  ? request.request_balance
                  : 0,
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      Request Balance
                      <Input
                        id="balance_amount"
                        name="balanceAmount"
                        placeholder=" Balance"
                        value={values.balanceAmount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.balanceAmount && touched.balanceAmount ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.balanceAmount}
                        </p>
                      ) : null}
                    </div>

                    <Button
                      // type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                );
              }}
            </Formik>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}
