import { Button, DatePicker, Select, Tooltip } from "antd";
import React, { Component } from "react";
import { getActivityLog } from "../../Graphs/tracker/ActivityLog";
import { alertError } from "../../utils/alert";
import TableWrap from "../common/Table";
import moment from "moment";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { tracker } from "../../utils/track";
import { getUserByRole } from "../../Graphs/tracker/getUserByRole";
import { dateFormat, timeFormat } from "../../utils/general";

const { RangePicker } = DatePicker;

class EmployeeTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      primaryColor: "#4466f2",
      activityList: [],
      totalActivity: 0,
      employeeUserOptions: [],
      startDate: "",
      endDate: "",
      selectedUser: "",
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidMount() {
    let page = 0;
    let limit = 10;
    this.getTracker(page, limit);
    this.getEmployeeUserDataList();
  }

  onChangeDate = (date) => {
    if (!date) {
      this.setState({
        startDate: "",
        endDate: "",
      });
    } else {
      this.setState({
        startDate: date?.[0]?.format("YYYY-MM-DD"),
        endDate: date?.[1]?.format("YYYY-MM-DD"),
      });
    }
  };

  onFilter = () => {
    this.getTracker(0, 10);
  };

  getTracker = async (page, limit) => {
    try {
      const { loginUserData, history } = this.props;

      const payload = {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        ...(this.state.selectedUser && { id: this.state.selectedUser }),
      };
      let activityData = await getActivityLog(
        loginUserData.authToken,
        "Employee",
        payload,
        page,
        limit
      );

      this.setState({
        isLoading: true,
      });
      if (activityData.status == 200) {
        this.setState({
          activityList: activityData.data,
          totalActivity: activityData.total,
          isLoading: false,
        });
      } else {
        throw activityData && activityData.message
          ? activityData.message
          : "Error";
      }
    } catch (e) {
      alertError("ACTIVITY", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  getEmployeeUserDataList = async () => {
    try {
      const { authToken } = this.props;

      let employeeData = await getUserByRole(authToken, "Employee");

      if (employeeData.status == 200) {
        let employee = employeeData.data.map((item) => {
          return {
            value: item.first_name + " " + item.last_name,
            label: item.first_name + " " + item.last_name,
            id: item.id,
          };
        });
        this.setState({
          employeeUserOptions: [
            { value: "", label: "All", id: "" },
            ...employee,
          ],
        });
      } else {
        throw employeeData && employeeData.message
          ? employeeData.message
          : "Error";
      }
    } catch (e) {
      alertError("ACTIVITY", e);
    }
  };

  handleChangeDistributor = (value) => {
    let id = this.state.employeeUserOptions.find((i) => i.value == value).id;
    this.setState({ selectedUser: id });
  };

  handleTableChange(pagination, filters) {
    this.setState(
      {
        pagination,
      },
      () => {
        const page =
          pagination.current * pagination.pageSize - pagination.pageSize;
        const limit = pagination.pageSize;

        this.setState(
          {
            isLoading: true,
            startIndex: page,
            limitNumber: limit,
          },
          async () => {
            this.getTracker(page, limit);
          }
        );
      }
    );
  }

  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "name",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                  <div>
                    {item?.admin
                      ? item?.admin?.first_name + " " + item?.admin?.last_name
                      : "-"}
                  </div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Email",
      key: "email",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>{item?.admin ? item?.admin?.email : "-"}</span>
          </div>
        );
      },
    },
    {
      title: "Phone Number",
      key: "phone",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>{item?.admin ? item?.admin?.phone : "-"}</span>
          </div>
        );
      },
    },
    {
      title: "Date",
      key: "track api",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>
              {" "}
              {item.createdAt !== null ? dateFormat(item.createdAt) : "-"}
            </span>
          </div>
        );
      },
    },
    {
      title: "Time",
      key: "track api",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>
              {" "}
              {item.createdAt !== null ? timeFormat(item.createdAt) : "-"}
            </span>
          </div>
        );
      },
    },

    {
      title: "Track API",
      key: "track api",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>
              {item?.card
                ? tracker?.[item?.end_point] + " of " + item.card.name
                : item
                ? tracker?.[item?.end_point]
                : "-"}
            </span>
          </div>
        );
      },
    },
  ];

  render() {
    return (
      <>
        <div style={{ display: "flex", gap: "20px", justifyContent: "start" }}>
          <Select
            showSearch
            defaultValue="All"
            style={{
              width: " 150px",
            }}
            onChange={this.handleChangeDistributor}
            options={this.state.employeeUserOptions}
          />
          <div>
          <RangePicker
            format="DD-MM-YYYY"
            onChange={this.onChangeDate}
            disabledDate={(current) => {
              return moment().add(0, "days") <= current;
            }}
            style={{ height: "32px" }}
          />

          <Button
            style={{
              marginTop: "0px",
              background: "#5C6AC4",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={this.onFilter}
          >
            Filter
          </Button>
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <TableWrap
            data={this.state.activityList}
            columns={this.columns}
            isLoading={this.state.isLoading}
            total={
              this.state.totalActivity < 11 ? 11 : this.state.totalActivity
            }
            pagination={this.state.pagination}
            handleTableChange={this.handleTableChange}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EmployeeTracker));
