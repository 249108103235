import * as React from "react";
import { Modal, Spin } from "antd";
import { getCompanyWalletBalance } from "../../Graphs/Company/listCompany";
import { notification } from "antd";
import { Link } from "react-router-dom";
import { Users, List, Gift, Settings, CreditCard, Flag } from "react-feather";
// import Breadcrumb from '../../common/breadcrumb';
import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isNull } from "lodash";

export class WalletModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
    };
  }

  async componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }

    let user = this.props.data;
    this.setState({
      isLoading: true,
    });

    try {
      let walletData = await getCompanyWalletBalance(
        this.props.authToken,
        user.id
      );
      if (walletData.status == 200) {
        this.setState({
          wallet: walletData.data,
        });
      }
    } catch (exx) {
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show() {
    this.setState({
      visible: true,
    });
  }

  hide() {
    this.setState({
      visible: false,
    });
    this.props.onClose();
  }

  render() {
    const { wallet } = this.state;
    return (
      // <StyleBox>
      <Modal
        width="600px"
        closable={this.state.closable}
        open={this.state.visible}
        title="Coin Wallet Balance"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        {this.state.isLoading ? (
          <div style={{ justifyContent: "center" }}>
            <center>
              <Spin />
            </center>
          </div>
        ) : (
          <>
            {wallet &&
              wallet.map((i) => {
                return (
                  <div className="col-sm-6 col-xl-6 col-lg-6">
                    <Link>
                      <div className="card o-hidden">
                        <div className="bg-primary b-r-4 card-body">
                          <div className="media static-top-widget">
                            <div className="align-self-center text-center">
                              <Flag />
                            </div>
                            <div className="media-body">
                              <span className="m-0">
                                {i.coin_name ? i.coin_name : "-"}
                              </span>
                              <h4 className="mb-0 counter">
                                {i.total_wallet_balance}
                                {/* <CountUp className="counter" end={i.total_wallet_balance} /> */}
                              </h4>
                              <Flag className="icon-bg" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}

            {wallet && wallet.length == 0 ? (
              <center>No Coins Found</center>
            ) : null}
          </>
        )}
      </Modal>
    );
  }
}
